import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import UploadIcon from '../../Assets/img/icon-upload.svg';
import toast from 'react-hot-toast';
import { isValidInteger } from '../Utils';

const UploadKMLModal = (props) => {
    const [rouWidth, setRouWidth] = useState("");
    const [coaWidth, setCoaWidth] = useState("");
    const [file, setFile] = useState("");

    const submit = () => {
        if(!isValidInteger(rouWidth)) {
            toast.error("Please enter valid integer for ROU Width");
            return;
        }
        if(!isValidInteger(coaWidth)) {
            toast.error("Please enter valid integer for COA Width");
            return;
        }
        if(parseInt(coaWidth) < parseInt(rouWidth)) {
            toast.error("COA Width should be greater than or equal to ROU");
            return;
        }
        if(file == "") {
            toast.error("Please upload a KML/SHP file");
            return;
        }

        toast.success("KML has been uploaded successfully");
        props.show(false);
    };

    const handleFileChange = (event) => {
        if (event.target.files[0]) {
            setFile(event.target.files[0]);
        } else {
            setFile(null);
        }
    };

    return(
        <>
            <Modal
                show={() => props.show(true)}
                onHide={() => props.show(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-600 send-report-modal-dialog"
                className="upload-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Upload KML/SHP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="form-group">
                        <label htmlFor="" className="form-label">KML/SHP</label>
                        <div className="drag-area">
                            <div className="icon">
                                <img src={UploadIcon} alt="" />
                            </div>
                            <h4>Drag &amp; Drop to Upload File</h4>
                            <span>OR</span>
                            <button>Choose files</button>
                            <input type="file" hidden="" onChange={handleFileChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">

                            <div className="form-group">
                                <label className="form-label" for="ROUWidth">ROU Width</label>
                                <input type="text" className="form-control" placeholder="Enter ROU Width" value={rouWidth} onChange={(e) => setRouWidth(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" for="COAWidth">COA Width</label>
                                <input type="text" className="form-control" placeholder="Enter COA Width" value={coaWidth} onChange={(e) => setCoaWidth(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="footer-content">
                        <div className="custom-check">
                            <label htmlFor="doNotShow">
                                <input type="checkbox" name="doNotShow" id="doNotShow" />
                                <span> Do not show this again</span>
                            </label>
                        </div>
                    </div>
                    <div className="btn-area">
                        <a onClick={() => props.show(false)} className="btn btn-text">Cancel</a>
                        <button type="button" className="btn btn-primary" aria-label="Submit" onClick={submit}>Submit</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UploadKMLModal;