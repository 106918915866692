import React, {useContext, useEffect, useState} from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { adminService } from '../../Services/adminService';
import ConfigurationEdit from './ConfigurationEdit';
import ViewLogo from '../../Assets/img/icon-view-grey.svg';
import ShowJson from './ShowJson';

const Configuration = () => {
    const [configs, setConfigs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const {user} = useContext(UserContext);
    const [displayJson, setDisplayJson] = useState(false);
    const [json, setJson] = useState({
        title: "",
        name: "",
        value: null
    });

    useEffect(() => {
        refreshConfigs();
    }, []);

    const refreshConfigs = () => {
        adminService.getConfig(user.originalRegion).then((data) => {
            setConfigs(data);
        }).catch((e) => {
            toast.error("Unable to retrieve configurations. Error: " + e);
        });
    };

    const showJsonValue = (title, name, value) => {
        setJson({
            title: title,
            name: name,
            value: value
        });
        setDisplayJson(true);
    };

    const getDefaultValue = (value) => {
        if (typeof value === 'object' && value !== null) {
            return JSON.stringify(value, null, 2);
        }
        return String(value);
    };

    const renderRow = (config) => {
        return <tr key={config.name}>
                    <td><img src={ViewLogo} onClick={() => showJsonValue("Value Definition", config.name, config.value_definition)}/> </td>
                    <td>{config.name}</td>
                    <td>{config.description}</td>
                    <td>{getDefaultValue(config.default_value)}</td>
                    <td>{config.type}</td>
                    <td>{config.config != null ? <a className="test" onClick={() => showJsonValue("Configuration", config.name, config.config)}>Config</a> : <span></span>}</td>
                </tr>;
    }

    return (
        <div className="form-content">
            <div className="form-head">
                <h3>Configuration</h3>
                {
                    user.isSuperAdmin &&
                    <a className="add-link" onClick={() => setShowModal(true)}>+ Add a Configuration</a>
                }
            </div>
            <div className="admin-table-responsive">
                <table className="table table-style">
                    <thead>
                        <tr>
                            <th scope="col">&nbsp;</th>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            <th scope="col">Default Value</th>
                            <th scope="col">Type</th>
                            <th scope="col">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            configs && 
                            configs.map((config) => renderRow(config))
                        }
                    </tbody>
                </table>
            </div>
            {
                showModal && <ConfigurationEdit setShowModal={setShowModal} refreshConfigs={refreshConfigs}/>
            }
            {
                displayJson && <ShowJson setShowModal={setDisplayJson} title={json.title + " - " + json.name} json={json.value} />
            }
        </div>
    );
}

export default Configuration;