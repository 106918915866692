import React, { useState, useContext, useEffect} from 'react';
import { UserContext } from "../../Contexts/UserContext";
import { adminService } from '../../Services/adminService';
import { memberService } from "../../Services/memberService";
import { getUserPreference } from '../Utils';
import { SketchPicker } from 'react-color';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { userService } from '../../Services/userService';

const SettingsEdit = (props) => {
    const {user, setUser, showAlert, impersonatedUserList, setImpersonatedUserList} = useContext(UserContext);
    const [values, setValues] = useState({});
    const [allSettings, setAllSettings] = useState([]);
    const [settingTypes, setSettingTypes] = useState({});
    const [gailSettings, setGailSettings] = useState(user.userSettings?.dashboard ?? 
        {
            aoiBorderColor: "#073091",
            aoi50mBorderColor: "#f6dc07",
            pipelineColor: "#fa0523",
            lineThickness: 3,
            isDotted: true,
            is50MDotted: false,
            thickness: [5, 11, 16, 22],
            zoomLevel: 18,
            pipelineSettings: {
                style: 'dotted',
                firstColor: '#f9071b',
                secondColor: '#d10808'
            },
            rou50mSettings: {
                style: 'straightBorder',
                firstColor: '#f8e71c',
                secondColor: '#0f185c'
            },
            mapSettings : {
                "landscape": "#faf7d2",
                "water": "#94b4ba"
            },
            landPipelineSettings: {
                style: 'dotted',
                firstColor: '#d0021b',
                secondColor: '#ff0000'
            },
            landRouSettings: {
                style: 'straightBorder',
                firstColor: '#f8e71c',
                secondColor: '#000000'
            },
            deformationLineThickness: 3
        }
    );
    const [marks, setMarks] = useState({});

    const setMarksFromArray = (e) => {
        let customMarks = {};
        for(var i in e) {
            customMarks[e[i]] = e[i];
        }
        setMarks(customMarks);
    }

    const handleInputChange = (event) => {
        if(props.isReadOnly == true)
            return;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value; 
        let name = event.target.name; 
        setValues({
          ...values,
          [name]: value
        });
    };

    const reset = () => {
        let initialValues = {};
        allSettings.map((setting) => {
            initialValues[setting.name] = props.configuration.find(c => c.name == setting.name)?.value;
        });
        setValues(initialValues);
    }

    const saveSettings = () => {
        // var settings = [];
        // for (const property in values) {
        //     if(property == "GailSettings")
        //         continue;
        //     let value = values[property];
        //     if(settingTypes[property] == "integer") {
        //         value = parseInt(value);
        //     }
        //     if(value != values[property]) {
        //         showAlert("Please enter a valid integer for integer fields", "fail");
        //         return;
        //     }
        //     settings.push({name: property, value: value});
        // }
        // settings.push({name: "GailSettings", value: gailSettings});

        userService.updateSettings({dashboard: gailSettings}).then((data) => {
            showAlert("Settings have been updated.", "success");
            setUser({...user, userSettings: {...user.userSettings, dashboard: gailSettings}});
        }).catch((error) => {
            console.log(error);
            showAlert("There was an error in updating the settings", "fail");
        });
    };

    const updateUserSettingsInImpersonatedUserList = (mkey, configuration) => {
        if (!!mkey) {
            let userList= impersonatedUserList;
            let userData = userList?.find(user => user.mkey == mkey);
            if (!!userData) {
                userData.configuration = configuration;
            }
            setImpersonatedUserList(userList);
            sessionStorage.setItem("impersonatedUserList", JSON.stringify(userList));
        }
    };

    const renderColor = (color, description, action) => {
        return <div className="settings-color-block">
                    <SketchPicker color={color} onChangeComplete={(color) => setGailSettings(action(color))} />
                    <div>{description}</div>
                </div>;
    };

    const renderColorAoi = (name, description) => {
        return renderColor(gailSettings[name], description, (color) => ({...gailSettings, [name]: color.hex}));
    };

    const renderColorLine = (line, name, description) => {
        return renderColor(gailSettings[line][name], description, (color) => ({...gailSettings, [line]: {...gailSettings[line], [name]: color.hex}}));
    };

    const renderColorMap = (area, description) => {
        return renderColor(gailSettings.mapSettings[area], description, (color) => ({...gailSettings, mapSettings: {...gailSettings.mapSettings, [area]: color.hex}}));
    };

    const handleSliderChange = (e) => {
        setGailSettings((s) => {return {...s, thickness: e}; });
        setMarksFromArray(e);
    };

    const changeSettingStyle = (name, value) => {
        setGailSettings({...gailSettings, [name]: {...gailSettings[name], style: value}});
    };

    const renderColorSettings = (header, name, firstLabel, secondLabel) => {
        return <div>
            <div className="settings-select">
                <span className="label">{header}</span>
                <div className="margin-10px">
                    {
                        <select id="pipeline" name="pipeline" onChange={(e) => changeSettingStyle(name, e.target.value)} className="form-select" value={gailSettings[name]?.style}>
                            <option value="straight">Straight Line - No Border</option>
                            <option value="straightBorder">Straight Line - Border</option>
                            <option value="dotted">Dotted - No Border/Single Color</option>
                            <option value="twoDotted">Dotted - Two Colors</option>
                            <option value="dottedBorder">Dotted - Border</option>
                        </select>
                    }
                </div>
            </div>
            <div className="settings-color-container">
            {
                renderColorLine(name, "firstColor", firstLabel)
            }
            {
                renderColorLine(name, "secondColor", secondLabel)
            }
            </div>
        </div>
    };
 
    const renderGailSettings = () => {
        return <>
                {
                    renderColorSettings("Pipeline Setting", "pipelineSettings", "Pipeline Color", "Pipeline Border Color")
                }
                {
                    renderColorSettings("ROU 50M Setting", "rou50mSettings", "ROU Color", "ROU Border Color")
                }
                <div className="settings-color-container">
                {
                    renderColorAoi("aoiBorderColor", "AOI Border Color")
                }
                {
                    renderColorMap("landscape", "Landscape")
                }
                {
                    renderColorMap("water", "Water")
                }
                </div>
                {
                    renderColorSettings("Deformation Pipeline Setting", "landPipelineSettings", "Pipeline Color", "Pipeline Border Color")
                }
                {
                    renderColorSettings("Deformation ROU Setting", "landRouSettings", "ROU Color", "ROU Border Color")
                }
                    <div className="toggle-switch" key="defaultZoomlevel">
                        <span className="label">Default Zoom Level for Map Move</span>
                        <Slider min={1}
                            max={22}
                            step={1}
                            count={1}
                            defaultValue={gailSettings.zoomLevel}
                            key={gailSettings.zoomLevel}
                            pushable
                            marks={{[gailSettings.zoomLevel]: [gailSettings.zoomLevel]}}
                            // trackStyle={[{ backgroundColor: 'red' }, { backgroundColor: 'green' }]}
                            // handleStyle={[{ backgroundColor: 'yellow' }, { backgroundColor: 'gray' }]}
                            // railStyle={{ backgroundColor: 'black' }}
                            onChangeComplete={(e) => setGailSettings((s) => ({...s, zoomLevel: e}))}
                        />
                    </div>
                    <div className="toggle-switch" key="zoomlevel">
                        <span className="label">Adjustable line thickness per zoom level</span>
                        <Slider range
                            min={-2}
                            max={26}
                            count={3}
                            step={1}
                            defaultValue={gailSettings.thickness}
                            key={gailSettings.thickness[0] * 1000 + gailSettings.thickness[1]*100 + gailSettings.thickness[2]*10 + gailSettings.thickness}
                            pushable
                            styles={[{ backgroundColor: 'red' }, { backgroundColor: 'green' }, { backgroundColor: 'yellow' }, { backgroundColor: 'blue' }]}
                            // trackStyle={[{ backgroundColor: 'red' }, { backgroundColor: 'green' }]}
                            // handleStyle={[{ backgroundColor: 'yellow' }, { backgroundColor: 'gray' }]}
                            // railStyle={{ backgroundColor: 'black' }}
                            marks={marks}
                            onChangeComplete={handleSliderChange}
                        />
                    </div>
                    <div className="toggle-switch" key="lineThickness">
                        <span className="label">Line Thickness for Land Deformation/Leakage</span>
                        <Slider min={1}
                            max={20}
                            step={1}
                            count={1}
                            defaultValue={gailSettings.deformationLineThickness}
                            key={gailSettings.deformationLineThickness}
                            pushable
                            marks={{[gailSettings.deformationLineThickness]: [gailSettings.deformationLineThickness]}}
                            onChangeComplete={(e) => setGailSettings((s) => ({...s, deformationLineThickness: e}))}
                        />
                    </div>
                    <div>
                        <br />
                    </div>
                </>;
    };

    const renderSetting = (setting) => {
        if(setting?.config?.appui_settings_visibility === false)
            return null;
        if(setting?.value_definition?.type == "string_choices") {
            if(setting?.name == "AOIBorderColour" || setting?.name == "AOIInnerBorderColour1" || setting?.name == "PipelineColour1") {
                return null;
            }
            return  <div style={{display:'flex', flexDirection:'row'}} key={setting.name}>
                        <span className="label">{setting.value_definition.display_text} : </span>
                        <div style={{marginLeft:'10px'}}>
                            {
                                <select id={setting.name} name={setting.name} onChange={(e) => handleInputChange(e)} className="form-select" value={values[setting.name]}>
                                {
                                    setting.value_definition.value_options.map((color)=>(<option value={color} key={color}>{color}</option>))
                                }
                                </select>
                            }
                        </div>
                    </div>;
        } else if(setting?.value_definition?.type == "boolean") {
            return  <div className="toggle-switch" key={setting.name}>
                        <span className="label">{setting.value_definition.display_text} : </span>
                        <input type="checkbox" id={setting.name} name={setting.name} checked={values[setting.name]} onChange={(e) => handleInputChange(e)} />
                        <label htmlFor={setting.name}>Toggle</label>
                    </div>;
        } else if(setting?.value_definition?.type == "integer") {
            return  <div className="toggle-switch" key={setting.name}>
                        <span className="label">{setting.value_definition.display_text ?? setting.name} : </span>
                        <input type="text" id={setting.name} name={setting.name} value={values[setting.name]} onChange={(e) => handleInputChange(e)} />
                    </div>;
        } else {
            return null;
        }
    };

    return (
            <>
                <div className="form-group">
                {
                    renderGailSettings()
                }
                {
                    // allSettings.map((setting) => {
                    //     return renderSetting(setting);
                    // })
                }
                </div>
                <div className="btn-area">
                    {
                        props.type == "user" 
                        ? <button type="button" className="btn btn-outline" onClick={reset}>RESET</button>
                        : <button type="button" className="btn btn-outline" onClick={() => props.close()}>CLOSE</button>
                    }
                    {
                        props.isReadOnly != true && 
                        <button type="button" className="btn btn-primary ml-auto" onClick={saveSettings}>SAVE</button>
                    }
                </div>
            </>
    );
}

export default SettingsEdit;