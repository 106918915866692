import BannerResources from '../Assets/img/services/banner-resources.png';
import ResourceTile from '../Components/Resource/ResourceTile';

const Resources = () => {

    return (
        <div className="resources-page">
            <div className="resources-hero">
                <div className="banner-img">
                    <img src={BannerResources} alt="" />
                </div>
                <div className="banner-content">
                    <h2>Resources</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer varius rutrum turpis ut
                        placerat. Ut id nibh fringilla, consectetur mi varius, elementum ipsum.</p> */}
                </div>
            </div>

            <div className="container">
                <div className="resource-cards">
                    <ResourceTile 
                        highvideo="https://www.datadeli.com/media/videos/Login-Flow-Help.gif" 
                        lowvideo="https://www.datadeli.com/media/videos/Login-Flow-Help.gif" 
                        image="https://www.datadeli.com/media/images/Registration-Profile.png"
                        title="Login Flow" />
                    <ResourceTile 
                        highvideo="https://www.datadeli.com/media/videos/AOIListViewDetails.gif" 
                        lowvideo="https://www.datadeli.com/media/videos/AOIListViewDetails.gif" 
                        image="https://www.datadeli.com/media/images/AOIListViewDetails.png"
                        title="AOI List View Details" />
                    <ResourceTile 
                        highvideo="https://www.datadeli.com/media/videos/AOIMapViewDetails.gif" 
                        lowvideo="https://www.datadeli.com/media/videos/AOIMapViewDetails.gif" 
                        image="https://www.datadeli.com/media/images/AOIMapViewDetails.png"
                        title="AOI Map View Details" />
                    <ResourceTile 
                        highvideo="https://www.datadeli.com/media/videos/ChangeSettings.gif" 
                        lowvideo="https://www.datadeli.com/media/videos/ChangeSettings.gif" 
                        image="https://www.datadeli.com/media/images/ChangeSettings.png"
                        title="Change Settings" />
                    <ResourceTile 
                        highvideo="https://www.datadeli.com/media/videos/EditAOI.gif" 
                        lowvideo="https://www.datadeli.com/media/videos/EditAOI.gif" 
                        image="https://www.datadeli.com/media/images/EditAOI.png"
                        title="Edit AOI" />
                    <ResourceTile 
                        highvideo="https://www.datadeli.com/media/videos/Help.gif" 
                        lowvideo="https://www.datadeli.com/media/videos/Help.gif" 
                        image="https://www.datadeli.com/media/images/Help.png"
                        title="Help" />
                    <ResourceTile 
                        highvideo="https://www.datadeli.com/media/videos/UserProfileUpdateAndNotificationPreferences.gif" 
                        lowvideo="https://www.datadeli.com/media/videos/UserProfileUpdateAndNotificationPreferences.gif" 
                        image="https://www.datadeli.com/media/images/UserProfileUpdateandNotificationPreferences.png"
                        title="User Profile Update and Notification Preferences" />
                </div>
            </div>
        </div>
    );
}

export default Resources;