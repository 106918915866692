import React, {useCallback, useEffect, useRef, useState} from 'react';
import { GoogleMap, DrawingManager, Marker, InfoWindow } from '@react-google-maps/api';
import { AOIOptions } from '../AOIOptions';
import { MapUtils } from '../MapUtils';
import YellowMapPinIcon from '../../Assets/img/icon-map-pin-yellow.png';
import BlueMapPinIcon from '../../Assets/img/icon-map-pin-blue.png';
import toast from 'react-hot-toast';

const MarkerIcon = { url: YellowMapPinIcon, scaledSize: { width: 32, height: 52 } };
const SelectedIcon = { url: BlueMapPinIcon, scaledSize: { width: 32, height: 52 } };

const UsecaseEditMap = (props) => {
    const center = AOIOptions.defaultCenter;
    const mapRef = useRef();
    const drawingManagerRef = useRef();
    const rectangleStyle = {...AOIOptions.userAOIoptions, strokeColor: "blue"};
    const [reloadedMap, setReloadedMap] = useState(0);
    const [polygons, setPolygons] = useState([]);
    const [shapes, setShapes] = useState([]);
    const [selectedMarkerId, setSelectedMarkerId] = useState(-1);
    const [markerMap, setMarkerMap] = useState({});
    const [drawingMode, setDrawingMode] = useState(null);
    const [fitBounds, setFitBounds] = useState(true);
    const [savedPolygons, setSavedPolygons] = useState([]);

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
        setReloadedMap(reloadedMap + 1);
    }, []);

    useEffect(() => {
        const isValid = isValidGeometry(props.savedGeometry);
        if(!isValid) {
            toast.error("Ignoring Saved GeoJson as it is not valid.");
            setSavedPolygons([]);
        }
    }, []);

    useEffect(() => {
        if(!!props.geometry) {
            const isUpdatedValid = isValidGeometry(props.geometry);
            const isSavedValid = isValidGeometry(props.savedGeometry);
            setSavedPolygons(isSavedValid ? getPolygonsFromGeometry(props.savedGeometry) : []);
            setPolygons(isUpdatedValid ? getPolygonsFromGeometry(props.geometry) : []);
            if(isSavedValid && !isUpdatedValid) {
                toast.error("GeoJson is not valid. Resetting to empty map");
            }
            
            shapes.forEach(shape => shape.setMap(null));
            setShapes([]);
            setSelectedMarkerId(-1);
            setReloadedMap(reloadedMap + 1);
        }
    }, [props?.geometry]);

    useEffect(() => {
        if(mapRef && mapRef.current && !!polygons) {
            mapRef.current.data.forEach((feature) => mapRef.current.data.remove(feature));
            const features = [];
            polygons.map((polygon, index) => {
                const isSavedPolygon = isPolygonIn(polygon, savedPolygons);
                features.push({"type": "Feature", "properties":{"id": index, "saved": isSavedPolygon}, "geometry": {type: "Polygon", coordinates: polygon}});
            });
            const geoJson = {type: "FeatureCollection", features: features};

            var geoJsonFeatures = mapRef.current.data.addGeoJson(geoJson);
            mapRef.current.data.setStyle(rectangleStyle);
            geoJsonFeatures.forEach((feature) => {
                const isSavedPolygon = feature.getProperty('saved');
                if(!isSavedPolygon) {
                    const newStyle = {...rectangleStyle, strokeColor: "red"};
                    mapRef.current.data.overrideStyle(feature, newStyle);
                }
            });
            if(polygons && polygons.length >= 1 && !!fitBounds) {
                mapRef.current.fitBounds(MapUtils.getBounds({type: "MultiPolygon", coordinates: polygons}));
                setFitBounds(false);
            }
        }
    }, [reloadedMap, polygons]);

    const onDrawingManagerLoad = useCallback((drawingManager) => {
        drawingManagerRef.current = drawingManager;
    }, []);

    const isPolygonIn = (polygon, savedPolygons) => {
        for(var i=0; i<savedPolygons.length; i++) {
            for(var j=0; j<savedPolygons[i].length; j++) {
                if(arePolygonsSame(polygon, savedPolygons[i]))
                    return true;
            }
        }
        return false;
    };

    const arePolygonsSame = (polygon1, polygon2) => {
        if(polygon1.length != polygon2.length)
            return false;
        for(var i=0; i<polygon1.length; i++) {
            if(polygon1[i].length != polygon2[i].length)
                return false;
            for(var j=0; j<polygon1.length; j++) {
                if(polygon1[i][j][0] != polygon2[i][j][0] || polygon1[i][j][1] != polygon2[i][j][1])
                    return false;
            }
        }
        return true;
    };

    const isValidGeometry = (geometry) => {
        if(geometry == null)
            return true;
        if(!Array.isArray(geometry.coordinates))
            return false;
        if(geometry.type == "MultiPolygon") {
            for(var i=0; i<geometry.coordinates.length; i++)
                if(!isValidPolygon(geometry.coordinates[i]))
                    return false;
        } else if(geometry.type == "Polygon") {
            return isValidPolygon(geometry.coordinates);
        }
        return true;
    };

    const isValidPolygon = (polygon) => {
        if(!Array.isArray(polygon))
            return false;
        for(var i=0; i<polygon.length; i++) {
            if(!Array.isArray(polygon[i]))
                return false;
            for(var j=0; j<polygon.length; j++) {
                if(!Array.isArray(polygon[i][j]))
                    return false;
                if(polygon[i][j].length != 2)
                    return false;
            }
        }
        return true;
    }

    const getPolygonsFromGeometry = (geometry) => {
        if(geometry == null)
            return [];
        if(geometry.type == "MultiPolygon") {
            return geometry.coordinates;
        } else if(geometry.type == "Polygon") {
            return [geometry.coordinates];
        }
        return [];
    };

    const onDrawingComplete = (event) => {
        let shape = event.overlay;
        setShapes((prevShapes) => [...prevShapes, shape]);
        var geometry = null;
        if (event.type == 'rectangle') {
            geometry = getRectangleGeometry(shape);
        } else if(shape.getPath) {
            geometry = getPolygonGeometry(shape);
        }
        if(geometry != null) {
            polygons.push(geometry);
            setPolygons(polygons);
        }
        props.updateMap({type: "MultiPolygon", coordinates: polygons});
        setDrawingMode(null);
    };

    const getRectangleGeometry = (shape) => {
        let p = shape.getBounds();
        let paths = [
            [p.getNorthEast().lng(), p.getNorthEast().lat()],
            [p.getSouthWest().lng(), p.getNorthEast().lat()],
            [p.getSouthWest().lng(), p.getSouthWest().lat()],
            [p.getNorthEast().lng(), p.getSouthWest().lat()],
            [p.getNorthEast().lng(), p.getNorthEast().lat()]
        ];
        return [paths];
    };

    const getPolygonGeometry = (shape) => {
        let polygonBounds = shape.getPath();
        let bounds = [];
        for (let i = 0; i < polygonBounds.length; i++) {
            let point = [polygonBounds.getAt(i).lng(), polygonBounds.getAt(i).lat()];
            bounds.push(point);
        }
        bounds.push([polygonBounds.getAt(0).lng(), polygonBounds.getAt(0).lat()]);
        return [bounds];
    };

    const deletePolygon = (index) => {
        polygons.splice(index, 1);
        setPolygons(polygons);
        setSelectedMarkerId(-1);
        setReloadedMap(reloadedMap+1);
        props.updateMap({type: "MultiPolygon", coordinates: polygons});
    };

    return (
        <div className="usecase-map-area">
            <div className="service-area-actions">
                <input type="button" name="draw" value="Draw" className="btn btn-primary" onClick={() => setDrawingMode("rectangle")} />
                <input type="button" name="reset" value="Reset" className="btn btn-primary" onClick={props.reset} />
            </div>
            <div className="usecase-map">
                <GoogleMap
                    center = {center}
                    zoom={3}
                    mapContainerStyle={AOIOptions.mapContainerStyle}
                    options = {AOIOptions.roadMapOptions}
                    onLoad = {onMapLoad}
                >
                    <DrawingManager
                        onLoad = {onDrawingManagerLoad}
                        onOverlayComplete = {onDrawingComplete}
                        options = {AOIOptions.usecaseDrawingManagerOptions}
                        drawingMode={drawingMode}
                    />
                    {
                        polygons.map((polygon, index) => <Marker 
                                icon={index == selectedMarkerId ? SelectedIcon : MarkerIcon}
                                key={index}
                                position={MapUtils.getCenterForPolygon(polygon)} 
                                onLoad={marker => {setMarkerMap((prev) => {return {...prev, [index]: marker};})}} 
                                onClick={event => {setSelectedMarkerId(index)}}
                            />
                        )
                    }
                    {
                        selectedMarkerId != -1 &&
                        <InfoWindow onCloseClick={()=>setSelectedMarkerId(-1)} anchor={markerMap[selectedMarkerId]} key={selectedMarkerId}>
                            <div className='usecase-edit-popover-div'>
                                <input type="button" name="reset" value="Cancel" className="btn btn-outline" onClick={() => setSelectedMarkerId(-1)} />
                                <input type="button" name="submit" value="Delete Polygon" className="btn btn-primary" onClick={() => deletePolygon(selectedMarkerId)} />
                            </div>
                        </InfoWindow>
                    }
                </GoogleMap>
            </div>
        </div>
    );
}

export default UsecaseEditMap;