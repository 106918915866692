import { useContext, useState } from "react";
import EditIcon from '../../../Assets/img/icon-edit.svg';
import { UserContext } from "../../../Contexts/UserContext";
import { aoiService } from "../../../Services/aoiService";
import { toast } from "react-hot-toast";

const AOINotes = (props) => {
    const { user } = useContext(UserContext);
    const [expand, setExpand] = useState(true);
    const [notes, setNotes] = useState(props.aoi?.attributes?.notes ?? "");
    const [mode, setMode] = useState(props.mode);

    const expandCollapseEdit = (event) => {
        if(event.target.classList.contains('edit')) {
            setMode('edit');
            setExpand(true);
        } else {
            setExpand(!expand);
        }
    };

    const saveNotes = () => {
        if(notes == props.aoi?.attributes?.notes) {
            setMode("view");
            return;
        }
        let request = {
            aoiAttributes: {
                landuse: props.aoi?.attributes?.landuse?.name,
                member_landuse_attributes: props.aoi?.attributes?.member_landuse_attributes ?? {},
                notes: notes
            }
        };
        aoiService.attributes(user.mkey, user.region, props.aoi?.member_aoi_id, request)
        .then((data) => { 
            props.onSave();
            setMode("view");
            toast.success("Successfully Updated Notes");
        })
        .catch(error => {
            console.log('Error in Saving Notes', error, request);
            toast.error('Failed to Save Notes! Try Again.');
        });
    };

    const editNotes = () => {
        return <>
                <textarea name="" id="" cols="3" rows="3" className="form-control" value={notes} onChange={(e)=>{setNotes(e.target.value)}}></textarea>
                    <div className="btn-area">
                        <button type="button" className="btn btn-primary" onClick={saveNotes}>Update</button>
                        <button type="button" className="btn btn-outline" onClick={() => setMode('view')}>Cancel</button>
                    </div>
                </>;
    };

    return <div className={`aoi-detail-box ${expand ? "expanded" : "collapsed"}`}>
                    <div className="aoi-detail-header" onClick={expandCollapseEdit}>Notes
                        {
                            user.isReadOnly == false && 
                            <img src={EditIcon} alt="" className="edit edit-icon" />
                        }
                    </div>
                    <div className="aoi-detail-content">
                        <div className="row">
                            <div className="col-12">
                                <div className="notes-text">
                                    {
                                        mode == "view" 
                                        ? <p>{notes}</p>
                                        : editNotes()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;
};

export default AOINotes;