import Gallery01 from '../Assets/img/services/gallery-01.jpg';
import Slider from 'react-slick';
import { UserContext } from '../Contexts/UserContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

const Services = (props) => {
    const {user, openLoginModal} = useContext(UserContext);

    const sliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 3000 
    };

    return (
        <div className="services-page">
        <div className="services-hero">
            <h2>Introducing Mega-View!</h2>
            <ul>
                <li>Our fusion of satellite technology and AI delivers top-tier geovisualization and analytics.</li>
                <li>Our tools will provide actionable intelligence when and where you need it.</li>
                <li>Uncover previously concealed insights by exploring your assets and resources with utmost clarity
                    and precision.</li>
                <li>Unlock hidden opportunities, optimize resources, and make informed decisions that can drive your
                    opportunity to new heights.</li>
            </ul>
        </div>

        <div className="container">
            <div className="get-satrted">
                <h3>Ready to transform the way you see your spatial property? <span>Let's get started!</span></h3>
                <p>You gain access to a suite of world-class geospatial solutions. Dive deep into your data and uncover patterns, trends, and
                    correlations that can guide strategic decisions. We're here to empower you with the tools you
                    need to leverage the full power of your spatial assets, making data-driven decisions that drive
                    growth, sustainability, and innovation.</p>
            </div>
        </div>
        <div className="prices-section">
            <div className="container">
                <h2>Join us on this <span>exciting journey!</span></h2>
                <p>Explore our services, discover the possibilities, and let us be your trusted partner in turning
                    spatial data into actionable intelligence.</p>

                <h3>
                    {
                        user.isAuthenticated 
                        ? <>Go to <span><Link to="/portfolio">Portfolio</Link></span> </> 
                        : <><span onClick={()=>openLoginModal(true)}>Sign-in</span> </> 
                    } 
                    and draw your AOI (Area of Interest) for FREE today.
                </h3>
                <p>If one of these pricing tiers doesn't fit your needs, let's talk.</p>
                <div className="price-info">
                    <div className="price-left">
                        <h4>
                            <img src="img/services/icon-individual.svg" alt="" />
                            Individual Plan
                        </h4>
                        <h5>Features:</h5>
                        <ul>
                            <li>Monthly High-Resolution Image of your Property</li>
                            <li>Annotations of the Changes in your Property</li>
                            <li>Timely Notifications &amp; Alerts to you</li>
                        </ul>
                        <h5>Pricing Info:</h5>
                        <p>
                            $15/month for the first 50 acres of an AOI and $10 more for every additional 50 acres<br /><br />
                            <b>Bulk Purchase:</b><br />
                            &nbsp; 10% bonus for every $150 or above<br />
                            &nbsp; 20% bonus for every $1500 or above
                        </p>
                    </div>
                    <div className="price-right">
                        <h4>
                            <img src="img/services/icon-enterprise.svg" alt="" />
                            Enterprise Plan
                        </h4>
                        <h5>Features:</h5>
                        <ul className="two-col">
                            <li>Property Monitoring</li>
                            <li>Object Detection</li>
                            <li>Enumeration</li>
                            <li>Notifications and Alerts</li>
                            <li>Height Monitoring</li>
                            <li>Data Analytics & Dashboarding</li>
                            <li>Change Detection with Annotations</li>
                        </ul>
                        <h6>+ More depending on the use case</h6>

                        <h5>Pricing Info:</h5>
                        <p>
                            For pricing, please <Link to={'/contact-us'} state={{source: 'services'}}>Contact Us</Link> <br />
                            We understand that every organization is unique. That's why we offer tailored solutions
                            to address your specific challenges and opportunities. Our team of experts is ready to
                            work closely with you to design and implement solutions that deliver tangible results.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="gallery-section">
                <div id="serviceCarousel" className="carousel slide">
                    <div className="carousel-inner">
                        <Slider {...sliderSettings} >
                            <div className="carousel-item">
                                <img src="https://www.datadeli.com/media/images/Services-Slide-1.jpg" className="d-block w-100" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src="https://www.datadeli.com/media/images/Services-Slide-2.jpg" className="d-block w-100" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src="https://www.datadeli.com/media/images/Services-Slide-3.jpg" className="d-block w-100" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={Gallery01} className="d-block w-100" alt="..." height="585px"/>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Services;