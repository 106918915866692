import { useContext } from 'react';
import {Modal} from 'react-bootstrap';
import { AOIContext } from '../../Contexts/AOIContext';

const SubscriptionSuccessModal = () => {
    const { openSubscriptionSuccessModal } = useContext(AOIContext);
    return (
        <>
            <Modal
                show={() => openSubscriptionSuccessModal(true)}
                onHide={() => openSubscriptionSuccessModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="subscription-success-modal-dialog"
                className="subscription-success-modal success-modal"
                size='lg'
                style={{ top: '10%'}}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                <div class="aoi-success">
                    <div class="before"></div>
                    <div class="after"></div>
                    <div class="check-container">
                        <div class="check-background">
                            <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                        <div class="check-shadow"></div>
                    </div>
                    <h3>Congratulations!</h3>
                    <h4>Your AOI (Area Of Interest) is now Subscribed.</h4>
                    <h5>You will be notified as soon as the new image is uploaded!</h5>
                </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SubscriptionSuccessModal;