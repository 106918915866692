import { Route } from "react-router";
import Copyright from "../Components/Copyright";
import Footer from "../Components/Footer";
import Login from "../Pages/Login";
import Timeline from "../Pages/Timeline";
import TimelineV2 from "../Pages/TimelineV2";
import TimelineR1 from "../Pages/TimelineR1";
import { Link } from "react-router-dom";

const getUsecaseUrls = () => {
    return {
        "AoIRGBImage": "timelinev1",
        "AoIRGBImageV2": "timelinev2",
        "AoIRGBImageR1": "timeline",
        "EnhancedAoIRGBImage": "timelinesr"
    };
};

const getUsecaseUrl = (usecaseName) => {
    return getUsecaseUrls()[usecaseName];
}

const getComponents = (usecaseName) => {
    switch(usecaseName) {
        case "timelinev1":
            return <><Timeline/><Copyright/></>;
        case "timelinev2":
            return <><TimelineV2/><Copyright/></>;
        case "timelinesr":
            return <><Timeline/><Copyright/></>;
        case "timeliner1":
            return <><TimelineR1/><Copyright/></>;
    }
    return <></>;
};

const getUsecaseRoutes = (isAuthenticated) =>
{
    return <>
                <Route key="timelinev1"
                        path={`/timelinev1/:aoiId`}
                        element={isAuthenticated ? getComponents("timelinev1") : <><Login /><Footer /></>} />
                <Route key="timeliner1"
                        path={`/timeline/:aoiId`}
                        element={isAuthenticated ? getComponents("timeliner1") : <><Login /><Footer /></>}>
                    <Route path=":timelineParamView" element={isAuthenticated ? getComponents("timeliner1") : <><Login /><Footer/></>}>
                        <Route path=":eventId1" element={isAuthenticated ? getComponents("timeliner1") : <><Login /><Footer/></>}>
                            <Route path=":eventId2" element={isAuthenticated ? getComponents("timeliner1") : <><Login /><Footer/></>} />
                        </Route>
                    </Route>
                </Route>
                <Route key="timelinev2" path={`/timelinev2/:aoiId`} element={isAuthenticated ? getComponents("timelinev2") : <><Login /><Footer /></>}>
                    <Route path=":timelineParamView" element={isAuthenticated ? getComponents("timelinev2") : <><Login /><Footer/></>}>
                        <Route path=":eventId1" element={isAuthenticated ? getComponents("timelinev2") : <><Login /><Footer/></>}>
                            <Route path=":eventId2" element={isAuthenticated ? getComponents("timelinev2") : <><Login /><Footer/></>} />
                        </Route>
                    </Route>
                </Route>
                <Route key="timelinesr"
                        path={`/timelinesr/:aoiId`}
                        element={isAuthenticated ? getComponents("timelinesr") : <><Login /><Footer /></>} />
            </>;
};

const notificationTitles = {
    "timeline": "New Image in timeline",
    "timeline V2": "New Image in timeline V2",
    "timeline SR": "New Image in timeline SR",
    "timeline R1": "New Image in the timeline"
};

const getNotificationTitle = (notificationType) => {
    return notificationTitles[notificationType];
};

const getNavigationUrl = (notificationType, aoiId) => {
    switch(notificationType) {
        case "timeline":
            return "/timelinev1/" + aoiId;
        case "timeline V2":
            return "/timelinev2/" + aoiId;
        case "timeline SR":
            return "/timelinesr/" + aoiId;
        case "timeline R1":
            return "/timeline/" + aoiId;
    }
    return null;
};

const getAllSubscriptionChoices = () => {
    let choices = {};
    choices["AoIRGBImageV2"] = [{"plan_type": "basic"},{"plan_type": "standard"},{"plan_type": "premium"}];
    choices["AoIRGBImageR1"] = [{}];
    return choices;
};

const getSubscriptionChoices = (domainUsecases, allUsecases) => {
    let choices = {};
    let usecase = allUsecases.find((u) => u.name == "AoIRGBImageV2");
    if(usecase != null && usecase.price != null && domainUsecases.find((u) => u.name == "AoIRGBImageV2" && !!u.isEnabled))
        choices["AoIRGBImageV2"] = [{"plan_type": "basic"},{"plan_type": "standard"},{"plan_type": "premium"}];

    usecase = allUsecases.find((u) => u.name == "AoIRGBImageR1");
    if(usecase != null && usecase.price != null && domainUsecases.find((u) => u.name == "AoIRGBImageR1" && !!u.isEnabled))
        choices["AoIRGBImageR1"] = [{}];

    return choices;
};

const getDefaultSubscriptionChoices = (usecaseName) => {
    if(usecaseName == "AoIRGBImageV2") {
        return {"AoIRGBImageV2": [{"plan_type": "standard"}]};
    }
    if(usecaseName == "AoIRGBImageR1") {
        return {"AoIRGBImageR1": [{}]};
    }
    return {};
};

const getDefaultPlanName = (usecaseName) => {
    if(usecaseName == "AoIRGBImageV2")
        return "standard";
    return "";
};

const getUsecaseLink = (usecaseItem, aoiId, domainConfig) => {
    var domainUsecase = domainConfig.usecases.find(u=>u.name == usecaseItem.usecase.name && u.isEnabled === true);
    if(domainUsecase == null)
        return null;

    const urls = getUsecaseUrls();
    if(urls[usecaseItem.usecase.name] == null)
        return null;

    return [usecaseItem.usecase.name, <Link key={usecaseItem.usecase.name} className="text-link" onClick={() => window.scroll(0,0)} to={`/${urls[usecaseItem.usecase.name]}/${aoiId}`}>{domainUsecase.displayName}</Link>];
};

const getUsecaseLinks = (aoi, type, domainConfig) => {
    if(aoi == null || aoi.subscribed_usecases == null || aoi.subscribed_usecases.length == 0)
        return [];
    return aoi.subscribed_usecases?.filter(u=>u.usecase.type == type)?.map((usecaseItem) => getUsecaseLink(usecaseItem, aoi.member_aoi_id, domainConfig));
};

export const usecaseService = {
    getUsecaseUrls,
    getUsecaseUrl,
    getUsecaseRoutes,
    getNotificationTitle,
    getNavigationUrl,
    getSubscriptionChoices,
    getAllSubscriptionChoices,
    getDefaultSubscriptionChoices,
    getDefaultPlanName,
    getUsecaseLinks
};