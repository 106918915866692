import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { UserContext } from "../../Contexts/UserContext";
import { adminService } from "../../Services/adminService";
import MessageBox from "../SendMessage/MessageBox";
import { isValidAuthId } from "../Utils";

const SendMessageAdmin = () => {
    const {user, usecases, sendNotification} = useContext(UserContext);
    const messageRef = useRef();
    const subjectRef = useRef();
    const [messageOption, setMessageOption] = useState('authIds');
    const [subscribedValue, setSubscribedValue] = useState('subscribed');
    const [authIds, setAuthIds] = useState([]);
    const [selectedUseCases, setSelectedUseCases] = useState([]);
    const [disableSendBtn, setDisableSendBtn] = useState(false);
    const [authIdsInputWidth, setAuthIdsInputWidth] = useState(0);
    const [mailType, setMailType] = useState("promotional");

    const handleSetMessageOption = (option) => {
        setMessageOption(option);

        if(option == "authIds") {
            // Reset AuthIds
            setAuthIds([]);
        }

        if (option == "subscription") {
            // Reset SubscribedValue
            setSubscribedValue("subscribed");
            setSelectedUseCases([]);
        }
    }

    const handleSetSubscribedValue = (e) => {
        // Reset the existing selected usecases
        setSelectedUseCases([]);
        setSubscribedValue(e.target.value);
    }

    const handleSendMessage = () => {
        setDisableSendBtn(true);
        let search_criteria = {};
        if (messageOption == "authIds") {
            if (!authIds || authIds.length <= 0) {
                toast.error('Enter atleast one valid authId');
                return;
            }
            search_criteria = {auth_ids: [...authIds]}
        }
        if (messageOption == "subscription") {
            search_criteria = {subscription: subscribedValue}
            if (subscribedValue == "subscribed") {
                if(!selectedUseCases || selectedUseCases.length <= 0) {
                    toast.error("Select atleast one usecase");
                    return;
                }
                search_criteria = {...search_criteria, usecases: [...selectedUseCases]}
            }
        }
        const subject = subjectRef.current.value ?? "";
        const message = messageRef.current.value ?? "";

        const onSuccess = (isSuccess) => {
            setDisableSendBtn(false);
        }
        sendNotification(search_criteria, subject, message, mailType, onSuccess);
    }

    const handleUseCaseSelect = (e) => {
        let arr = [...e.target.selectedOptions];
        setSelectedUseCases(arr.filter(option => option.selected).map(option => option.value));
    }

    const onAuthIdEnter = (e) => {
        setAuthIdsInputWidth((e.target.value.length + 1) * 9); // Set min-width dynamically based on input text length to avoid text overflow
        if (e.keyCode == 13 || e.keyCode == 32 || e.type == 'blur') {
            const authIdValue = e.target.value.trim();
            if (authIdValue) {
                if (authIds.findIndex(id => id == authIdValue) >= 0) {
                    toast.error('AuthId already present');
                }
                else {
                    if (isValidAuthId(authIdValue)) {
                        setAuthIds([...authIds, authIdValue]);
                        e.target.value = '';
                    }
                    else {
                        toast.error('Invalid AuthId');
                    }
                }
            }  
        }
    }

    const removeAuthId = (authId) => {
        setAuthIds(authIds.filter(id => id != authId));
    }

    return (
        <div className="form-content">
            <div className="form-head">
                <h3>Send Message</h3>
            </div>
            <div className="message-container">
                <div className="message-options">
                    <div className="options-title"> Choose an Option:</div>
                    <div className="options-group">
                        <a className={messageOption == "all" ? "active" : "test"} onClick={()=> handleSetMessageOption('all')}>All Users</a>
                        <a className={messageOption == "authIds" ? "active" : "test"} onClick={()=> handleSetMessageOption('authIds')}>List of AuthIds</a>
                        <a className={messageOption == "subscription" ? "active" : "test"} onClick={()=> handleSetMessageOption('subscription')}>Subscription</a>
                    </div>
                </div>
                {
                    messageOption == "all" &&
                    <div className="col-12">
                        <p>Email will be sent to all the users</p>
                    </div>
                }
                {
                    messageOption == "authIds" &&
                    <div className="col-12">
                        <div className="form-group">
                            <label className="form-label" htmlFor="AuthIds">AuthIds <span className="required">*</span></label>
                            <div className="email-id-row">
                                <div className="all-mail">
                                    {
                                        authIds.map(authId => {
                                            return <span className="email-ids">{authId}<span className="cancel-email" onClick={()=>removeAuthId(`${authId}`)}>x</span></span>
                                        })
                                    }
                                </div>
                                <input type="text" name="AuthIds" id="AuthIds" className="enter-mail-id" placeholder="Enter AuthId.." onKeyDown={onAuthIdEnter} onBlur={onAuthIdEnter}
                                    style={{minWidth: `${authIdsInputWidth}px`}}
                                />
                            </div>
                        </div>
                    </div> 
                }
                {
                    messageOption == "subscription" &&
                    <div>
                        <div className="subscription-group">
                            <div className="custom-radio">
                                <input type="radio" id="Subscribed" name="Subscription" value="subscribed" onChange={handleSetSubscribedValue} checked={subscribedValue == 'subscribed'}/>
                                <label htmlFor="Subscribed">Subscribed</label>
                            </div>
                            <div className="custom-radio">
                                <input type="radio" id="NotSubscribed" name="Subscription" value="unsubscribed" onChange={handleSetSubscribedValue} checked={subscribedValue == 'unsubscribed'}/>
                                <label htmlFor="NotSubscribed">Not Subscribed</label>
                            </div>
                        </div>
                        {
                            subscribedValue == "subscribed" &&
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="Usecases">Usecases</label>
                                    <select name="Usecases[]" multiple id="Usecases" className="form-control" onChange={handleUseCaseSelect}>
                                        {
                                            usecases && usecases.map(usecase => {
                                                return <option value={usecase.name} key={usecase.name}>{usecase.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        }
                    </div>
                }
                <MessageBox showSubject={true} messageRef={messageRef} subjectRef={subjectRef} mailType={mailType} setMailType={setMailType} />
                <div className="btn-area">
                    <button type="button" className="btn btn-primary ml-auto" onClick={handleSendMessage} disabled={disableSendBtn}>Send</button>
                </div>
            </div>
        </div>
    );
}

export default SendMessageAdmin;