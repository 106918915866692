import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOIGridCard from '../AOIGridCard';

const AOIGroupAOISlider = (props) => {
    const sliderSettings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        autoplay: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    return (
        <Slider {...sliderSettings}>
            {
                props.memberIds.map((aoiId) => props.aoiDict[aoiId] && <AOIGridCard aoi={props.aoiDict[aoiId]} key={aoiId} show="links" />)
            }
        </Slider>
    );
}

export default AOIGroupAOISlider;