import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { AOIContext } from '../../Contexts/AOIContext';
import UsecaseLinks from './UsecaseLinks';
import { UserContext } from '../../Contexts/UserContext';
import RenderTag from './RenderTag';
import TokensIcon from '../../Assets/img/icon-tokens.png';
import SubscribedTokensIcon from '../../Assets/img/icon-tokens-subscribed.png';
import { MapUtils } from '../MapUtils';

const AOIGridCard = (props) => {
    const {user, aoiThumbnails, aoiPrices, usecases} = useContext(UserContext);
    const {showEditAttributes, viewAOIDetails, setZoomToFit, domainConfig, isServiceAvailable} = useContext(AOIContext);
    const availableServices = domainConfig.usecases?.filter(usecase=>usecase.type == 'aoi' && usecase.isEnabled === true);

    const setPageParams = () => {
        window.scrollTo(0, 0);
        setZoomToFit(true);
    };

    const isUserSubscribed = () => {
        let exists = false;
        availableServices.map((service) => {
            if(props.aoi.subscribed_usecases?.find(u => u.usecase.name == service.name))
                exists = true;
        });
        return exists;
    };

    const getNoServiceMessage = (aoi) => {
        const area = MapUtils.calculateGeometryArea(aoi.geometry);
        const usecase = usecases.find((u) => u.name == availableServices[0].name);
        let areaTooLarge = false;
        let serviceAvailable = isServiceAvailable(aoi);
        if(usecase != null && usecase.configuration != null && usecase.configuration.max_aoi_area != null) {
            if(area > usecase.configuration.max_aoi_area)
                areaTooLarge = true;
        }
        if(areaTooLarge == true && serviceAvailable == true) {
            return "Area too large to service";
        }
        if(areaTooLarge == false && serviceAvailable == false) {
            return "Service is not available in this region";
        }
        if(areaTooLarge == true && serviceAvailable == false) {
            return "Area too large and service is not available in the region";
        }
        return "Area too large or service is not available in the region";
    };

    const showTokenAmount = () => {
        if(!domainConfig.showTokens || !domainConfig.isSingleUsecase || !aoiPrices || !aoiPrices[props.aoi.ext_id])
            return false;
        const usecase = usecases.find((u) => u.name == availableServices[0].name);
        if(usecase.price == null)
            return false;
        return true;
    };

    return (
            <div className="aoi-card">
                {props.show == "all" && isUserSubscribed() && <div className="subscribed">Subscribed</div>}
                {
                    props.customComponent && props.renderCustomComponent(props.aoi)
                }
                <div className="aoi-card-body">
                    <figure>
                        {
                            props.show != "all" && props.show != "links" ?
                                <img src={aoiThumbnails[props.aoi.ext_id]} alt="" onClick={setPageParams} />
                            :
                                <Link to={`/portfolio/map/aoi/${props.aoi.member_aoi_id}`}>
                                    <img src={aoiThumbnails[props.aoi.ext_id]} alt="" onClick={setPageParams} />
                                </Link>
                        }
                    </figure>
                    <div className="aoi-info">
                        <div className="info-row">
                            <h4>AOI Name</h4>
                            <RenderTag tag={props.aoi.tag} />
                        </div>
                        <div className="info-row">
                            <h4>Area</h4>
                            <h3>{props.aoi.area}</h3>
                        </div>
                        <div className="info-row">
                            <h4>Date Created/Modified</h4>
                            <h3><Moment date={props.aoi.timestamp} format={"Do MMMM YYYY"}></Moment></h3>
                        </div>
                        {
                            (props.show == "all" || props.show == "links") && <a className="text-link" onClick={()=>viewAOIDetails(props.aoi)}>View Details</a>
                        }
                    </div>
                </div>
                {
                    props.show == "all" && availableServices.length > 0 &&
                    <div className="aoi-card-footer">
                    {
                        domainConfig.showTokens && domainConfig.isSingleUsecase && aoiPrices && aoiPrices[props.aoi.ext_id] && aoiPrices[props.aoi.ext_id] == -1 
                        ? <span>{getNoServiceMessage(props.aoi)}</span>
                        :
                        <>
                            <div className={"services " + (showTokenAmount() ? "singleusecase" : "multiusecase")}>
                                {
                                    user.canSubscribe && 
                                    !isUserSubscribed() && 
                                    <a className="btn-link" onClick={()=>showEditAttributes(props.aoi, 'view', 2)}>Subscribe</a>
                                }
                                {isUserSubscribed() && <h4>Service(s)</h4>}
                                <UsecaseLinks aoi={props.aoi} state={{dashboardView:props.view}} type="aoi"></UsecaseLinks>
                            </div>
                            {
                                showTokenAmount() == true &&
                                <div className={isUserSubscribed() ? "subcribe-tokens subscribed-user" : "subcribe-tokens"}>
                                    {
                                        isUserSubscribed() 
                                        ? <img src={SubscribedTokensIcon} alt="" />
                                        : <img src={TokensIcon} alt="" />
                                    }
                                    <div className="subcribe-tokens-count">$ {aoiPrices[props.aoi.ext_id]} <div className="month">/month</div></div>
                                </div>
                            }
                        </>
                    }
                    </div>
                }
            </div>
    );
}

export default AOIGridCard;