const AOIWizzardHead = (props) => {
    return (
        <>
            <div className="aoi-wizzard-head wizzard-head aoi-head">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-2"> &nbsp; </div>
                        <div className="col-12 col-md-8">
                            <div className="aoi-name">
                                AOI Name : <span>{props.selectedAOI?.tag}</span>
                            </div>
                        </div>
                        <div className="col-12 col-md-2 justify-content-end">
                            <button type="button" className="btn-close" onClick={props.handleCloseWizard}></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AOIWizzardHead;