import React, { useState } from 'react';
import CesiumUI from '../Components/GMRDemo/CesiumUI';
import GMRDate from '../Components/GMRDemo/GMRDate';
import GMRLayer from '../Components/GMRDemo/GMRLayer';
import GMRVisible from '../Components/GMRDemo/GMRVisibile';
import { useEffect } from 'react';
import { memberService } from '../Services/memberService';

const GMRInnovexDemo = (props) => {
    const [hasAccess, setHasAccess] = useState(false);
    const [cesiumToken, setCesiumToken] = useState("");
    const [extrusion, setExtrusion] = useState(1);
    const [counter, setCounter] = useState(1);
    const homePosition = {
        lon: Cesium.Math.toRadians(78.44),
        lat: Cesium.Math.toRadians(17.33),
        height: 60000
    };
    const [position, setPosition] = useState(homePosition);
    const homeOrientation = {
        heading: Cesium.Math.toRadians(360),
        pitch: Cesium.Math.toRadians(-90),
        roll: Cesium.Math.toRadians(0)
    };
    const [orientation, setOrientation] = useState(homeOrientation);
    const [vwr, setVwr] = useState(null);
    const [showLeftPanel, setShowLeftPanel] = useState(true);
    const [terrainOnly, setTerrainOnly] = useState(false);
    const [rightclickToGoogleMaps, setRightclickToGoogleMaps] = useState(false);
    const dates = ['Sep 15th 2023','May 1st 2023'];
    const [selectedDate, setSelectedDate] = useState("May 1st 2023");
    const zoneLegend = [["#64F4DA", "565M"],
                        ["#EAE250", "580M"],
                        ["#F0A8E6", "600M"],
                        ["#134924", "620M"],
                        ["#7D7AC2", "640M"],
                        ["#D1C9C9", "660M"],
                        ["#ef7416", "675M"],
                        ["#e2a31b", "700M"],
                        ["#b58449", "720M"],
                        ["#445ce6", "740M"],
                        ["#76d46f", "760M"],
                        ["#00000000", "NOC"]];
    const [zone, setZone] = useState({
        name: "Zones",
        visibility: false,
        alpha: 0.5
    });

    const srtmLegend = [["#2E81BA", "<= 500"],
    ["#ADDBA6", "500 - 550"],
    ["#FFFEC0", "550 - 600"],
    ["#FDAE64", "600 - 650"],
    ["#D81B1B", "> 650"]];
    const [srtm, setSrtm] = useState({
        name: "DEM",
        visibility: false,
        alpha: 0.5
    });

    const [geoid, setGeoid] = useState({
        name: "Geoid",
        visibility: false,
        alpha: 0.8
    });

    const permissibleHeightsLegend = [
        ["#0205c2", "<= 0"],
        ["#cf4501", "0 - 10"],
        ["#e05206", "10 - 30"],
        ["#ec620f", "30 - 50"],
        ["#f4721d", "50 - 70"],
        ["#fd8c3b", "70 - 100"],
        ["#fdb373", "100 - 150"],
        ["#fff5eb", "> 150"]
    ];
    const [permissibleHeights, setPermissibleHeights] = useState({
        name: "Permissible Heights",
        visibility: false,
        alpha: 0.5
    });
    const [satelliteImagery, setSatelliteImagery] = useState({
        name: "Satellite Imagery",
        visibility: false
    });
    const [shamshabadBuildings, setShamshabadBuildings] = useState({
        name: "Buildings - Shamshabad",
        visibility: false
    });
    const [osmBuildings, setOsmBuildings] = useState({
        name: "OSM Buildings",
        visibility: false
    });
    const [runWayLayer, setRunWayLayer] = useState({
        name: "Airport Runway",
        visibility: true
    });


    const update = () => {
        const camera = vwr.scene.camera;
        const camPos = camera.positionCartographic;
        setPosition({lon: camPos.longitude, lat: camPos.latitude, height: camPos.height});
        setOrientation({heading: camera.heading, pitch: camera.pitch, roll: camera.roll});
        vwr.entities.removeAll();
        vwr.destroy();
        setCounter(counter+1);
    };

    useEffect(() => {
        memberService.getGMRToken().then((a) => {
            if(a.status == 200) {
                a.text().then((text) => {
                    setCesiumToken(text);
                    setHasAccess(true);
                });
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    if(!hasAccess)
        return <div><br /><br /><br /><br /><br /> &nbsp; &nbsp; &nbsp; If you have access to this page, it would be loaded in a few seconds. Otherwise contact administrator. </div>;

    return <div className={showLeftPanel ? "gmr-innovex-demo gmr-layout" : "gmr-innovex-demo gmr-layout minimized" }>
        <div className='gmr-layout-left'>
            <GMRLayer layer={zone} legend={zoneLegend} updateLayer={(layer) => setZone(layer)}/>
            <div className="range-slider">
                <div className="range-label">
                    <div className='range-number'>1</div>
                    <label htmlFor="Extrusion" className="form-label">Extrusion Factor <span className='selected-value'>{extrusion}</span></label> 
                    <div className='range-number'>50</div>
                </div>
                <input type="range" min="1" max="50" className="form-range" id="Extrusion" value={extrusion} onChange={(e) => setExtrusion(e.target.value)}></input>
            </div>
            <GMRLayer layer={srtm} legend={srtmLegend} updateLayer={(layer) => setSrtm(layer)}/>
            <GMRLayer layer={geoid} updateLayer={(layer) => setGeoid(layer)}/>
            <GMRLayer layer={permissibleHeights} legend={permissibleHeightsLegend} updateLayer={(layer) => setPermissibleHeights(layer)}/>
            <GMRVisible layer={satelliteImagery} updateLayer={(layer) => setSatelliteImagery(layer)} />
            <GMRVisible layer={shamshabadBuildings} updateLayer={(layer) => setShamshabadBuildings(layer)} />
            <GMRVisible layer={osmBuildings} updateLayer={(layer) => setOsmBuildings(layer)} />
            <GMRVisible layer={runWayLayer} updateLayer={(layer) => setRunWayLayer(layer)} />
            <GMRDate dates={dates} selectedDate={selectedDate} changeDate={(date) => setSelectedDate(date)}/>
            <div className="filter-box filter-header">
                <div className="toggle-switch">
                    <span className="label">Terrain Only</span>
                    <input type="checkbox" id="Terrain" name="Terrain" onChange={() => setTerrainOnly(!terrainOnly)} />
                    <label htmlFor="Terrain">Toggle</label>
                </div>
            </div>
            <div className="filter-box filter-header">
                <div className="toggle-switch">
                    <span className="label">Google Maps Search</span>
                    <input type="checkbox" id="RightclickToGoogleMaps" name="RightclickToGoogleMaps" onChange={() => setRightclickToGoogleMaps(!rightclickToGoogleMaps)} />
                    <label htmlFor="RightclickToGoogleMaps">Toggle</label>
                </div>
            </div>
            <div className='update-btn-container'>
                <input type="button" className='btn btn-primary' name="Update" value="Update" onClick={update} />
            </div>
        </div>
        <div className='gmr-layout-right'>
            <a className='side-toggle' onClick={() => setShowLeftPanel(!showLeftPanel)}><i></i></a>
            <CesiumUI extrusionFactor={extrusion}
                      zones={zone}
                      srtm={srtm}
                      geoid={geoid}
                      homePosition = {homePosition}
                      homeOrientation = {homeOrientation}
                      permissibleHeights={permissibleHeights}
                      satelliteImagery={satelliteImagery}
                      shamshabadBuildings={shamshabadBuildings}
                      accessToken = {cesiumToken}
                      osmBuildings={osmBuildings}
                      runWayLayer={runWayLayer}
                      terrainOnly={terrainOnly}
                      rightclickToGoogleMaps={rightclickToGoogleMaps}
                      position={position}
                      orientation={orientation}
                      terrain={terrainOnly}
                      counter={counter}
                      date={selectedDate}
                      setViewer={setVwr}/>
        </div>
    </div>;
}
export default GMRInnovexDemo;