import RightArrow from '../../Assets/img/services/arrow-right.svg';
import React, {useState} from 'react';
import ImageModal from './ImageModal';

const ResourceTile = (props) => {
    const [show, setShow] = useState(false);
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
      };
    
    const handleMouseLeave = () => {
        setHover(false);
    };

    return (
        <div className="resource-card">
            <div className="resource-img">
                <img src={hover ? props.lowvideo : props.image} alt="" 
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => setShow(true)} 
                />
            </div>
            <div className="resource-content">
                <h3>{props.title}</h3>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Lorem ipsum</p> */}
                <div className="resource-link">
                    <a className="test" onClick={() => setShow(true)}>Learn More <img src={RightArrow} alt="" /></a>
                </div>
            </div>
            {
                show && <ImageModal image={props.highvideo} show={setShow} title={props.title} />
            }
        </div>
    );
}

export default ResourceTile;