import {Modal} from 'react-bootstrap';
import React from 'react';
import Profile from './Profile';

const ProfileModal = (props) => {

    const onUpdate = (data) => {
        if(props.onUpdate)
            props.onUpdate(data);
        props.setShowModal(false);
    };

    return (
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName=""
                className="member-edit-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>{!!props.createUser ? "Add" : "Edit"} Member</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Profile user={props.user} isAdmin={props.isAdmin} createUser={props.createUser} onUpdate={onUpdate} />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
    );
}

export default ProfileModal;