import React, {useContext, useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../Contexts/UserContext';
import { adminService } from '../../Services/adminService';
import LanduseEdit from './LanduseEdit';
import EditLogo from '../../Assets/img/icon-edit-grey.svg';
import ViewLogo from '../../Assets/img/icon-view-grey.svg';
import ShowJson from './ShowJson';

const Landuse = () => {
    const [landuses, setLanduses] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const {user} = useContext(UserContext);
    const [editLanduse, setEditLanduse] = useState(null);
    const [viewModal, setViewModal] = useState(false);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        adminService.getLanduses(user.originalRegion).then((data) => {
            setLanduses(data);
        }).catch((e) => {
            toast.error("Unable to retrieve Landuses. Error: " + e);
        });
    };

    const edit = (landuse, isEdit) => {
        setEditLanduse(landuse);
        if(isEdit)
            setShowModal(true);
        else 
            setViewModal(true);
    };

    const renderRow = (landuse) => {
        return <tr key={landuse.name}>
                    <td><img src={ViewLogo} onClick={() => edit(landuse, false)} /> </td>
                    {
                        user.isSuperAdmin &&
                        <td><img src={EditLogo} alt="" onClick={() => edit(landuse, true)}/></td>
                    }
                    <td>{landuse.name}</td>
                    <td>{landuse.description}</td>
                </tr>;
    }

    return (
        <div className="form-content">
            <div className="form-head">
                <h3>Land Use</h3>
                {
                    user.isSuperAdmin && 
                    <a className="add-link" onClick={() => edit(null, true)}>+ Add a Land Use</a>
                }
            </div>
            <div className="admin-table-responsive">
                <table className="table table-style">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {
                                user.isSuperAdmin &&
                                <th scope="col"></th>
                            }
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            landuses && 
                            landuses.map((config) => renderRow(config))
                        }
                    </tbody>
                </table>
            </div>
            {
                showModal && <LanduseEdit landuse={editLanduse} setShowModal={setShowModal} refresh={refresh} />
            }
            {
                viewModal && <ShowJson setShowModal={setViewModal} title={"Landuse - " + editLanduse.name} json={editLanduse.attribute_schema} />
            }
        </div>
    );
}

export default Landuse;