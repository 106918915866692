import {Modal} from 'react-bootstrap';
import React, {useContext, useEffect, useState} from 'react';
import { adminService } from '../../Services/adminService';
import toast from 'react-hot-toast';
import { UserContext } from '../../Contexts/UserContext';

const MemberTokens = (props) => {
    const {user} = useContext(UserContext);
    const [tokenData, setTokenData] = useState(
        {
            credit: "C",
            amount: "",
            description: ""
        }
    );

    const handleInputChange = (event) => {
        const target = event.target;

        setTokenData({
          ...tokenData,
          [target.name]: target.value
        });
    };

    const submit = () => {
        const amount = parseInt(tokenData.amount);
        if(amount != tokenData.amount.trim()) {
            toast.error("Please enter a valid integer for the amount");
            return;
        }
        var postData = {
            last_transaction_id: props.user.tokens.last_transaction_id,
            qty: amount,
            type: tokenData.credit,
            details: {
                description: tokenData.description
            }
        };
        adminService.creditDebitTokens(props.user.mkey, props.user.region, user.authId, postData).then((data) => {
            toast.success("Successfully credited/debited the amount in the wallet");
            props.refresh();
            props.setShowModal(false);
        }).catch((error) => {
            toast.error("Unable to credit/debit the amount in the wallet " + error);
            console.error(error);
        });
    };

    return (
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName=""
                className="member-create-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Credit/Debit Amount</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="credit">Credit/Debit<span className="required">*</span></label>
                                <select className="form-select" name="credit" value={tokenData.credit} onChange={(e) => handleInputChange(e)}>
                                    <option value="C">Credit</option>
                                    <option value="D">Debit</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="amount">Amount <span className="required">*</span></label>
                                <input type="text" className="form-control" id="amount" name="amount" value={tokenData.amount} onChange={(e) => handleInputChange(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="description">Description <span className="required">*</span></label>
                                <input type="text" className="form-control" id="description" name="description" value={tokenData.description} onChange={(e) => handleInputChange(e)}/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.setShowModal(false)}>CANCEL</button>
                    <button type="button" className="btn btn-danger" onClick={() => submit()}>SUBMIT</button>
                </Modal.Footer>
            </Modal>
    );
}

export default MemberTokens;