import TextEditor from "./TextEditor";

const MessageBox = (props) => {
    const {showSubject, messageRef, subjectRef, mailType, setMailType, showEditor} = props;

    return (
        <div className="message-box">
            {
                mailType && 
                <div className="subscription-group">
                    <div className="custom-radio">
                        <input type="radio" id="promotional" name="mailType" value="promotional" onChange={() => setMailType("promotional")} checked={mailType == 'promotional'}/>
                        <label htmlFor="promotional">Promotional</label>
                    </div>
                    <div className="custom-radio">
                        <input type="radio" id="transactional" name="mailType" value="transactional" onChange={() => setMailType("transactional")} checked={mailType == 'transactional'}/>
                        <label htmlFor="transactional">Transactional</label>
                    </div>
                    <div className="custom-radio">
                        <input type="radio" id="critical" name="mailType" value="critical" onChange={() => setMailType("critical")} checked={mailType == 'critical'}/>
                        <label htmlFor="critical">Critical</label>
                    </div>
                </div>
            }
            {
                showSubject &&
                <div className="subject">
                    <label className="form-label" htmlFor="message-box-subject">Subject <span className="required">*</span></label>
                    <input className="input form-control" ref={subjectRef}/>
                </div>
            }            
            <div className="message">
                <label className="form-label" htmlFor="message-box-input">Message <span className="required">*</span></label>
                {
                    (showEditor ?? true) ?
                    <TextEditor messageRef={messageRef} body={props.body}/> :
                    <div id="mail-content" ref={messageRef}></div>
                }
            </div>  
        </div>
    )
}

export default MessageBox;