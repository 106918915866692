import {fetchWrapper} from "../Helpers/fetchWrapper";
import { apiService } from './apiService';

const run = (region, name, data) => {
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/state-machine/${name}/run`, data);
};

const getStatus = (region, name, executionId) => {
    const data = {
        "startId": 0
    };
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/state-machine/${name}/${executionId}/status`, data);
};

const stop = (region, name, executionId) => {
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/state-machine/${name}/${executionId}/stop`);
};

const callback = (region, name, executionId, data) => {
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/state-machine/${name}/${executionId}/callback`, data);
};

export const stateMachineService = {
    run,
    getStatus,
    stop,
    callback
};
