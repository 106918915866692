import { GoogleMap, GroundOverlay, Polyline, useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AOIOptions } from "../AOIOptions";
import { UserContext } from "../../Contexts/UserContext";
import { MapUtils } from "../MapUtils";

const appConfig = window.config;

const LeakageMap = (props) => {
    const { user } = useContext(UserContext);
    const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey: appConfig.mapApiKey, libraries });
    const mapOptions = {...AOIOptions.satelliteMapOptions, zoomControl: props.showZoom};
    const imageFile = props.imageFile;
    const [pipelineOptions, setPipelineOptions] = useState({ firstOptions: AOIOptions.defaultPipelineOptions });
    const [rouOptions, setRouOptions] = useState({ firstOptions: AOIOptions.defaultPipelineOptions });

    const zoom = AOIOptions.defaultZoom + 3;

    useEffect(() => {
        var defaultSettings = {...AOIOptions.defaultPipelineOptions}
        if(user.userSettings?.dashboard?.deformationLineThickness)
            defaultSettings = {...defaultSettings, strokeWeight: user.userSettings?.dashboard?.deformationLineThickness};

        if (!!user.userSettings?.dashboard?.landPipelineSettings) {
            setPipelineOptions(AOIOptions.getPipelineSettingsWithDefaults(user.userSettings?.dashboard?.landPipelineSettings, defaultSettings));
        }
        if (!!user.userSettings?.dashboard?.landRouSettings) {
            setRouOptions(AOIOptions.getPipelineSettingsWithDefaults(user.userSettings?.dashboard?.landRouSettings, defaultSettings));
        }
    }, []);

    const getCoordinates = (timeline) => {
        var coordinates = new google.maps.LatLngBounds(
            new google.maps.LatLng(timeline.miny, timeline.minx),
            new google.maps.LatLng(timeline.maxy, timeline.maxx)
        );
        return coordinates;
    };

    const onMapLoad = useCallback((map) => {
        if(props.onMapLoad)
            props.onMapLoad(map);
    }, []);

    return (
        isLoaded &&
        <GoogleMap
            center={props.data.center}
            zoom={zoom}
            mapContainerStyle={AOIOptions.mapContainerStyle}
            options={mapOptions}
            onLoad={onMapLoad}
        >  
            {
                imageFile != null && props.bounds != null &&
                <GroundOverlay
                    url={imageFile}
                    key={imageFile}
                    bounds={getCoordinates(props.bounds)}
                />
            }
            {
                props.data.rouGeometry && rouOptions.secondLine &&
                props.data.rouGeometry.map((rou, index) => <Polyline
                                            key={"rouBorder"+index}
                                            path={MapUtils.geoJsonToCoordinates(rou)}
                                            options={rouOptions.secondOptions}
                                        />)
            }
            {
                props.data.rouGeometry &&
                props.data.rouGeometry.map((rou, index) => <Polyline
                                            key={"rouLine"+index}
                                            path={MapUtils.geoJsonToCoordinates(rou)}
                                            options={rouOptions.firstOptions}
                                        />)
            }
            {
                props.data.pipelineGeometry && pipelineOptions.secondLine &&
                <Polyline
                    key="polylineBorder"
                    path={MapUtils.geoJsonToCoordinates(props.data.pipelineGeometry)}
                    options={pipelineOptions.secondOptions}
                />
            }
            {
                props.data.pipelineGeometry &&
                <Polyline
                    key="polyline"
                    path={MapUtils.geoJsonToCoordinates(props.data.pipelineGeometry)}
                    options={pipelineOptions.firstOptions}
                />
            }

        </GoogleMap>
    );
}

export default LeakageMap;