import { useState } from 'react';
import { useContext } from 'react';
import { AOIContext } from '../../Contexts/AOIContext';
import { UserContext } from '../../Contexts/UserContext';
import { useNavigate } from 'react-router';
import SubscriptionConfirmation from '../AOIWizzard/SubscriptionConfirmation';

const SubscribeTimelineR1 = (props) => {
    const navigate = useNavigate();
    const {isServiceAvailable, isAreaTooLargeToService} = useContext(AOIContext);
    const [isSubscribed, setIsSubscribed] = useState(props.isSubscribed);
    const {user, expensesPerMonth} = useContext(UserContext);
    const [showSubscriptionConfirmation, setShowSubscriptionConfirmation] = useState(false);
    const serviceInfo = "This service provides monthly satellite images and default email notifications for any major AOI changes";
    const currentUserTokens = user.isImpersonated == true ? user.impersonatedUser.tokens.amount : user.tokens.amount;

    const subscribeService = (subscribe) => {
        if(!props.canSubscribe)
            return;
        props.subscribe(props.service.name, subscribe, null, props.displayName);
        setIsSubscribed(subscribe);
    };

    const getNoServiceMessage = () => {
        const serviceAvailable = isServiceAvailable(props.aoi);
        const areaTooLarge = isAreaTooLargeToService(props.aoi, props.service.name);
        if(!serviceAvailable && !areaTooLarge)
            return "The service is currently not available in this location. But we will communicate with you as soon as it becomes available.";
        if(!serviceAvailable && areaTooLarge)
            return "The area is too large and the service is currently not available in this location. We will communicate with you as soon as it becomes available.";
        if(serviceAvailable && areaTooLarge)
            return "Area is too large to service";
        return "Area is too large or service is not available in the region";
    };

    const hasPricing = () => {
        return props.service.price != null && 
                props.pricingDetails != null && 
                props.pricingDetails[props.service.name] != null &&
                props.pricingDetails[props.service.name][""] != null;
    };

    const addTokens = () => {
        navigate("/payment");
    };

    const RenderSingleUsecase = () => {
        let price = "";
        if(props.pricingDetails != null && props.pricingDetails[props.service.name] != null && props.pricingDetails[props.service.name][""] != null)
            price = props.pricingDetails[props.service.name][""];
        if(props.isSubscribed)
        {
            return <div>
                        <div className="service-content">{serviceInfo}</div>
                        <>
                        {
                            showSubscriptionConfirmation ?
                            <>
                                <SubscriptionConfirmation 
                                    handleConfirm={() => subscribeService(!props.isSubscribed)} 
                                    handleCancel={()=>setShowSubscriptionConfirmation(false)}
                                    pricingDetails={props.pricingDetails}
                                    subscriptionRequest={{
                                        serviceName: props.service.name,
                                        isSubscribe: !props.isSubscribed
                                    }}
                                />
                            </>
                            :
                            <>
                                <div>You have subscribed to {props.displayName}</div>
                                <div>$ {price} / month</div>
                                {
                                    props.canSubscribe && 
                                    <button type="button" className="btn btn-primary ml-auto" onClick={()=>setShowSubscriptionConfirmation(true)}>
                                        Unsubscribe
                                    </button>
                                }
                            </>
                        }
                        </>
                        
                    </div>;
        }
        if(hasPricing() && price == -1) {
            return <div>
                        <div className="service-content">{serviceInfo}</div>
                        <div>{getNoServiceMessage()}</div>
                    </div>;
        } 
        if(props.canSubscribe)
        {
            if(hasPricing() && currentUserTokens < expensesPerMonth + price) {
                return <div>
                            <div className="service-content">{serviceInfo}</div>
                            <div>
                                Please add <b>${expensesPerMonth + price - currentUserTokens}</b> to your wallet to subscribe. <br /><br />
                                <span><>(It costs </>
                                    {
                                        expensesPerMonth > 0 && 
                                        <> ${expensesPerMonth} for your existing services and </>
                                    }
                                    <> ${price} for this service. You have only ${currentUserTokens}.)</></span><br /><br />
                            </div>
                            <div><a className="tokens-add" onClick={addTokens}>Add Amount</a></div>
                        </div>;
            }

            return <div>
                        <div className="service-content">{serviceInfo}</div>
                        <>
                        {
                            showSubscriptionConfirmation ?
                            <>
                                <SubscriptionConfirmation 
                                    handleConfirm={()=>subscribeService(!props.isSubscribed)} 
                                    handleCancel={()=>setShowSubscriptionConfirmation(false)}
                                    pricingDetails={props.pricingDetails}
                                    subscriptionRequest={{
                                        serviceName: props.service.name,
                                        isSubscribe: !props.isSubscribed
                                    }}
                                />
                            </>
                            :
                            <>
                                <div>Would you like to subscribe to {props.displayName}?</div>
                                {
                                    hasPricing() && <div>$ {price} / month</div>
                                }
                                <button type="button" className="btn btn-primary ml-auto" onClick={()=>setShowSubscriptionConfirmation(true)}>
                                    Subscribe
                                </button>
                            </>
                        } 
                        </>
                    </div>;
        }
        return <div>
                    <div className="service-content">{serviceInfo}</div>
                    <div>You are not subscribed to {props.displayName}.</div>
                    {
                        hasPricing() && <div>$ {price} / month</div>
                    }
                </div>;
    };

    const RenderSubscribeOptions = () => {
        return <>
                <input type="radio" className="switch-input" name={props.service.name} value="Subscribe" id={"Subscribe" + props.service.name} 
                                checked={isSubscribed} onClick={() => subscribeService(true)} onChange={() => {}} />
                <label htmlFor={"Subscribe" + props.service.name} className="switch-label switch-label-off">Subscribe</label>
                <input type="radio" className="switch-input" name={props.service.name} value="Unsubscribe" id={"UnSubscribe" + props.service.name} 
                    checked={!isSubscribed} onClick={() => subscribeService(false)} onChange={() => {}} />
                <label htmlFor={"UnSubscribe" + props.service.name} className="switch-label switch-label-on">Unsubscribe</label>
                <span className="switch-selection"></span>
            </>;
    };

    const RenderSubscription = () => {
        if(!hasPricing())
            return <div className="subscribe-switch">{RenderSubscribeOptions()}</div>;

        let subscriptionCost = props.pricingDetails[props.service.name][""];
        if(subscriptionCost == -1) 
            return <span>Area is too large or service is not available in the region</span>;

        if(currentUserTokens < expensesPerMonth + subscriptionCost)
            return <span>Insufficient Amount. Please add ${expensesPerMonth + subscriptionCost - currentUserTokens} to the wallet.</span>;
        
        return <div className="subscribe-switch">
                    <div>
                        {RenderSubscribeOptions()}
                    </div>
                    <div>
                        $ {props.pricingDetails[props.service.name][""]} / month 
                    </div>
                </div>;
    }

    if(props.isSingleUsecase)
        return <RenderSingleUsecase></RenderSingleUsecase>;

    return  <div className="col-12" key={props.service.name}>
                <div className="service-row">
                    <div className='left-content'>
                        <label className="service-label"> {props.displayName}
                        </label>
                        <p>{serviceInfo}</p>
                    </div>
                    <div className='right-content'>
                        {
                            RenderSubscription()
                        }
                </div>
                </div>
            </div>;
}

export default SubscribeTimelineR1;