
import { ResizeGrip, TextMarker, FontFamilyPanel, ColorPickerPanel, SvgHelper, OpacityPanel } from "markerjs2";

export class CustomCalloutMarker extends TextMarker {
  /**
   * String type name of the marker type.
   *
   * Used when adding {@link MarkerArea.availableMarkerTypes} via a string and to save and restore state.
   */
  static typeName = "CalloutMarker"

  /**
   * Marker type title (display name) used for accessibility and other attributes.
   */
  static title = "Custom Callout marker"
  /**
   * SVG icon markup displayed on toolbar buttons.
   */
  static icon = `<svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-square" viewBox="0 0 16 16"> <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" fill="white"></path> </svg>`;
  /**
   * SVG icon markup displayed on color panel
   */
  fillIcon = `<svg viewBox="0 0 24 24"><path d="M19 11.5s-2 2.17-2 3.5a2 2 0 002 2 2 2 0 002-2c0-1.33-2-3.5-2-3.5M5.21 10L10 5.21 14.79 10m1.77-1.06L7.62 0 6.21 1.41l2.38 2.38-5.15 5.15c-.59.56-.59 1.53 0 2.12l5.5 5.5c.29.29.68.44 1.06.44s.77-.15 1.06-.44l5.5-5.5c.59-.59.59-1.56 0-2.12z"></path></svg>`;

  bgColor = "transparent"


  tipPosition = { x: 0, y: 0 }
  tipBase1Position = { x: 0, y: 0 }
  tipBase2Position = { x: 0, y: 0 }
  tipMoving = false
  opacity = 1;
  /**
   * Creates a new marker.
   *
   * @param container - SVG container to hold marker's visual.
   * @param overlayContainer - overlay HTML container to hold additional overlay elements while editing.
   * @param settings - settings object containing default markers settings.
   */
  constructor(container, overlayContainer, settings) {
    super(container, overlayContainer, settings)

    this.color = settings.defaultStrokeColor
    this.bgColor = settings.defaultFillColor
    this.fontFamily = settings.defaultFontFamily

    this.defaultSize = { x: 100, y: 30 }
    

    this.setBgColor = this.setBgColor.bind(this)
    this.getTipPoints = this.getTipPoints.bind(this)
    this.positionTip = this.positionTip.bind(this)
    this.setTipPoints = this.setTipPoints.bind(this)
    this.setOpacity = this.setOpacity.bind(this)

    this.colorPanel = new ColorPickerPanel(
      "Text color",
      settings.defaultColorSet,
      this.color
    )
    this.colorPanel.onColorChanged = this.setColor

    this.bgColorPanel = new ColorPickerPanel(
      "Fill color",
      settings.defaultColorSet,
      this.bgColor,
      this.fillIcon
    )
    this.bgColorPanel.onColorChanged = this.setBgColor

    this.fontFamilyPanel = new FontFamilyPanel(
      "Font",
      settings.defaultFontFamilies,
      settings.defaultFontFamily
    )
    this.fontFamilyPanel.onFontChanged = this.setFont

    this.opacityPanel = new OpacityPanel(
        'Opacity',
        settings.defaultOpacitySteps,
        this.opacity
      );
    this.opacityPanel.onOpacityChanged = this.setOpacity;

    this.tipGrip = new ResizeGrip()
    this.tipGrip.visual.transform.baseVal.appendItem(
      SvgHelper.createTransform()
    )
    this.controlBox.appendChild(this.tipGrip.visual)
  }

  /**
   * Returns true if passed SVG element belongs to the marker. False otherwise.
   *
   * @param el - target element.
   */
  ownsTarget(el) {
    return (
      super.ownsTarget(el) || this.tipGrip.ownsTarget(el) || this.tip === el
    )
  }

  createTip() {
    SvgHelper.setAttributes(this.bgRectangle, [
      ["fill", this.bgColor],
      ["rx", "10px"],
      ['opacity', this.opacity]
    ])

    this.tip = SvgHelper.createPolygon(this.getTipPoints(), [
      ["fill", this.bgColor],
      ['opacity', this.opacity]
    ])
    this.visual.appendChild(this.tip)
  }

  /**
   * Handles pointer (mouse, touch, stylus, etc.) down event.
   *
   * @param point - event coordinates.
   * @param target - direct event target element.
   */
  pointerDown(point, target) {
    if (this.state === "new") {
      super.pointerDown(point, target)
    }

    if (this.state === "creating") {
      this.createTip()
    } else if (this.tipGrip.ownsTarget(target)) {
      this.manipulationStartLeft = this.left
      this.manipulationStartTop = this.top
      this.tipMoving = true
    } else {
      super.pointerDown(point, target)
    }
  }

  /**
   * Handles pointer (mouse, touch, stylus, etc.) up event.
   *
   * @param point - event coordinates.
   */
  pointerUp(point) {
    if (this.tipMoving) {
      this.tipMoving = false
      this.isMoved = true
      super.pointerUp(point)
    } else {
      const isCreating = this.state === "creating"
      super.pointerUp(point)
      this.setTipPoints(isCreating)
      this.positionTip()
    }
  }

  /**
   * Handles marker manipulation (move, resize, rotate, etc.).
   *
   * @param point - event coordinates.
   */
  manipulate(point) {
    if (this.tipMoving) {
      const rotatedPoint = this.unrotatePoint(point)
      this.tipPosition = {
        x: rotatedPoint.x - this.manipulationStartLeft,
        y: rotatedPoint.y - this.manipulationStartTop
      }
      this.positionTip()
    } else {
      super.manipulate(point)
    }
  }

  /**
   * Sets marker's background/fill color.
   * @param color - new background color.
   */
  setBgColor(color) {
    if (this.bgRectangle && this.tip) {
      SvgHelper.setAttributes(this.bgRectangle, [["fill", color]])
      SvgHelper.setAttributes(this.tip, [["fill", color]])
    }
    this.bgColor = color
    this.fillColorChanged(color)
  }

  getTipPoints() {
    this.setTipPoints(this.state === "creating")
    return `${this.tipBase1Position.x},${this.tipBase1Position.y} ${this.tipBase2Position.x},${this.tipBase2Position.y} ${this.tipPosition.x},${this.tipPosition.y}`
  }

  setTipPoints(isCreating = false) {
    let offset = Math.min(this.height / 2, 15)
    let baseWidth = this.height / 5
    if (isCreating) {
      this.tipPosition = { x: offset + baseWidth / 2, y: this.height + 20 }
    }

    const cornerAngle = Math.atan(this.height / 2 / (this.width / 2))
    if (
      this.tipPosition.x < this.width / 2 &&
      this.tipPosition.y < this.height / 2
    ) {
      // top left
      const tipAngle = Math.atan(
        (this.height / 2 - this.tipPosition.y) /
          (this.width / 2 - this.tipPosition.x)
      )
      if (cornerAngle < tipAngle) {
        baseWidth = this.width / 5
        offset = Math.min(this.width / 2, 15)
        this.tipBase1Position = { x: offset, y: 0 }
        this.tipBase2Position = { x: offset + baseWidth, y: 0 }
      } else {
        this.tipBase1Position = { x: 0, y: offset }
        this.tipBase2Position = { x: 0, y: offset + baseWidth }
      }
    } else if (
      this.tipPosition.x >= this.width / 2 &&
      this.tipPosition.y < this.height / 2
    ) {
      // top right
      const tipAngle = Math.atan(
        (this.height / 2 - this.tipPosition.y) /
          (this.tipPosition.x - this.width / 2)
      )
      if (cornerAngle < tipAngle) {
        baseWidth = this.width / 5
        offset = Math.min(this.width / 2, 15)
        this.tipBase1Position = { x: this.width - offset - baseWidth, y: 0 }
        this.tipBase2Position = { x: this.width - offset, y: 0 }
      } else {
        this.tipBase1Position = { x: this.width, y: offset }
        this.tipBase2Position = { x: this.width, y: offset + baseWidth }
      }
    } else if (
      this.tipPosition.x >= this.width / 2 &&
      this.tipPosition.y >= this.height / 2
    ) {
      // bottom right
      const tipAngle = Math.atan(
        (this.tipPosition.y - this.height / 2) /
          (this.tipPosition.x - this.width / 2)
      )
      if (cornerAngle < tipAngle) {
        baseWidth = this.width / 5
        offset = Math.min(this.width / 2, 15)
        this.tipBase1Position = {
          x: this.width - offset - baseWidth,
          y: this.height
        }
        this.tipBase2Position = { x: this.width - offset, y: this.height }
      } else {
        this.tipBase1Position = {
          x: this.width,
          y: this.height - offset - baseWidth
        }
        this.tipBase2Position = { x: this.width, y: this.height - offset }
      }
    } else {
      // bottom left
      const tipAngle = Math.atan(
        (this.tipPosition.y - this.height / 2) /
          (this.width / 2 - this.tipPosition.x)
      )
      if (cornerAngle < tipAngle) {
        baseWidth = this.width / 5
        offset = Math.min(this.width / 2, 15)
        this.tipBase1Position = { x: offset, y: this.height }
        this.tipBase2Position = { x: offset + baseWidth, y: this.height }
      } else {
        this.tipBase1Position = { x: 0, y: this.height - offset }
        this.tipBase2Position = { x: 0, y: this.height - offset - baseWidth }
      }
    }
  }

  /**
   * Sets marker's opacity.
   * @param opacity - new opacity value (0..1).
   */
  setOpacity(opacity) {
    this.opacity = opacity;
    if (this.visual) {
      SvgHelper.setAttributes(this.bgRectangle, [['opacity', this.opacity.toString()]]);
      SvgHelper.setAttributes(this.tip, [['opacity', this.opacity.toString()]]);
    }
    this.stateChanged();
  }

  /**
   * Resize marker based on current pointer coordinates and context.
   * @param point
   */
  resize(point) {
    super.resize(point)
    this.positionTip()
  }

  positionTip() {
    SvgHelper.setAttributes(this.tip, [["points", this.getTipPoints()]])
    const translate = this.tipGrip.visual.transform.baseVal.getItem(0)
    translate.setTranslate(this.tipPosition.x, this.tipPosition.y)
    this.tipGrip.visual.transform.baseVal.replaceItem(translate, 0)
  }

  /**
   * Returns the list of toolbox panels for this marker type.
   */
  get toolboxPanels() {
    return [this.colorPanel, this.bgColorPanel, this.fontFamilyPanel, this.opacityPanel]
  }

  /**
   * Selects this marker and displays appropriate selected marker UI.
   */
  select() {
    this.positionTip()
    super.select()
  }

  /**
   * Returns current marker state that can be restored in the future.
   */
  getState() {
    const result = Object.assign(
      {
        bgColor: this.bgColor,
        tipPosition: this.tipPosition,
        opacity: this.opacity
      },
      super.getState()
    )
    result.typeName = CustomCalloutMarker.typeName

    return result
  }

  /**
   * Restores previously saved marker state.
   *
   * @param state - previously saved state.
   */
  restoreState(state) {
    const calloutState = state
    this.bgColor = calloutState.bgColor
    this.tipPosition = calloutState.tipPosition
    this.opacity = calloutState.opacity

    super.restoreState(state)
    this.createTip()
    this.setTipPoints()
  }

  /**
   * Scales marker. Used after the image resize.
   *
   * @param scaleX - horizontal scale
   * @param scaleY - vertical scale
   */
  scale(scaleX, scaleY) {
    super.scale(scaleX, scaleY)

    this.tipPosition = {
      x: this.tipPosition.x * scaleX,
      y: this.tipPosition.y * scaleY
    }

    this.positionTip()
  }
}
