import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { aoiService } from "../Services/aoiService";
import { eventService } from "../Services/eventService";
import React from 'react';
import moment from "moment";
import SingleTimeline from "../Components/Timeline/SingleTimeline";
import TwoTimelines from "../Components/Timeline/TwoTimelines";
import { useJsApiLoader } from "@react-google-maps/api";
import TimelineBar from "../Components/Timeline/TimelineBar";
import { UserContext } from '../Contexts/UserContext';
import AddEventModal from "../Components/Timeline/AddEventModal";
import EventsDelete from "../Components/Timeline/EventsDelete";
import SendUsecaseMessageModal from "../Components/Timeline/SendUsecaseMessageModal";

const appConfig = window.config;
const pollIntervalValues = [1,2,4,8,16,32,64,128,256,512,1024,1800];
const steadyStatePollInterval = pollIntervalValues[pollIntervalValues.length-1];

const DataSources = [
    {name: 'WAYBACK', resolution: 'high'},
    {name: 'GOOGLEEARTH', resolution: 'high', credit: '\u00A9Google Earth'},
    {name: 'SKYSAT', resolution: 'high', credit: '\u00A9Planet Labs'},
    {name: 'SENTINEL2', resolution: 'low'},
    {name: 'GEESentinelDW', resolution: 'low'}
]

function getPollInterval(index, t0){
    let timeDiff = Math.round((Date.now()-t0)/1000);
    return timeDiff < steadyStatePollInterval ? pollIntervalValues[index]*1000 : steadyStatePollInterval*1000;    
}

const TimelineR1 = (props) => {
    const navigate = useNavigate();
    const { aoiId, timelineParamView, eventId1, eventId2 } = useParams();
    const {user, siteCache, showAlert} = useContext(UserContext);
    const { state } = useLocation(); 
    const { dashboardView } = state || {dashboardView: siteCache?.dashboardView ?? "list"};
    const [aoi, setAoi] = useState(null);
    const [currentTimeline, setCurrentTimeline] = useState(null);
    const [latestTimeline, setLatestTimeline] = useState(null);
    const [view, setView] = useState("comparison");
    const [mapKey, setMapKey] = useState(0);
    const [allYears, setAllYears] = useState([]);
    const [yearToMonth, setYearToMonth] = useState([]);
    const [yearMonthToTimeline, setYearMonthToTimeline] = useState([]);
    const [fullTimelineData, setFullTimelineData] = useState([]);
    const [beforeDate, setBeforeDate] = useState(null);
    const [afterDate, setAfterDate] = useState(null);
    const [pollDelayIndex, setPollDelayIndex] = useState(0);
    const [pollTrigger, setPollTrigger] = useState(false);
    const [showAddEvent, setShowAddEvent] = useState(false);
    const [showDeleteEvents, setShowDeleteEvents] = useState(false);
    const [showSendUsecaseMessageModal, setShowSendUsecaseMessageModal] = useState(false);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: appConfig.mapApiKey,
        libraries
    });

    useEffect(() => {
        const isValidView = ['single','slider','comparison'].some(view => view == timelineParamView?.trim().toLowerCase());
        if(isValidView)
            setView(timelineParamView.trim().toLowerCase());
    }, [timelineParamView]);

    useEffect(() => {
        aoiService.get(user.mkey, user.region, aoiId)
        .then((data) => {
            const aoiData = data;
            if (aoiData?.subscribed_usecases?.length > 0 && aoiData.subscribed_usecases.some(u=>u.usecase.name == "AoIRGBImageR1")) {
                setAoi(aoiData);
            }
            else {
                showAlert('Please Subscribe to access this service');
                navigate("/portfolio");
            }  
        })
        .catch((error) => {
            console.error("Error in getting AOI Details", error);
            showAlert('AOI Not Found');
            navigate("/portfolio");
            return null;
        })
    }, [aoiId]);

    useEffect(() => {
        refresh();
    }, [aoiId, pollTrigger]);

    const refresh = () => {
        //this request needs to follow backoff logic
        eventService.getTimelineR1(user.mkey, user.region, aoiId).
        then((data) => {
            // filter the duplicate images:
            // obj.result["image-date"].split(" ")[0]  is used instead of just obj.result["image-date"]
            // as the events contain images which are just a few minutes apart on the same date
            // Hence only the date part is extracted leaving out the timepart    
            let uniq={};
            let originalData = data;
            data = data.filter(obj => !uniq[obj.result["image-date"].split(" ")[0]] && (uniq[obj.result["image-date"].split(" ")[0]] = true));

            // If landuse-dir-location is not available, then take it from one of the items that is available. 
            data.forEach((item) => { 
                if(item.result != null && item.result["landuse-dir-location"] == null) {
                    var date = item.result["image-date"].split(" ")[0];
                    var landuse = originalData.find((obj) => obj.result["image-date"].split(" ")[0] == date && obj.result["landuse-dir-location"] != null);
                    if(landuse != null)
                        item.result["landuse-dir-location"] = landuse.result["landuse-dir-location"];
                }
            })

            data = addEvent(data, originalData, eventId1);
            data = addEvent(data, originalData, eventId2);

            data.sort((a,b) => new Date(a.result["image-date"]) - new Date(b.result["image-date"]));

            //poll backoff:
            //if there are no events t0 should start as soon as events request returns. 
            //So t0 is now or the timestamp of first event. It would use t0 from then on for backoff
            let t0 = data.length == 0 ? Date.now() : new Date(data[data.length-1]["timestamp"]).getTime()
            setTimeout(()=>setPollTrigger(!pollTrigger), getPollInterval(pollDelayIndex, t0));
            setPollDelayIndex(pollDelayIndex+1);
       
            setTimelineInMap(data, eventId1, eventId2);
            return data;
        })
        .catch((error) => {
            console.error("Error in getting TimelineR1!", error)
            return [];
        });
    };

    const addEvent = (data, originalData, eventId) => {
        if(eventId == null)
            return data;
        var event = data.filter(d => d.ext_id == eventId);
        if(event != null && event.length > 0)
            return data;
        event = originalData.filter(d => d.ext_id == eventId);
        if(event == null || event.length == 0)
            return data;
        data = data.filter(d => d.result["image-date"].split(" ")[0] != event[0].result["image-date"].split(" ")[0]);
        data.push(event[0]);
        return data;
    };

    const findAndSetLatestTimeline = (data, eventId1, eventId2) => {
        var eventId = eventId1;
        if(timelineParamView != "single")
            eventId = eventId2;
        if(data.length >= 1) {
            if(eventId != null) {
                var eventTimeline = data.find(timeline => timeline.ext_id == eventId);
                if(eventTimeline != null) {
                    setLatestTimeline(eventTimeline);
                    return;
                }
            }
            if (!!latestTimeline) {
                if (!data.find(timeline => timeline.ext_id == latestTimeline.ext_id)) {
                    // If the latest timeline is not available in the newly fetched data, set the nearest date timeline
                    setLatestTimeline(getNearestDateTimeline(data, latestTimeline.result["image-date"]));
                    return;
                }
            }
            setLatestTimeline(data[data.length - 1]);
        } else {
            setLatestTimeline(null);
        }
    };

    const findAndSetCurrentTimeline = (data, eventId1, eventId2) => {
        var eventId = eventId2;
        if(timelineParamView != "single")
            eventId = eventId1;
        if(data.length >= 2) {
            if(eventId != null) {
                var eventTimeline = data.find(timeline => timeline.ext_id == eventId);
                if(eventTimeline != null) {
                    setCurrentTimeline(eventTimeline);
                    return;
                }
            }
            if (!!currentTimeline) {
                // If the current timeline is not available in the newly fetched data, set the nearest date timeline
                if (!data.find(timeline => timeline.ext_id == currentTimeline.ext_id)) {
                    setCurrentTimeline(getNearestDateTimeline(data, currentTimeline.result["image-date"]));
                    return;
                }
            }
            setCurrentTimeline(data[data.length - 2]);
        } else {
            setCurrentTimeline(null);
        }
    };

    const setTimelineInMap = (data, eventId1, eventId2) => {
        setFullTimelineData(data);
        findAndSetLatestTimeline(data, eventId1, eventId2);
        //cannot keep the below if loop nested in the above if loop because latestTimeline becomes non-null first
        //As a result, left side timeline(i.e. currentTimeline) will not have data set.
        findAndSetCurrentTimeline(data, eventId1, eventId2);

        let years = [];
        let yearMonth = {};
        let yearToMonthToTimeline = {};
        data.map((timeline) => {
            let date = new Date(timeline.result["image-date"]);
            if(yearMonth[date.getFullYear()] == null) {
                years.push(date.getFullYear());
                yearMonth[date.getFullYear()] = [];
            }
            if(!yearMonth[date.getFullYear()].includes(date.getMonth()))
                yearMonth[date.getFullYear()].push(date.getMonth());
            if(yearToMonthToTimeline[date.getFullYear() * 100 + date.getMonth()] == null) {
                yearToMonthToTimeline[date.getFullYear() * 100 + date.getMonth()] = [];
            }
            yearToMonthToTimeline[date.getFullYear() * 100 + date.getMonth()].push(timeline);            
        });
        setAllYears(years);
        setYearToMonth(yearMonth);        
        setYearMonthToTimeline(yearToMonthToTimeline);
    };

    const getNearestDateTimeline = (data, timelineDate) => {
        let testDate = new Date(timelineDate);
        let bestDate = data.length;
        let bestDiff = -(new Date(0,0,0)).valueOf();
        let currDiff = 0;

        for(let i = 0; i < data.length; ++i){
            currDiff = Math.abs(new Date(data[i].result["image-date"]) - testDate);
            if(currDiff < bestDiff){
                bestDate = i;
                bestDiff = currDiff;
            }   
        }
        return data[bestDate];
    }

    const changeView = (view) => {
        setView(view);
        setMapKey(mapKey+1);
        changeURL(view);
    };

    const selectTimeline = (timelineType, timeline, selectedMonth = null, selectedYear = null) => {
        if (selectedYear != null) {
            if (selectedMonth == null) {
                selectedMonth = yearToMonth[selectedYear][0];
            }
            const timelineYearMonth = getYearMonthFromTimeline(timelineType == "latest" ? latestTimeline : currentTimeline);
            const selectedYearMonth = selectedYear * 100 + selectedMonth;
            const timelinesInSelectedYearMonth = yearMonthToTimeline[selectedYearMonth];
            if (timelineYearMonth > selectedYearMonth) {
                // User going back in timeline, Get the latest timeline of the selected month 
                timeline = timelinesInSelectedYearMonth[timelinesInSelectedYearMonth.length - 1];                
            }
            else if (timelineYearMonth < selectedYearMonth) {
                // User going in future in timeline, Get the earliest timeline of the selected month
                timeline = timelinesInSelectedYearMonth[0];
            }
            else {
                // User selected the same month, Don't do anything
                return;
            }
        } 
        if (!!timeline && !!timelineType) {
            if (timelineType == "current")
                selectCurrentTimeline(timeline);
            else if (timelineType == "latest")
                selectLatestTimeline(timeline);
        }
        changeURL(view);
    };

    const changeURL = (view) => {
        if(view == "single") {
            if(latestTimeline != null)
                navigate(`/timeline/${aoiId}/single/${latestTimeline.ext_id}`);
        } else {
            if(latestTimeline != null && currentTimeline != null)
                navigate(`/timeline/${aoiId}/${view}/${currentTimeline.ext_id}/${latestTimeline.ext_id}`);
        }
    };

    const getYearMonthFromTimeline = (timeline) => {
        const timelineDate = new Date(timeline.result["image-date"]);
        return timelineDate.getFullYear() * 100 + timelineDate.getMonth();
    }

    const selectCurrentTimeline = async (timeline) => {
        const beforeDate = await getAOIDate(timeline);
        setBeforeDate(beforeDate);
        setCurrentTimeline(timeline);
    }

    const selectLatestTimeline = async (timeline) => {  
        const afterDate = await getAOIDate(timeline);
        setAfterDate(afterDate);
        setLatestTimeline(timeline);  
    }

    const getAOIDate = async (timeline) => {
        if (timeline && timeline?.result) {
            const imageDate = timeline.result["image-date"];
            const bounds = timeline.result?.bounds;
            const [centerLat, centerLng] = [((bounds?.maxy+bounds?.miny)/2).toFixed(6),((bounds?.maxx+bounds?.minx)/2).toFixed(6)];
            
            //fetch timezone only once per aoi and cache it for later use       
            const latLngStr = `lat:${centerLat.toString()},lng:${centerLng.toString()}`
            let timezoneData = JSON.parse(localStorage.getItem(latLngStr));            
            if (!timezoneData){                        
                timezoneData = await aoiService.getAOITimezone(centerLat, centerLng, imageDate);
                localStorage.setItem(latLngStr, JSON.stringify(timezoneData));
            }

            const aoiDate = new Date(new Date(imageDate).getTime() + (timezoneData.rawOffset * 1000) + (timezoneData.dstOffset * 1000)).toISOString();
            return moment(aoiDate).utc().format('Do MMMM YYYY h:mm A').toString();
        }
        return null;
    }

    return (
        <div className='page-body timeline-view'>
            <div className="header">
                <Link className="back-btn" to={`/portfolio/${dashboardView}${dashboardView == 'map' ? `/aoi/${aoiId}` : ''}`} ><i></i> Back</Link>
                Timeline View for:&nbsp; <b>{aoi && aoi.tag}</b>
                {
                    <>
                        <div className='view-btns'>
                            <a className={view == "single" ? "active" : ""} onClick={() => changeView("single")}>Single View</a>
                            <a className={view == "slider" ? "active" : ""} onClick={() => changeView("slider")}>Slider View</a>
                            <a className={view == "comparison" ? "active" : ""} onClick={() => changeView("comparison")}>Comparison View</a>
                            {
                                user.isDA && 
                                <>
                                    <a className="da-link photo-upload" onClick={() => setShowAddEvent(true)}><i></i></a>
                                    <a className="da-link reset" onClick={() => setShowDeleteEvents(true)}><i></i></a>
                                    <a className="da-link send-message" onClick={() => setShowSendUsecaseMessageModal(true)}><i></i></a>
                                </>
                            }
                        </div>
                    </>
                }
            </div>
            <div className="timeline-compare">
                {
                    view == "single" &&
                    <div className="timeline-year-month right full-view">
                        <TimelineBar 
                          years={allYears} 
                          yearToMonth={yearToMonth} 
                          yearMonthToTimeline={yearMonthToTimeline} 
                          fullTimelineData={fullTimelineData} 
                          initialTimeline={latestTimeline}
                          selectTimeline={selectTimeline} 
                          timelineType="latest"
                        />
                    </div>
                }
                {
                    view != "single" && 
                    <>
                        <div className={"separator-" + view}></div>
                        <div className={"timeline-year-month left " + view}>
                            <TimelineBar years={allYears} yearToMonth={yearToMonth} yearMonthToTimeline={yearMonthToTimeline} 
                                fullTimelineData={fullTimelineData} initialTimeline={currentTimeline} selectTimeline={selectTimeline} timelineType="current"/>
                        </div>
                        <div className={"timeline-year-month right " + view}>
                            <TimelineBar years={allYears} yearToMonth={yearToMonth} yearMonthToTimeline={yearMonthToTimeline} 
                                fullTimelineData={fullTimelineData} initialTimeline={latestTimeline} selectTimeline={selectTimeline} timelineType="latest"/>
                        </div>
                    </>
                }
            </div>
            <div className='timeline-map-container'>
                {
                    view != "single" && 
                    <>
                        <div className="before-date">
                            <div>{beforeDate}</div>
                        </div>
                        {
                            currentTimeline && 
                            (currentTimeline.result["image-credit"] || DataSources.find(d => d.name == currentTimeline.result["datasource-name"])?.credit) &&
                            <div className="before-credit">
                                Image Credits:&nbsp; 
                                {currentTimeline.result["image-credit"] || DataSources.find(d => d.name == currentTimeline.result["datasource-name"])?.credit}
                            </div>
                        }
                    </>
                }
                <div className='after-date'>
                    <div>{afterDate}</div>
                </div>
                {
                    latestTimeline && 
                    (latestTimeline.result["image-credit"] || DataSources.find(d => d.name == latestTimeline.result["datasource-name"])?.credit) &&
                    <div className="after-credit">
                        Image Credits:&nbsp;
                        {latestTimeline.result["image-credit"] || DataSources.find(d => d.name == latestTimeline.result["datasource-name"])?.credit}
                    </div>
                }
                <div className='timeline-map'>
                    {
                        isLoaded &&
                        (
                            view == "single" 
                            ? <SingleTimeline aoi={aoi} timeline={latestTimeline} showZoom={true} drawInfo={true} refresh={refresh} showToggleOptions={true} view={view}/>
                            : <TwoTimelines aoi={aoi} currentTimeline={currentTimeline} latestTimeline={latestTimeline} splitPanel={view == "comparison"} key={mapKey} drawInfo={true} refresh={refresh}/>
                        )
                    }
                </div>
            </div>
            {
                showAddEvent && <AddEventModal show={(show) => setShowAddEvent(show)} aoi={aoi} usecase="AoIRGBImageR1" refresh={refresh}></AddEventModal>
            }
            {
                showDeleteEvents && <EventsDelete show={(show) => setShowDeleteEvents(show)} aoi={aoi} usecase="AoIRGBImageR1" refresh={refresh} />
            }
            {
                showSendUsecaseMessageModal && <SendUsecaseMessageModal setShowSendUsecaseMessageModal={setShowSendUsecaseMessageModal} 
                    aoi={aoi} url={`/timeline/${aoiId}/single/${latestTimeline?.ext_id}`} comparisonUrl={`/timeline/${aoiId}/comparison/${currentTimeline?.ext_id}/${latestTimeline?.ext_id}`}/>
            }
        </div>
    );
}

export default TimelineR1;