import React, { useContext } from "react";
import { AOIWizzardContext } from "../../Contexts/AOIWizzardContext";
import { aoiService } from "../../Services/aoiService";
import AOIServicesFormMultipleUC from './AOIServicesFormMultipleUC';
import toast from 'react-hot-toast';
import { AOIContext } from "../../Contexts/AOIContext";
import { UserContext } from "../../Contexts/UserContext";

const AOIServicesForm = (props) => {
    const {user, selectedAOI, openAOIWizzardModal} = useContext(AOIWizzardContext);
    const {setSelectedAOIId, openSubscriptionSuccessModal, domainConfig} = useContext(AOIContext);
    const {reloadAOI} = useContext(UserContext);
    const isSingleUsecase = domainConfig.isSingleUsecase;

    const subscribeUsecase = (usecaseName, data, displayName) => {
        aoiService.subscribeUsecase(user.mkey, user.region, selectedAOI.member_aoi_id, usecaseName, data).then((result) => {
            setSelectedAOIId(selectedAOI.member_aoi_id);
            reloadAOI(selectedAOI.member_aoi_id, selectedAOI.ext_id);
            if (isSingleUsecase) {
                openAOIWizzardModal(false);
                openSubscriptionSuccessModal(true);
            }
            else {
                toast.success("Subscribed to the usecase " + displayName);
            }
        }).catch((error) => {
            console.log('Error in subscribing ', error, data);
            toast.error('Failed to Subscribe! ' + error);
        });
    };

    const unsubscribeUsecase = (usecaseName, displayName) => {
        aoiService.unsubscribeUsecase(user.mkey, user.region, selectedAOI.member_aoi_id, usecaseName).then((result) => {
            setSelectedAOIId(selectedAOI.member_aoi_id);
            reloadAOI(selectedAOI.member_aoi_id, selectedAOI.ext_id);
            toast.success("Unsubscribed to the usecase " + displayName);
        }).catch((error) => {
            console.log('Error in Unsubscribe', error);
            toast.error('Failed to Unsubscribe! ' + error);
        });
    };

    const close = () => {
        openAOIWizzardModal(false);
    };

    return (
        <>
            {
                props.availableServices?.length == 0 ? "No Services Available" : 
                <AOIServicesFormMultipleUC subscribeUsecase={subscribeUsecase} unsubscribeUsecase={unsubscribeUsecase} close={close} type="aoi" isCreateFlow={props.isCreateFlow}/>
            }
        </>
    );
}

export default AOIServicesForm;