import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import usePlacesAutoComplete from 'use-places-autocomplete';
import { AOIContext } from '../../Contexts/AOIContext';
import { UserContext } from '../../Contexts/UserContext';
import LocationAccess from './LocationAccess';

const LocationSearch = (props) => {
    const searchBarRef = useRef(null);
    const { searchLocation, handleClearLocationSearch } = useContext(AOIContext);
    const { showAlert } = useContext(UserContext);
    const { ready, value, setValue, suggestions: { status, data }, clearSuggestions } = usePlacesAutoComplete();
    const [showSuggestion, setShowSuggestion] = useState(true);
    const [currentLocationIntervalId, setCurrentLocationIntervalId] = useState(null);
    const [showLocationAccess, setShowLocationAccess] = useState(false);
    const [locationPermission, setLocationPermission] = useState("");
    const [showSearchBar, setShowSearchBar] = useState(false);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, false);
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        };
    }, []);

    useEffect(() => {
        if (value == '' || value == null) {
            setLocationInSearchBar(searchLocation);
        }
        else if (searchLocation == "" || searchLocation == null) {
            setValue("");
        }
    }, [searchLocation]);

    const setLocationInSearchBar = (location) => {
        setValue(location);
        setShowSuggestion(false);
    }

    const handleClickOutside = (event) => {
        if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
            clearSuggestions();
        }
    }

    const handleClearInput = (event) => {
        // User clicked search bar reset
        setValue('');
        handleClearLocationSearch();
    }

    const handleInputChange = (e) => {
        setValue(e.target.value);
        setShowSuggestion(true);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            searchForAddress(value);
            setShowSuggestion(false);
        }
    };

    const handleSelect = (e) => {
        const locationValue = e.target.innerText;
        searchForAddress(locationValue);
    };

    const searchForAddress = (locationValue) => {
        setValue(locationValue, false);
        clearSuggestions();
        if (locationValue && locationValue.trim()) {
            props.gotoSearchLocation(locationValue);
        }
        else {
            showAlert('Please Enter a valid Location/Address');
        }
    }

    const renderSuggestions = useCallback((() => {
        return (
            <div className='data-result'>
                {
                    data.map(
                        ({ place_id, description }) =>
                        (
                            <a key={place_id} className='data-item' onClick={handleSelect}>{description}</a>
                        )
                    )
                }
            </div>
        );
    }), [data])

    const updateCurrentLocation = () => {
        const onCurrentLocation = !props.showCurrentLocation;
        props.setShowCurrentLocation(onCurrentLocation);
        if (onCurrentLocation) {
            props.setCurrentLocationCoords(); // Set Initial current location 
            const intervalId = setInterval(() => {
                props.setCurrentLocationCoords(); // Update Current location every 10 seconds
            }, 10000);
            setCurrentLocationIntervalId(intervalId);
        }
        else {
            if (currentLocationIntervalId)
                clearInterval(currentLocationIntervalId);
        }
    };

    const checkAndUpdateCurrentLocation = () => {
        getLocationAccess(() => updateCurrentLocation(), (permission) => {
            setLocationPermission(permission);
            setShowLocationAccess(true);
        });
    }

    const getLocationAccess = (success, failure) => {
        return navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
                if (result.state == "granted") {
                    success();
                } else {
                    failure(result.state);
                }
            });
    };

    return (
        <div className='map-location-search location-search'>
            <div className={"search-toggle " + (showSearchBar ? "show" : "hide")} onClick={() => setShowSearchBar(!showSearchBar)}>
                <i></i>
            </div>
            {
                showSearchBar && 
                <div className='map-search-field'>
                    <div className="input-group">
                        <a className="input-clear" onClick={handleClearInput}>
                            <i></i>
                        </a>
                        <input
                            type="text"
                            id='location-input'
                            className="form-control location-control location"
                            ref={searchBarRef}
                            placeholder="Enter address"
                            value={value ?? ''}
                            onChange={handleInputChange}
                            onFocus={handleInputChange}
                            onKeyDown={handleKeyDown}
                            autoComplete="off"
                        />
                        {
                            !props.hideCurrentLocation &&
                            <button className={`btn btn-location current-location ${props.showCurrentLocation ? 'active' : ''}`} type="button" onClick={checkAndUpdateCurrentLocation}><i></i></button>
                        }
                    </div>
                    {showSuggestion && ready && status === 'OK' && renderSuggestions()}
                    {
                        showLocationAccess &&
                        <LocationAccess permission={locationPermission} show={setShowLocationAccess} />
                    }
                </div>
            }
        </div>
    );
}

export default LocationSearch;