import {Modal} from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import * as markerjs2 from 'markerjs2';
import TransparentImg from '../../Assets/img/transparent-img.png';
import { CustomCalloutMarker } from '../Annotations/CustomCallOutMarker';

const ImageAnnotationModal = (props) => {
    const {timeline, setShowImageAnnotationModal, annotation, sourceImageUrl, targetImageUrl, saveImageAnnotation, deleteAnnotation} = props;
    const targetRef = useRef();
    const sourceRef = useRef();
    const transRef = useRef();
    const [selectedMeasurementLength, setSelectedMeasurementLength] = useState(0);
    let markerState = annotation?.markerjs_json?.state ?? null;
    let markerUrl = annotation?.markerjs_json?.dataUrl ?? null;
    let sourceImage, targetRoot;
    
    let isDragging = false;
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        isDragging = true;
        const offsetX = e.clientX - position.x;
        const offsetY = e.clientY - position.y;

        const handleMouseMove = (e) => {
            if(isDragging) {
                const newX = e.clientX - offsetX;
                const newY = e.clientY - offsetY;
                setPosition({ x: newX, y: newY });
            }
        };

        const handleMouseUp = () => {
            isDragging = false;
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const showMarkerArea = (target) => {
        sourceImage = transRef.current;
        targetRoot = sourceImage.parentElement;
        targetRef.current.style.zIndex = 3;

        target.style.zIndex = 1;
        const markerArea = new markerjs2.MarkerArea(sourceImage);
        markerArea.availableMarkerTypes = [CustomCalloutMarker, ...markerArea.ALL_MARKER_TYPES];

        // enable redo, notes, zoom, and clear buttons
        markerArea.uiStyleSettings.redoButtonVisible = true;
        markerArea.uiStyleSettings.notesButtonVisible = true;
        markerArea.uiStyleSettings.zoomButtonVisible = true;
        markerArea.uiStyleSettings.zoomOutButtonVisible = true;
        markerArea.uiStyleSettings.clearButtonVisible = true;

        // render only the marker layer without the original image
        markerArea.renderMarkersOnly = true;
        
        markerArea.targetRoot = targetRoot;
        markerArea.show();
        // if previous state is present - restore it
        if (markerState) {
          markerArea.restoreState(markerState);
        }

        // Fired when user presses the save button 
        markerArea.addEventListener("render", event => {
            target.src = event.dataUrl;
            // save the state & Url of MarkerArea
            markerState = event.state;
            markerUrl = event.dataUrl; 
            target.style.zIndex = 3;
            saveImageAnnotation(event.state, event.dataUrl);
        });

        // When a user presses the close button
        markerArea.addEventListener("beforeclose", event => {
            if (!confirm("Do you want to discard changes ?")) {
              event.preventDefault();
            }
        });

        // Fired after the UI closes.
        markerArea.addEventListener("close", event => {
            target.style.zIndex = 3;
        })

        // Fired when a marker is selected.
        markerArea.addEventListener("markerselect", event => {
            if (event.marker.typeName == "MeasurementMarker") {
                onMeasurementChange(event.marker);
                event.marker.onStateChanged = onMeasurementChange;
            }
        })

        // Fired when a marker is deselected.
        markerArea.addEventListener("markerdeselect", event => {
            setSelectedMeasurementLength(0);
        })
    }

    const onMeasurementChange = (e) => {
        let length = calculateLength(e.x1, e.y1, e.x2, e.y2)
        setSelectedMeasurementLength(length);
    }

    const calculateLength = (x1, y1, x2, y2) => {
        let bounds = timeline.result.bounds;
        let E = bounds.maxx, W = bounds.minx, N = bounds.maxy, S = bounds.miny;
        const ImageWidth = 700, ImageHeight = 700 * (N - S)/(E - W); // Width & Height of Image container in Image annotation Modal
        let p1 = imageToGeographicCoordinates(x1, ImageHeight-y1, E, W, N, S, ImageWidth, ImageHeight);
        let p2 = imageToGeographicCoordinates(x2, ImageHeight-y2, E, W, N, S, ImageWidth, ImageHeight);
        return distanceBetweenCoordinates(p1[0], p1[1], p2[0], p2[1])
    }

    // function to convert from image coordinates to geographic coordinates
    // px, py are the pixel coordinates of the image from the bottom left corner
    const imageToGeographicCoordinates = (px, py, east, west, north, south, imgWidthPix, imgHeightPix) => {
        const x = west + (east - west) * px / imgWidthPix;
        const y = south + (north - south) * py / imgHeightPix;
        return [x, y];
    }

    //function to find distance between two geographic coordinates
    const distanceBetweenCoordinates = (lon1, lat1, lon2, lat2) => {
        const R = 6371e3; // metres
        let φ1 = lat1 * Math.PI / 180; // φ, λ in radians
        let φ2 = lat2 * Math.PI / 180;
        let Δφ = (lat2-lat1) * Math.PI / 180;
        let Δλ = (lon2-lon1) * Math.PI / 180;

        let a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return (R * c); // in metres
    }

    return (
        <>
            <Modal
                show={() => setShowImageAnnotationModal(true)}
                onHide={() => setShowImageAnnotationModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog"
                size='lg'
                className={`image-annotation-modal draggable-modal ${isDragging ? 'dragging' : ''}`}
                style={{ top: position.y, left: position.x }}
            >
                <Modal.Header closeButton onMouseDown={handleMouseDown}>
                    <Modal.Title>
                        <h5>Image Annotation</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="distance">Distance: {selectedMeasurementLength} m</p>
                    <div className="image-container">
                        <img src={TransparentImg} className="trans-img" ref={transRef} onClick={() => showMarkerArea(targetRef.current)}></img>
                        <img src={sourceImageUrl} className="source-img" ref={sourceRef}></img>
                        <img src={targetImageUrl} className="target-img" ref={targetRef} onLoad={() => showMarkerArea(targetRef.current)}></img>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        annotation.id &&
                        <button className='btn btn-danger delete-img-annotation' onClick={() => deleteAnnotation(annotation.id)}>Delete this Annotation</button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ImageAnnotationModal;