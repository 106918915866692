import React, { useContext, useEffect, useState } from 'react';
import EditLogo from '../../Assets/img/icon-edit-grey.svg';
import DeleteLogo from '../../Assets/img/icon-delete-grey.svg';
import SearchLogo from '../../Assets/img/icon-search-white.svg';
import memberSettingsIcon from '../../Assets/img/icon-member-settings.svg';
import memberDetailsIcon from '../../Assets/img/icon-member-details.svg';
import memberTokensIcon from '../../Assets/img/icon-member-tokens.svg';


import ShowJson from './ShowJson';
import MemberDelete from './MemberDelete';
import MemberEdit from './MemberEdit';
import toast from 'react-hot-toast';
import { adminService } from '../../Services/adminService';
import { UserContext } from '../../Contexts/UserContext';
import SettingsModal from './SettingsModal';
import MemberTokens from './MemberTokens';
import BillingSubscriptions from './BillingSubscriptions';
import { useNavigate, useParams } from 'react-router';

const Member = () => {
    let { authId } = useParams();
    const navigate = useNavigate();
    const [showViewModal, setShowViewModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showTokensModal, setShowTokensModal] = useState(false);
    const { user, impersonateUser, removeUserFromImpersonatedUserList } = useContext(UserContext);
    const [currentUser, setCurrentUser] = useState(null);
    const [mkey, setMkey] = useState(null);
    const [region, setRegion] = useState(null);
    const [lastAuthId, setLastAuthId] = useState("");
    const [counter, setCounter] = useState(1);

    useEffect(() => {
        searchUserByAuthId(authId);
    }, [authId]);

    const searchUserByAuthId = (userAuthId) => {
        setLastAuthId(userAuthId?.toLowerCase());
        adminService.getUser(userAuthId?.toLowerCase()).then((data) => {
            setMkey(data.mkey);
            setRegion(data.region);
            setCurrentUser(data);
            setCounter(counter + 1);
            impersonateUser(true, data);
        }).catch((e) => {
            toast.error("Unable to retrieve the user. Error: " + e);
            console.error(e);
        });
    };

    const refresh = () => {
        searchUserByAuthId(authId);
    };

    const onDeleteUser = () => {
        removeUserFromImpersonatedUserList(authId);
        impersonateUser(false);
        navigate("/");
    };

    return (
        <div className="page-body">
            <div className='container'>
                <div className='member-details'>
                    {
                        currentUser &&
                        <div className="form-content">
                            <div className="form-head">
                                <h3>Member Detail :  <span>{currentUser["auth-id"]}</span></h3>
                            </div>
                            <div className='member-card'>
                                <div className='member-row'>
                                    <div className='member-info'>
                                        <label>Auth ID : </label> {currentUser["auth-id"]}
                                    </div>
                                    <div className='member-info'>
                                        <label>Roles : </label> {currentUser.roles && currentUser.roles.join(", ")}  
                                        {
                                            user.isSuperAdmin && 
                                            <a className='roles-edit-link' onClick={() => setShowEditModal(true)}><img src={EditLogo} alt="" />Edit Roles</a>
                                        }
                                        
                                    </div>
                                    <div className='member-info'>
                                        <label>Region : </label> {region}
                                    </div>
                                </div>
                                <div className='member-actions'>
                                    <a className="test" onClick={() => setShowSettingsModal(true)}><img src={memberSettingsIcon} alt="" />Settings</a> &nbsp;
                                    <a className="test" onClick={() => setShowViewModal(true)}><img src={memberDetailsIcon} alt="" />Details</a> &nbsp;
                                    {
                                        (user.isAdmin || user.isCustomerService) 
                                        ? <a className="test" onClick={() => setShowTokensModal(true)}><img src={memberTokensIcon} alt="" />Balance ($ {currentUser.tokens?.amount})</a>
                                        : <><img src={memberTokensIcon} alt="" />Balance ($ {currentUser.tokens?.amount})</>
                                    }
                                    {
                                        user.isSuperAdmin &&
                                        <img src={DeleteLogo} alt="" onClick={() => setShowDeleteModal(true)} />
                                    }
                                </div>
                            </div>

                            {
                                showViewModal && <ShowJson setShowModal={setShowViewModal} json={currentUser} title={"Member - " + currentUser["auth-id"]} />
                            }
                            {
                                showDeleteModal && <MemberDelete setShowModal={setShowDeleteModal} name={currentUser["auth-id"] ?? lastAuthId} region={region ?? user.region} onDeleteUser={onDeleteUser} />
                            }
                            {
                                showEditModal &&
                                <MemberEdit setShowModal={setShowEditModal} user={currentUser} region={region} mkey={mkey} refresh={refresh} />
                            }
                            {
                                showSettingsModal && <SettingsModal setShowModal={setShowSettingsModal} configuration={currentUser.configuration}
                                    mkey={currentUser.mkey} region={currentUser.region} refresh={refresh} isReadOnly={!(user.isAdmin || user.isCustomerService)}/>
                            }
                            {
                                showTokensModal && <MemberTokens setShowModal={setShowTokensModal} user={currentUser} refresh={refresh} />
                            }
                            {
                                (user.isAdmin || user.isCustomerService) &&
                                <BillingSubscriptions mkey={currentUser.mkey} region={currentUser.region} role="admin" refresh={refresh} key={counter}></BillingSubscriptions>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Member;