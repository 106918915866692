import simplify from 'simplify-js';
import { AOIOptions } from './AOIOptions';

const getCenterFromCoordinates = (coordinates = null) => {
    if (coordinates && coordinates?.length > 0) {
        let bounds = new google.maps.LatLngBounds();
        coordinates.forEach(function(latlng, index){bounds.extend(latlng)});
        return bounds.getCenter();
    }
    return null;
};

const getCenterForGeometry = (geometry) => {
    return getBounds(geometry).getCenter();
};

const getCenterForPolygon = (polygon) => {
    if (polygon && polygon?.length > 0) {
        let bounds = new google.maps.LatLngBounds();
        for(var i=0; i<polygon.length; i++) {
            for(var j=0; j<polygon[i].length; j++) {
                bounds.extend({lat: polygon[i][j][1], lng: polygon[i][j][0]});
            }
        }
        return bounds.getCenter();
    }
    return null;
};

const fitBoundsSelectectedAOI = (aoi, mapRef) => {
    if (aoi?.geometry != null && mapRef && mapRef.current) {
        mapRef.current.fitBounds(getBounds(aoi.geometry));
    }
};

const fitBoundsGeometry = (geometry, mapRef) => {
    if (geometry != null && mapRef && mapRef.current) {
        mapRef.current.fitBounds(getBounds(geometry));
    }
};

const extendBounds = (bounds, geometry) => {
    if(geometry?.type == "Polygon") {
        for(var i=0; i<geometry.coordinates.length; i++) {
            for(var j=0; j<geometry.coordinates[i].length; j++) {
                bounds.extend({lat: geometry.coordinates[i][j][1], lng: geometry.coordinates[i][j][0]});
            }
        }
    }
    else if(geometry?.type == "MultiPolygon") {
        for(var i=0; i<geometry.coordinates.length; i++) {
            for(var j=0; j<geometry.coordinates[i].length; j++) {
                geometry.coordinates[i][j].forEach((latlng) => bounds.extend({lat: latlng[1], lng: latlng[0]}));
            }
        }
    }
    return bounds;
};

const getBounds = (geometry) => {
    let bounds = new google.maps.LatLngBounds();
    return extendBounds(bounds, geometry);
};

const getPolygonPaths = (geometry) => {
    var paths = [];
    if(geometry.type == "Polygon") {
        for(var i=0; i<geometry.coordinates[0].length; i++) {
            paths.push({lat: geometry.coordinates[0][i][1], lng: geometry.coordinates[0][i][0]});
        }
    }
    else if(geometry.type == "MultiPolygon") {
        for(let i=0; i<geometry.coordinates.length; i++) {
            let path = [];
            for (let j=0; j<geometry.coordinates[i][0].length; j++) {
                path.push({lat: geometry.coordinates[i][0][j][1], lng: geometry.coordinates[i][0][j][0]});
            }
            paths.push(path);
        }
    }
    return paths;
}

const areAllBoundsInsideMap = (geometry, mapRef) => {
    const mapBounds = mapRef.current.getBounds();
    let neCorner = mapBounds?.getNorthEast();
    let swCorner = mapBounds?.getSouthWest();
    let isInside = true;

    if(neCorner == null || swCorner == null)
        return isInside;

    const bounds = geoJsonToCoordinates(geometry);
    bounds.forEach((latlng, index) => {
        if(swCorner.lat() <= latlng.lat && latlng.lat <= neCorner.lat() && 
            swCorner.lng() <= latlng.lng && latlng.lng <= neCorner.lng())
            return;
        isInside = false;
    });

    return isInside;
}

const calculateGeometryArea = (geometry) => {
    if(geometry.type != "Polygon")
        return 0;
    var path = getPath(geometry);
    const area = (
        google.maps.geometry.spherical.computeArea(path) / 4046.86
      ).toFixed(2);
    return area;
}

const getPath = (geometry) => {
    var path = [];
    geometry.coordinates[0].forEach((latLng) => {
        path.push({lat: latLng[1], lng: latLng[0]});
    });
    return path;
};

const calculateArea = (polygon) => {
    const area = (
        google.maps.geometry.spherical.computeArea(polygon.getPath()) / 4046.86
      ).toFixed(2);
    return area;
}

const calculateGeometryCircumference = (geometry) => {
    var path = getPath(geometry);
    const circumference = google.maps.geometry.spherical
    .computeLength(path)
    .toFixed(2);
    return circumference;
};

const calculateCircumference = (polygon) => {
    const circumference = google.maps.geometry.spherical
    .computeLength(polygon.getPath())
    .toFixed(2);
    return circumference;
}

const coordinatesToGeoJson = (coors) => {
    let geoJson = [];
    if (coors.length > 0) {
        coors.forEach((latlng)=>{
            geoJson.push([latlng.lng, latlng.lat]);
        })
    }
    return [geoJson];
}

const geoJsonToCoordinates = (geometry) => {
    let coordinates = [];
    if(geometry?.type == "Polygon" || geometry?.type == "LineString") {
        geometry.coordinates[0].forEach((lnglat) => {
            coordinates.push({lat: lnglat[1], lng: lnglat[0]});
        });
    } else if(geometry?.type == "MultiPolygon") {
        geometry.coordinates.forEach((polygon) => {
            let second = [];
            polygon[0].forEach((lnglat) => {
                second.push({lat: lnglat[1], lng: lnglat[0]});
            });
            coordinates.push(second);
        });
    }
    return coordinates;
};

function tolerance(area){
    if (area > 1000){
        return 10
    }else if (area > 100){
        return 1
    }else if (area > 10){
        return 0.1
    }else if (area > 1){
        return 0.01
    }else if (area > 0.1){
        return 0.001
    }else if (area > 0.01){
        return 0.0001
    }else{
        return 0.00001
    }
}

function degreeExtentArea(bounds){
    return Math.abs(bounds['maxy']-bounds['miny']) * Math.abs(bounds['maxx']-bounds['minx'])
}

const simplifyRing = (ring, bounds) => {
    const xyPoints = ring.map(coord => ({
        x: coord["lng"],
        y: coord["lat"]
    }));
    const t=tolerance(degreeExtentArea(bounds));
    const simplifiedPoints = simplify(xyPoints, t);
    return simplifiedPoints.map(coord => ({lat: coord["y"], lng: coord["x"]}));   
}

const encodeRing = (ring)=>{ 
    const polyline = new google.maps.Polyline({path: ring});
    const path = polyline.getPath();   
    return google.maps.geometry.encoding.encodePath(path);;   
}
const appConfig = window.config;

const getThumbnailUrl = (aoi) => {
    const center = `center=${(aoi.maxy+aoi.miny)/2},${(aoi.maxx+aoi.minx)/2}`;
    const visible = `visible=${aoi.miny},${aoi.minx}|${aoi.maxy},${aoi.maxx}`;
    const bounds = {"minx": aoi.minx, "miny": aoi.miny, "maxx": aoi.maxx, "maxy": aoi.maxy};    
    let paths="", encodedGeom;
    if (aoi.geometry.type === "Polygon"){
        encodedGeom = encodeRing(simplifyRing(geoJsonToCoordinates(aoi.geometry), bounds));  
        paths = `path=color:0xffff00|weight:9|enc:${encodedGeom}`;
    }else if(aoi.geometry.type === "MultiPolygon"){        
        let rings = geoJsonToCoordinates(aoi.geometry);
        for (let i = 0; i < rings.length; i++) { 
            encodedGeom = `${encodeRing(simplifyRing(rings[i], bounds))}`; 
            paths += `path=color:0xffff00|weight:9|enc:${encodedGeom}&`;
            if(paths.length > 8000){
                break;
            }         
        }
    }
    const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap'; 
    return `${baseUrl}?${center}&size=300x300&maptype=satellite&${visible}&key=${appConfig.mapApiKey}&${paths}`
            .slice(0,8192);
}

const getServiceArea = (serviceAreaGeometry) => {
    const serviceAreaPath = getPolygonPaths(serviceAreaGeometry);
        
    const serviceAreaCoords = [];
    if (serviceAreaGeometry.type == "Polygon") {
        let coords = [];
        serviceAreaPath.map((lat, lng) => {
            coords.push(new google.maps.LatLng(lat, lng));
        });
        serviceAreaCoords.push(coords);
    }
    else if (serviceAreaGeometry.type == "MultiPolygon") {
        for (let i=0; i<serviceAreaPath.length; i++) {
            let coords = [];
            serviceAreaPath[i].map((lat, lng) => {
                coords.push(new google.maps.LatLng(lat, lng));
            });
            serviceAreaCoords.push(coords);
        }
    }

    const worldCoords = [
        new google.maps.LatLng(-85.1054596961173, -180),
        new google.maps.LatLng(85.1054596961173, -180),
        new google.maps.LatLng(85.1054596961173, 180),
        new google.maps.LatLng(-85.1054596961173, 180),
        new google.maps.LatLng(-85.1054596961173, 0)
    ];
    
    const serviceAreaPolygonPaths = [worldCoords];
    serviceAreaCoords.map(path => {
        serviceAreaPolygonPaths.push(path);
    });

    const serviceArea = new google.maps.Polygon({
        paths: serviceAreaPolygonPaths,
        ...AOIOptions.serviceAreaOptions
    });

    return serviceArea;
}

export const MapUtils = {
    getCenterForGeometry,
    getCenterFromCoordinates,
    getCenterForPolygon,
    fitBoundsSelectectedAOI,
    fitBoundsGeometry,
    areAllBoundsInsideMap,
    calculateGeometryArea,
    calculateGeometryCircumference,
    calculateArea,
    calculateCircumference,
    coordinatesToGeoJson,
    geoJsonToCoordinates,
    getBounds,
    extendBounds,
    getPolygonPaths,
    simplifyRing,
    encodeRing,
    getThumbnailUrl,
    getServiceArea
};
