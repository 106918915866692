const DB_NAME = "datadeli";
const DB_AOI_GEOMETRY_STORE = "AOIGeometry";
const DB_AOI_THUMBNAIL_STORE = "AOIThumbnail";
const DB_AOI_PRICE = "AOIPrice";
const DB_TIMELINE_IMAGES = "TimelineImages";
const DB_VERSION = 6;

const appConfig = window.config;

const initializeDB = (callback) => {
    if(appConfig.cacheGeometryInDB == false && appConfig.cacheThumbnailsInDB == false)
    {
        callback(null);
        return;
    }
    var req = indexedDB.open(DB_NAME, DB_VERSION);
    req.onsuccess = function (evt) {
        callback(req.result);
    };
    req.onerror = function (evt) {
        console.error("Unable to open Db:", evt.target.errorCode);
        callback(null);
    };

    req.onupgradeneeded = (evt) => {
        let db = evt.target.result;
        if(db.objectStoreNames.contains(DB_AOI_GEOMETRY_STORE)) {
            let transaction = evt.target.transaction;
            var store = transaction.objectStore(DB_AOI_GEOMETRY_STORE);
            store.clear();
        } else {
            let geometryStore = db.createObjectStore(DB_AOI_GEOMETRY_STORE, { keyPath: 'ext_id' });
            geometryStore.createIndex('mkey', 'mkey', { unique: false });
        }
        if(!db.objectStoreNames.contains(DB_AOI_THUMBNAIL_STORE)) {
            let thumbnailStore = db.createObjectStore(DB_AOI_THUMBNAIL_STORE, { keyPath: 'ext_id' });
            thumbnailStore.createIndex('mkey', 'mkey', { unique: false });
        }
        if(!db.objectStoreNames.contains(DB_AOI_PRICE)) {
            let priceStore = db.createObjectStore(DB_AOI_PRICE, { keyPath: ['ext_id', 'usecaseName', 'plan'] });
            priceStore.createIndex('mkey', 'mkey', { unique: false });
        }
        if(!db.objectStoreNames.contains(DB_TIMELINE_IMAGES)) {
            let timelineImageStore = db.createObjectStore(DB_TIMELINE_IMAGES, { keyPath: ['url'] });
            timelineImageStore.createIndex('url', 'url', { unique: true });
        }
    };
};

const noDB = (db, store_name) => {
    return db == null
        || store_name == DB_AOI_GEOMETRY_STORE && appConfig.cacheGeometryInDB == false
        || store_name == DB_AOI_THUMBNAIL_STORE && appConfig.cacheThumbnailsInDB == false
        || store_name == DB_AOI_PRICE && appConfig.cachePriceInDB == false;
};

const getStoreFromDB = (db, store_name, mode) => {
    var tx = db.transaction(store_name, mode);
    return tx.objectStore(store_name);
};

const getDataFromStore = (db, store_name, mkey, callback) => {
    if(noDB(db, store_name)) {
        callback([]);
        return;
    }
    var store = getStoreFromDB(db, store_name, "readonly");
    var mkeyIndex = store.index("mkey");
    var req = mkeyIndex.getAll(mkey);
    req.onsuccess = (evt) => {
        var value = evt.target.result;
        if (value) {
            callback(value);
        } else {
            callback([]);
        }
    };
    req.onerror = () => {
        console.log(req.error);
        callback([]);
    };
};

const getImageFromDb = (db, url, callback) => {
    if(noDB(db, DB_TIMELINE_IMAGES)) {
        callback(null);
        return;
    }
    var store = getStoreFromDB(db, DB_TIMELINE_IMAGES, "readonly");
    var urlIndex = store.index("url");
    var req = urlIndex.get(url);
    req.onsuccess = (evt) => {
        var value = evt.target.result;
        if (value) {
            callback(value.image);
        } else {
            callback(null);
        }
    };
    req.onerror = () => {
        console.log(req.error);
        callback(null);
    };
};

const storeImageInDb = (db, url, blob) => {
    var expires = new Date();
    expires.setDate(new Date().getDate() + 80 + Math.floor(Math.random() * 20));
    var store = getStoreFromDB(db, DB_TIMELINE_IMAGES, "readwrite");
    var req = store.put({"url": url, "image": blob, "expires": expires});
    req.onerror = function() {
        console.log(req.error);
    };
};


const getAOIGeometry = (db, mkey, callback) => {
    getDataFromStore(db, DB_AOI_GEOMETRY_STORE, mkey, callback);
};

const getAOIThumbnails = (db, mkey, callback) => {
    getDataFromStore(db, DB_AOI_THUMBNAIL_STORE, mkey, (thumbnails) => {
        callback(thumbnails.filter(t => t.expires > new Date()));
    });
};

const getAOIPrice = (db, mkey, callback) => {
    getDataFromStore(db, DB_AOI_PRICE, mkey, callback);
};

const storeAOIGeometry = (db, mkey, aois) => {
    if(noDB(db, DB_AOI_GEOMETRY_STORE)) {
        return;
    }
    var store = getStoreFromDB(db, DB_AOI_GEOMETRY_STORE, "readwrite");
    aois.map((aoi) => {
        var req = store.put({"ext_id": aoi.ext_id, mkey: mkey, aoi: {geometry: aoi.geometry}});
        req.onerror = function() {
            console.log(req.error);
        }
    });
};


const storeAOIPrice = (db, mkey, ext_id, usecaseName, usecaseVersion, plan, tokenPrice) => {
    if(noDB(db, DB_AOI_PRICE)) {
        return;
    }
    var expires = new Date();
    expires.setDate(new Date().getDate() + 80 + Math.floor(Math.random() * 20));
    var store = getStoreFromDB(db, DB_AOI_PRICE, "readwrite");
    var req = store.put({ext_id: ext_id, mkey: mkey, expires: expires, 
        usecaseName: usecaseName, 
        usecaseVersion: usecaseVersion, 
        plan: plan, 
        tokenPrice: tokenPrice
    });
    req.onerror = function() {
        console.log(req.error);
    };
};

const deleteAOIs = (db, aois, store_name) => {
    if(noDB(db, store_name)) {
        return;
    }
    var store = getStoreFromDB(db, store_name, "readwrite");
    aois.map((aoi) => {
        var req = store.delete(aoi);
        req.onerror = function() {
            console.log(req.error);
        };
    });
};

const deleteAOIGeometry = (db, aois) => {
    deleteAOIs(db, aois, DB_AOI_GEOMETRY_STORE);
};

const deleteAOIThumbnails = (db, aois) => {
    deleteAOIs(db, aois, DB_AOI_THUMBNAIL_STORE);
};

const deleteAOIPrice = (db, aois) => {
    deleteAOIs(db, aois, DB_AOI_PRICE);
};

const deleteImages = (db, str) => {
    if(noDB(db, DB_TIMELINE_IMAGES)) {
        return;
    }
    var pattern = new RegExp("^.*" + str + ".*$");
    var store = getStoreFromDB(db, DB_TIMELINE_IMAGES, "readwrite");
    var request = store.openCursor();
    request.onsuccess = (evt) => {
        const cursor = evt.target.result;
        if(cursor) {
            if(pattern.test(cursor.key)) {
                console.log("Deleting the cache for the image " + cursor.key);
                cursor.delete();
            }
            cursor.continue();
        }
    };
    request.onerror = () => {
        console.log(request.error);
    }
};

const storeAOIThumbnail = (db, mkey, ext_id, blob) => {
    if(noDB(db, DB_AOI_THUMBNAIL_STORE)) {
        return;
    }
    var expires = new Date();
    expires.setDate(new Date().getDate() + 80 + Math.floor(Math.random() * 20));
    var store = getStoreFromDB(db, DB_AOI_THUMBNAIL_STORE, "readwrite");
    var req = store.put({"ext_id": ext_id, "mkey": mkey, "image": blob, "expires": expires});
    req.onerror = function() {
        console.log(req.error);
    };
};

export const dbService = {
    initializeDB,
    getAOIGeometry,
    getAOIThumbnails,
    getAOIPrice,
    storeAOIGeometry,
    storeAOIThumbnail,
    storeAOIPrice,
    deleteAOIGeometry,
    deleteAOIThumbnails,
    deleteAOIPrice,
    getImageFromDb,
    storeImageInDb,
    deleteImages
};
