import { toast, Toaster, ToastBar } from 'react-hot-toast';

const Snackbar = () => {
    return <Toaster position="top-right" reverseOrder={true}>
        {(t) => (
            <ToastBar toast={t}>
            {({ icon, message }) => (
                <>
                {icon}
                {message}
                {t.type !== 'loading' && (
                    <button onClick={() => toast.dismiss(t.id)}>X</button>
                )}
                </>
            )}
            </ToastBar>
        )}
        </Toaster>;
};

export default Snackbar;