import React from 'react';

const PrivacyPolicy = (props) => {

    return (
        <>
            <div>
                <section class="main-banner small">
                    <div class="container">
                        <div class="banner-content">
                            <h2 data-aos="fade-right">Privacy Policy</h2>
                        </div>
                    </div>
                </section>
                <div class="privacy-terms">
                    <div class="container">
                        <div class="">
                            <div>This privacy policy ("Policy") describes how DataDeli, Inc. and any related entity (together
                                "DataDeli.com", "We", "Us", "Our") collects, uses, shares, and stores the personal information of
                                users of this website, https://DataDeli.com ("the Site"). This Policy applies to the Site,
                                applications, products, and services (collectively, "the Services") on or in which it is posted,
                                linked, or referenced and includes any sub-domains of the site.</div>
                            <div>By using the Services, you accept the terms of this Privacy Policy, and consent to our collection,
                                use, disclosure, and retention of your information as described in this Policy. IF YOU DO NOT AGREE
                                WITH ANY PART OF THIS PRIVACY POLICY OR OUR TERMS OF SERVICE, THEN PLEASE DO NOT USE ANY OF THE
                                SERVICES.</div>
                            <div>Please note that this Privacy Policy does not apply to information collected through third-party
                                websites or services that you may access through the Services or that you submit to us through
                                email, text message, or other electronic message or offline.</div>
                            <h4>1. Information we collect from you may include</h4>
                            <div>
                                <ul>
                                    <li>Network information regarding transactions, both paid and unpaid;</li>
                                    <li>Information about you that you consent to provide such as Your:</li>
                                    <li>Name</li>
                                    <li>Email address</li>
                                    <li>Social media profiles</li>
                                    <li>Other information within your user profile</li>
                                    <li>Information related to content that you upload to the site</li>
                                    <li>Content ordered, purchased, or downloaded from the Site</li>
                                    <li>Feedback and correspondence, such as information you provide in Your responses to surveys
                                        when you participate in market research activities, report a problem with Service, receive
                                        customer support or otherwise correspond with us;</li>
                                    <li>Usage information, such as information about how you use the Site or the Service and
                                        interact with us</li>
                                </ul>
                            </div>
                            <div><b>Information We Get from Others:</b></div>
                            <div>We may get information about you from other third-party sources, and we may add this to the
                                information we get from your use of the Services.</div>
                            <div>Such information may include social media features, Payment amounts, and certain details regarding
                                your transactions, understanding your usage of the site and manage site performance.</div>
                            <div><b>Our third-party providers who may collect data from You are:</b></div>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <td><b>Company</b></td>
                                            <td><b>Purpose and basis of use</b></td>
                                            <td><b>Privacy Policy</b></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Stripe</td>
                                            <td>Secure processing of payments made on the platform and communicate with internal
                                                accounting systems.</td>
                                            <td><a href="https://stripe.com/privacy" target="_blank">Stripe Privacy Policy</a></td>
                                        </tr>
                                        <tr>
                                            <td>Google Analytics</td>
                                            <td>Site performance and marketing</td>
                                            <td><a href="https://policies.google.com/privacy?hl=en-US" target="_blank">Google
                                                Privacy Policy</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div><b>Information Automatically Collected:</b></div>
                            <div>We may automatically record certain information about how you use the Site (we refer to this
                                information as "Log Data"). Log Data may include information such as:</div>
                            <div>
                                <ul>
                                    <li>The Internet Protocol (IP) address you access the site from </li>
                                    <li>Device and browser type </li>
                                    <li>Operating system</li>
                                    <li>The pages or features of our Site to which you browsed</li>
                                    <li>The time spent on those pages or features</li>
                                    <li>The frequency with which the Site is used</li>
                                    <li>Search terms or locations</li>
                                    <li>The links on our Site that you clicked on or used, and</li>
                                    <li>Other statistics.</li>
                                </ul>
                            </div>
                            <div>We may use cookies or similar technologies to analyze trends, administer the website, track user
                                movements around the website, and gather demographic information about our user base. Users can
                                control the use of cookies at the individual browser level.</div>
                            <div>For more information, please see the "Cookies Policy" section.</div>
                            <div>We also use Google Analytics to help us offer you an optimized user experience. You can find more
                                information about Google Analytics use of Your data here at <a
                                    href="https://policies.google.com/privacy?hl=en"
                                    target="_blank">https://policies.google.com/privacy?hl=en</a></div>
                            <h4>2. Use of personal information</h4>
                            <div>We will use your personal information in the following ways:</div>
                            <div>
                                <ul>
                                    <li>To enable you to access and use the Services;</li>
                                    <li>To provide and deliver products and services that you may request;</li>
                                    <li>To identify you as the owner, creator, or authorized personal of content;</li>
                                    <li>To send information, including confirmations, technical notices, updates, security alerts,
                                        and support and administrative messages.</li>
                                    <li>To comply with the law</li>
                                </ul>
                            </div>
                            <div>As per your selection of Notification Preference in the Profile page, you will be communicated
                                about promotions, upcoming events, and other news about products and services offered by us and our
                                selected partners.</div>
                            <div>We may use your personal information to protect, investigate, and deter against fraudulent,
                                unauthorized, or illegal activity.</div>
                            <h4>3. Sharing of Personal Information</h4>
                            <div>We do not share or sell the personal information that you provide us with other organizations
                                without your express consent, except as explicitly described in this Privacy Policy.</div>
                            <div>We disclose personal information to third parties under the following circumstances:</div>
                            <div>
                                <ul>
                                    <li>To our subsidiaries and corporate affiliates for purposes consistent with this Privacy
                                        Policy.</li>
                                    <li>When we do a business deal, or negotiate a business deal, involving the sale or transfer of
                                        all or a part of our business or assets. These deals can include any merger, financing, or
                                        acquisition.</li>
                                    <li>For legal, protection, and safety purposes.</li>
                                    <li>To comply with laws and to respond to lawful requests and legal processes.</li>
                                    <li>To protect the rights and our property, agents, customers, and others. This includes
                                        enforcing our agreements, policies, and Terms &amp; Conditions.</li>
                                    <li>In an emergency including protecting the safety of our employees and agents, customers, or
                                        any person.</li>
                                    <li>With those who need it to do work for Us. These recipients may include third-party companies
                                        and individuals to administer and provide the Service on our behalf (such as customer
                                        support, hosting, email delivery, and database management services), as well as lawyers,
                                        bankers, auditors, and insurers.</li>
                                </ul>
                            </div>
                            <h4>4. International Transfer</h4>
                            <div>We have offices outside of the US and affiliates and service providers in the United States and
                                other countries. Your personal information may be transferred to or from the United States or other
                                locations outside of your state, province, country, or other governmental jurisdiction where privacy
                                laws may be different from those in your jurisdiction.</div>
                            <div>We employ industry-standard security measures designed to protect the security of all information
                                submitted through the Services. However, the security of information transmitted through the
                                internet can never be guaranteed. We are not responsible for any interception or interruption of any
                                communications through the internet or changes to or losses of data. Users of the Services are
                                responsible for maintaining the security of any:</div>
                            <div>
                                <ul>
                                    <li>User ID</li>
                                    <li>Password</li>
                                    <li>Multi-factor authentication devices</li>
                                    <li>Other forms of authentication involved in obtaining access to secure areas of any of our
                                        digital services.</li>
                                    <li>To protect you and your data, we may suspend your use of any of the Services if any breach
                                        of security is suspected.</li>
                                </ul>
                            </div>
                            <h4>5. Changes to this Privacy Policy</h4>
                            <div>We may change this Privacy Policy at any time. we encourage you to periodically review this page
                                for the latest information on our privacy practices.</div>
                            <div>If we make any changes, we will change the Last Updated date above. Any modifications to this
                                Privacy Policy will be effective upon our posting of the new terms and/or upon implementation of the
                                changes to the Site (or as otherwise indicated at the time of posting).</div>
                            <div>In all cases, your continued use of the Site or Services after the posting of any modified Privacy
                                Policy indicates your acceptance of the terms of the modified Privacy Policy.</div>
                            <h4>6. Eligibility</h4>
                            <div>If you are under the age of majority in your jurisdiction of residence, you may use the Services
                                only with the consent of or under the supervision of your parent or legal guardian. Consistent with
                                the requirements of the Children's Online Privacy Protection Act (COPPA), if we learn that we have
                                received any information directly from a child under age 13 without first receiving his or her
                                parent's verified consent, we will use that information only to respond directly to that child (or
                                his or her parent or legal guardian) to inform the child that he or she cannot use the Site and
                                subsequently, we will delete that information.</div>
                            <h4>7. Cookies Policy</h4>
                            <div>We understand that your privacy is important to you and are committed to being transparent about
                                the technologies we use. In the spirit of transparency, this policy provides detailed information
                                about how and when we use cookies on the Site.</div>
                            <div><b>Do we use cookies?</b></div>
                            <div>Yes, native and third-party cookies may be present on the Site and through the use of the Services.
                            </div>
                            <div><b>What is a cookie?</b></div>
                            <div>A cookie ("Cookie") is a small text file that is placed on your hard drive by a web page server.
                                Cookies contain information that can later be read by a web server in the domain that issued the
                                cookie to you. Some of the cookies will only be used if you use certain features or select certain
                                preferences, and some cookies will always be used. You can find out more about each cookie by
                                viewing our current cookie list below. We update this list periodically, so there may be additional
                                cookies that are not yet listed. Web beacons, tags, and scripts may be used in the Site or in emails
                                to help us deliver cookies, count visits, understand usage and campaign effectiveness, and determine
                                whether an email has been opened and acted upon. We may receive reports based on the use of these
                                technologies by our service/analytics providers on an individual and aggregated basis.</div>
                            <div><b>How to disable cookies?</b></div>
                            <div>You can generally activate or later deactivate the use of cookies through functionality built into
                                your web browser. Please note that disabling cookies may impact the performance of the site.</div>
                            <div>If you want to learn more about cookies, or how to control, disable or delete them, please visit <a
                                href="https://www.aboutcookies.org/" target="_blank">https://www.aboutcookies.org/</a> for
                                detailed guidance. In addition, certain third-party advertising networks, including Google, permit
                                users to opt out of or customize preferences associated with their internet browsing.</div>
                            <div>Please note that disabling cookies may impact the performance of the site.</div>
                            <div><b>Third-Party Cookies</b></div>
                            <div>The following cookies are used on the Site.</div>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <td><b>Cookie Name</b></td>
                                            <td><b>Generated By</b></td>
                                            <td><b>Purpose / Basis of Use</b></td>
                                            <td><b>Type</b></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>_ga</td>
                                            <td>Google Analytics</td>
                                            <td>The _ga cookie, installed by Google Analytics, calculates visitor, session, and
                                                campaign data and also keeps track of site usage for the site's analytics report.
                                                The cookie stores information anonymously and assigns a randomly generated number to
                                                recognize unique visitors.</td>
                                            <td>Analytics</td>
                                        </tr>
                                        <tr>
                                            <td>_gid</td>
                                            <td>Google Analytics</td>
                                            <td>_gid cookie stores information on how visitors use a website, while also creating an
                                                analytics report of the website's performance. Some of the data that are collected
                                                include the number of visitors, their source, and the pages they visit anonymously.
                                            </td>
                                            <td>Analytics</td>
                                        </tr>
                                        <tr>
                                            <td>_gcl_au</td>
                                            <td>Google Tag Manager</td>
                                            <td>Provided by Google Tag Manager to experiment advertisement efficiency of websites
                                                using their services.</td>
                                            <td>Analytics</td>
                                        </tr>
                                        <tr>
                                            <td>_gat</td>
                                            <td>Google Analytics</td>
                                            <td>This cookie is installed by Google Universal Analytics to restrain request rate and
                                                thus limit the collection of data on high-traffic sites.</td>
                                            <td>Performance</td>
                                        </tr>
                                        <tr>
                                            <td>__stripe_mid</td>
                                            <td>Stripe</td>
                                            <td>Stripe sets this cookie to process payments</td>
                                            <td>Necessary</td>
                                        </tr>
                                        <tr>
                                            <td>__stripe_sid</td>
                                            <td>Stripe</td>
                                            <td>Stripe sets this cookie to process payments</td>
                                            <td>Necessary</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h4>8. Contact Information</h4>
                            <div>We welcome your comments or questions about this Policy, and you may contact us at:</div>
                            <div>DataDeli, Inc.<br/>Registered Office (DataDeli, Inc.): 251 Little Falls Drive, Wilmington, New
                                Castle County, Delaware - 19808.<br/>Communication Address (USA): 22775 Trailing Rose Ct, Brambleton,
                                    VA - 20148.<br/>Email: contactus@datadeli.com</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
                );
}

                export default PrivacyPolicy;