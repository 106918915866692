import {Modal} from 'react-bootstrap';
import React, {useContext} from 'react';

const ImageModal = (props) => {

    return (
        <>
            <Modal
                show={() => props.show(true)}
                onHide={() => props.show(false)}
                keyboard={false}
                dialogClassName="image-modal-dialog"
                className="image-modal"
            >
                <Modal.Body>
                    <img src={props.image} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ImageModal;