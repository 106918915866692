import { Modal } from "react-bootstrap";
import AOIServicesFormMultipleUC from "../../AOIWizzard/AOIServicesFormMultipleUC";
import { groupService } from "../../../Services/groupService";
import { UserContext } from "../../../Contexts/UserContext";
import { useContext } from "react";
import toast from 'react-hot-toast';

const AOIGroupServices = (props) => {
    const {user} = useContext(UserContext);
    const availableServices = props.domainConfig.usecases?.filter(usecase=>usecase.type == 'group');

    const close = () => {
        props.setShowSubscribeModal(false);
    }

    const subscribeUsecase = (usecaseName, data) => {
        groupService.subscribeUsecase(user.mkey, user.region, props.group.ext_id, usecaseName, data).then((result) => {
            toast.success("Subscribed to the usecase " + usecaseName);
            props.setShowSubscribeModal(false);
            props.refresh();
        }).catch((error) => {
            console.log('Error in subscribing ', error, data);
            toast.error('Failed to Subscribe! ' + error);
        });
    };

    const unsubscribeUsecase = (usecaseName) => {
        groupService.unsubscribeUsecase(user.mkey, user.region, props.group.ext_id, usecaseName).then((result) => {
            toast.success("Unsubscribed to the usecase " + usecaseName);
            props.setShowSubscribeModal(false);
            props.refresh();
        }).catch((error) => {
            console.log('Error in Unsubscribe', error);
            toast.error('Failed to Unsubscribe! ' + error);
        });
    };

    return (
        <>
            <Modal
                show={() => props.setShowSubscribeModal(true)}
                onHide={close}
                backdrop='static'
                keyboard={false}
                dialogClassName=''
                className='aoi-group-services-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Group Services Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {
                            availableServices.length == 0 ? "No Services Available" : 
                            <AOIServicesFormMultipleUC group={props.group} subscribeUsecase={subscribeUsecase} unsubscribeUsecase={unsubscribeUsecase} close={close} type="group"/>
                        }
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AOIGroupServices;