import {fetchWrapper} from "../Helpers/fetchWrapper";
import { apiService } from './apiService';

const contactus = (data) => {
    return fetchWrapper.post(`${apiService.getApiUrl()}/contactus`, data);
};

export const siteService = {
    domainConfig,
    contactus
};

function domainConfig(region, domain) {
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/domain-based-configuration?domain=${domain}`);
}

// function contactus(captcha, contactUsData) {
//     const url = window.location.protocol + '//' + window.location.host + "/contactus";
//     let requestOptions = {
//         method: 'POST',
//         body: contactUsData,
//         headers: {'Content-Type': 'text/html', captcha: captcha},        
//     };
//     return fetch(url, requestOptions).then(handleResponse);
// }

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}