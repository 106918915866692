import React, { useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../Assets/Css/text-editor.css';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';


const TextEditor = (props) => {
    const { messageRef } = props;
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        messageRef.current = {value: html};
    }, [editorState]);

    useEffect(() => {
        setEditorState(EditorState.createWithContent(ContentState.createFromText(props.body ?? "")));
    }, [props.body]);

    return (
        <Editor 
            editorState={editorState}
            onEditorStateChange={setEditorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={{options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history']}}
        />
    )
}

export default TextEditor;