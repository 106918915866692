import {
  useContext,
  useEffect,
  useState,
} from 'react';

import toast from 'react-hot-toast';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

import DeleteIcon from '../../../Assets/img/icon-delete-grey.svg';
import EditIcon from '../../../Assets/img/icon-edit-grey.svg';
import { AOIContext } from '../../../Contexts/AOIContext';
import { UserContext } from '../../../Contexts/UserContext';
import { groupService } from '../../../Services/groupService';
import AOIGroupAddEdit from './AOIGroupAddEdit';
import AOIGroupAOISlider from './AOIGroupAOISlider';
import AOIGroupDelete from './AOIGroupDelete';
import AOIGroupServices from './AOIGroupServices';

const AOIGroupSingle = (props) => {
    const {user} = useContext(UserContext);
    const {allAOIs, domainConfig} = useContext(AOIContext);
    const [aoiDict, setAoiDict] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [filteredMemberIds, setFilteredMemberIds] = useState([]);
    const [showSubscribeModal, setShowSubscribeModal] = useState(false);
    const [totalGroupArea, setTotalGroupArea] = useState("");
    const [area, setArea] = useState(0);
    const [hasAOIs, setHasAOIs] = useState(true);
    const availableServices = domainConfig.usecases?.filter(usecase=>usecase.type == 'group');

    useEffect(() => {
        let dict = {};
        allAOIs.map((aoi) => {
            dict[aoi.member_aoi_id] = aoi;
        });
        setAoiDict(dict);
    }, [allAOIs]);

    useEffect(() => {
        let filtered = [];
        props.group?.member_aoi_ids?.map(aoiId => {
            if(!searchQuery || (aoiDict[aoiId] && aoiDict[aoiId].tag.toUpperCase().includes(searchQuery.toUpperCase())))
                filtered.push(aoiId);
        });
        setFilteredMemberIds(filtered);
    }, [aoiDict, searchQuery, props.group?.member_aoi_ids]);

    useEffect(() => {
        let totalArea = 0;
        props.group?.member_aoi_ids?.map(aoiId => {
            totalArea += parseFloat(aoiDict[aoiId]?.area?.split(' ')[0] ?? 0)
        });
        setTotalGroupArea(`${totalArea.toFixed(2)} acres`);
        setArea(totalArea.toFixed(2));
        setHasAOIs(props.group?.member_aoi_ids?.length > 0);
    }, [aoiDict, props.group?.member_aoi_ids]);

    return (
            props.group != null && 
            <div className="aoi-group-box">
                <div className="group-head">
                    {props.group.tag} <span className="badge">{props.group.member_aoi_ids?.length}</span>
                    <div className="group-actions">
                            {
                                user.isReadOnly == false &&
                                <>
                                    <img src={EditIcon} alt="" onClick={() => setShowEdit(true)}/>
                                    <img src={DeleteIcon} alt="" onClick={() => setShowDelete(true)}/>
                                </>
                            }
                            <div className="aoi-group-search">
                                <input className="search" id="search" type="search" name="query" placeholder="Enter Search Text" 
                                    value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} required />
                            </div>
                    </div>
                </div>
                <div className="group-body">
                    <div className="group-details"> 
                            {
                                hasAOIs && 
                                <div className="map-link">
                                    <Link to={`/portfolio/map/group/${props.group?.ext_id}`}>Show on Map</Link>
                                </div>
                            }
                            {
                                area > 0 && 
                                <div className="view-group">
                                <label className="view-label">Total Area</label>
                                <span>{totalGroupArea}</span>
                            </div>
                            }
                              <div className="view-group">
                                    <label className="view-label">Created/Modified</label>
                                    <span>{<Moment date={props.group?.timestamp} format={"Do MMMM YYYY"}></Moment>}</span>
                                </div>
                            {
                                availableServices.length > 0 && 
                                <div className="subscribe">
                                    <a className="btn-link" onClick={()=>setShowSubscribeModal(true)}>
                                        Subscriptions
                                        {props.group?.usecases?.length > 0 &&<span className="badge">{props.group?.usecases?.length}</span>}
                                    </a>
                                </div>
                            }
                   
                    </div>
                    <div className="aoi-slide-container">
                        <div className="card-holder">
                            <AOIGroupAOISlider memberIds={filteredMemberIds} aoiDict={aoiDict} />
                        </div>
                    </div>
                </div>
                {
                    showDelete && <AOIGroupDelete show={setShowDelete} group={props.group} refresh={props.refresh}/>
                }
                {
                    showEdit && <AOIGroupAddEdit show={setShowEdit} group={props.group} refresh={props.refresh} userGroups={props.userGroups} />
                }
                {
                    showSubscribeModal && 
                    <AOIGroupServices 
                        setShowSubscribeModal={setShowSubscribeModal} 
                        group={props.group} 
                        refresh={props.refresh} 
                        domainConfig={domainConfig}
                    />
                }
            </div>
    );
}

export default AOIGroupSingle;