import React, { useContext } from 'react';
import { UserContext } from '../../Contexts/UserContext';
import SettingsEdit from './SettingsEdit';

const Settings = () => {
    const {user} = useContext(UserContext);

    return (
            <div className="form-content">
                <h3>Settings</h3>
                <SettingsEdit type="user" configuration={user.configuration} mkey={user.originalmKey} region={user.originalRegion}></SettingsEdit>
            </div>
    );
}

export default Settings;