import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import toast from 'react-hot-toast';

import { AOIContext } from '../../../Contexts/AOIContext';
import { UserContext } from '../../../Contexts/UserContext';
import { groupService } from '../../../Services/groupService';
import AOIGridCard from '../AOIGridCard';

const AOIGroupAddEditAOIs = (props) => {
    const {allAOIs} = useContext(AOIContext);
    const {user} = useContext(UserContext);
    const [selectedAOIs, setSelectedAOIs] = useState({});

    useEffect(() => {
        let aoisMap = {};
        allAOIs.map(aoi => aoisMap[aoi.member_aoi_id] = false);
        if(props.group?.member_aoi_ids) {
            props.group.member_aoi_ids.map((aoiId) => aoisMap[aoiId] = true);
        }
        setSelectedAOIs(aoisMap);
    }, [allAOIs, props.group]);

    const onChecboxClick = (event) => {
        setSelectedAOIs({...selectedAOIs, [event.target.name]: event.target.checked});
    }

    const renderCheckbox = (aoi) => {
        return <div className="add-to-group" key={aoi.member_aoi_id}>
                    <label htmlFor={aoi.member_aoi_id} className="custom-check">
                        <input type="checkbox" name={aoi.member_aoi_id} id={aoi.member_aoi_id} checked={selectedAOIs[aoi.member_aoi_id]} onChange={(e) => onChecboxClick(e)}/>
                        <span>&nbsp;</span>
                    </label>
                </div>
    };

    const save = () => {
        if(!props.isValidGroupName())
            return;
        var aoiIds = [];
        allAOIs.map((aoi) => {
            if(selectedAOIs[aoi.member_aoi_id])
                aoiIds.push(aoi.member_aoi_id);
        });
        if(aoiIds.length == 0) {
            toast.error("Please add atleast one AOI to the group");
            return;
        }
        groupService.addUpdateGroup(user.mkey, user.region, props.groupName, props.groupDesc, aoiIds, props.group?.ext_id).then(() => {
            toast.success(`Successfully ${props.group?.ext_id ? "Updated" : "Added"} group ${props.groupName}`);
            props.show(false);
            props.refresh();
        }).catch((e) => {
            console.error(e);
            toast.error(`There is an error in ${props.group?.ext_id ? "updating" : "adding"} the group`);
        });
    };

    return (
        <>
            <div className="aoi-list-scroll">
                <div className="row">
                {
                    allAOIs.map((aoi) => <div className="col-xs-12 col-md-6 col-xl-4" key={aoi.member_aoi_id}>
                                            <AOIGridCard aoi={aoi} view="group" show="basic"
                                                customComponent={true} renderCustomComponent={(aoi) => renderCheckbox(aoi)}/>
                                        </div>)
                }
                </div>
            </div>
            <div className='modal-footer'>
            <button type="button" className="btn btn-outline" onClick={() => props.show(false)}>CANCEL</button>
            <button type="button" className="btn btn-primary" onClick={() => save()}>SUBMIT</button>
            </div>
        </>
    );
}

export default AOIGroupAddEditAOIs;