import { Modal } from 'react-bootstrap';

const LocationAccess = (props) => {
    const allowCurrentLocation = () => {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function(position) {
                }
            );
        }
        props.show(false);
    };

    const ShowEnableInBrowser = () => {
        return <div>
                    <div>You have denied the location access from your browser. You have to enable the location access from your browser to use this functionality.</div>
                    <div>Please follow the below instructions to enable the location access based on your browser.</div>
                    <div><b>Chrome:</b></div>
                    <div>Click on the lock icon next to the URL.</div>
                    <div>If you see location in that, please enable it.</div>
                    <div>If you do not see location in that, please click on site settings. </div>
                    <div>In the permissions, for Location, please select Allow.</div>
                    <div></div>
                    <div><b>Microsoft Edge:</b></div>
                    <div>Click on the lock icon next to the URL.</div>
                    <div>Click on "Permissions for this site".</div>
                    <div>For the location, please select Allow.</div>
                    <div></div>
                    <div><b>Firefox:</b> </div>
                    <div>Go to Tools -&gt; Settings -&gt; Privacy &amp; Security -&gt; Permissions</div>
                    <div>For Location, Click on Settings</div>
                    <div>If you see datadeli.com, please change the status to allow.</div>
                </div>;
    };

    const ShowInformation = () => {
        return <div>
                <div>If you are going to allow the location access, you would get another notification from the browser. Please allow there as well. </div>
                <div></div>
                <div>If you are using Firefox, and if you do not get prompt, please do the following.</div>
                <div>Click on the Location Icon next to the URL.</div>
                <div>If you see "Blocked" for "Access your location", please remove "Blocked". </div>
                <div>Click on the current location icon again in the map.</div>
                </div>;
    };

    if(props.permission == "granted") {
        props.show(false);
        return;
    }

    return <>
                <Modal
                    show={() => props.show(true)}
                    onHide={() => props.show(false)}
                    backdrop='static'
                    keyboard={false}
                    dialogClassName=''
                    className='location-access-modal'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                props.permission == "prompt" 
                                ? "Allow Access to Location" 
                                : "Please enable the location access from the browser"
                            }
                            
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            props.permission == "prompt"
                            ? <ShowInformation></ShowInformation>
                            : <ShowEnableInBrowser></ShowEnableInBrowser>
                        }
                        
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            props.permission == "prompt"
                            ? <>
                                <button type="button" className="btn btn-outline" aria-label="Close" onClick={() => props.show(false)}>LATER</button> &nbsp; 
                                <button type="button" className="btn btn-danger" onClick={allowCurrentLocation}>ALLOW</button>
                            </>
                            : <button type="button" className="btn btn-outline" aria-label="Close" onClick={() => props.show(false)}>CLOSE</button>
                        }
                    </Modal.Footer>
                </Modal>
            </>;
}

export default LocationAccess;