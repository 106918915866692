import {Modal} from 'react-bootstrap';
import React from 'react';

const DomainConfigDelete = (props) => {
    return (
        <>
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Delete Domain Config</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-member">
                        <h6>Are you sure you want to delete the domain config <strong>{props.name}?</strong></h6>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.setShowModal(false)}>CANCEL</button>
                    <button type="button" className="btn btn-danger" onClick={() => props.deleteConfig(props.name)}>DELETE</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DomainConfigDelete;