import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../Contexts/UserContext";
import { siteService } from "../Services/siteService";
import captchaImage from '../Assets/Images/captcha.jpg'
import { useLocation } from "react-router";
import toast from "react-hot-toast";

const Contact = (props) => {
    let location = useLocation();
    const nameRef = useRef(null);
    const containerRef = useRef(null);
    const { user, showAlert } = useContext(UserContext);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const message = location.state?.message ?? "";
    const source = location.state?.source ?? "";

    const initialValues = {
        firstname: user.profile?.name ?? '',
        company: '',
        email: user.email ?? user.profile?.communication_email ?? '',
        phone: user.profile?.communication_phone_number ?? '',
        message: message ?? '',
        captcha: ''
    };

    const [values, setValues] = useState({...initialValues});

    useEffect(() => {
        setValues({...values, message: message});
        if(!!message) {
            nameRef.current.focus();
            containerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [message]);

    useEffect(() => {
        setValues({...initialValues});
    }, [user]);

    useEffect(() => {
        if(values.firstname != "" && (values.email != "" || values.phone != "") && values.message != "") {
            setShowCaptcha(true);
        } else {
            setShowCaptcha(false);
        }
    }, [values]);

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setValues({
          ...values,
          [name]: value
        });
    }

    const handleSubmit  = () => {
        if (!values.firstname.trim()) {
            toast.error('Please enter the name');
            return;
        }
        if(!values.email.trim() && !values.phone.trim()) {
            toast.error('Please enter a valid email id or phone number');
            return;
        }
        if(values.email.trim() && ! (/(.+)@(.+){2,}\.(.+){2,}/.test(values.email.trim()) )) {
            toast.error('Please enter a valid email id');
            return;
        }
        if (!values.message.trim()) {
            toast.error('Please enter the Message');
            return;
        }
        if(!values.captcha.trim()) {
            toast.error('Please enter the Captcha');
            return;
        }
        if(values.captcha.trim() != "AEDRB") {
            toast.error("Please enter valid Captcha");
            return;
        }

        let contactUsMessage = values.message;
        if (!!source?.trim()) {
            contactUsMessage = `${contactUsMessage} <br /><b>User has contacted from ${source} page</b>`;
        }

        let data = {
            name: values.firstname.trim(),
            company: values.company.trim(),
            email: values.email.trim(),
            phoneNo: values.phone.trim(),
            message: values.message,
            location: window.location.href
        };

        submitContactUs(data);
    }

    const submitContactUs = (data) => {
        return siteService.contactus(data)
        .then((result) => { 
            toast.success('Submitted successfully.');
            setValues(initialValues);
        })
        .catch(error => {
            console.log('Error in Contact us Form Submission', error);
            toast.error('Submission failed! Please try again.');
        });
    }

    return (
        <section className="contact-section" id="contactus">
            <div className="container" ref={containerRef}>
                <div className="stay-connected">
                    <div className="left-content">
                        <h3>
                            STAY<br />
                            In Touch
                        </h3>
                        <p>We’d love to hear from you! <br/>
                            If you are interested in scheduling a demo or <br/>
                            Would like to know more, please contact us. </p>
                    </div>
                    <div className="right-form">
                        <div className="contact-form">
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <input type="text" ref={nameRef} className="form-control" placeholder="Name*" name="firstname" value={values.firstname} onChange = {(e) => handleInputChange(e)} />
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <input type="text" className="form-control" placeholder="Company" name="company" value={values.company} onChange = {(e) => handleInputChange(e)} />
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <input type="text" className="form-control" placeholder="Phone Number**" name="phone"  value={values.phone} onChange = {(e) => handleInputChange(e)} />
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <input type="text" className="form-control" placeholder="Email**" name="email" value={values.email} onChange = {(e) => handleInputChange(e)} />
                                </div>
                                <div className="col-xs-12 col-md-12">
                                    <textarea className="form-control" id="" cols="30" rows="3" placeholder="Message*" name="message" value={values.message} onChange = {(e) => handleInputChange(e)}></textarea>
                                </div>
                                {
                                    showCaptcha && 
                                    <>
                                        <div className="col-xs-12 col-md-12">
                                            <input type="text" className="form-control" placeholder="Please enter below text*" name="captcha" value={values.captcha} onChange = {(e) => handleInputChange(e)} />
                                        </div>
                                        <div className="col-xs-12 col-md-12">
                                            <img src={captchaImage} />
                                        </div>
                                    </>
                                }
                            </div>
                            <button type="submit" className="btn btn-submit" onClick={handleSubmit}>SUBMIT</button>
                            <div>&nbsp;</div>
                            <div>
                                <i>** Please provide one of the email or phone number</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;