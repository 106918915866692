import React, { useCallback, useContext, useState } from 'react';
import { AOIContext } from '../../../Contexts/AOIContext';
import { UserContext } from '../../../Contexts/UserContext';
import { getFileBlobUrl } from '../../Utils';
import toast from 'react-hot-toast';
import { aoiService } from "../../../Services/aoiService";
import { AOIWizzardContext } from '../../../Contexts/AOIWizzardContext';
import { usecaseService } from '../../../Services/usecaseService';
import AOIViewInfo from './AOIViewInfo';
import AOINotes from './AOINotes';
import AOIPropertyFiles from './AOIPropertyFiles';

const _ = require("lodash");

const getServiceNames = (domConfig, usecases) => {
    let domainUsecases = {};
    domConfig.usecases.forEach((usecase) => {
        domainUsecases[usecase.name] = usecase.displayName
    })
    let toBeDisplayedUsecases = "";
    usecases?.forEach((usecaseItem) => {
        toBeDisplayedUsecases += domainUsecases[usecaseItem.usecase.name] ? (domainUsecases[usecaseItem.usecase.name] + ", ") : "";
    })
    return toBeDisplayedUsecases.slice(0, -2);
}

const AOIViewDetails = (props) => {
    const { discardAOIEdit, aoiMode, openAOIWizzardModal, setSelectedAOIId } = useContext(AOIContext);
    const { goNext } = useContext(AOIWizzardContext);
    const { domainConfig, user, removeAOIFromDB, reloadAOI, landuse } = useContext(UserContext);
    const displayAOIServiceNames = props.aoi?.subscribed_usecases?.some(u => u.usecase.type == 'aoi')
        ? getServiceNames(domainConfig, props.aoi?.subscribed_usecases?.filter(u => u.usecase.type == 'aoi')) : "";
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [expandServices, setExpandServices] = useState(true);
    const [expandGroups, setExpandGroups] = useState(true);

    const deleteAOI = () => {
        if (aoiMode == 'edit')
            discardAOIEdit();
        aoiService.deleteAoi(user.mkey, user.region, props.aoi.member_aoi_id)
            .then((data) => {
                toast.success('You have successfully deleted the AOI ' + props.aoi.tag);
                openAOIWizzardModal(false);
                removeAOIFromDB(props.aoi.ext_id);
            })
            .catch(error => {
                console.log('Error in deleting the AOI ', error);
                toast.error('Failed to delete the AOI! Please Try Again.');
            });
    };

    const showConfirmDelete = () => {
        if (displayAOIServiceNames.length == 0) {
            setConfirmDelete(true);
        }
    };

    const onSave = () => {
        reloadAOI(props.aoi.member_aoi_id, props.aoi.ext_id);
        setSelectedAOIId(props.aoi.member_aoi_id);
    };

    const getDisabledServices = () => {
        const subServices = props.aoi?.subscribed_usecases?.filter(u => u.usecase.type == 'aoi');
        return domainConfig.usecases.filter(u => !u.isEnabled && subServices.some(s => s.usecase.name == u.name)).map(u => u.displayName);
    };

    const AOIServices = () => {
        if(!displayAOIServiceNames)
            return <></>;

        return <div className={`aoi-detail-box ${expandServices ? "expanded" : "collapsed"}`}>
                    <div className="aoi-detail-header" onClick={() => setExpandServices(!expandServices)}>Services</div>
                    <div className="aoi-detail-content">
                        <div className="services-list">
                            {
                                usecaseService.getUsecaseLinks(props.aoi, "aoi", domainConfig).map((c) => c && <div key={c[0]}>{c[1]}</div>)
                            }
                            {
                                getDisabledServices().map(c => <div>{c}</div>)
                            }
                        </div>
                    </div>
                </div>;
    };

    const AOIGroups = () => {
        if(props.aoi?.groups == null || props.aoi?.groups?.length == 0) 
            return <></>;

            return <div className={`aoi-detail-box ${expandGroups ? "expanded" : "collapsed"}`}>
                        <div className="aoi-detail-header" onClick={() => setExpandGroups(!expandGroups)}>Groups</div>
                        <div className="aoi-detail-content">
                            <div className="groups-list">
                                {
                                    props.aoi?.groups?.map(group => {
                                        return <div key={group.ext_id} style={{ display: 'flex' }}>
                                            <p className="col-4">{group.tag}</p>
                                            {
                                                getServiceNames(domainConfig, group.usecases)
                                                && <div>(Subscriptions: {getServiceNames(domainConfig, group.usecases)})</div>
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>;
    };

    return (
        <>
            <div className="aoi-wizzard-content wizzard-content">
                <div className="container">
                    <div className="aoi-details-section">
                        <AOIViewInfo aoi={props.aoi} mode={props.mode} onSave={onSave} />
                        {
                            landuse?.name == "PropertyFiles" && 
                            <AOIPropertyFiles aoi={props.aoi} onSave={onSave} isImages={true} />
                        }
                        {
                            landuse?.name == "PropertyFiles" && 
                            <AOIPropertyFiles aoi={props.aoi} onSave={onSave} isImages={false}/>
                        }
                        <AOINotes aoi={props.aoi} onSave={onSave} mode={props.mode} />
                        <AOIServices />
                        <AOIGroups />
                        {
                            user.isReadOnly == false &&
                            confirmDelete == false &&
                            <div className="delete-aoi-panel">
                                {
                                    displayAOIServiceNames.length > 0 &&
                                    <p>
                                        This AOI cannot be deleted as there are active subscriptions. To delete this AOI, please unsubscribe to the services.
                                    </p>
                                }
                                <a href="#" className={displayAOIServiceNames.length == 0 ? "btn-delete" : "btn-delete disabled"} onClick={showConfirmDelete}>
                                    DELETE AOI
                                </a>
                            </div>

                        }
                        {
                            displayAOIServiceNames.length == 0 && confirmDelete == true &&
                            <div className="delete-aoi-panel">
                                <div className='confirmation-block'>
                                    <h2>Are you sure you want to delete this AOI?</h2>
                                    <div>
                                        <a href="#" className="btn-yes" onClick={deleteAOI}>YES</a>
                                        <a href="#" className="btn-no" onClick={() => setConfirmDelete(false)}>NO</a>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="wizzard-footer">
                <div className="container">
                    <div className="btn-area">
                        <button type="button" className="btn btn-outline" onClick={() => openAOIWizzardModal(false)}>Close</button>
                        <button type="button" className="btn btn-primary ml-auto" onClick={goNext}>Continue</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AOIViewDetails;