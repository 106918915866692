const AddEventShowMessages = (props) => {

    const RenderCompleted = () => {
        return <>
            <div className="col-12">
                {
                    props.data.completionStatus == "FAILURE" 
                    ? <span className="error-msg">Addition of Event failed</span>
                    : <span className="success-msg">Event has been added successfully</span>
                }
            </div>
            {
                props.data.Errors && props.data.Errors.length > 0 &&
                <>
                    <div className="col-12">
                        &nbsp;
                    </div>
                    <div className="col-12">
                    {
                        props.data.Errors.map((e) => <div key={e}><span className="error-msg">{e}</span></div>)
                    }
                    </div>
                </>
            }
            <div className="col-12">
                &nbsp;
            </div>
            <div className="col-12">
                {
                    props.data.messages.map((message) => <div key={message}>{message}</div>)
                }
            </div>
            <div className="col-12">
                &nbsp;
            </div>
            <div className='modal-footer'>
                    <button type="button" className="btn btn-outline" onClick={() => props.close()}>CLOSE</button>
            </div>
        </>;
    };

    return <>
                {
                    props.message && 
                    <div>{props.message}</div>
                }
                {
                    props.data && props.data.status == "COMPLETED" &&
                    <div className="col-12">
                        <RenderCompleted />
                    </div>
                }
            </>;
};

export default AddEventShowMessages;