import { useContext, useEffect, useState } from "react";
import EditIcon from '../../../Assets/img/icon-edit.svg';
import { UserContext } from "../../../Contexts/UserContext";
import { aoiService } from "../../../Services/aoiService";
import { toast } from "react-hot-toast";

const getFullAddress = (address) => {
    let addressFields = [address.address1, address.address2, address.address3, address.city, address.state, address.country, address.pin];
    let fullAddress = "";
    addressFields.forEach((field) => {
        fullAddress += (field == null ? "" : field + ", ");
    })
    return fullAddress.slice(0, -2);
};

const AOIViewInfo = (props) => {
    const { user } = useContext(UserContext);
    const [expand, setExpand] = useState(true);
    const [mode, setMode] = useState(props.mode);
    const fullAddress = props?.aoi?.address ? getFullAddress(props.aoi?.address) : "";

    const initialAOIValues = {
        tag: props.aoi?.tag ?? '',
        area: props.aoi?.area ?? '',
        circumference: props.aoi?.circumference ?? '',
        address1: props.aoi?.address?.address1 ?? '',
        address2: props.aoi?.address?.address2 ?? '',
        address3: props.aoi?.address?.address3 ?? '',
        city: props.aoi?.address?.city ?? '',
        state: props.aoi?.address?.state ?? '',
        country: props.aoi?.address?.country ?? '',
        pin: props.aoi?.address?.pin ?? ''
    };

    const [values, setValues] = useState(initialAOIValues);

    const isDataChanged = () => {
        return Object.keys(initialAOIValues).map(key => {
            return values.hasOwnProperty(key) && initialAOIValues[key] === values[key].trim()
        }).some(isSame=> isSame == false);
    };

    const isNotNull = (str, name) => {
        if(str == null || str.trim().length == 0) {
            toast.error(`Please enter ${name}`);
            return false;
        }
        return true;
    };

    const isSizeLessThan = (str, size, name) => {
        if(str == null || str.trim().length <= size)
            return true;
        toast.error(`${name} should have ${size} or less no.of characters`);
        return false;
    };

    const getAOIInfo = () => {
        var isValid = true;
        isValid &&= isNotNull(values.tag, "AOI tag");
        isValid &&= isSizeLessThan(values.tag, 50, "AOI tag");
        isValid &&= isSizeLessThan(values.pin, 6, "PIN/Zip Code");
        isValid &&= isSizeLessThan(values.address1, 50, "Address 1");
        isValid &&= isSizeLessThan(values.address2, 50, "Address 2");
        isValid &&= isSizeLessThan(values.address3, 50, "Address 3");
        isValid &&= isSizeLessThan(values.city, 50, "City");
        isValid &&= isSizeLessThan(values.state, 50, "State");
        isValid &&= isSizeLessThan(values.country, 50, "Country");

        if(!isValid) 
            return null;

        const address = {
            address1: values.address1.trim(),
            address2: values.address2.trim(),
            address3: values.address3.trim(),
            city: values.city.trim(),
            state: values.state.trim(),
            country: values.country.trim(),
            pin: values.pin.trim()
        };

        let aoiInfo = {
            tag: values.tag.trim(),
            area: values.area,
            circumference: values.circumference,
            address: address,
            geometry: props.aoi?.geometry
        };

        let aoiPayload = JSON.parse(JSON.stringify(aoiInfo));
        return aoiPayload;
    };

    const saveAOIInfo = () => {
        const aoiChanged = isDataChanged();
        if (!aoiChanged) {
            setMode('view');
            return;
        }
        var aoiPayload = getAOIInfo();
        if(aoiPayload != null)
        {
            aoiService.update(user.mkey, user.region, props.aoi?.member_aoi_id, aoiPayload).then((data) => {
                reloadOnSuccess();
            }).catch((error) => {
                console.log('Error in saving AOI Details', error, aoiPayload);
                toast.error(error + " - Unable to save AOI", 'fail');
            });
        }
    };

    const reloadOnSuccess = () => {
        props.onSave();
        toast.success("AOI Details saved successfully");
        setMode('view');
    };

    const expandCollapseEdit = (event) => {
        if(event.target.classList.contains('edit')) {
            setMode('edit');
            setExpand(true);
        } else {
            setExpand(!expand);
        }
    };

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === 'pin' && value.trim().length > 6) {
            toast.error('PIN/ZIP Code should be in 6 digits');
            return;
        }

        setValues({
          ...values,
          [name]: value
        });
    };

    const ShowAOIDetails = () => {
        return <>
                    {
                        fullAddress && 
                        <div className="col-12 col-md-6">
                            <div className="view-group"><label className="view-label">Address</label>
                                <p>{fullAddress}</p>
                            </div>
                        </div>
                    }
                    {
                        props.aoi?.area && 
                        <div className="col-12 col-md-3">
                            <div className="view-group"><label className="view-label">Area</label>
                                <p>{props.aoi?.area}</p>
                            </div>
                        </div>
                    }
                    {
                        props.aoi?.circumference && 
                        <div className="col-12 col-md-3">
                            <div className="view-group"><label
                                    className="view-label">Circumference</label>
                                <p>{props.aoi?.circumference}</p>
                            </div>
                        </div>
                    }
                </>;
    };

    const EditAOIDetails = () => {
        return  <div className="aoi-form aoi-information">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="tag">Tag <span className="required">*</span></label>
                                <input type="text" className="form-control" id="tag" placeholder="" name="tag" value={values.tag} onChange={(e)=>handleInputChange(e)} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label">Area</label>
                                <p>{values.area}</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label">Circumference</label>
                                <p>{values.circumference}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <h4>Address</h4>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="address1">Address 1</label>
                                <input type="text" className="form-control" id="address1" placeholder="" name="address1" value={values.address1} onChange={(e)=>handleInputChange(e)} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="address2">Address 2</label>
                                <input type="text" className="form-control" id="address2" placeholder="" name="address2" value={values.address2} onChange={(e)=>handleInputChange(e)} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="address3">Address 3</label>
                                <input type="text" className="form-control" id="address3" placeholder="" name="address3" value={values.address3} onChange={(e)=>handleInputChange(e)} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="city">City</label>
                                <input type="text" className="form-control" id="city" placeholder="" name="city" value={values.city} onChange={(e)=>handleInputChange(e)} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="State">State</label>
                                <input type="text" className="form-control" id="State" placeholder="" name="state" value={values.state} onChange={(e)=>handleInputChange(e)} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Country">Country</label>
                                <input type="text" className="form-control" id="Country" placeholder="" name="country" value={values.country} onChange={(e)=>handleInputChange(e)} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Pincode">Pincode</label>
                                <input type='number' className="form-control" id="Pincode" placeholder="" name="pin" value={values.pin} onChange={(e)=>handleInputChange(e)} />
                            </div>
                        </div>
                        {
                            mode != "create" && 
                            <div className="btn-area">
                                <button type="button" className="btn btn-primary" onClick={saveAOIInfo}>Update</button>
                                <button type="button" className="btn btn-outline" onClick={() => setMode('view')}>Cancel</button>
                            </div>
                        }
                    </div>
                </div>;
    };

    return  <div className={`aoi-detail-box ${expand ? "expanded" : "collapsed"}`}>
                <div className="aoi-detail-header" onClick={expandCollapseEdit}>AOI Information
                {
                    user.isReadOnly == false && mode == 'view' &&
                    <img src={EditIcon} alt="" className="edit edit-icon" />
                }
                </div>
                <div className="aoi-detail-content">
                    <div className="row">
                        {
                            mode == "view" ? ShowAOIDetails() : EditAOIDetails()
                        }
                    </div>
                </div>
            </div>;
};

export default AOIViewInfo;