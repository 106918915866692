import PhoneInput from "react-phone-input-2";
import React, {useContext, useState, useEffect, useRef} from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { memberService } from '../../Services/memberService';
import { toast } from "react-hot-toast";
import { timeZoneUtil } from "../../Utils/timeZoneUtil";

const MFA = () => {
    const {user} = useContext(UserContext);
    const [mfaDetails, setMFADetails] = useState(null);
    const [mfaEnabled, setMFAEnabled] = useState(false);
    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState(timeZoneUtil.getISDCode());
    const [showSendCodeBtn, setShowSendCodeBtn] = useState(false);
    const [showCodeTxt, setShowCodeTxt] = useState(false);
    const [readOnlyPhone, setReadOnlyPhone] = useState(false);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [timerRunning, setTimerRunning] = useState(false);
    const [timeDisplay, setTimeDisplay] = useState(60);
    const [hideEdit, setHideEdit] = useState(false);
    const showCodeTxtRef = useRef(showCodeTxt);
    const appConfig = window.config;

    useEffect(() => {
        refresh();
    }, [user]);

    useEffect(() => {
        showCodeTxtRef.current = showCodeTxt;
    }, [showCodeTxt]);

    const refresh = () => {
        setShowCodeTxt(false);
        setCode("");
        setLoading(true);
        memberService.getMFA(user.originalmKey, user.authId).then((data) => {
            setLoading(false);
            setMFADetails(data);
            setMFAEnabled(data.sms_mfa_enabled);
            resetElements(data);
        }).catch((error) => {
            setLoading(false);
        });
    };

    const resetElements = (details) => {
        setPhone(details.phone_number ?? timeZoneUtil.getISDCode());
        if(details.phone_number == user.authId) {
            setReadOnlyPhone(true);
            setHideEdit(true);
        }
        else if(details.phone_number_verified) {
            setReadOnlyPhone(true);
            setShowSendCodeBtn(false);
        } else {
            setReadOnlyPhone(false);
            if(details.phone_number != null && details.phone_number != "") {
                setShowSendCodeBtn(true);
            } else {
                setPhone(countryCode);
                setShowSendCodeBtn(false);
            }
        }
    };

    const changePhoneNumber = (value, country) => {
        setPhone(value);
        setCountryCode(country.dialCode);
        setShowSendCodeBtn(mfaDetails.phone_number_verified == false || mfaDetails.phone_number != prependPlus(value));
    };

    const RenderFederated = () => {
        if(mfaDetails == null) 
            return <></>;

        return <div>
                    <div>
                        You are using Social login to this application.<br/>
                        Please use the URL(s) below to setup Multi-Factor Authentication for your account.
                    </div>
                    <div>
                        {
                            mfaDetails.provider_names.includes("Google") && 
                                <div>Google: <a href="https://myaccount.google.com/signinoptions/two-step-verification" target="_blank">
                                                https://myaccount.google.com/signinoptions/two-step-verification
                                            </a>
                                </div>
                        }
                        {
                            mfaDetails.provider_names.includes("Facebook") && 
                                <div>Facebook: <a href="https://www.facebook.com/security/2fac/setup/intro" target="_blank">
                                                https://www.facebook.com/security/2fac/setup/intro
                                            </a>
                                </div>
                        }
                    </div>
                </div>;
    };

    const startTimer = () => {
        const endTime = new Date(new Date().getTime() + 120000);
        setTimeDisplay(parseInt((endTime - new Date())/1000));
        setTimerRunning(true);

        var interval = setInterval(() => {
            var seconds = parseInt((endTime - new Date())/1000);
            setTimeDisplay(seconds);
            if(seconds <= 0) {
                clearInterval(interval);
                setTimerRunning(false);
                if(showCodeTxtRef.current == true)
                    setShowSendCodeBtn(true);
            }
        }, 1000);
    };

    const requestCode = () => {
        if (!!timerRunning)
        {
            toast.error(`Please wait for ${timeDisplay} seconds for sending code again`);
            return;
        }
        if(! /^\+{0,1}\d{10,12}$/.test(phone))
        {
            toast.error("Please enter a phone number between 10-12 digits including ISD code");
            return;
        }

        if(!!mfaDetails.phone_number_verified && mfaDetails.phone_number == prependPlus(phone)) {
            if(!!mfaEnabled) {
                resetElements(mfaDetails);
                toast.success("Phone number is already verified and MFA is enabled");
            } else {
                changeMFA(true, true);
            }
            return;
        }

        setReadOnlyPhone(true);
        setLoading(true);
        memberService.enableMFA(user.originalmKey, user.authId, {"phone_number": prependPlus(phone), "roles": user.roles}).then((data) => {
            setLoading(false);
            setShowCodeTxt(true);
            setShowSendCodeBtn(false);
            startTimer();
        }).catch((error) => {
            setReadOnlyPhone(false);
            setLoading(false);
            console.error(error);
            toast.error(error);
        });
    };

    const cancelCode = () => {
        setShowCodeTxt(false);
        setReadOnlyPhone(false);
        setShowSendCodeBtn(mfaDetails.phone_number_verified == false || mfaDetails.phone_number != prependPlus(phone));
    }

    const prependPlus = (phone) => {
        if(phone.startsWith("+"))
            return phone;
        return "+" + phone;
    };

    const validateCode = () => {
        setLoading(true);
        memberService.verifyMFAToken(user.originalmKey, user.authId, code).then((data) => {
            toast.success("Successfully Verified the phone number");
            setTimerRunning(false);
            refresh();
        }).catch((error) => {
            setLoading(false);
            console.error(error);
            toast.error("The code is invalid");
        });
    };

    const changeMFA = (checked, keep_phone_number) => {
        if(checked == true) {
            setMFAEnabled(checked);
            setLoading(true);
            memberService.enableMFA(user.originalmKey, user.authId, {"phone_number": prependPlus(phone), "roles": user.roles}).then((data) => {
                refresh();
                toast.success("Enabled Multi-Factor Authentication");
            }).catch((error) => {
                setLoading(false);
                console.error(error);
            });
        } else {
            if(user.isDA || user.isAdmin || user.isSuperAdmin) {
                toast.error("You have a privileged role and MFA cannot be disabled");
                return;
            }
            setMFAEnabled(checked);
            setLoading(true);
            memberService.disableMFA(user.originalmKey, user.authId, {"roles": user.roles, keep_phone_number: !!keep_phone_number}).then((data) => {
                refresh();
                if(keep_phone_number)
                    toast.success("Disabled Multi-Factor Authentication.");
                else 
                    toast.success("Disabled Multi-Factor Authentication and removed the Phone number.");
            }).catch((error) => {
                setLoading(false);
                console.error(error);
            });
        }
    };

    return (
        <div className="form-content">
        <h3>Multi-Factor Authentication</h3>
        {
            loading == true && 
            <div> Loading .... </div>
        }
        {
            mfaDetails == null || mfaDetails.is_federated_provider == true 
            ? <RenderFederated></RenderFederated>
            :   <div className="form-group">
                    {
                        !mfaDetails.phone_number_verified &&
                        <div className="mb10 mfa-verify-note">
                            Please verify your phone number to enable Multi-Factor Authentication.
                        </div>
                    }
                    <div className='phone'>
                        <label className='form_label' htmlFor='phone'>Phone </label>
                        <div className="phone-input">
                            <div className='form_input'>
                                <PhoneInput
                                    name="phone"
                                    placeholder="Phone"
                                    value={phone}
                                    disabled={readOnlyPhone}
                                    countryCodeEditable={false}
                                    onChange = {(value, country) => changePhoneNumber(value, country)}
                                />
                            </div>
                            <div className="action-btns">
                                {
                                    !readOnlyPhone &&
                                    <input className={showSendCodeBtn && timerRunning == false ? "btn btn-primary" : "btn btn-secondary"} 
                                        type="button" name="sendcode" 
                                        value={timerRunning ? `Send Code in ${timeDisplay} Seconds`  : "Send Code"}
                                        onClick={requestCode}/>
                                }
                                {
                                    timerRunning == false && readOnlyPhone && hideEdit == false && <a className="btn btn-primary edit-link" onClick={cancelCode}>Change</a>
                                }
                                {
                                    readOnlyPhone == false && showCodeTxt == false && (mfaDetails.phone_number != prependPlus(phone) || !!mfaDetails.phone_number_verified) &&
                                    <div><input type="button" name="cancelEdit" className="btn btn-outline" value="Cancel" onClick={() => resetElements(mfaDetails)} /></div>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        timerRunning && (showSendCodeBtn || showCodeTxt) &&
                        <div>Please wait {timeDisplay} seconds to resend the code</div>
                    }
                
                    {
                        showCodeTxt && 
                        <div className="verify-code">
                            <input type="text" name="code" placeholder="Enter Verification Code" className="form-control" value={code} onChange={(e) => setCode(e.target.value)}/>
                            <input type="button" name="submitcode" value="Submit" className="btn btn-primary" onClick={validateCode} />
                            <input type="button" name="cancelCode" value="Cancel" className="btn btn-outline" onClick={cancelCode} />
                        </div>
                    }
                    {
                        showSendCodeBtn == false && showCodeTxt == false && readOnlyPhone == true && mfaDetails.phone_number_verified == true &&
                        <div className="toggle-switch" >
                            <span className="label">Multi-Factor Authentication</span>
                            <input type="checkbox" id="multifactor" name="multifactor" checked={mfaEnabled} onChange={(e) => changeMFA(e.target.checked, true)} />
                            <label htmlFor="multifactor"></label>
                        </div>
                    }
                    {
                        showCodeTxt == false && (!!readOnlyPhone || 
                            mfaDetails.phone_number_verified == false && mfaDetails.phone_number != null && mfaDetails.phone_number == prependPlus(phone)) &&
                        <input className="btn btn-primary" type="button" name="deletePhone" value="Remove Phone No" onClick={() => changeMFA(false, false)}/>
                    }
                </div>
        }
        </div>
    );
}

export default MFA;