import { fetchWrapper } from '../Helpers/fetchWrapper';
import { apiService } from './apiService';

const getDomainConfig = (region) => {
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/domain-based-configuration`);
};

const postDomainConfig = (config) => {
    return fetchWrapper.post(`${adminApiBaseUrl}/domain-based-configuration`, config);
};

const addUsecase = (usecase) => {
    return fetchWrapper.post(`${adminApiBaseUrl}/usecase`, usecase);
};

const updateUsecase = (name, usecase) => {
    return fetchWrapper.put(`${adminApiBaseUrl}/usecase/${name}`, usecase);
};

const addLanduse = (landuse) => {
    return fetchWrapper.post(`${adminApiBaseUrl}/landuse`, landuse);
};

const updateLanduse = (name, landuse) => {
    return fetchWrapper.put(`${adminApiBaseUrl}/landuse/${name}`, landuse);
};

const creditDebitTokens = (mkey, region, authId, data) => {
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/billing/${mkey}/token-transaction`, data, {"auth-id": authId});
};

const getUsecase = (region, name) => {
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/usecase/${name}`);
};



function getConfig(region){
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/configuration/all`);
}

function getRoles(region) {
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/role/all`);
}

function getDatasources(region) {
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/datasource?include_details=true`);
}

const getLanduse = (region, landuseName) => {
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/landuse/${landuseName}`);
}

function getLanduses(region) {
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/landuse`);
}

function getUsecases(region) {
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/usecase`);
}

function getUser(username, include_aois=false) {
    let queryString = "include_profile=true&include_roles=true&include_config=true&include_tokens=true"
    if (include_aois){
        queryString = `${queryString}&include_aois=true`
    }
    const query = {
        "auth-id": username, 
        "query-string": queryString
    };
    return fetchWrapper.post(`${adminApiBaseUrl}/member/query`, query);
}

function deleteUser(region, userName, authId) {
    return fetchWrapper.deleteRequest(`${adminApiBaseUrl}/${region}/member`, {"auth-id": userName},{"auth-id": authId});
}

function createUser(userDetails) {
    return fetchWrapper.post(`${adminApiBaseUrl}/member/create-cognito-user`, userDetails);
}

function updateUserRoles(mkey, region, roles) {
    const postRoles = { "roles": roles };
    return fetchWrapper.put(`${adminApiBaseUrl}/${region}/role/member/${mkey}`, postRoles);
}

function addConfiguration(config) {
    return fetchWrapper.post(`${adminApiBaseUrl}/configuration`, config);
}

function addDatasource(datasource) {
    return fetchWrapper.post(`${adminApiBaseUrl}/datasource`, datasource);
}

function addRole(role) {
    return fetchWrapper.post(`${adminApiBaseUrl}/role`, role);
}

function sendNotification(search_criteria, message, notification_type) {
    const url = `${adminApiBaseUrl}/member/notify`;
    const payload = {
        search_criteria: search_criteria ?? {},
        notification_msg: JSON.stringify(message),
        notification_type: notification_type
    };
    return fetchWrapper.post(url, payload);
}

const getAOIsInGeometry = (usecase, payload) => {
    return fetchWrapper.post(`${adminApiBaseUrl}/aoi/${usecase}/geom-query`, payload);
};

const getAOIInsights = (payload) => {
    return fetchWrapper.post(`${adminApiBaseUrl}/aoi/insights`, payload);
};

const getUsers = (authId, region, usecase, subscription) => {
    const data = {};
    let parameters = "";
    if(region != null && region != "all") {
        parameters = `?regions=${region}`;
    }
    if(authId != null && authId != "") {
        data["auth_ids"] = [authId];
    }
    if(subscription != null && subscription != "all") {
        data["subscription"] = "subscribed";
    }
    if(usecase != null && usecase != "all") {
        data["usecases"] = [usecase];
        data["subscription"] = "subscribed";
    }
    return fetchWrapper.post(`${adminApiBaseUrl}/member/search${parameters}`, {
        search_criteria: data,
        attributes: [
            "name",
            "communication_email",
            "communication_phone_number",
            "address"
        ]
    });
}

export const adminService = {
    getConfig,
    getDomainConfig,
    postDomainConfig,
    getRoles,
    getDatasources,
    getLanduse,
    getLanduses,
    getUsecases,
    getUsecase,
    addConfiguration,
    addDatasource,
    addLanduse,
    updateLanduse,
    addRole,
    addUsecase,
    updateUsecase,
    getUser,
    getUsers,
    deleteUser,
    updateUserRoles,
    createUser,
    creditDebitTokens,
    sendNotification,
    getAOIsInGeometry,
    getAOIInsights
};