import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import moment from "moment";

import { UserContext } from '../../Contexts/UserContext';
import { eventService } from '../../Services/eventService';
import AddEventBounds from './AddEventBounds';
import AddEventFileUpload from './AddEventFileUpload';
import AddEventShowMessages from './AddEventShowMessages';

const AddEventModal = (props) => {
    const {user} = useContext(UserContext);
    const [executionId, setExecutionId] = useState(null);
    const [eventStatus, setEventStatus] = useState(null);
    // 1 - Beginning - File is not uploaded
    // 2 - Running - After file is uploaded, before waiting for callback
    // 3 - Waiting for call back 
    // 4 - Running after call back is submitted
    // 5 - Completed 
    const [step, setStep] = useState(1);
    const [boundsData, setBoundsData] = useState(null);

    const getStatus = (execId) => {
        eventService.getCreateEventStatus(user.region, execId).then((data) => {
            setEventStatus(data);
            if(data.status == "COMPLETED") {
                setStep(5);
                props.refresh();
                return;
            }
            if(data.status == "RUNNING") {
                setStep(step == 1 ? 2 : 4);
                if(data.isWaitingForCallback == true) {
                    setBoundsData(data);
                    setStep(3);
                    return;
                }
            }
            setTimeout(() => {
                getStatus(execId);
            }, 5000);
        }).catch((error) => {
            setStep(5);
            setEventStatus({status: "COMPLETED", completionStatus: "FAILURE", messages: [error]});
            toast.error("There is an error in retrieving the status.");
            console.error(error);
        })
    };

    const uploadFiles = (uploadedImages, uploadedMetadata, s3urls) => {
        let imagePaths = {};
        uploadedImages.data.map((d) => {
            imagePaths[d.filename] = {"type":"temp", "path": d.relativePath};
        });
        s3urls.map((url) => {
            const trimmedUrl = url.trim() ?? "";
            if(trimmedUrl != "") {
                var fileName = trimmedUrl.substring(trimmedUrl.lastIndexOf('/') + 1);
                imagePaths[fileName] = {"type": "s3", "path": trimmedUrl};
            }
        });
        var data = {
                    "mkey": user.mkey,
                    "aoi_bounds": {
                        "minx": props.aoi.minx,
                        "miny": props.aoi.miny,
                        "maxx": props.aoi.maxx,
                        "maxy": props.aoi.maxy
                    },
                    "images_paths": imagePaths
                };
        if(uploadedMetadata.data.length > 0)
            data.metadata_key = uploadedMetadata.data[0].relativePath;

        eventService.createEventOld(user.region, data).then((data) => {
            setExecutionId(data.execution_id);
            getStatus(data.execution_id);
        });
    };

    const cancel = () => {
        eventService.cancelCreateEvent(user.region, executionId).then((result) => {
            toast.success("Cancelled the creation of event");
            props.show(false);
        }).catch((error) => {
            console.error(error);
            props.show(false);
        });
    };

    const submit = (data) => {
        var sdata = {
            "member_aoi_id": props.aoi.member_aoi_id,
            "aoi_geometry": props.aoi.geometry,
            "usecase": props.usecase,
            "images_details": {}
        };
        let isValid = 0;
        Object.keys(data).map((key) => {
            var val = {};
            val.date = data[key].timestamp ?? null;
            val.source = data[key].source ?? "";
            val.bounds = {};
            let [isValidEast, eastValue] = validateAndGetNumber(data[key].east);
            let [isValidWest, westValue] = validateAndGetNumber(data[key].west);
            let [isValidSouth, southValue] = validateAndGetNumber(data[key].south);
            let [isValidNorth, northValue] = validateAndGetNumber(data[key].north);
            if(val.date == null || !isValidEast || !isValidWest || !isValidSouth || !isValidNorth)
            {
                isValid = 1;
                return;
            }
            val.date = moment(val.date).format('DD-MM-YYYY h:mm:ss.000+00').toString();
            val.bounds.maxx = eastValue;
            val.bounds.minx = westValue;
            val.bounds.maxy = northValue;
            val.bounds.miny = southValue;
            if(val.bounds.maxx < props.aoi.minx || val.bounds.minx > props.aoi.maxx 
                || val.bounds.maxy < props.aoi.miny || val.bounds.miny > props.aoi.maxy) 
            {
                isValid = 2;
                return;
            }
            sdata["images_details"][key] = val;
        });
        if(isValid == 1) {
            toast.error("Please enter valid values for the date and bounds");
            return;
        }
        if(isValid == 2) {
            toast.error("The bounds of AOI does not intersect with the bounds of the image");
            return;
        }
        eventService.confirmBounds(user.region, executionId, sdata).then((result) => {
            getStatus(executionId);
        }).catch((error) => {
            console.error(error);
            toast.error("Unable to submit the bounds ");
        });
    }

    const validateAndGetNumber = (val) => {
        if(val == null || val == "")
            return [false, 0];
        if(typeof val == "number")
            return [true, val];
        let parsedNumber = Number(val);
        return [true, parsedNumber];
    };

    return (
        <>
            <Modal
                show={() => props.show(true)}
                onHide={() => props.show(false)}
                backdrop='static'
                keyboard={false}
                dialogClassName='add-event-dialog'
                className='add-event'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        step == 1 &&
                        <AddEventFileUpload cancel={() => props.show(false)} aoi={props.aoi} submit={uploadFiles}></AddEventFileUpload>
                    }
                    {
                        step == 2 &&
                        <div className='processing-alert alert alert-primary'>
                            <div className="processing"></div> 
                            <AddEventShowMessages message={"Please wait while the files are uploaded"} />
                        </div>
                    }
                    {
                        step == 3 &&
                        <AddEventBounds data={boundsData} cancel={cancel} submit={submit}/>
                    }
                    {
                        step == 4 &&
                        <div className='processing-alert alert alert-primary'>
                            <div className="processing"></div> 
                            <AddEventShowMessages message={"Please wait while the event is created"} />
                        </div>
                    }
                    {
                        step == 5 &&
                        <AddEventShowMessages data={eventStatus} close={() => props.show(false)}/>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddEventModal;