import { useContext } from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { AOIWizzardContext } from '../../Contexts/AOIWizzardContext';

const SubscriptionConfirmation = (props) => {
    const { expensesPerMonth } = useContext(UserContext);
    const { selectedAOI } = useContext(AOIWizzardContext);

    const getConfirmationMessage = () => {
        let message = "";
        if (props.subscriptionRequest.isSubscribe) {
            let projectedExpensesPerMonth =  calculateProjectedExpensePerMonth();
            message = `Your total monthly subscription cost will ${projectedExpensesPerMonth > expensesPerMonth ? 'increase' : 'decrease'} from ${expensesPerMonth} credits/month to ${projectedExpensesPerMonth} credits/month. \nDo you want to proceed?`;
        }
        else {
            message = `This AOI will no longer be monitored for changes and you will not receive any new updates. Do you still want to proceed?`;
        }
        return message;
    }

    const calculateProjectedExpensePerMonth = () => {
        let selectedPlanPrice = props.pricingDetails[props.subscriptionRequest.serviceName][props.subscriptionRequest.subDetails?.plan_type ?? ""];
        let subscribedPlanInSameUsecase = selectedAOI?.subscribed_usecases?.find(u => u.usecase.name == props.subscriptionRequest.serviceName)?.subscription_details?.plan_type ?? "";
        let existingSubscriptionPlanCost = subscribedPlanInSameUsecase != "" ? props.pricingDetails[props.subscriptionRequest.serviceName][subscribedPlanInSameUsecase] : 0;
        return expensesPerMonth + selectedPlanPrice - existingSubscriptionPlanCost;
    }

    return (
        <>
            <div className="subscription-confirmation">
                <div className="message">
                    <h5 style={{whiteSpace: 'pre-wrap'}}>{ getConfirmationMessage() }</h5>
                </div>
                <div className="btn-area">
                    <button type="button" className="btn btn-primary ml-auto" onClick={props.handleConfirm}>Yes</button>
                    <button type="button" className="btn btn-outline" onClick={props.handleCancel}>No</button>
                </div>
            </div>
        </>
    );
}

export default SubscriptionConfirmation;