import { useContext } from 'react';
import {Modal} from 'react-bootstrap';
import { AOIContext } from '../../Contexts/AOIContext';
import AOIWizzard from './AOIWizzard';

const AOIWizzardModal = ({options}) => {
    const {openAOIWizzardModal} = useContext(AOIContext);
    return (
        <>
            <Modal
                show={() => openAOIWizzardModal(true)}
                onHide={() => openAOIWizzardModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="aoi-wizzard-modal-dialog"
                className="aoi-wizzard-modal"
                fullscreen={true}
            >
                <Modal.Body>
                    <AOIWizzard options={options}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AOIWizzardModal;