import React, { useContext, useEffect, useState } from 'react';
import { billingService } from "../Services/billingService";
import { UserContext } from "../Contexts/UserContext";
import TokensIcon from '../Assets/img/icon-tokens.png';
import PoweredByStripeIcon from '../Assets/img/img-powered-by-stripe.png';
import toast from 'react-hot-toast';

const CustomerPayment = (props) => {
    const [priceOptions, setPriceOptions] = useState([]);
    const [selectedField, setSelectedField] = useState(null);
    const {user} = useContext(UserContext);

    useEffect(() => {
        billingService.getPricing(user.originalRegion).then((data) => {
            let options = [];
            Object.keys(data).map((field) => {
                options.push({
                    id: field,
                    tokens: data[field].tokens,
                    amount: data[field].amount,
                    description: data[field].description
                });
            });
            setPriceOptions(options);
        });
    }, []);

    const gotoPayment = () => {
        if(selectedField == null) {
            toast.error("Please select a package");
            return;
        }
        let postData = {
            items: [{"price": selectedField, "quantity": 1}],
            success_url: window.location.protocol + '//' + window.location.host + "/billing/success",
            cancel_url: window.location.protocol + '//' + window.location.host + "/billing/cancel"
        };
        billingService.stripeCheckoutSession(user.originalRegion, user.originalmKey, postData).then((data) => {
            window.location = data.url;
        });
    };

    const renderPriceOption = (option) => {
        return <div className="plan-row" key={option.id}>
                    <div className="plan-left">
                        <div className="custom-radio">
                            <input type="radio" id={option.id} name="plan" value={option.id} 
                                onClick={() => setSelectedField(option.id)} checked={selectedField == option.id} onChange={e => {}} />
                            <label htmlFor={option.id}>{option.amount} USD</label>
                        </div>
                    </div>
                    <div className="plan-rate">
                        {option.description}
                    </div>
                </div>;
    };

    return (
        <div className="page-body payments">
            <div className="container">
                <div className="form-content">
                    <div className="form-head">
                        <h3>Purchase Credits</h3>
                    </div>
                    <div className="plans-container">
                        <div className="packages-box">
                            <div className="head"><img src={TokensIcon} alt="" /> Choose Package</div>
                            <div className="package-options">
                                {
                                    priceOptions.map((option) => renderPriceOption(option))
                                }
                            </div>
                        </div>

                    </div>
                    <div className="payments-btn-area">
                        <div className="checkout-btn">
                            <button type="button" className="btn btn-primary" onClick={gotoPayment}>Checkout &rarr;</button>
                            <img src={PoweredByStripeIcon} className="stripe" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default CustomerPayment;