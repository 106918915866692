import { useState } from "react";

const GMRLayer = (props) => {
    const [expandPanel, setExpandPanel] = useState(true);

    return (
        <div className={expandPanel ? "filter-box closed" : "filter-box opened"}>
            <div className='filter-header'>
                <div className='filter-caret' onClick={() => setExpandPanel(!expandPanel)}>
                    <i></i>
                </div>
                <div className='filter-name' onClick={() => setExpandPanel(!expandPanel)}>
                    {props.layer.name}
                </div>
                <div className={props.layer.visibility ? 'filter-visibility show' : 'filter-visibility hide'} onClick={() => props.updateLayer({...props.layer, visibility: !props.layer.visibility})}>
                    <i></i>
                </div>
            </div>
            <div className='filter-body'>
                <div className="range-slider">
                    <div className="range-label">
                        <div className='range-number'>0</div>
                        <label htmlFor="Alpha" className="form-label">Alpha <span className='selected-value'>{props.layer.alpha}</span></label>
                        <div className='range-number'>1</div>
                    </div>
                    <input type="range" min="0" max="100" className="form-range" id="Alpha" value={props.layer.alpha * 100} onChange={(e) => props.updateLayer({...props.layer, alpha: e.target.value / 100})}></input>
                </div>
                <div className='filter-legends'>
                    {
                        props.legend && props.legend.map((legend) => <div className='legend-row' key={legend[0]}>
                                    <div className='legend-color' style={{backgroundColor: legend[0]}}></div> 
                                    <div className='legend-name'>{legend[1]}</div>
                                </div>)
                    }
                </div>
            </div>
        </div>

    );
}

export default GMRLayer;