import { useContext, useEffect, useRef, useState } from 'react';
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import {MapContext} from "../../Contexts/MapContext";
import { MapUtils } from '../MapUtils';

const INSET_DIFFERENCE = 3;
const INSET_MIN_ZOOM = 0;
const INSET_MAX_ZOOM = 4;

const containerStyle = {
    position:"absolute",
    height: "150px",
    width: "250px",
    bottom: "15px",
    right: "15px",
    textAlign: "center",
    border: "5px solid #FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
};

const customStyle = [
    {
        featureType: "all",
        elementType: "labels",
        stylers: [
            { visibility: "off" }
        ]
    },
    {
        featureType: "road",
        stylers: [
          { visibility: "off" }
        ]
    }
]

const clamp = function (num, min, max) {
    return Math.min(Math.max(num, min), max);
  };

const InsetMap = (props) => {
    const insetMapRef = useRef(null);
    const mapContext = useContext(MapContext);
    const [markerMap, setMarkerMap] = useState({});
    const [insetOptions, setInsetOptions] = useState( {
        center: {lat: 0, lng: 0},
        zoom: 0,
        mapTypeId: "roadmap",
        keyboardShortcuts: false,
        scrollwheel: false,
        draggable:false,
        disableDefaultUI: true,
    });
    const circle = {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: "red",
        fillOpacity: 0.4,
        scale: google.maps.Size(1,1),
        strokeColor: "red",
        strokeWeight: 1,
    };       
    const onLoad = (ref) => {
        insetMapRef.current = ref;
        insetMapRef.current.set('styles',customStyle);
    };
    const appConfig = window.config;  
    const {isLoaded, loadError} = useJsApiLoader({
        googleMapsApiKey: appConfig.mapApiKey,
        libraries: window.libraries,
        v: "weekly",
    });

    useEffect(() => {       
        if(mapContext.mapRef?.current){ 
            let mainMapBounds = mapContext.mapRef.current.getBounds();
            let neCorner = mainMapBounds.getNorthEast();
            let swCorner = mainMapBounds.getSouthWest();
            let coords = [
                { lat: neCorner.lat(), lng: swCorner.lng() },
                { lat: neCorner.lat(), lng: neCorner.lng() },
                { lat: swCorner.lat(), lng: neCorner.lng() },
                { lat: swCorner.lat(), lng: swCorner.lng() },
            ];

            if(Math.abs(coords[0]["lng"] - coords[1]["lng"]) > 180){
                coords.splice(1, 0,{lat: neCorner.lat(), lng: swCorner.lng()/2.0});
                coords.splice(2, 0,{lat: neCorner.lat(), lng: 0 });
                coords.splice(3, 0,{lat: neCorner.lat(), lng: -swCorner.lng()/2.0});
                coords.splice(6, 0,{lat: swCorner.lat(), lng: -swCorner.lng()/2.0});
                coords.splice(7, 0,{lat: swCorner.lat(), lng: 0});
                coords.splice(8, 0,{lat: swCorner.lat(), lng: swCorner.lng()/2.0});
            }

            if(insetMapRef.current.data) {
                insetMapRef.current.data.forEach(function(feature){
                    insetMapRef.current.data.remove(feature)
                });
                insetMapRef.current.data.add({ geometry: new google.maps.Data.Polygon([coords])});
                insetMapRef.current.data.setStyle({
                    strokeColor: 'purple',
                    strokeWeight: 2,
                    strokeOpacity: 1,
                    fillOpacity:0
                });
            }
          
            let newCenter= {
                lat: mapContext.mapRef?.current?.center?.lat(), 
                lng: mapContext.mapRef?.current?.center?.lng() 
            }          
            let newZoom = clamp(mapContext.mapRef?.current?.zoom - INSET_DIFFERENCE, INSET_MIN_ZOOM, INSET_MAX_ZOOM);
            setInsetOptions({
                center: newCenter,
                zoom: newZoom            
            });                    
        }    
     }, [props.mapState]);

    // We have to create a mapping of our places to actual Marker objects
    const markerLoadHandler = (marker, aoi) => {
        return setMarkerMap(prevState => {
            return { ...prevState, [aoi.member_aoi_id]: marker };
        });
    };
   
    return (
        <GoogleMap
            ref={insetMapRef}
            onLoad={onLoad}
            mapContainerStyle={containerStyle}
            options={insetOptions}
        >
            { /* Saved AOIs Marker */      
                mapContext.userAOIs && mapContext.userAOIs.map((aoi) => (
                    <Marker 
                        icon={circle}
                        key={aoi.member_aoi_id}
                        position={MapUtils.getCenterForGeometry(aoi.geometry)} 
                        onLoad={marker => markerLoadHandler(marker, aoi)}
                    />
                ))     
            }   
        </GoogleMap>
    );
};

export default InsetMap;
