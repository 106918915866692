import { useContext, useState } from "react";
import { AOIContext } from "../../Contexts/AOIContext";
import AOIWizzardContent from "./AOIWizzardContent";
import AOIWizzardHead from "./AOIWizzardHead";
import AOIWizzardSteps from "./AOIWizzardSteps";

const AOIWizzard = ({options}) => {
    const [activeStep, setActiveStep] = useState(options?.activeStep ?? 1);
    const {domainConfig, openAOIWizzardModal, selectedAOI} = useContext(AOIContext);
    const availableServices = domainConfig.usecases?.filter(usecase=>usecase.type == 'aoi' && !!usecase.isEnabled);

    const handleCloseWizard = () => {
        openAOIWizzardModal(false);
    };

    return (
        <div className="aoi-wizzard">
            <AOIWizzardHead 
                handleCloseWizard={handleCloseWizard}
                selectedAOI = {selectedAOI}
            />
            <AOIWizzardSteps 
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                availableServices={availableServices}
            />
            <AOIWizzardContent 
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                availableServices={availableServices}
                mode={options?.mode}
            />
        </div>  
    );
}

export default AOIWizzard;