import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Contexts/UserContext";
import toast from 'react-hot-toast';
import { adminService } from "../../../Services/adminService";
import SortIcon from '../../../Assets/img/icon-sort.svg';
import CSInsightsMap from "./CSInsightsMap";
import CSInsightsList from "./CSInsightsList";
import { MapUtils } from "../../MapUtils";
let Usecases = []

const DefaultFilter = {
    usecase: '',
    service_area: 'all',
    subscription: 'all',
    bounds: { W: -180, S: -90, E: 180, N: 90 },
    mapCoords: []
}

const CSInsights = () => {
    const { domainConfig, usecases, impersonateUser } = useContext(UserContext);
    Usecases = usecases.map(u => { 
        return {
            "name": u.name, 
            "displayName": domainConfig.usecases.find(d => d.name == u.name)?.displayName ?? u.name,
            "isEnabled": domainConfig.usecases.find(d => d.name == u.name)?.isEnabled ?? false
        } 
    });
    Usecases = Object.values(Usecases).sort((a,b) => a.isEnabled ? -1 : 1);
    const [filter, setFilter] = useState({...DefaultFilter, usecase: Usecases[0]?.name ?? ''});
    const [aois, setAOIs] = useState([]);
    const [sortBy, setSortBy] = useState('auth-id');
    const [bounds, setBounds] = useState(DefaultFilter.bounds);
    const [boundingBoxError, setBoundingBoxError] = useState('');
    const [selectedTab, setSelectedTab] = useState('list');
    const [csInsightsMgs, setCsInsightsMgs] = useState('');
    const [serviceArea, setServiceArea] = useState(null);
    const [selectedAOI, setSelectedAOI] = useState(null);
    const [showSelectedAOIPopover, setShowSelectedAOIPopover] = useState(false);

    useEffect(()=>{
        if (filter.usecase != '') {
            initServiceArea(filter.usecase);
        }
    }, []);

    useEffect(()=>{
        sortAois(aois, sortBy);
    }, [sortBy]);

    const getAOIs = async (filter) => {
        if (filter.usecase != '') {
            setCsInsightsMgs('Please wait.. Loading...');
            const bounds = filter.bounds;
            let coordinates = selectedTab == 'map' ? filter.mapCoords :
            [[
                [bounds.W, bounds.N],
                [bounds.W, bounds.S],
                [bounds.E, bounds.S],
                [bounds.E, bounds.N],
                [bounds.W, bounds.N]
            ]];

            const payload = {
                geometry: {
                    "type": "Polygon",
                    "coordinates": coordinates
                },
                usecase_name: filter.usecase,
                service_area: filter.service_area,
                subscription: filter.subscription,
                include_geometry: selectedTab == 'map' ? true : false
            }

            await adminService.getAOIInsights(payload).then((aoiCollection)=>{
                let aoiData = aoiCollection?.features ?? [];
                sortAois(aoiData, sortBy); 
                setCsInsightsMgs('');
            })
            .catch((error) => {
                toast.error("Error in getting AOI Details!");
                setCsInsightsMgs('');
                console.error("Error in getting AOI Details!", error);
            });        
        }  
    }

    const sortAois = (aois, sortBy) => {
        let sortedAois = aois;
        if (sortBy == 'auth-id') {
            sortedAois = aois.sort((a1, a2)=>{
                return a1.properties['auth-id'].toUpperCase() > a2.properties['auth-id'].toUpperCase() ? 1 : -1
            })
        } 
        else if (sortBy == 'region') {
            sortedAois = aois.sort((a1, a2)=>{
                return a1.properties['region'].toUpperCase() > a2.properties['region'].toUpperCase() ? 1 : -1
            })
        }
        else if (sortBy == 'creation-update-date-desc') {
            sortedAois = aois.sort((a1, a2)=>{
                return new Date(a1.properties['creation-update-date']) < new Date(a2.properties['creation-update-date']) ? 1 : -1
            })
        }

        setAOIs([...sortedAois]);
    }

    const initServiceArea = (usecaseName) => {
        const selectedUsecase = usecases.find(u => u.name == usecaseName);
        const serviceAreaGeometry = selectedUsecase?.configuration?.geographic_extent;
        if (serviceArea) {
            serviceArea.setMap(null);
        }
        
        setTimeout(()=>{
            if (serviceAreaGeometry) {
                setServiceArea(MapUtils.getServiceArea(serviceAreaGeometry));
            }
        })
    }

    const onChangeUsecase = (usecase) => {
        setFilter({...filter, usecase: usecase});
        initServiceArea(usecase);
    }

    const onChangeServiceArea = (serviceArea) => {
        setFilter({...filter, service_area: serviceArea});
    }

    const onChangeSubscription = (subscription) => {
        setFilter({...filter, subscription: subscription});
    }

    const impersonateAOIUser = (aoi) => {
        adminService.getUser(aoi.properties['auth-id'].toLowerCase()).then(async (data) => {
            await impersonateUser(true, data);
            window.open(`/portfolio/map/aoi/${aoi.properties['member-aoi-id']}`, '_blank');
        }).catch((e) => {
            toast.error("Unable to impersonate the user. Error: " + e);
            console.error(e);
        });
    }

    const setBoundingBoxValue = (direction, value) => {   
        let boundValues = {...bounds};
        boundValues[direction] = value;
        setBounds(boundValues);
        if (isValidBounds(boundValues)) {
            setBoundingBoxError('');
        }
    }

    const isValidBounds = (bounds) => {
        if (isNaN(parseInt(bounds.W)) || isNaN(parseInt(bounds.S)) || isNaN(parseInt(bounds.E)) || isNaN(parseInt(bounds.N))) {
            setBoundingBoxError(`Invalid value in bounding box`);
            return false;
        }
        else if (bounds.N > 90) {
            setBoundingBoxError(`North should be <= 90`);
            return false;
        }
        else if (bounds.S < -90) {
            setBoundingBoxError(`South should be >= -9-`);
            return false;
        }
        else if (bounds.W < -180) {
            setBoundingBoxError(`West should be >= -180`);
            return false;
        }
        else if (bounds.E > 180) {
            setBoundingBoxError(`West should be <= 180`);
            return false;
        }
        else if (bounds.N <= bounds.S) {
            setBoundingBoxError(`North should be greater than South`);
            return false;
        }
        else if (bounds.E <= bounds.W) {
            setBoundingBoxError(`East should be greater than West`);
            return false;
        }

        return true;
    }

    const onSearchClick = () => {
        if (isValidBounds(bounds)) {
            let searchFilter = {...filter, bounds: {W: parseInt(bounds.W), S: parseInt(bounds.S), E: parseInt(bounds.E), N: parseInt(bounds.N)}};
            getAOIs(searchFilter);
            initServiceArea(filter.usecase);
        }
        else {
            toast.error('Invalid Bounding Box values');
        }
    }

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        setCsInsightsMgs('');
        setAOIs([]);
    }

    const handleAOISelect = (aoi) => {
        setShowSelectedAOIPopover(false);
        setTimeout(() => {
            setShowSelectedAOIPopover(true);
        });
        setSelectedAOI(aoi);
    }

    return (
        <div className="form-content da-cockpit">
            <div className="form-head">
                <h3>CS Insights</h3>
            </div>
            <div className="cs-insights-controls">
                <div className="controls-left">
                    <div className='control usecase-list'>
                        <label>
                            <div>Usecase:</div>
                        </label>
                        <select className="usecase-list-options form-select" onChange={(e) => onChangeUsecase(e.target.value)} value={filter.usecase}>
                            {
                                Usecases.map(usecase => <option key={usecase.name} value={usecase.name}>{usecase.displayName}</option>)
                            }
                        </select>
                    </div>
                    <div className='control service-area-list'>
                        <label>
                            <div>Service Area:</div>
                        </label>
                        <select className="service-area-list-options form-select" onChange={(e) => onChangeServiceArea(e.target.value)} value={filter.service_area}>
                            <option value="all">All</option>
                            <option value="inside">Inside</option>
                            <option value="outside">Outside</option>
                        </select>
                    </div>
                    <div className='control subscription-list'>
                        <label>
                            <div>Subscription:</div>
                        </label>
                        <select className="subscription-list-options form-select" onChange={(e) => onChangeSubscription(e.target.value)} value={filter.subscription}>
                            <option value="all">All</option>
                            <option value="subscribed">Subscribed</option>
                            <option value="unsubscribed">UnSubscribed</option>
                        </select>
                    </div>
                    {
                        selectedTab == 'list' &&
                        <div className="search-btn">
                            <button className="btn btn-primary" onClick={onSearchClick}>Search</button>
                        </div>
                    }
                </div>
                
                <div className="controls-right">
                    {
                        selectedTab == 'list' &&
                        <>
                            <div className="bounding-boxes">
                                <label><b>Bounding Box: &nbsp;</b></label>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td className="bounding-box">N<br/><input type="text" value={bounds.N} onChange={e => setBoundingBoxValue('N', e.target.value)}/></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td className="bounding-box">W&nbsp; <input type="text" value={bounds.W} onChange={e => setBoundingBoxValue('W', e.target.value)}/></td>
                                            <td></td>
                                            <td className="bounding-box"><input type="text" value={bounds.E} onChange={e => setBoundingBoxValue('E', e.target.value)}/> &nbsp;E</td></tr>
                                        <tr>
                                            <td></td>
                                            <td className="bounding-box"><input type="text" value={bounds.S} onChange={e => setBoundingBoxValue('S', e.target.value)}/><br/>S</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {
                                !!boundingBoxError && <div className="bounding-box-error">{boundingBoxError}</div>
                            }
                        </>
                    }  
                </div> 
            </div>
            <div className="cs-insights-data-header">
                <div className="cs-insights-table-filter">
                    {
                        selectedTab == 'list' &&
                        <div className="input-group">
                            <span className="input-group-text" htmlFor="sortby">Sort by <img src={SortIcon} /></span>
                            <select className="form-select" id="sortby" value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                                <option value="auth-id">Auth ID</option>
                                <option value="region">Region</option>
                                <option value="creation-update-date-desc">Created Date(Desc)</option>
                            </select>
                        </div>
                    }
                </div>
                <div className="cs-insights-message">
                    <span>{csInsightsMgs}</span>
                </div>
                <div className="da-cockpit-tabs">
                    <ul className="view-toggle">
                        <li className={`grid-view ${selectedTab == 'list' && 'active'}`}><a onClick={()=>handleTabChange('list')}><i></i> <span>List</span></a></li>
                        <li className={`map-view ${selectedTab == 'map' && 'active'}`}><a onClick={()=>handleTabChange('map')}><i></i> <span>Map</span></a></li>
                    </ul>
                </div>
            </div>
            
            <div className="cs-insights-data">
                {
                    selectedTab == 'list' &&
                    <CSInsightsList aois={aois} impersonateAOIUser={impersonateAOIUser}
                        getAOIs={getAOIs}
                        filter={filter} setFilter={setFilter}
                    />
                }
                {
                    selectedTab == 'map' &&
                    <>
                        <div className="map-view-responsive">
                            <CSInsightsMap aois={aois} 
                                getAOIs={getAOIs}
                                filter={filter} setFilter={setFilter}
                                serviceArea={serviceArea}
                                selectedAOI={selectedAOI} setSelectedAOI={setSelectedAOI}
                                handleAOISelect={handleAOISelect}
                                impersonateAOIUser={impersonateAOIUser}
                                showSelectedAOIPopover={showSelectedAOIPopover} setShowSelectedAOIPopover={setShowSelectedAOIPopover}
                                setCsInsightsMgs={setCsInsightsMgs}
                                initServiceArea={initServiceArea}
                            />
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default CSInsights;