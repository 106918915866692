import { useContext, useState } from 'react';
import { UserContext } from '../../Contexts/UserContext';

const SubscribeNoPlans = (props) => {
    const [isSubscribed, setIsSubscribed] = useState(props.isSubscribed);
    const {user} = useContext(UserContext);

    const subscribeService = (subscribe) => {
        if(!user.canSubscribe)
            return;
        props.subscribe(props.service.name, subscribe, null, props.displayName);
        setIsSubscribed(subscribe);
    };

    const RenderSingleUsecase = () => {
        if(!user.canSubscribe) {
            return <div>
                    {
                        props.isSubscribed 
                        ? <div>You have subscribed to {props.displayName}</div>
                        : <div>You have not subscribed to {props.displayName}</div>
                    }
                    </div>;
        }
        return <div>
                    {
                        props.isSubscribed 
                        ? <div>You have subscribed to {props.displayName}</div>
                        : <div>Would you like to subscribe to {props.displayName}?</div>
                    }
                    <button type="button" className="btn btn-primary ml-auto" onClick={() => subscribeService(!props.isSubscribed)}>
                        {
                            props.isSubscribed ? "Unsubscribe" : "Subscribe"
                        }
                    </button>
                </div>;
    };

    if(props.isSingleUsecase)
        return <RenderSingleUsecase></RenderSingleUsecase>;

    return  <div className="col-12" key={props.service.name}>
                <div className="service-row">
                    <div className='left-content'>
                        <label className="service-label"> {props.displayName}
                        </label>
                        <p>{`${props.displayName} Information`}</p>
                    </div>
                    <div className='right-content'>
                        <div className="subscribe-switch">
                            <input type="radio" className="switch-input" name={props.service.name} value="Subscribe" id={"Subscribe" + props.service.name} 
                                checked={isSubscribed} onClick={() => subscribeService(true)} onChange={() => {}} />
                            <label htmlFor={"Subscribe" + props.service.name} className="switch-label switch-label-off">Subscribe</label>
                            <input type="radio" className="switch-input" name={props.service.name} value="Unsubscribe" id={"UnSubscribe" + props.service.name} 
                                checked={!isSubscribed} onClick={() => subscribeService(false)} onChange={() => {}} />
                            <label htmlFor={"UnSubscribe" + props.service.name} className="switch-label switch-label-on">Unsubscribe</label>
                            <span className="switch-selection"></span>
                        </div>
                    </div>
                </div>
            </div>;
}

export default SubscribeNoPlans;