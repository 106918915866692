import React, {useContext, useState} from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { siteService } from '../../Services/siteService';

const Help = (props) => {
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const {user, showAlert} = useContext(UserContext);

    const handleSubmit  = () => {
        var e = "<html><body>";
        e += "<div><b>Name</b>:  " + user.profile?.name;
        e += "<div><b>Auth Id</b>:  " + user["auth-id"];
        e += "</div><div><b>Subject</b>: " + subject;
        e += "</div><div><b>Message</b>: " + message;
        e += "</div></body></html>";

        return siteService.contactus("AEDRB", e)
        .then((data) => { 
            showAlert('Submitted successfully.', 'success');
            setMessage("");
            setSubject("");
        })
        .catch(error => {
            console.log('Error in in submitting', error);
            showAlert('Submission failed! Please try again.', 'fail');
        });
    }

    return (
        <div className="form-content">
            <h3>Help</h3>
            <div className='edit-profile-form'>
                <div className='form profile-form'>
                    <div className='form-body'>
                        <div className='profile-row name'>
                            <label className='form_label' htmlFor='name'>Subject</label>
                            <input className='form_input' type='text' name='customername' value={subject} onChange = {(e) => setSubject(e.target.value)} id='name' placeholder='Subject'/>
                        </div>
                        <div className='profile-row address1'>
                            <label className='form_label' htmlFor='address1'>Message</label>
                            <textarea name="message" cols="70" rows="10" className="form-control" value={message} onChange={(e)=>setMessage(e.target.value)}></textarea>
                        </div>
                    </div>
                </div>
                <div className='footer'>
                    <button onClick={()=>handleSubmit()} type='submit' className='btn btn-primary ml-auto'>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default Help;