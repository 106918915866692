import { useState } from "react";

const GMRDate = (props) => {
    const [expandPanel, setExpandPanel] = useState(true);

    return (
        <div className={expandPanel ? "dates-filter closed" : "dates-filter opened"}>
                <div className='df-header' onClick={() => setExpandPanel(!expandPanel)}>
                    <div className='df-caret'>
                        <i></i>
                    </div>
                    <div className='df-name'>
                        Dates
                    </div>
                </div>
                <div className='df-body'>
                    <div className='df-name'>
                        <ul className='dates-list'>
                            {
                                props.dates.map((date) => 
                                    <li key={date} className={date == props.selectedDate ? "active" : ""} onClick={() => props.changeDate(date)}>
                                        <label>{date}</label>
                                    </li>)
                            }
                        </ul>
                    </div>
                </div>
            </div>
    );
}

export default GMRDate;