import React from 'react';
import { useState } from 'react';
import { Modal } from "react-bootstrap";

const SaveAnnotationModal = (props) => {
    const [annotationText, setAnnotationText] = useState(props.annotation?.text ?? "");
    
    const handleOnChange = (event) => {
        setAnnotationText(event.target.value);
    }

    return (
        <>
            <Modal
                show={() => props.setShowSaveAnnotationModal(true)}
                onHide={() => props.cancel()}
                backdrop="static"
                keyboard={false}
                dialogClassName="save-annotation-modal-dialog"
                className="save-annotation-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h5>{props.annotationMode == "create" ? 'Create' : 'Edit'} Annotation</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea name="" id="" cols="10" rows="6" className="form-control" value={annotationText} onChange={handleOnChange}></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" aria-label="Close" onClick={() => props.cancel()}>CANCEL</button>
                    <button type="button" className="btn btn-primary" onClick={()=>props.saveAnnotation(annotationText)}>SUBMIT</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SaveAnnotationModal;