import BMPIcon from '../Assets/img/file-types/bmp.svg';
import DOCIcon from '../Assets/img/file-types/doc.svg';
import GIFIcon from '../Assets/img/file-types/gif.svg';
import JPGIcon from '../Assets/img/file-types/jpg.svg';
import JSONIcon from '../Assets/img/file-types/json.svg';
import ODTIcon from '../Assets/img/file-types/odt.svg';
import PDFIcon from '../Assets/img/file-types/pdf.svg';
import PNGIcon from '../Assets/img/file-types/png.svg';
import PPTIcon from '../Assets/img/file-types/ppt.svg';
import SVGIcon from '../Assets/img/file-types/svg.svg';
import TIFIcon from '../Assets/img/file-types/tif.svg';
import TXTIcon from '../Assets/img/file-types/txt.svg';
import XLSIcon from '../Assets/img/file-types/xls.svg';
import XMLIcon from '../Assets/img/file-types/xml.svg';

const fileTypeIcons = {
    default: DOCIcon,
    bmp: BMPIcon ,
    doc: DOCIcon,
    docx: DOCIcon,
    gif: GIFIcon,
    jpg: JPGIcon,
    jpeg: JPGIcon,
    json: JSONIcon,
    odt: ODTIcon,
    pdf: PDFIcon,
    png: PNGIcon,
    ppt: PPTIcon,
    svg: SVGIcon,
    tif: TIFIcon,
    txt: TXTIcon,
    xls: XLSIcon,
    csv: XLSIcon,
    xml: XMLIcon
};

export const getFileTypeIcon = (fileType) => {
    if (!fileType) return null;  
    return fileTypeIcons[fileType] ?? fileTypeIcons['default'];
}

export const openFile = async (fileUrl) => {
    const url = await getFileBlobUrl(fileUrl);
    window.open(url);
}

export const getFileBlobUrl = async (fileUrl) => {
    const requestOptions = {
        method: 'GET',
        headers: {'Authorization': `Bearer ${(window.authToken)}`}
    }
    try {
        const response = await fetch(fileUrl, requestOptions);
        if (response.ok) {
            const blob = await response.blob();
            return window.URL.createObjectURL(blob);
        }
        else 
            throw `${response.status} Error`;
    }
    catch(e) {
        console.log(`Error in getting file. Error: ${e}`);
        return "";
    }
}

export const getUserPreference = (memberConfiguration) => {
    let userPreferences = {};
    if (!!memberConfiguration) {
      memberConfiguration.forEach((item)=>{
        userPreferences[item["name"]] = item["value"]
      });
    }
    return userPreferences;
}

export const isValidAuthId = (authId) => {
    const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneNumberRegexp = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
    return emailRegexp.test(authId) || phoneNumberRegexp.test(authId);
}

export const dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const isValidInteger = (number) => {
    const amount = parseInt(number);
    return amount == number.trim();
};