const Role = {
    Read: "Read",
    Edit: "Edit",
    Admin: "Admin",
    SuperAdmin: "SuperAdmin",

    isAdmin(role) {
      return role == this.Admin || role == this.SuperAdmin;
    },

    isSuperAdmin(role) {
      return role == this.SuperAdmin;
    },

    canEdit(role) {
      return role == this.Edit || role == this.Admin || role == this.SuperAdmin;
    }
};
  
  export default Role;