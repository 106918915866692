import "react-phone-input-2/lib/style.css";
import { CountryDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import React, {useContext, useState, useEffect} from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { Link } from 'react-router-dom';
import Timezone from "./Timezone";
import { getUserPreference } from "../Utils";
import { userService } from "../../Services/userService";
import Role from "../../Helpers/Role";
import toast from "react-hot-toast";


const Profile = (props) => {
    const {user, setUser, showAlert } = useContext(UserContext);
    const [values, setValues] = useState({});

    useEffect(() => {
        reset();
    }, [props.user]);

    const reset = () => {
        setValues({
            customername: props.user?.name ?? '',
            email: props.user?.userName ?? '',
            phone: props.user?.phoneNo ?? '',
            address1: props.user?.address1 ?? '',
            address2: props.user?.address2 ?? '',
            address3: props.user?.address3 ?? '',
            city: props.user?.city ?? '',
            state: props.user?.state ?? '',
            country: props.user?.country ?? '',
            pin: props.user?.pincode ?? '',
            isClientUser: props.user?.isClientUser ?? false,
            role: props.user?.role ?? "Read",
            password1: '',
            password2: ''
        });
    };

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setValues({
          ...values,
          [name]: value
        });
    };

    const handlePhoneInputChange = (value, country) => {
        setValues({
            ...values,
            ['phone']: value ? value : '',
            ['country']: country ? country.name : ''
        });
    }

    const handleCountryInputChange = (country) => {
        setValues({
            ...values,
            ['country']: country ? country : ''
        });
    }

    const handleSubmit  = () => {
        if (!values.customername.trim()) {
            toast.error('Please enter the name');
            return;
        }

        const phoneNumber = values.phone.trim().startsWith("+") || values.phone.trim() == "" ? values.phone.trim() : "+" + values.phone.trim();

        if(phoneNumber.startsWith("+91") && phoneNumber.length != 13 || phoneNumber.startsWith("+1") && phoneNumber.length != 12)
        {
            toast.error('Please enter a valid phone number');
            return;
        }

        if((values.password1 != '' || values.password2 != '') && values.password1 != values.password2) 
        {
            toast.error('Both passwords should match');
            return;
        }

        if (values.pin && values.pin.length > 6) {
            toast.error('PIN/ZIP Code should be 6 digits or less');
            return;
        }

        let member = {
            userName: values.email, 
            name: values.customername.trim(),
            password: values.password1,
            phoneNo: phoneNumber,
            address1: values.address1.trim(),
            address2: values.address2.trim(),
            address3: values.address3.trim(),
            city: values.city.trim(),
            state: values.state.trim(),
            country: values.country.trim(),
            pincode: values.pin.trim(),
            isClientUser: values.isClientUser,
            role: values.role
        };
        if(!props.createUser)
            updateMember(member);
        else 
            createMember(member);
    };

    const updateMember = (member) => {
        userService.updateProfile(member).then((data) => {
            showAlert('Profile updated successfully.', 'success');
            if(props.onUpdate) {
                props.onUpdate(data);
            }
            if(!props.isAdmin)
                setUser({...user, ...member});
        }).catch(error => {
            console.log('Error in Updating Member Details', error);
            showAlert('Profile update failed! Please try again.', 'fail');
        });
    };

    const createMember = (member) => {
        userService.createUser(member).then((data) => {
            reset();
            if(props.onUpdate) {
                props.onUpdate(data);
            }
            toast.success("Created user successfully");
        }).catch((e) => {
            toast.error("Unable to create User");
            console.log(e);
        });
    };

    return (
        <div className="form-content">
            {
                !props.isAdmin && <h3>Profile</h3>
            }
            <div className='edit-profile-form'>
                <div className='form profile-form'>
                    <div className='form-body'>
                        <div className='profile-row email'>
                            <label className='form_label' htmlFor='email'>Username </label>
                            <input  type='email' id='email' className='form_input' name='email' value={values.email} placeholder='Email' onChange = {(e) => handleInputChange(e)} disabled={!props.createUser}/>
                        </div>
                        <div className='profile-row name'>
                            <label className='form_label' htmlFor='name'>Name <span className='required_icon'>*</span></label>
                            <input className='form_input' type='text' name='customername' value={values.customername} onChange = {(e) => handleInputChange(e)} id='name' placeholder='Name' required/>
                        </div>
                        {
                            !!props.isAdmin && 
                            <>
                                {
                                    !user.isClientUser && 
                                    <div className='profile-row isClientUser'>
                                        <label className='form_label' htmlFor="isClientUser">Is Client? <span className='required_icon'>*</span></label>
                                        <input className='form_input' type="checkbox" name="isClientUser" checked={values.isClientUser} onChange = {(e) => handleInputChange(e)} id='name' placeholder='Name' required/>
                                    </div>
                                }
                                <div className='profile-row role'>
                                    <label className='form_label' htmlFor="role">Role <span className='required_icon'>*</span></label>
                                    <select className="form-select" id="role" name="role" onChange={(e) => handleInputChange(e)} value={values.role}>
                                        <option value="Read">Read Only</option>
                                        <option value="Edit">Edit</option>
                                        <option value="Admin">Admin</option>
                                        {
                                            !user.isClientUser && Role.isSuperAdmin(user.role) && 
                                            <option value="SuperAdmin">Super Admin</option>
                                        }
                                    </select>
                                </div>
                            </>
                        }
                        <div className='profile-row password1'>
                            <label className='form_label' htmlFor='password1'>Password </label>
                            <input  type='password' id='password1' className='form_input' name='password1' value={values.password1} placeholder='Password' onChange = {(e) => handleInputChange(e)} />
                        </div>
                        <div className='profile-row password2'>
                            <label className='form_label' htmlFor='password2'>Confirm Password </label>
                            <input  type='password' id='password2' className='form_input' name='password2' value={values.password2} placeholder='Password' onChange = {(e) => handleInputChange(e)} />
                        </div>
                        <div className='profile-row phone'>
                            <label className='form_label' htmlFor='phone'>Phone </label>
                            <div className='form_input'>
                                <PhoneInput
                                    name="phone"
                                    placeholder="Phone"
                                    value={values.phone}
                                    countryCodeEditable={false}
                                    onChange = {(value, country) => handlePhoneInputChange(value, country)}
                                />
                            </div>
                        </div>
                        <div className='profile-row address1'>
                            <label className='form_label' htmlFor='address1'>Address 1</label>
                            <input className='form_input' type='text' id='address1' name='address1' value={values.address1} onChange = {(e) => handleInputChange(e)} placeholder='Address 1'/>
                        </div>
                        <div className='profile-row address2'>
                            <label className='form_label' htmlFor='address2'>Address 2</label>
                            <input className='form_input' type='text' id='address2' name='address2' value={values.address2} onChange = {(e) => handleInputChange(e)} placeholder='Address 2'/>
                        </div>
                        <div className='profile-row address3'>
                            <label className='form_label' htmlFor='address3'>Address 3</label>
                            <input className='form_input' type='text' id='address3' name='address3' value={values.address3} onChange = {(e) => handleInputChange(e)} placeholder='Address 3'/>
                        </div>
                        <div className='profile-row city'>
                            <label className='form_label' htmlFor='address1'>City</label>
                            <input className='form_input' type='text' id='city' name='city' value={values.city} onChange = {(e) => handleInputChange(e)} placeholder='City' required/>
                        </div>
                        <div className='profile-row state'>
                            <label className='form_label' htmlFor='state'>State</label>
                            <input className='form_input' type='text' id='state' name='state' value={values.state} onChange = {(e) => handleInputChange(e)} placeholder='State'/>
                        </div>
                        <div  className='profile-row country'>
                            <label className='form_label' htmlFor='country'>Country</label>
                            <div className='form_input'>
                                <CountryDropdown
                                    value={values.country}
                                    onChange={(val) => handleCountryInputChange(val)}
                                />
                            </div>
                        </div>
                        <div className='profile-row pin'>
                            <label className='form_label' htmlFor='pin'>PIN/ZIP</label>
                            <input className='form_input' type='number' id='pin' name='pin' value={values.pin} onChange = {(e) => handleInputChange(e)} placeholder='PIN/ZIP'/>
                        </div>
                    </div>
                    <div className='footer'>
                        {
                            !!props.isAdmin &&
                            <button type="button" className="btn btn-outline" onClick={() => props.onUpdate(null)}>CANCEL</button>
                        }
                        <button onClick={()=>handleSubmit()} type='submit' className='btn btn-primary ml-auto submit-btn'>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;