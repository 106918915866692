import MegaViewLogo from '../Assets/img/logo-megaview-dd.svg';
import DataDeliLogo from '../Assets/img/logo-DD.svg';
import DataDeliLogoWhite from '../Assets/img/logo-DD-W.svg';
import SpaceAeyeLogo from '../Assets/img/logo-spaceaeye.svg';
import DataDeliIncLogo from '../Assets/img/logo-DataDeli.svg';
import TimelineViewIcon from '../Assets/img/icon-timeline.svg';
import DashboardIcon from '../Assets/img/icon-dashboard.svg';
import LandDeformationIcon from '../Assets/img/icon-land-deformation-solid.svg';
import LeakageIcon from '../Assets/img/icon-leakage.svg';
import AOIStartIcon from '../Assets/img/icon-start.svg';
import AOIPauseIcon from '../Assets/img/icon-pause.svg';
import AOIContinueIcon from '../Assets/img/icon-continue.svg';
import AOIStopIcon from '../Assets/img/icon-stop.svg';
import LogoutIcon from '../Assets/img/menu-icon-logout.svg';
import ProfileIcon from '../Assets/img/menu-icon-profile.svg';
import SettingsIcon from '../Assets/img/menu-icon-settings.svg';
import AdminIcon from '../Assets/img/menu-icon-admin.svg';
import TokensIcon from '../Assets/img/icon-tokens.png';
import WalletIcon from '../Assets/img/icon-wallet.svg';
import EventIcon from '../Assets/img/menu-icon-event-image.svg';
import TokensAlertIcon from '../Assets/img/icon-alert-red.svg';
import BillingIcon from '../Assets/img/menu-icon-billing.svg';
import KrishiVigyanLogo from '../Assets/Logos/krishivigyan.png';
import GMRInnovexLogo from '../Assets/Logos/gmrinnovex.png';
import NotesIcon from '../Assets/img/menu-icon-notes.svg';
import React, { useContext, useState }  from "react";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { UserContext } from '../Contexts/UserContext'
import $ from 'jquery';
import { HashLink } from 'react-router-hash-link';
import LogoutTimer from './LogoutTimer';
import Notifications from './Notifications';
import UserViewMenubar from './UserViewMenubar';
import Role from '../Helpers/Role';

const appConfig = window.config;
const logoNameMapping = {
    'krishivigyan.png': KrishiVigyanLogo,
    'gmrinnovex.png': GMRInnovexLogo};

const NavBar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {user, signOut, openLoginModal, domainConfig, originalUserExpensesPerMonth, showMapMoveOptions, timelineView, setTimelineView, resetTimeline, setResetTimeline } = useContext(UserContext);
    const protectedPages = ["login", "profile", "swagger", "portfolio", "timeline"];
    const showUserViewInPages = ["portfolio", "timelinev1", "timelinev2", "timeline", "timelinesr", "member"];
    const showUserViewMenu = user.isAuthenticated 
        && (user.isCustomerService || user.isDA || user.isAdmin || user.isSuperAdmin) 
        && showUserViewInPages.some((page) => location.pathname.includes(page));
    const isHomePage = location.pathname == "/";
    const [distance, setDistance] = useState(100);
    const [selectedPage, setSelectedPage] = useState("");

    const logout = () => {
        navigate("/");
        signOut();
    };
    const handleMobileMenuClick = () => {
        $('.main-nav').toggle("");
    };
    const handleMainNavClick = () => {
        $('.main-nav').hide();
    };

    const timelineClick = () => {
        setSelectedPage('timeline');
        if(props.mapMoveState == 0)
            setResetTimeline(resetTimeline + 1);
    };

    return (
        <>
            <header className={isHomePage ? "home-page" : "user-pages"}>
                <div className="mobile-menu" onClick={handleMobileMenuClick}>
                    <i></i>
                </div>

                <nav className="navbar">
                    <div className="top-left">
                        <Link to="/" className="navbar-brand">
                            <img src={SpaceAeyeLogo} alt="DataDeli, Inc." />
                        </Link>
                    </div>

                    {
                        user.isAuthenticated
                        &&
                        <ul className="main-nav">
                            <li><Link to="/dashboard" className={selectedPage == 'dashboard' ? 'active' : ''} onClick={() => setSelectedPage('dashboard')}><img src={DashboardIcon} /> Dashboard</Link></li>
                            <li className="timeline-active">
                                <Link to="/timelinev2/e759afee-a9a7-4b1b-80b1-9cd535660282" className={selectedPage == 'timeline' ? 'active' : ''}  onClick={timelineClick}><img src={TimelineViewIcon} /> Timeline View</Link>
                                {
                                    showMapMoveOptions && 
                                    <select className="timeline-options" value={timelineView} onChange={(e) => setTimelineView(e.target.value)}>
                                        <option value="single">Single</option>
                                        <option value="slider">Slider</option>
                                        <option value="comparison">Compare</option>
                                    </select>
                                }
                            </li>

                            {
                                showMapMoveOptions &&
                                <li className="group">
                                    <select className="form-select custom-select timeline-options" onChange={(e) => setDistance(e.target.value)} value={distance}>
                                        <option value="100">100 Meters</option>
                                        <option value="200">200 Meters</option>
                                        <option value="300">300 Meters</option>
                                        <option value="400">400 Meters</option>
                                        <option value="500">500 Meters</option>
                                        <option value="800">800 Meters</option>
                                        <option value="1000">1 Kilometer</option>
                                    </select>

                                    {
                                        props.mapMoveState == 0 && 
                                        <a onClick={()=>props.startMapMove(distance)}><img src={AOIStartIcon}/> AOI Start</a>
                                    }
                                    {
                                        props.mapMoveState == 1 && 
                                        <a onClick={() => props.changeMapMoveState(2)}><img src={AOIPauseIcon} /> Pause</a>
                                    }
                                    {
                                        props.mapMoveState == 2 && 
                                        <a onClick={() => props.changeMapMoveState(1)}><img src={AOIContinueIcon} /> Continue</a>
                                    }
                                    {
                                        (props.mapMoveState == 1 || props.mapMoveState == 2) && 
                                        <a onClick={() => props.changeMapMoveState(0)}><img src={AOIStopIcon} /> Stop</a>
                                    }
                                    
                                </li>
                            }

                            {
                                !!appConfig.showLandDeformation && 
                                <li><Link to="/land-deformation" className={selectedPage == 'land-deformation' ? 'active' : ''} onClick={() => setSelectedPage('land-deformation')}><img src={LandDeformationIcon} /> Land Deformation</Link></li>
                            }
                            {
                                !!appConfig.showLeakage && 
                                <li><Link to="/leakage" className={selectedPage == 'leakage' ? 'active' : ''} onClick={() => setSelectedPage('leakage')}><img src={LeakageIcon} /> Leakage</Link></li>
                            }
                        </ul>
                    }

                    {
                        user.isAuthenticated && <LogoutTimer time={window.expiryTime}/>
                    }
                    
                    {
                        user.isAuthenticated
                        ?
                        <ul className="user-nav">
                            <li className="profile">
                                <Dropdown>
                                    <Dropdown.Toggle id="profileMenus">
                                        <i className="user"></i>
                                        <span className="name">{user.name ?? user.email ?? ""}</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="profile-ddl">
                                        <Dropdown.Item onClick={() => navigate("/profile")}><img src={ProfileIcon} alt='' />Profile</Dropdown.Item>
                                        <Dropdown.Item onClick={() => navigate("/settings")}><img src={SettingsIcon} alt='' />Settings</Dropdown.Item>
                                        {
                                            Role.isAdmin(user.role) && 
                                            <>
                                                <Dropdown.Item onClick={() => navigate("/members")}><img src={AdminIcon} alt='' />Members</Dropdown.Item>
                                                {
                                                    !user.isClientUser &&
                                                    <Dropdown.Item onClick={() => navigate("/manageevents")}><img src={EventIcon} alt='' />Events</Dropdown.Item>
                                                }
                                            </>
                                        }
                                        <Dropdown.Item onClick={() => logout()} className="line"><img src={LogoutIcon} alt='' />Log out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </ul>
                        :
                        <div className="top-right">
                            {
                                !protectedPages.some((page) => location.pathname.includes(page)) &&
                                <a onClick={()=>openLoginModal(true)}>
                                    Sign In
                                </a>
                            }              
                        </div>        
                    }
                </nav>
            </header>
        </>
    );
}

export default NavBar;