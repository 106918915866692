const getHostUrl = () => {
    const hostUrl = window.config["configHostname"] ?? window.location.host;
    return 'https://' + hostUrl;
};

const getApiUrl = () => {
    const host = window.location.protocol + '//' + window.location.host;
    const apiBaseUrl = window.config["apiBaseUrl"]; 
    return apiBaseUrl || `${host}/api`;
};

const getApiBaseUrl = (region) => {
    return getApiUrl();
};

const getMemberApiBaseUrl = (region) => {
    return `${getApiBaseUrl(region)}/member`;
};

const getCloundFrontUrl = () => {
    const host = window.location.protocol + '//' + window.location.host;
    let hostUrl = window.config["cloudFrontUrl"] || host;
    return hostUrl;
};

const getDataUrl = (region) => {
    return `${getCloundFrontUrl()}/data/${region}`;
};

export const apiService = {
    getHostUrl,
    getApiBaseUrl,
    getCloundFrontUrl,
    getMemberApiBaseUrl,
    getDataUrl,
    getApiUrl
};
