import { useState } from "react";

const GMRVisible = (props) => {

    return (
        <div className="filter-box closed">
            <div className='filter-header'>
                <div className='filter-name'>
                    {props.layer.name}
                </div>
                <div className={props.layer.visibility ? 'filter-visibility show' : 'filter-visibility hide'} 
                    onClick={() => props.updateLayer({...props.layer, visibility: !props.layer.visibility})}>
                    <i></i>
                </div>
            </div>
        </div>
    );
};

export default GMRVisible;