import {Modal} from 'react-bootstrap';
import React from 'react';
import YouTube from 'react-youtube';

const ShowVideoModal = (props) => {

    const opts = {
        height: '370',
        width: '660',
        playerVars: {
            controls: 1, 
            modestbranding: 1, 
            fs: 1,
            autoplay: 1,
            rel: 0
        }
    };

    return (
        <>
            <Modal
                show={() => {}}
                onHide={() => props.show(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="tour-modal-dialog"
                className="first-video-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="video-container">
                        <YouTube videoId={props.id} opts={opts} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" 
                            aria-label="Close" onClick={() => props.show(false)}>Close</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ShowVideoModal;