import "../Assets/Css/da-tools.css";
import "../Assets/Css/timeline.css";
import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { aoiService } from "../Services/aoiService";
import { eventService } from "../Services/eventService";
import React from 'react';
import moment from "moment";
import SingleTimeline from "../Components/Timeline/SingleTimeline";
import TwoTimelines from "../Components/Timeline/TwoTimelines";
import { useJsApiLoader } from "@react-google-maps/api";
import TimelineBar from "../Components/Timeline/TimelineBar";
import { UserContext } from '../Contexts/UserContext';

const appConfig = window.config;

const DataSources = [
    {name: 'WAYBACK', resolution: 'high'},
    {name: 'GOOGLEEARTH', resolution: 'high', credit: '\u00A9Google Earth'},
    {name: 'SKYSAT', resolution: 'high', credit: '\u00A9Planet Labs'},
    {name: 'SENTINEL2', resolution: 'low'},
    {name: 'GEESentinelDW', resolution: 'low'}
]

const Timeline = (props) => {
    const navigate = useNavigate();
    const { aoiId } = useParams();
    const {user, siteCache, showAlert} = useContext(UserContext);
    const { state } = useLocation(); 
    const { dashboardView } = state || {dashboardView: siteCache?.dashboardView ?? "list"};
    const [aoi, setAoi] = useState(null);
    const [currentTimeline, setCurrentTimeline] = useState(null);
    const [latestTimeline, setLatestTimeline] = useState(null);
    const [view, setView] = useState("single");
    const [mapKey, setMapKey] = useState(0);
    const [allTimelines, setAllTimelines] = useState([]);
    const [availableDatasource, setAvailableDatasource] = useState([]);
    const [selectedDatasource, setSelectedDatasource] = useState("all");
    const [allYears, setAllYears] = useState([]);
    const [yearToMonth, setYearToMonth] = useState([]);
    const [yearMonthToTimeline, setYearMonthToTimeline] = useState([]);
    const [fullTimelineData, setFullTimelineData] = useState([]);
    const [beforeDate, setBeforeDate] = useState(null);
    const [afterDate, setAfterDate] = useState(null);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: appConfig.mapApiKey,
        libraries
    });

    useEffect(() => {
        aoiService.get("081df3ef475240e1d975725850cb97d9", user.region, aoiId)
        .then((data) => {
            const aoiData = data;
            if (aoiData?.subscribed_usecases?.length > 0 && aoiData.subscribed_usecases.some(u=>u.usecase.name == "AoIRGBImage")) {
                setAoi(aoiData);
            }
            else {
                showAlert('Please Subscribe to access this service');
                navigate("/portfolio");
            }  
        })
        .catch((error) => {
            console.error("Error in getting AOI Details", error);
            showAlert('AOI Not Found');
            navigate("/portfolio");
            return null;
        })
    }, [aoiId]);

    useEffect(() => {
        refresh();
    }, [aoiId]);

    const refresh = () => {
        eventService.getTimeline(user.mkey, user.region, aoiId)
        .then((data) => {
            setAllTimelines(data);            
            data.map((timeline) => {        
                if (availableDatasource.findIndex(ds => ds == timeline.result['datasource-name'])==-1) {
                    availableDatasource.push(timeline.result['datasource-name']);
                }
            });
            if(hasHighResolutionImages(availableDatasource)) {
                changeDatasource("high", data);
            } else {
                setTimelineInMap(data);
            }
            setAvailableDatasource(availableDatasource);
            return data;
        })
        .catch((error) => {
            console.error("Error in getting Timeline!", error)
            return [];
        });
    };

    const hasHighResolutionImages = (datasources) => {
        var hashHigh = false;
        datasources.map((datasource) => {
            if(DataSources.findIndex(d => d.name == datasource && d.resolution == 'high') >= 0) 
                hashHigh = true;
        });
        return hashHigh;
    };

    const setTimelineInMap = (data) => {
        //filter the duplicate images
        let uniq={};            
        data = data.filter(obj => !uniq[obj.result["image-date"]] && (uniq[obj.result["image-date"]] = true));

        data.sort((a,b) => new Date(a.result["image-date"]) - new Date(b.result["image-date"]));
        setFullTimelineData(data);
        if(data.length >= 1) {
            if (!!latestTimeline) {
                if (!data.find(timeline => timeline.ext_id == latestTimeline.ext_id)) {
                    // If the latest timeline is not available in the newly fetched data, set the nearest date timeline
                    setLatestTimeline(getNearestDateTimeline(data, latestTimeline.result["image-date"]));
                }
            }
            else {
                setLatestTimeline(data[data.length - 1]);
            }

            if(data.length >= 2) {
                if (!!currentTimeline) {
                    if (!data.find(timeline => timeline.ext_id == currentTimeline.ext_id)) {
                        // If the current timeline is not available in the newly fetched data, set the nearest date timeline
                        setCurrentTimeline(getNearestDateTimeline(data, currentTimeline.result["image-date"]));
                    }
                }
                else {
                    setCurrentTimeline(data[data.length - 2]);
                }   
            }
        }

        let years = [];
        let yearMonth = {};
        let yearToMonthToTimeline = {};
        data.map((timeline) => {
            let date = new Date(timeline.result["image-date"]);
            if(yearMonth[date.getFullYear()] == null) {
                years.push(date.getFullYear());
                yearMonth[date.getFullYear()] = [];
            }
            if(!yearMonth[date.getFullYear()].includes(date.getMonth()))
                yearMonth[date.getFullYear()].push(date.getMonth());
            if(yearToMonthToTimeline[date.getFullYear() * 100 + date.getMonth()] == null) {
                yearToMonthToTimeline[date.getFullYear() * 100 + date.getMonth()] = [];
            }
            yearToMonthToTimeline[date.getFullYear() * 100 + date.getMonth()].push(timeline);
        });
        setAllYears(years);
        setYearToMonth(yearMonth);
        setYearMonthToTimeline(yearToMonthToTimeline);
    };

    const getNearestDateTimeline = (data, timelineDate) => {
        let testDate = new Date(timelineDate);
        let bestDate = data.length;
        let bestDiff = -(new Date(0,0,0)).valueOf();
        let currDiff = 0;

        for(let i = 0; i < data.length; ++i){
            currDiff = Math.abs(new Date(data[i].result["image-date"]) - testDate);
            if(currDiff < bestDiff){
                bestDate = i;
                bestDiff = currDiff;
            }   
        }
        return data[bestDate];
    }

    const changeView = (view) => {
        setView(view);
        setMapKey(mapKey+1);
    };

    const onChangeDatasource = (event) => {
        const selectedResolution = event.target.value;
        changeDatasource(selectedResolution, allTimelines);
    };

    const changeDatasource = (selectedResolution, timelines) => {
        setSelectedDatasource(selectedResolution);
        let filteredTimeLines = timelines;
        if (selectedResolution != 'all') {
            filteredTimeLines = timelines.filter(timeline => DataSources.findIndex(d => d.name == timeline.result['datasource-name'] && d.resolution == selectedResolution) >= 0);      
        }
        setTimelineInMap(filteredTimeLines);
    };

    const selectTimeline = (timelineType, timeline, selectedMonth = null, selectedYear = null) => {
        if (selectedYear != null) {
            if (selectedMonth == null) {
                selectedMonth = yearToMonth[selectedYear][0];
            }
            const timelineYearMonth = getYearMonthFromTimeline(timelineType == "latest" ? latestTimeline : currentTimeline);
            const selectedYearMonth = selectedYear * 100 + selectedMonth;
            const timelinesInSelectedYearMonth = yearMonthToTimeline[selectedYearMonth];
            if (timelineYearMonth > selectedYearMonth) {
                // User going back in timeline, Get the latest timeline of the selected month 
                timeline = timelinesInSelectedYearMonth[timelinesInSelectedYearMonth.length - 1];                
            }
            else if (timelineYearMonth < selectedYearMonth) {
                // User going in future in timeline, Get the earliest timeline of the selected month
                timeline = timelinesInSelectedYearMonth[0];
            }
            else {
                // User selected the same month, Don't do anything
                return;
            }
        } 
        if (!!timeline && !!timelineType) {
            if (timelineType == "current")
                selectCurrentTimeline(timeline);
            else if (timelineType == "latest")
                selectLatestTimeline(timeline);
        }  
    }

    const getYearMonthFromTimeline = (timeline) => {
        const timelineDate = new Date(timeline.result["image-date"]);
        return timelineDate.getFullYear() * 100 + timelineDate.getMonth();
    }

    const selectCurrentTimeline = async (timeline) => {
        const beforeDate = await getAOIDate(timeline);
        setBeforeDate(beforeDate);
        setCurrentTimeline(timeline);
    }

    const selectLatestTimeline = async (timeline) => {
        const afterDate = await getAOIDate(timeline);
        setAfterDate(afterDate);
        setLatestTimeline(timeline);
    }

    const getAOIDate = async (timeline) => {
        if (timeline && timeline?.result) {
            const imageDate = timeline.result["image-date"];
            const bounds = timeline.result?.bounds;
            const timezoneData = await aoiService.getAOITimezone((bounds?.maxy+bounds?.miny)/2, (bounds?.maxx+bounds?.minx)/2, imageDate);
            const aoiDate = new Date(new Date(imageDate).getTime() + (timezoneData.rawOffset * 1000) + (timezoneData.dstOffset * 1000)).toISOString();
            return moment(aoiDate).utc().format('Do MMMM YYYY h:mm A').toString();
        }
        return null;
    }

    return (
        <div className='page-body timeline-view'>
            <div className="header">
                {
                    <>
                        <div className='view-btns'>
                            <a className={view == "single" ? "active" : ""} onClick={() => changeView("single")}>Default View</a>
                            <a className={view == "slider" ? "active" : ""} onClick={() => changeView("slider")}>Slider View</a>
                            <a className={view == "sidebyside" ? "active" : ""} onClick={() => changeView("sidebyside")}>Comparison View</a>
                        </div>
                        {
                            availableDatasource.length > 0 &&
                            <div className='filter-datasource h6'>
                            DataSource:
                                <select onChange={onChangeDatasource} className="form-select" value={selectedDatasource}>
                                    {
                                        availableDatasource.some(ds=> DataSources.findIndex(d => d.resolution == 'high' && d.name == ds) >= 0) && 
                                        availableDatasource.some(ds=> DataSources.findIndex(d => d.resolution == 'low' && d.name == ds) >= 0) &&
                                        <option value='all'>All</option>
                                    }
                                    { 
                                        availableDatasource.some(ds => DataSources.findIndex(d => d.resolution == 'high' && d.name == ds) >= 0) && 
                                        <option value='high'>High Resolution</option>
                                    }
                                    {
                                        availableDatasource.some(ds => DataSources.findIndex(d => d.resolution == 'low' && d.name == ds) >= 0) && 
                                        <option value='low'>Low Resolution</option>
                                    }
                                </select>
                            </div>
                        }
                    </>
                }
            </div>
            <div className="timeline-compare">
                {
                    view == "single" &&
                    <div className="timeline-year-month right full-view">
                        <TimelineBar years={allYears} yearToMonth={yearToMonth} yearMonthToTimeline={yearMonthToTimeline} 
                            fullTimelineData={fullTimelineData} initialTimeline={latestTimeline} selectTimeline={selectTimeline} timelineType="latest"/>
                    </div>
                }
                {
                    view != "single" && 
                    <>
                        <div className={"separator-" + view}></div>
                        <div className={"timeline-year-month left " + view}>
                            <TimelineBar years={allYears} yearToMonth={yearToMonth} yearMonthToTimeline={yearMonthToTimeline} 
                                fullTimelineData={fullTimelineData} initialTimeline={currentTimeline} selectTimeline={selectTimeline} timelineType="current"/>
                        </div>
                        <div className={"timeline-year-month right " + view}>
                            <TimelineBar years={allYears} yearToMonth={yearToMonth} yearMonthToTimeline={yearMonthToTimeline} 
                                fullTimelineData={fullTimelineData} initialTimeline={latestTimeline} selectTimeline={selectTimeline} timelineType="latest"/>
                        </div>
                    </>
                }
            </div>
            <div className='timeline-map-container'>
                {
                    view != "single" && 
                    <>
                        <div className="before-date">
                            <div>{beforeDate}</div>
                        </div>
                        {
                            currentTimeline && 
                            (currentTimeline.result["image-credit"] || DataSources.find(d => d.name == currentTimeline.result["datasource-name"])?.credit) &&
                            <div className="before-credit">
                                Image Credits:&nbsp; 
                                { currentTimeline.result["image-credit"] || DataSources.find(d => d.name == currentTimeline.result["datasource-name"])?.credit}
                            </div>
                        }
                    </>
                }
                <div className='after-date'>
                    <div>{afterDate}</div>
                </div>
                {
                    latestTimeline && 
                    (latestTimeline.result["image-credit"] || DataSources.find(d => d.name == latestTimeline.result["datasource-name"])?.credit) &&
                    <div className="after-credit">
                        Image Credits:&nbsp;
                        {latestTimeline.result["image-credit"] || DataSources.find(d => d.name == latestTimeline.result["datasource-name"])?.credit}
                    </div>
                }
                <div className='timeline-map'>
                    {
                        isLoaded &&
                        (
                            view == "single" 
                            ? <SingleTimeline aoi={aoi} timeline={latestTimeline} showZoom={true} drawInfo={false} refresh={refresh} showToggleOptions={true} view={view}/>
                            : <TwoTimelines aoi={aoi} currentTimeline={currentTimeline} latestTimeline={latestTimeline} splitPanel={view == "sidebyside"} key={mapKey} drawInfo={false} refresh={refresh}/>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Timeline;