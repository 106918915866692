import {fetchWrapper} from "../Helpers/fetchWrapper";
import { apiService } from './apiService';

function get(mkey, region) {
    return fetchWrapper.get(`${apiService.getMemberApiBaseUrl(region)}/${mkey}?include_profile=true&include_config=true&include_tokens=true`);
}

const getTokens = (mkey, region) => {
    return fetchWrapper.get(`${apiService.getMemberApiBaseUrl(region)}/${mkey}?include_tokens=true`);
}

function getAll(mkey, region) {
    return fetchWrapper.get(`${apiService.getMemberApiBaseUrl(region)}/${mkey}?include_profile=true&include_config=true&include_roles=true&include_aois=true&include_tokens=true`);
}

const getMFA = (mkey, auth_id) => {
    return fetchWrapper.get(`${apiService.getHostUrl()}/mfa/${mkey}`, { "auth-id": auth_id });
};

const enableMFA = (mkey, auth_id, data) => {
    return fetchWrapper.post(`${apiService.getHostUrl()}/mfa/${mkey}/enable`, data, { "auth-id": auth_id });
};

const disableMFA = (mkey, auth_id, data) => {
    return fetchWrapper.post(`${apiService.getHostUrl()}/mfa/${mkey}/disable`, data, { "auth-id": auth_id });
};

const verifyMFAToken = (mkey, auth_id, verification_code) => {
    return fetchWrapper.post(`${apiService.getHostUrl()}/mfa/${mkey}/verify`, {
        "access_token": window.accessToken,
        "verification_code": verification_code
    }, { "auth-id": auth_id });
};

const optout = (region, code) => {
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/optout`, {
        "code": code,
        "notification_type": "PR"
    });
};

function create(userDetails){
    let hostUrl = window.config["cloudFrontUrl"] || window.location.protocol + '//' + window.location.host;
    return fetchWrapper.post(`${hostUrl}/create-user`, userDetails, null, true);
}

function register(region, auth_id, member) {
    const auth_id_header = {"auth-id": auth_id};
    return fetchWrapper.post(apiService.getMemberApiBaseUrl(region), member, auth_id_header);
}

function update(mkey, region, member) {
    return fetchWrapper.put(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/profile`, member);
}

const updateSetting = (mkey, region, type, name, value) => {
    const settings = [{name: name, value: value}];
    return updateSettings(mkey, region, type, settings);
};

const updateSettings = (mkey, region, type, settings) => {
    return fetchWrapper.put(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/configuration/${type}`, settings);
};

function getNotifications(mkey, region) {
    return fetchWrapper.get(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/notifications`);
}

function markNotificationsAsRead(mkey, region, ids) {
    const notifications = { "ext_ids": ids };
    return fetchWrapper.put(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/notifications/mark-read`, notifications);
}

const getGMRToken = () => {
    return fetchWrapper.plainGet(`${apiService.getCloundFrontUrl()}/cesium-token.txt`);
};

export const memberService = {
    get,
    getAll,
    getTokens,
    getMFA,
    enableMFA,
    disableMFA,
    verifyMFAToken,
    create,
    register,
    update,
    updateSetting,
    updateSettings,
    getNotifications,
    markNotificationsAsRead,
    getGMRToken,
    optout
};
