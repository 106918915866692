import {Modal} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { eventService } from '../../Services/eventService';

const EventDelete = (props) => {
    const [date, setDate] = useState("");
    const [eventDate, setEventDate] = useState("");

    useEffect(() => {
        if(props.event && props.event.eventDate)
            setEventDate(moment(props.event?.eventDate).format('DD-MM-YYYY'));
    },[props.event]);

    const deleteUser = () => {
        if(date != eventDate) {
            toast.error("Please enter the event date to delete");
            return;
        }
        eventService.deleteEvent(props.event.eventGuid).then((data) => {
            props.setShowModal(false);
            props.onDelete();
            toast.success(`Event with date ${eventDate} has been successfully deleted`);
        }).catch((e) => {
            toast.error(`Unable to delete the event with date ${eventDate}. Error: ${e}`);
        });
    };

    console.log(props);

    return (
        <>
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Delete Event</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-member">
                        <h6>You are about to delete the event for the date <strong>{eventDate}</strong></h6>
                        <div>Type the above date to confirm 
                            <input type="text" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.setShowModal(false)}>CANCEL</button>
                    <button type="button" className="btn btn-danger" onClick={() => deleteUser()}>DELETE</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EventDelete;