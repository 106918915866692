import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { UserContext } from '../../Contexts/UserContext';
import { billingService } from '../../Services/billingService';
import BillingRefund from './BillingRefund';

const Billing = (props) => {
    const { domainConfig } = useContext(UserContext);
    const [billingData, setBillingData] = useState([]);
    const [showTransactions, setShowTransactions] = useState("credit");
    const [showRefund, setShowRefund] = useState(false);
    const [transaction, setTransaction] = useState(null);

    useEffect(() => {
        refresh();
    }, [domainConfig, props.mkey, props.region]);

    const refresh = () => {
        if(props.mkey != null) {
            billingService.getTokenTransactions(props.region, props.mkey).then((data) => {
                setBillingData(data);
            });
        }
    };

    const refreshParent = () => {
        refresh();
        props.refresh();
    };

    const getCurrencySymbol = (currency) => {
        if(currency == "INR")
            return "₹";
        return "$";
    }

    const getUsecaseDisplayName = (details) => {
        if (domainConfig == null || domainConfig.usecases == null)
            return "";
        var usecase = domainConfig.usecases.find((u) => u.name == details.usecase_name);
        if (usecase == null)
            return "";
        if(details.event_ext_id != null && details.member_aoi_id != null) {
            return <>{details.aoi_tag} {usecase.displayName} {details.image_date && <Moment date={details.image_date} format={"YYYY-MM-DD"}></Moment>}</>;
        }
        return usecase.displayName ?? "";
    }

    const getDescription = (row) => {
        const details = row.details;
        if (details.type == 'event-metering')
            return getUsecaseDisplayName(details);
        if (details.type == "customer-payment") {
            let refundAmount = 0;
            if(details.payment_intent != null) {
                billingData.map((b) => {
                    if(b.details != null && b.details.payment_intent == details.payment_intent && b.type == "D" && b.details.amount > 0)
                        refundAmount += b.details.amount;
                });
            }
            let refund = "";
            if(refundAmount > 0)
                refund = `Refunded ${getCurrencySymbol(details.currency?.toUpperCase())} ${refundAmount / 100}.`
            return `Customer Payment of ${getCurrencySymbol(details.currency?.toUpperCase())} ${details.amount / 100}. ${refund}`;
        }
        if (details.type == "new-member-initialization" && row.type == "C") {
            return 'New Member Bonus';
        }
        if (details.type == "admin-added") {
            if(props.role == "admin")
                return details.description;
            if(row.type == "C")
                return "Credited by Administrator";
            if(row.type == "D")
                return "Debited by Administrator";
        }
        if(details.type == "refund") {
            let refund = `Refunded ${getCurrencySymbol(details.currency?.toUpperCase())} ${details.amount / 100}.`;
            if(props.role == "admin" && details.description != null)
                refund = `${refund} ${details.description}`;
            return refund;
        }
        return details.type;
    };

    const showRefundModel = (row) => {
        setTransaction(row);
        setShowRefund(true);
    }

    const renderRefund = (row) => {
        if(row == null || row.type == "D" || row.details == null || row.details.payment_intent == null || row.details.amount == 0)
            return <></>;
        return <a className="test" onClick={() => showRefundModel(row)}>Refund</a>;
    }

    const renderBillingRow = (row) => {
        if (row.qty == 0 && row.details.type != 'event-metering')
            return null;
        if(showTransactions == "credit" && row.type == "D")
            return;
        if(showTransactions == "debit" && row.type == "C")
            return;

        return <tr className={row.type == "C" ? "green" : "red"} key={row.timestamp}>
                    <td><span>{row.type == "C" ? row.qty : -row.qty}</span></td>
                    <td><Moment date={row.timestamp} format={"YYYY-MM-DD"}></Moment></td>
                    <td>{getDescription(row)}</td>
                    {
                        props.role == "admin" &&
                        <td>{renderRefund(row)}</td>
                    }
                </tr>;
    };

    return (
        <div className="tab-content mb-4" id="billingTabContent">
            <div className="tab-pane fade show active" id="billing" role="tabpanel"
                aria-labelledby="billing-tab">
                <div className='billing-filters'>
                    <div className='filters-title'>Transaction Filters:</div>
                    <div className='filter-group'>
                        <a className={showTransactions == "all" ? "active": "test"} onClick={() => setShowTransactions("all")}>All Transactions</a> 
                        <a className={showTransactions == "debit" ? "active": "test"} onClick={() => setShowTransactions("debit")}>Debit Only</a> 
                        <a className={showTransactions == "credit" ? "active": "test"} onClick={() => setShowTransactions("credit")}>Credit Only</a>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-style">
                        <thead>
                            <tr>
                                <th scope="col">Amount</th>
                                <th scope="col" className="date">Date</th>
                                <th scope="col">Description</th>
                                {
                                    props.role == "admin" &&
                                    <th scope="col"></th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                billingData.map((d) => renderBillingRow(d))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {
                showRefund && 
                <BillingRefund transaction={transaction} mkey={props.mkey} region={props.region} refresh={refreshParent} allTransactions={billingData}
                    setShowModal={(show) => setShowRefund(show)}>
                </BillingRefund>
            }
        </div>
    );
}

export default Billing;