const Copyright = () => {
    return (
        <footer className="app-footer">
            <div className="copyright">
                <div className="container">
                    Copyright © 2023 DataDeli, Inc. All rights Reserved.
                </div>
            </div>
        </footer>)
}

export default Copyright;