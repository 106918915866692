import { useState } from "react";
import { Link } from "react-router-dom";
import ShowVideoModal from "../Components/ShowVideoModal";
import YoutubeIcon from '../Assets/img/icon-youtube.svg';
import CallIcon from '../Assets/img/icon-call.svg';


const Help = (props) => {
    const [faqState, setFaqState] = useState(Array(100).fill(false));
    const [showVideo, setShowVideo] = useState(false);
    const [video, setVideo] = useState({
        title: "",
        id: ""
    });

    const toggleFAQ = (index) => {
        setFaqState((prevState) => {
          const newState = [...prevState];
          newState[index] = !newState[index];
          return newState;
        });
    };

    const displayVideo = (title, id) => {
        setVideo({title: title, id: id});
        setShowVideo(true);
    };

    return (
        <div className="help-pgae">
            <div className="container">
                <div className="videos-container">
                    <h2>Help Videos</h2>
                    <div className="video-list">
                        <div className="video" onClick={() => displayVideo("Draw your AOI", "90xDcRI028g")}>
                            <img src="https://datadeli.com/media/images/draw_aoi.png" className="video-placeholder"/>
                            <img src={YoutubeIcon} className="video-play" alt="" />
                        </div>
                        <div className="video" onClick={() => displayVideo("Add money to your wallet and subscribe", "JsPR0peHRj8")}>
                            <img src="https://datadeli.com/media/images/add_money.png" className="video-placeholder"/>
                            <img src={YoutubeIcon} className="video-play" alt="" />
                        </div>
                        <div className="video" onClick={() => displayVideo("Get alerts and visualize changes", "yqeyhDBvfGg")}>
                            <img src="https://datadeli.com/media/images/visualize_changes.png" className="video-placeholder"/>
                            <img src={YoutubeIcon} className="video-play" alt="" />
                        </div>
                    </div>
                </div>    
            </div>
            {
                showVideo && <ShowVideoModal title={video.title} id={video.id} show={setShowVideo} />
            }

            <div className="help-faq">

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 col-lg-9">
                            <h2>FAQ</h2>
                            <div className={`faq-row ${faqState[0] ? "open" : ""}`}>
                                <div className="faq-question" onClick={() => toggleFAQ(0)}>What is MegaView?</div>
                                <div className="faq-answer">MegaView provides a seamless, intuitive interface for remote landowners to easily track and monitor changes on their properties. As soon as satellite imagery of the property is available, complete with annotations of identified changes, registered users who subscribed will receive monthly notifications sent directly to their registered email.</div>
                            </div>
                            <div className={`faq-row ${faqState[1] ? "open" : ""}`}>
                                <div className="faq-question" onClick={() => toggleFAQ(1)}>How do I draw my AOI? </div>
                                <div className="faq-answer">
                                    <div>
                                    Creating your Area of Interest (AOI) is a straightforward process, consisting of just three easy steps. For a visual guide, be sure to watch <a className="test" onClick={() => displayVideo("Draw your AOI", "90xDcRI028g")}>the instructional video</a></div><br/>
                                    <div>Step 1: Start on the ListView of your Portfolio. By selecting 'Create AOI', you'll be directed to the MapView, displaying a world map. If you're already in MapView, simply move on to the following steps.</div><br/>
                                    <div>Step 2: Type the desired address into the top left corner's address bar. The map will then focus on your selected location.</div><br/>
                                    <div>Step 3: To draw your AOI, click the 'Create AOI' button located at the top right. A polygon will appear for you to adjust its edges to encompass your area of interest. Once satisfied, save your configuration.</div><br/>
                                    <div>Alternatively, you can utilize additional drawing tools by going to 'Settings', enabling 'Show Free Style Drawing', and clicking 'Save'. This action unlocks various polygon drawing options found at the bottom left corner of MapView.</div><br/>
                                    <div>The AOI information page serves as a resource for managing your area. Here, you can upload any pertinent images or documents related to your AOI. It also features a 'Tag' section to edit the name of your AOI. Additionally, there's space for any notes you'd like to keep regarding your AOI.</div>
                                </div>
                            </div>
                            <div className={`faq-row ${faqState[2] ? "open" : ""}`}>
                                <div className="faq-question" onClick={() => toggleFAQ(2)}>How will I be charged for the subscribed service?
</div>
                                <div className="faq-answer">The cost of your subscription varies based on the size of your Area of Interest (AOI). A monthly fee of $15 applies for AOIs up to 50 acres. For each subsequent 50 acres, the subscription amount will increase by $10. If you wish to talk to us regarding pricing, please <Link to="/contact-us">reach out to us</Link></div>
                            </div>
                            <div className={`faq-row ${faqState[3] ? "open" : ""}`}>
                                <div className="faq-question" onClick={() => toggleFAQ(3)}>Can I draw an Area of Interest (AOI) anywhere in the world?
</div>
                                <div className="faq-answer">You can draw Areas of Interest (AOIs) across the globe at no cost. Yet, imagery will be supplied exclusively for AOIs within MegaView's serviceable area. We are initially launching our services in select areas within Greater Hyderabad, Chennai, and Visakhapatnam, and plan to expand to more regions based on demand. Feel free to create your free Area of Interest (AOI) even if it falls outside our current service areas. This assists us in identifying and prioritizing new locations to serve in the future. Should your AOI lie outside our serviceable area, you'll be notified upon subscription attempt, and subscription for this AOI will be restricted. You'll be promptly notified when the area enters our serviceable range, allowing you to subscribe.  </div>
                            </div>
                            <div className={`faq-row ${faqState[4] ? "open" : ""}`}>
                                <div className="faq-question" onClick={() => toggleFAQ(4)}>How big or small of an AoI can I draw?</div>
                                <div className="faq-answer">Your Area of Interest (AoI) is not restricted by a minimum size, yet we advise outlining a minimum of 0.5 acres to ensure optimal results. You are welcome to mark AoIs up to 500 acres. For needs exceeding this limit, please <Link to="/contact-us">contact us</Link> for personalized assistance and to explore the most advantageous deals. We also offer customized solutions that may better suit your specific requirements.</div>
                            </div>
                            <div className={`faq-row ${faqState[5] ? "open" : ""}`}>
                                <div className="faq-question" onClick={() => toggleFAQ(5)}>How many AoIs can I draw?</div>
                                <div className="faq-answer">You are permitted to draw up to 100 Areas of Interest (AOIs). Should your requirements exceed this number, do not hesitate to <Link to="/contact-us">contact us</Link> for dedicated support and access to the most favorable deals. Additionally, we may provide tailor-made solutions that could more precisely align with your needs.</div>
                            </div>
                            <div className={`faq-row ${faqState[6] ? "open" : ""}`}>
                                <div className="faq-question" onClick={() => toggleFAQ(6)}>Can others access my data?</div>
                                <div className="faq-answer">Rest assured, your account remains inaccessible to other registered users. In cases where another user's Area of Interest (AoI) overlaps with yours, they will only view information within their designated boundaries, ensuring no data is shared between users. DataDeli's customer service and support staff may have tiered access for the sole purpose of offering you necessary assistance. Regarding payment details, DataDeli employs Stripe for secure transactions, and we do not retain any payment information within our systems.</div>
                            </div>
                            <div className={`faq-row ${faqState[7] ? "open" : ""}`}>
                                <div className="faq-question" onClick={() => toggleFAQ(7)}>How soon can I expect my images after I subscribe?</div>
                                <div className="faq-answer">Upon subscribing, you can anticipate receiving your initial image in one to two weeks, subject to the cloud cover conditions prevailing over your Area of Interest (AOI) at that time.</div>
                            </div>
                            <div className={`faq-row ${faqState[8] ? "open" : ""}`}>
                                <div className="faq-question" onClick={() => toggleFAQ(8)}>Do you provide any free samples of data?</div>
                                <div className="faq-answer">Keep an eye on our website for exciting deals. Additionally, based on availability, we are committed to finding the best solutions for your needs. For assistance or inquiries, feel free to <Link to="/contact-us">contact us</Link>.</div>
                            </div>
                            <div className={`faq-row ${faqState[9] ? "open" : ""}`}>
                                <div className="faq-question" onClick={() => toggleFAQ(9)}>Where can I find the promotional information?</div>
                                <div className="faq-answer">Please visit our <Link to="/services">Website</Link> for any pricing/promotional deals.</div>
                            </div>
                            <div className={`faq-row ${faqState[10] ? "open" : ""}`}>
                                <div className="faq-question" onClick={() => toggleFAQ(10)}>What is the reimbursement policy?</div>
                                <div className="faq-answer">The process is straightforward: simply send a written request from your registered email to contactus@datadeli.com. We will reimburse any unused funds in your wallet as of the date of your request. Note that this reimbursement will exclude any promotional amounts added by DataDeli. You can expect the reimbursed amount to be processed within 30 days.</div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3">
                            <div className="help-contact">
                                <div className="help-contact-body">
                                    <div className="msg-container">
                                        <p>
                                        Didn't find what you are looking for? You can message our support team, and we'll get back to you shortly.
                                        </p>
                                        <Link to="/contact-us" className="msg-link">Message our team</Link>
                                    </div>
                                    <div className="give-call">
                                        <img src={CallIcon} alt="" /> Give Us a Call
                                    </div>
                                    <div className="help-contact-phone">
                                        <a href="tel:+15715289393">Phone: +1 571-528-9393</a>
                                    </div>
                                    <div className="help-contact-info">
                                        Available 24*7
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Help;