import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const PriorityDataRequestModal = (props) => {
    const [date, setDate] = useState(new Date());
    const [dataRequest, setDataRequest] = useState(0);
    const [coordinates, setCoordinates] = useState("");

    const addDays = (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    const tomorrow = addDays(new Date(), 1);

    const submit = () => {
        if(date == "") {
            toast.error("Please select a date");
            return;
        }

        if(dataRequest == 1 && (coordinates == "" || !coordinates.includes(","))) {
            toast.error("Please enter two Geo-Coordinates");
            return;
        }

        toast.success("Priority Data Request has been updated successfully ");
        props.show(false);
    };

    return(
        <>
            <Modal
                show={() => props.show(true)}
                onHide={() => props.show(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-600 send-report-modal-dialog"
                className="upload-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Priority Data Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="row">
                        <div className="col-md-6">
                            <div className="form-group-inline">
                                <label className="form-label" for="Date">Date</label>
                                <div className="input-group">
                                    <ReactDatePicker selected={date} onChange={(date) => setDate(date)} dateFormat="P" />
                                </div>
                            </div>
                        </div>
                        {
                            date != "" && date <= tomorrow && 
                            <div className="col-md-12">
                                <div className="alert alert-warning d-flex align-items-center" role="alert">
                                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.2276 0.957501C11.1039 0.741956 10.9255 0.562875 10.7104 0.438342C10.4953 0.313808 10.2512 0.24823 10.0026 0.24823C9.75408 0.24823 9.50994 0.313808 9.29486 0.438342C9.07977 0.562875 8.90135 0.741956 8.77762 0.957501L0.206366 15.5412C-0.364885 16.5137 0.320115 17.75 1.43137 17.75H18.5726C19.6839 17.75 20.3701 16.5125 19.7976 15.5412L11.2276 0.957501ZM10.0001 5.25C10.6689 5.25 11.1926 5.8275 11.1251 6.49375L10.6876 10.8775C10.6729 11.0497 10.5941 11.2101 10.4668 11.327C10.3395 11.4439 10.173 11.5088 10.0001 11.5088C9.82728 11.5088 9.66073 11.4439 9.53342 11.327C9.40611 11.2101 9.32732 11.0497 9.31262 10.8775L8.87512 6.49375C8.8594 6.33654 8.8768 6.17779 8.92617 6.02771C8.97555 5.87763 9.05581 5.73956 9.1618 5.62239C9.26778 5.50522 9.39713 5.41155 9.54152 5.34741C9.6859 5.28327 9.84213 5.25009 10.0001 5.25ZM10.0026 12.75C10.3341 12.75 10.6521 12.8817 10.8865 13.1161C11.1209 13.3505 11.2526 13.6685 11.2526 14C11.2526 14.3315 11.1209 14.6495 10.8865 14.8839C10.6521 15.1183 10.3341 15.25 10.0026 15.25C9.6711 15.25 9.35315 15.1183 9.11873 14.8839C8.88431 14.6495 8.75262 14.3315 8.75262 14C8.75262 13.6685 8.88431 13.3505 9.11873 13.1161C9.35315 12.8817 9.6711 12.75 10.0026 12.75Z"
                                            fill="#664D03" />
                                    </svg>
                                    <div>
                                        {
                                            date <= new Date() &&
                                            <span>SpaceAeye will try its best to provide the imagery but there is no guarantee as the date provided is historical.</span>
                                        }
                                        {
                                            new Date() < date && date <= tomorrow && 
                                            <span>SpaceAeye will try its best to provide imagery but there is no guarantee since the date is tomorrow.</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-md-12">
                            <div className="form-group-inline">

                                <div className="custom-radio">
                                    <input type="radio" id="dataRequest1" name="dataRequest"
                                        value="0" onChange={() => setDataRequest(0)} checked={dataRequest == 0}/>
                                    <label for="dataRequest1">Data for the whole Area</label>
                                </div>

                            </div>
                            <div className="form-group-inline">
                                <div className="custom-radio">
                                    <input type="radio" id="dataRequest2" name="dataRequest"
                                        value="1"  onChange={() => setDataRequest(1)} checked={dataRequest == 1}/>
                                    <label for="dataRequest2">Add 2 Geo-Coordinates</label>
                                </div>
                                <input type="text" className="form-control" placeholder="Enter Geo Coordinates" value={coordinates} disabled={dataRequest == 0} onChange={(e) => setCoordinates(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="footer-content">

                    </div>
                    <div className="btn-area">
                        <a className="btn btn-text" onClick={() => props.show(false)}>Cancel</a>
                        <button type="button" className="btn btn-primary" aria-label="Submit" onClick={submit}>Submit</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PriorityDataRequestModal;