import { Circle, GoogleMap, GroundOverlay, OverlayView, Polyline, useJsApiLoader } from "@react-google-maps/api";
import { AOIOptions } from "../Components/AOIOptions";
import { useContext, useEffect, useState } from "react";
import { fetchWrapper } from "../Helpers/fetchWrapper";
import { UserContext } from "../Contexts/UserContext";
import { MapUtils } from "../Components/MapUtils";
import Legend from '../Assets/img/deformation-legend.jpg';

const appConfig = window.config;

const circleOptions = {  // Circle configuration
    strokeColor: '#FF0000',
    strokeOpacity: 0.35,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    radius: 1000,  // Radius in meters
};

const TimelineLandDef = (props) => {
    const { user } = useContext(UserContext);
    const mapOptions = AOIOptions.satelliteMapOptions;
    const [center, setCenter] = useState(AOIOptions.defaultCenter);
    const [zoom, setZoom] = useState(AOIOptions.defaultZoom);
    const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey: appConfig.mapApiKey, libraries });
    const [pipelineGeometry, setPipelineGeometry] = useState(null);
    const [rouGeometry, setRouGeometry] = useState([]);

    const [pipelineOptions, setPipelineOptions] = useState({ firstOptions: AOIOptions.defaultPipelineOptions });
    const [rouOptions, setRouOptions] = useState({ firstOptions: AOIOptions.defaultPipelineOptions });

    const [imageFile, setImageFile] = useState(null);
    const [bounds, setBounds] = useState(null);
    const [showLegends, setShowLegends] = useState(false);
    const [showLeftDate, setShowLeftDate] = useState(false);
    const [leftDate, setLeftDate] = useState("Sep 2022");
    const [showRightDate, setShowRightDate] = useState(false);
    const [rightDate, setRightDate] = useState("Aug 2023");
    const [showValues, setShowValues] = useState(false);
    const [points, setPoints] = useState([]);

    const onMapLoad = () => {

    };

    useEffect(() => {
        fetchWrapper.get("/geometry/land-deformation.json").then((res) => {
            setImageFile(res.imageFile);
            setBounds(res.bounds);
            setPipelineGeometry(res.pipelineGeometry);
            setRouGeometry(res.rouGeometry);
            setCenter(res.center);
            setZoom(res.zoomLevel);
            setPoints(res.points);
        });

        var defaultSettings = {...AOIOptions.defaultPipelineOptions}
        if(user.userSettings?.dashboard?.deformationLineThickness)
            defaultSettings = {...defaultSettings, strokeWeight: user.userSettings?.dashboard?.deformationLineThickness};

        if (!!user.userSettings?.dashboard?.landPipelineSettings) {
            setPipelineOptions(AOIOptions.getPipelineSettingsWithDefaults(user.userSettings?.dashboard?.landPipelineSettings, defaultSettings));
        }
        if (!!user.userSettings?.dashboard?.landRouSettings) {
            setRouOptions(AOIOptions.getPipelineSettingsWithDefaults(user.userSettings?.dashboard?.landRouSettings, defaultSettings));
        }
    }, []);

    const getCoordinates = (timeline) => {
        var coordinates = new google.maps.LatLngBounds(
            new google.maps.LatLng(timeline.miny, timeline.minx),
            new google.maps.LatLng(timeline.maxy, timeline.maxx)
        );
        return coordinates;
    };

    const changeLeftDate = (date) => {
        setLeftDate(date);
        setShowLeftDate(false);
    };

    const changeRightDate = (date) => {
        setRightDate(date);
        setShowRightDate(false);
    };

    const renderDates = (change) => {
        return <ul className="date-options show">
                    <li onClick={() => change("Sep 2022")}>
                        Sep 2022
                    </li>
                    <li onClick={() => change("Dec 2022")}>
                        Dec 2022
                    </li>
                    <li onClick={() => change("Apr 2023")}>
                        Apr 2023
                    </li>
                    <li onClick={() => change("Aug 2023")}>
                        Aug 2023
                    </li>
                </ul>;
    };

    const getColorCode = (value) => {
        let red = 255;
        let green = 255;
        let blue = 191;

        if(value < 0) {
            red = red + value * 4.67;
            green = green + value * 25.55;
            blue = blue + value * 17.78;
        } else {
            red = red - value * 38;
            green = green - value * 27.2;
            blue = blue + value * 12.8;
        }
        if(red > 255)
            red = 255;
        if(blue > 255)
            blue = 255;
        if(green > 255)
            green = 255;

        const result = "#" + getHexaValue(red) + getHexaValue(green) + getHexaValue(blue);
        return result;
    };

    const getHexaValue = (value) => {
        if(value >= 255)
            return "ff";
        if(value <= 0)
            return "00";

        const result = (Math.round(value)).toString(16);
        if(result.length == 2)
            return result;
        return "0" + result;
    };

    const getCircles = (point) => {
        var circles = [];
        for(var i=0.5; i>0; i-=0.05) {
            circles.push(<Circle options={{...circleOptions, 
                                        center: point.center, 
                                        strokeWeight: 0, 
                                        fillColor: getColorCode(point.value), 
                                        fillOpacity: 0.035,
                                        radius: 100+(0.55-i)*2000 }} />);
        }
        return circles;
    };

    return (
        <div className="page-body timeline-view">
            <div>
                <div className='timeline-map-container'>
                    <div className="dates-container">
                        <div className="before-date date-ddl">
                            <div className="date-label">From</div>
                            <div className="selected-date" onClick={() => setShowLeftDate(!showLeftDate)}>{leftDate}</div>
                            {
                                showLeftDate && renderDates(changeLeftDate)
                            }
                        </div>
                        <div className="after-date date-ddl">
                        <div className="date-label">To</div>
                            <div className="selected-date" onClick={() => setShowRightDate(!showRightDate)}>{rightDate}</div>
                            {
                                showRightDate && renderDates(changeRightDate)
                            }
                        </div>
                    </div>
                    <div className="aoi-tools ld">
                        <a className="tool-item analytics show" title="Analytics" target="_blank" href="/Kachch-Deformations.pdf"> <i></i></a>
                        <a className={"tool-item values " + (showValues ? "show" : "hide")} title="Values" onClick={() => setShowValues(!showValues)}> <i></i></a>
                        <a className={"tool-item legends " + (showLegends ? "show" : "hide")} title="Legends" onClick={() => setShowLegends(!showLegends)}> <i></i></a>
                    </div>
                    {
                        showLegends && 
                        <div className="legends-panel">
                            <div className="legend-row">
                                <img src={Legend} className="legend-image" />
                            </div>
                            <div className="legend-row">
                                <div className="legend-value left">-10</div>
                                <div className="legend-value center">0</div>
                                <div className="legend-value right">10</div>
                            </div>
                            <div className="legend-row">
                                <div className="legend-value left">Subsidence</div>
                                <div className="legend-value center"></div>
                                <div className="legend-value right">Uplift</div>
                            </div>
                            <div className="legend-row">
                                <div className="legend-value">Deformation in mm/year</div>
                            </div>
                        </div>
                    }
                    <div className='timeline-map'>
                        {
                            isLoaded &&
                            (
                                <GoogleMap
                                    center={center}
                                    zoom={zoom}
                                    mapContainerStyle={AOIOptions.mapContainerStyle}
                                    options={mapOptions}
                                    onLoad={onMapLoad}
                                >
                                     {
                                        imageFile != null && bounds != null &&
                                        <GroundOverlay
                                            url={imageFile}
                                            key={imageFile}
                                            bounds={getCoordinates(bounds)}
                                        />
                                    }
                                    {
                                        //points && points.map(point => <>{getCircles(point)}</>)
                                    }
                                    {
                                        // points && points.map(point => <Circle
                                        //                 options={{...circleOptions, center: point.center, strokeColor: "#000000", fillColor: "#000000", fillOpacity: 0.35, radius: 100 }}
                                        //             />)
                                    }
                                    {
                                        showValues && points && points.map(point =>  <OverlayView
                                            position={point.center}
                                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                          >
                                            <div style={{
                                              color: "#000000",
                                              fontSize: "16px"
                                            }}>
                                              {point.value + ""}
                                            </div>
                                            </OverlayView>)
                                    }
                                    {
                                        rouGeometry && rouOptions.secondLine &&
                                        rouGeometry.map((rou, index) => <Polyline
                                                                    key={"rouBorder"+index}
                                                                    path={MapUtils.geoJsonToCoordinates(rou)}
                                                                    options={rouOptions.secondOptions}
                                                                />)
                                    }
                                    {
                                        rouGeometry &&
                                        rouGeometry.map((rou, index) => <Polyline
                                                                    key={"rouLine"+index}
                                                                    path={MapUtils.geoJsonToCoordinates(rou)}
                                                                    options={rouOptions.firstOptions}
                                                                />)
                                    }
                                    {
                                        pipelineGeometry && pipelineOptions.secondLine &&
                                        <Polyline
                                            key="polylineBorder"
                                            path={MapUtils.geoJsonToCoordinates(pipelineGeometry)}
                                            options={pipelineOptions.secondOptions}
                                        />
                                    }
                                    {
                                        pipelineGeometry &&
                                        <Polyline
                                            key="polyline"
                                            path={MapUtils.geoJsonToCoordinates(pipelineGeometry)}
                                            options={pipelineOptions.firstOptions}
                                        />
                                    }
                                </GoogleMap>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimelineLandDef;