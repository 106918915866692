import { Modal } from 'react-bootstrap';
import React, {useContext } from 'react';
import { UserContext } from '../Contexts/UserContext';
import Profile from './UserProfile/Profile';

const EditProfileModal = () => {
    const { openEditProfileModal } = useContext(UserContext);

    return(
        <>
            <Modal
                show={() => openEditProfileModal(true)}
                onHide={() => openEditProfileModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="registration-modal-dialog"
                className="registration-modal"
            >
                <Modal.Header>
                    <Modal.Title>Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Profile inModal={true} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditProfileModal;