import {Modal} from 'react-bootstrap';
import React, {useState} from 'react';
import YouTube from 'react-youtube';

const TourModal = (props) => {
    const [doNotShow, setDoNotShow] = useState(false);
    const [showingVideo, setShowingVideo] = useState(1);

    const onEnd = () => {
        if(showingVideo == 3) {
            props.show(false);
            return;
        }
        setShowingVideo(showingVideo + 1);
    };

    const opts = {
        height: '315',
        width: '560',
        playerVars: {
            controls: 1, 
            modestbranding: 1, 
            fs: 1
        }
    };

    const autoPlayOpts = {
        ...opts,
        playerVars: {
            autoplay: 1
        }
    };

    return (
        <>
            <Modal
                show={() => {}}
                onHide={() => props.show(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="tour-modal-dialog"
                className="first-video-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Getting Started From Here!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="video-steps">
                        <li className={showingVideo == 1 ? "active" : "collapsed"}>
                            <div className="video-header" onClick={() => setShowingVideo(1)}>
                                <h3>Draw your AOI</h3>
                            </div>
                            <div className="video-container">
                                {
                                    showingVideo == 1 && 
                                    <YouTube videoId="90xDcRI028g" onEnd={onEnd} opts={opts} />
                                }
                            </div>
                        </li>
                        <li className={showingVideo == 2 ? "active" : "collapsed"}>
                            <div className="video-header" onClick={() => setShowingVideo(2)}>
                                <h3>Add money to your wallet and subscribe</h3>
                            </div>
                            <div className="video-container">
                                {
                                    showingVideo == 2 && 
                                    <YouTube videoId="JsPR0peHRj8" onEnd={onEnd} opts={autoPlayOpts} />
                                }
                            </div>
                        </li>
                        <li className={showingVideo == 3 ? "active" : "collapsed"}>
                            <div className="video-header" onClick={() => setShowingVideo(3)}>
                                <h3>Get alerts and visualize changes</h3>
                            </div>
                            <div className="video-container">
                                {
                                    showingVideo == 3 && 
                                    <YouTube videoId="yqeyhDBvfGg" onEnd={onEnd} opts={autoPlayOpts} />
                                }
                            </div>
                        </li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <div className="footer">
                        <div className="footer-content">
                            <div className="custom-check">
                                <label htmlFor="doNotShow">
                                    <input type="checkbox" name="doNotShow" id="doNotShow" onChange={(e) => setDoNotShow(e.target.checked)}/>
                                    <span> Do not show this again</span>
                                </label>

                            </div>
                            <p>You can always access this tour from help page</p>
                        </div>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" 
                            aria-label="Close" onClick={() => props.show(doNotShow)}>Close</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TourModal;