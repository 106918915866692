import React, {useContext, useState} from 'react';
import { UserContext } from '../../Contexts/UserContext';

const ClearCache = () => {
    const { deleteImageCache } = useContext(UserContext);
    const [cacheKey, setCacheKey] = useState("");

    return (
        <div className="form-content member-create-modal">
            <div className="form-head">
                <h3>Clear Cache</h3>
            </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="form-label" htmlFor="cacheKey">Cache Key <span className="required">*</span> (Keep it empty to clear all the cache)</label>
                            <input type="text" className="form-control" id="cacheKey" name="cacheKey" value={cacheKey} onChange={(e) => setCacheKey(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <button type="button" className="btn btn-danger" onClick={() => deleteImageCache(cacheKey)}>CLEAR</button>
                </div>
            </div>
    );
}

export default ClearCache;