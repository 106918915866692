import { useContext } from 'react';
import LoginAuth from '../Components/LoginAuth';
import { UserContext } from '../Contexts/UserContext';

const Login = () => {
    const {isAuthInProgress} = useContext(UserContext);
    return (     
        <div className="page-body">
            <div className="login-page-container">
                {
                    isAuthInProgress
                    ? <div> <h1>Loading .........</h1> </div>
                    : <LoginAuth />
                }
            </div>
        </div>
    );
}

export default Login;