import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserContext } from '../../Contexts/UserContext';
import Billing from './Billing';
import Subscriptions from './Subscriptions';

const BillingSubscriptions = (props) => {
    const [section, setSection] = useState(props.section);
    const navigate = useNavigate();
    const { allAOIs, originalAllAOIs, user } = useContext(UserContext);
    const [mkey, setMkey] = useState(null);
    const [region, setRegion] = useState(null);
    const [aois, setAois] = useState([]);

    useEffect(() => {
        setSection(props.section);
    }, [props.section]);

    useEffect(() => {
        setMkey(props.role == "user" ? user.originalmKey : props.mkey);
        setRegion(props.role == "user" ? user.originalRegion : props.region);
        if(props.role == "user" && user.isImpersonated) {
            setAois(originalAllAOIs);
        } else {
            setAois(allAOIs);
        }
    }, [props.role, props.mkey, props.region, allAOIs]);

    const changeSectionTo = (section) => {
        setSection(section);
        if(props.role == "user")
            navigate("/" + section);
    };

    return (
        <div className="form-content">
            {
                props.role == "user" &&
                <div className="form-head">
                    <h3>Billing &amp; Subscriptions</h3>
                </div>
            }
            <ul className="nav nav-tabs mb-4" id="billingTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className={section != "subscriptions" ? "nav-link active" : "nav-link"} id="billing-tab" type="button" role="tab" aria-controls="billing"
                        aria-selected={section != "subscriptions" ? "true" : "false"} onClick={() => changeSectionTo("billing")}>Billing</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className={section == "subscriptions" ? "nav-link active" : "nav-link"} id="subscriptions-tab" type="button" role="tab" aria-controls="subscriptions"
                        aria-selected={section == "subscriptions" ? "true" : "false"} onClick={() => changeSectionTo("subscriptions")}>Subscriptions</button>
                </li>
            </ul>
            {
                section == "subscriptions" 
                ?   <div className="tab-content mb-4" id="billingTabContent">
                        <div className="tab-pane fade show active" id="subscriptions" role="tabpanel" aria-labelledby="subscriptions-tab">
                            <Subscriptions mkey={mkey} region={region} aois={aois} hideLinks={props.role == "user" && user.isImpersonated}></Subscriptions>
                        </div>
                    </div>
                :   <div className="tab-content mb-4" id="billingTabContent">
                        <div className="tab-pane fade show active" id="billing" role="tabpanel" aria-labelledby="billing-tab">
                            <Billing mkey={mkey} region={region} role={props.role} refresh={props.refresh}></Billing>
                        </div>
                    </div>
            }
        </div>
    );
}

export default BillingSubscriptions;