import $ from 'jquery';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MapContext } from '../../Contexts/MapContext';
import { MapUtils } from '../MapUtils';

const handleSideToggleClick = () => {
    $(".aoi-panel").toggleClass("minimized");
}

const AOIPanel = () => {
    const {userAOIs, mapRef} = useContext(MapContext);

    return (
        <>
            {
                userAOIs?.length > 0 &&
                <div className="aoi-panel minimized">
                    <div className="head">
                        <span>AOIs</span> 
                        <a className="side-toggle" onClick={handleSideToggleClick}>
                            <i className="icon-chevron-left"></i>
                        </a>
                    </div>
                    <div className="aoi-list-body">
                        <div className="aoi-list">
                            {
                                userAOIs.map((aoi) => (
                                    <div className="aoi-row" key={aoi.member_aoi_id}>
                                        <Link className="name" to={`/portfolio/map/aoi/${aoi.member_aoi_id}`} replace={true}>
                                            {aoi.tag}
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default AOIPanel;