import { fetchWrapper } from '../Helpers/fetchWrapper';
import { apiService } from './apiService';
import { stateMachineService } from './stateMachineService';

const CREATE_IMAGE_EVENTS = "CreateImageEvents";

const getEventsByAoiId = (aoiId) => {
    return fetchWrapper.get(`${apiService.getApiUrl()}/event/${aoiId}`);
};

const updateEvent = (eventId, eventData) => {
    return fetchWrapper.put(`${apiService.getApiUrl()}/event/${eventId}`, eventData);
};

const createEvent = (aoiId, eventData) => {
    return fetchWrapper.post(`${apiService.getApiUrl()}/event/${aoiId}`, eventData);
};

const deleteEvent = (eventId) => {
    return fetchWrapper.deleteRequest(`${apiService.getApiUrl()}/event/${eventId}`);
};

const createEventOld = (region, data) => {
    return stateMachineService.run(region, CREATE_IMAGE_EVENTS, data);
};

const getCreateEventStatus = (region, executionId) => {
    return stateMachineService.getStatus(region, CREATE_IMAGE_EVENTS, executionId);
};

const confirmBounds = (region, executionId, data) => {
    return stateMachineService.callback(region, CREATE_IMAGE_EVENTS, executionId, data);
};

const cancelCreateEvent = (region, executionId) => {
    return stateMachineService.stop(region, CREATE_IMAGE_EVENTS, executionId);
};

const deleteAllEvents = (region, member_aoi_id, usecase) => {
    return fetchWrapper.deleteRequest(`${apiService.getApiBaseUrl(region)}/event/aoi/${member_aoi_id}/${usecase}`);
};

const getTimelineR1 = (mkey, region, member_aoi_id) => {
    return fetchWrapper.get(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/aoi/${member_aoi_id}/AoIRGBImageR1/events`);
};

const getAoiAnnotations = (member_aoi_id) => {
    return fetchWrapper.get(`${apiService.getApiBaseUrl("")}/aoi/annotations/${member_aoi_id}`);
}

export const eventService = { 
    getEventsByAoiId,
    updateEvent,
    createEvent,
    getTimeline,
    getTimelineV2,
    getTimelineR1,
    getAnnotations,
    createAnnotation,
    editAnnotation,
    deleteAnnotation,
    deleteEvent,
    createEventOld,
    getCreateEventStatus,
    confirmBounds,
    cancelCreateEvent,
    deleteAllEvents,
    saveAnnotatedImage,
    getAoiAnnotations
};

function getTimeline(mkey, region, member_aoi_id) {
    return fetchWrapper.get(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/aoi/${member_aoi_id}/AoIRGBImage/events`);
}

function getTimelineV2(mkey, region, member_aoi_id){
    return fetchWrapper.get(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/aoi/${member_aoi_id}/AoIRGBImageV2/events`);
}

function getAnnotations(region, ext_id){
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/event/${ext_id}/annotations`);
}

function createAnnotation(region, ext_id, authId, annotation){
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/event/${ext_id}/annotation`, annotation, {"auth-id": authId});
}

function editAnnotation(region, ext_id, authId, annotation_id, annotation){
    return fetchWrapper.put(`${apiService.getApiBaseUrl(region)}/event/${ext_id}/annotation/${annotation_id}`, annotation, {"auth-id": authId});
}

function deleteAnnotation(region, ext_id, authId, annotation_id){
    return fetchWrapper.deleteRequest(`${apiService.getApiBaseUrl(region)}/event/${ext_id}/annotation/${annotation_id}`, null, {"auth-id": authId});
}

function saveAnnotatedImage(url, image) {
    const headers={'Content-Type': 'image/png'};
    return fetchWrapper.put(url, image, headers);
}