import { GoogleMap, InfoWindow, Marker, Polygon, useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useEffect, useState } from "react";
import { AOIOptions } from "../../AOIOptions";
import { useRef } from "react";
import { MapUtils } from "../../MapUtils";
import GreenMapPinIcon from '../../../Assets/img/icon-map-pin-green.png';
import OrangeMapPinIcon from '../../../Assets/img/icon-map-pin-orange.png';
import RedMapPinIcon from '../../../Assets/img/icon-map-pin-red.png';
import BlueMapPinIcon from '../../../Assets/img/icon-map-pin-blue.png';
import YellowMapPinIcon from '../../../Assets/img/icon-map-pin-yellow.png';
import LinkIcon from '../../../Assets/img/icon-link-blue.svg';

const appConfig = window.config;
const GreenMarker = { url: GreenMapPinIcon, scaledSize: { width: 32, height: 52 } };
const OrangeMarker = { url: OrangeMapPinIcon, scaledSize: { width: 32, height: 52 } };
const RedMarker = { url: RedMapPinIcon, scaledSize: { width: 32, height: 52 } };
const BlueMarker = { url: BlueMapPinIcon, scaledSize: { width: 32, height: 52 } };
const YellowMarker = { url: YellowMapPinIcon, scaledSize: { width: 32, height: 52 } };

const DefaultCSInsightsZoomLevel = 6;

const CSInsightsMap = (props) => {
    const mapRef = useRef();
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [aois, setAois] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState('subscriptions');
    const [showServiceArea, setShowServiceArea] = useState(false);
    const [markerMap, setMarkerMap] = useState({});

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: appConfig.mapApiKey,
        libraries
    });

    useEffect(() => {
        if (isMapLoaded && mapRef && mapRef.current) {
            // Once Map is loaded, Set map to certain zoom level and set default Center
            mapRef.current.setZoom(DefaultCSInsightsZoomLevel);
            mapRef.current.setCenter(AOIOptions.defaultCenter);
        }
    }, [isMapLoaded])

    useEffect(()=>{
        props.setShowSelectedAOIPopover(false);
        setAois(props.aois);
    }, [props.aois]);

    useEffect(() => {
        if (props.serviceArea) {
            props.serviceArea.setMap(null);
        }
        if (showServiceArea) {
            setTimeout(() => {
                setServiceArea();
            });
        } 
    }, [showServiceArea, props.serviceArea]);

    useEffect(() => {
        if (mapRef.current && mapRef.current.zoom >= DefaultCSInsightsZoomLevel) {
            props.getAOIs(props.filter);
        }
        else {
            props.setCsInsightsMgs("Zoom In Further to see the AOIs");
        }
    }, [props.filter])

    const onMapLoad = (map) => {
        // Store the Map reference in mapRef
        mapRef.current = map;
        setIsMapLoaded(true);
        props.initServiceArea(props.filter.usecase);
    }

    const setServiceArea = () => {
        if (mapRef.current && props.serviceArea) {
            props.serviceArea.setMap(mapRef.current);
        }
    }

    const onMapZoomChange = () => {
        if (mapRef.current) {
            onMapBoundsChange();
        }
    }

    const onMapBoundsChange = () => {
        const mapBounds = mapRef.current?.getBounds();
        if (mapBounds) {
            let NE = mapBounds.getNorthEast();
            let SW = mapBounds.getSouthWest();
            let NW = new google.maps.LatLng(NE.lat(), SW.lng());
            let SE = new google.maps.LatLng(SW.lat(), NE.lng());
    
            let coords = [];
            coords.push([NW.lng(), NW.lat()]);
            coords.push([SW.lng(), SW.lat()]);
            coords.push([SE.lng(), SE.lat()]);
            coords.push([NE.lng(), NE.lat()]);
            coords.push([NW.lng(), NW.lat()]);
            let coordinates = [];
            coordinates.push(coords);
            props.setFilter({...props.filter, mapCoords: coordinates});
        }   
    }

    const handleCategoryFilterChange = (e) => {
        setCategoryFilter(e.target.value)
    }

    const getMarkerIcon = (aoi) => {
        if (categoryFilter == "subscriptions") {
            if (aoi.properties.subscribed == "Y") {
                return GreenMarker;
            }
            else if (aoi.properties.subscribed == "N" && aoi.properties.all_usecases != null) {
                return OrangeMarker;
            }
            else {
                return RedMarker;
            }
        }
        else if (categoryFilter == "region") {
            if (aoi.properties.region == "us-east-1") {
                return BlueMarker;
            }
            else {
                return YellowMarker;
            }
        }    
    }

    const markerLoadHandler = (marker, aoi) => {
        return setMarkerMap(prevState => {
            console.log('markerLoaded');
            return { ...prevState, [aoi.properties["member-aoi-id"]]: marker };
        });
    };

    const onMarkerClick = (event, aoi) => {
        props.handleAOISelect(aoi);
    }

    const renderMap = () => {
        return (
            <>
                <div className="map-actions">
                    <div className="toggle-switch">
                        <span className="label">Show Service Area : </span>
                        <input type="checkbox" name="service-area-toggle" checked={showServiceArea}/>
                        <label htmlFor="service-area-toggle" onClick={()=>setShowServiceArea(!showServiceArea)}>Toggle</label>
                    </div>
                    <div className="category-filter">
                        <select className="form-select" id="categoryFilter" value={categoryFilter} onChange={(e)=>handleCategoryFilterChange(e)}>
                            <option value="subscriptions">Subscriptions</option>
                            <option value="region">Region</option>
                        </select>
                    </div>
                </div>
                <div className="map map-container" style={{height: "600px"}}>
                    {
                        <GoogleMap
                            center = {AOIOptions.defaultCenter}
                            zoom={3}
                            mapContainerStyle={AOIOptions.mapContainerStyle}
                            options = {AOIOptions.roadMapOptions}
                            onLoad = {onMapLoad}
                            onZoomChanged={onMapZoomChange}
                            onDragEnd={onMapBoundsChange}
                            onTilesLoaded={onMapBoundsChange}
                        >
                            { 
                                /* AOIs Marker */   
                                aois.length > 0 &&   
                                aois.map((aoi) => (
                                    <>
                                        <Polygon
                                            key={`geom_${aoi.properties["member-aoi-id"]}`}
                                            paths={MapUtils.geoJsonToCoordinates(aoi.geometry)}
                                            options={AOIOptions.userAOIoptions}
                                        />
                                        <Marker 
                                            icon={getMarkerIcon(aoi)}
                                            key={`marker_${aoi.properties["member-aoi-id"]}`}
                                            onLoad={(marker)=>markerLoadHandler(marker, aoi)}
                                            position={MapUtils.getCenterForGeometry(aoi.geometry)}
                                            onClick={(event)=>onMarkerClick(event, aoi)}
                                        />
                                    </>
                                    
                                ))
                            }
                            {
                                // AOI's InfoWindow
                                props.showSelectedAOIPopover && markerMap[props.selectedAOI.properties["member-aoi-id"]] &&
                                <InfoWindow anchor={markerMap[props.selectedAOI.properties["member-aoi-id"]]}>
                                    <div className='da-cockpit-bounds-info'>         
                                        <table >
                                            <tbody>
                                                <tr><td>Auth Id</td><td>{props.selectedAOI.properties["auth-id"]}</td></tr>
                                                <tr><td>AOI Tag</td><td>{props.selectedAOI.properties["tag"]}</td></tr>
                                                <tr><td>Region</td><td>{props.selectedAOI.properties["region"]}</td></tr>
                                                <tr><td>Subscribed</td><td>{props.selectedAOI.properties["subscribed"]}</td></tr>
                                                <tr><td>Usecases</td><td>{props.selectedAOI.properties["all_usecases"]}</td></tr> 
                                                <tr><td>Tokens</td><td>{props.selectedAOI.properties["token-amount"]}</td></tr>    
                                            </tbody>          
                                        </table>
                                        <div className="follow"><a className="link" onClick={() => props.impersonateAOIUser(props.selectedAOI)}><img src={LinkIcon}/>Follow</a></div>
                                    </div>
                                </InfoWindow>
                            }
                        </GoogleMap>
                    }
                </div>
            </>   
        )
    }

    if (loadError) {
        return <div className='map-load-text'>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded ? renderMap() : <div className='map-load-text'><span>Loading Map...</span></div>
}

export default CSInsightsMap;