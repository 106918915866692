import React, {useContext, useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../Contexts/UserContext';
import { adminService} from '../../Services/adminService';
import ViewLogo from '../../Assets/img/icon-view-grey.svg';
import EditLogo from '../../Assets/img/icon-edit-grey.svg';
import DeleteLogo from '../../Assets/img/icon-delete-grey.svg';
import DomainConfigEdit from './DomainConfigEdit';
import DomainConfigDelete from './DomainConfigDelete';
import ShowJson from './ShowJson';

const DomainConfig = () => {
    const [domainConfig, setDomainConfig] = useState({});
    const [currentDomain, setCurrentDomain] = useState("");
    const [currentValue, setCurrentValue] = useState("");
    const [currentJsonValue, setCurrentJsonValue] = useState(null);
    const {user} = useContext(UserContext);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        adminService.getDomainConfig(user.originalRegion).then((data) => {
            setDomainConfig(data);
        }).catch((e) => {
            toast.error("Unable to retrieve domain config. Error: " + e);
        });
    };

    const deleteConfig = (name) => {
        let updatedConfig = {...domainConfig};
        delete updatedConfig[name];
        save(name, updatedConfig);
    }
    
    const update = (name, value) => {
        let updatedConfig = {...domainConfig};
        updatedConfig[name] = JSON.parse(value);
        save(name, updatedConfig);
    }

    const save = (name, updatedConfig) => {
        adminService.postDomainConfig(updatedConfig).then((data) => {
            refresh();
            setShowEditModal(false);
            setShowDeleteModal(false);
            toast.success("Updated domain configuration for " + name);
        }).catch((e) => {
            toast.error("Unable to save domain configuration" + e);
        });
    }

    const editDomain = (name, isEdit) => {
        setCurrentDomain(name);
        if(domainConfig[name])
            setCurrentValue(JSON.stringify(domainConfig[name], null, 4));
        else 
            setCurrentValue("");
        setCurrentJsonValue(domainConfig[name]);
        if(isEdit)
            setShowEditModal(true);
        else 
            setShowViewModal(true);
    };

    const deleteDomain = (name) => {
        setCurrentDomain(name);
        setShowDeleteModal(true);
    };

    const renderRow = (name, value) => {
        return <tr key={name}>
                    <td>
                        <img src={ViewLogo} alt="" onClick={() => editDomain(name, false)} title="Show Configuration"/>
                    </td>
                    {
                        user.isSuperAdmin &&
                        <>
                            <td>
                                <img src={EditLogo} alt="" onClick={() => editDomain(name, true)}/>
                            </td>
                            <td>
                                {
                                    name != "default" &&
                                    <img src={DeleteLogo} alt="" onClick={() => deleteDomain(name)}/>
                                }
                            </td>
                        </>
                    }
                    <td>{name}</td>
                </tr>;
    }

    return (
        <div className="form-content">
            <div className="form-head">
                <h3>Domain Configuration</h3>
                {
                    user.isSuperAdmin && 
                    <a className="add-link" onClick={() => editDomain("", true)}>+ Add a Domain Config</a>
                }
            </div>
            <div className="admin-table-responsive">
                <table className="table table-style">
                    <thead>
                        <tr>
                        <th scope="col"></th>
                        {
                            user.isSuperAdmin &&
                            <>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </>
                        }
                        <th scope="col">Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(domainConfig).map((key) => renderRow(key, domainConfig[key]))
                        }
                    </tbody>
                </table>
            </div>
            {
                showEditModal && <DomainConfigEdit setShowModal={setShowEditModal} name={currentDomain} 
                                    value={currentValue} save={update}/>
            }
            {
                showDeleteModal && <DomainConfigDelete setShowModal={setShowDeleteModal} name={currentDomain} deleteConfig={deleteConfig} />
            }
            {
                showViewModal && <ShowJson setShowModal={setShowViewModal} title={"Domain Configuration - " + currentDomain} json={currentJsonValue} />
            }
        </div>
    );
}

export default DomainConfig;