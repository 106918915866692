import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MapContext } from '../../Contexts/MapContext';
import DrawingIcon from '../../Assets/img/icon-drawing.svg';
import SaveIcon from '../../Assets/img/icon-check-mark.svg';
import DiscardIcon from '../../Assets/img/icon-discard.svg';

const AOIMapActions = (props) => {
    const {user, showAOIPopover, fitBounds, clearFilters, discardAOI} = useContext(MapContext);
    
    const handleCreateAOIClick = () => {
        if (props.aoiMode == 'edit') {
            props.discardAOIEdit();
        }
        props.initCreateAOI();
    }

    const showAllAOIs = () => {
        clearFilters(false);
        fitBounds();
    }

    return (
        <>
            {
                ((props.aoiMode == 'create' || props.aoiMode == 'edit') && showAOIPopover == false ) &&
                <div className='aoi-edit-actions'>
                    <a className='discard'  onClick={discardAOI} >
                        <img src={DiscardIcon} alt='' /> Discard
                    </a>
                    <a className='save' onClick={props.handleSaveAOI} >
                        <img src={SaveIcon} alt='' /> Save
                    </a>
                </div>
            }
    
            <div className='map-action-links action-links'>
                {
                    <Link className='show-all-aoi-btn' to={"/portfolio/map"} onClick={() => showAllAOIs()}>
                        Show All <span>AOIs</span>
                    </Link>
                }   
                {
                    user.isReadOnly == false &&
                    <Link className='create-aoi-btn' to={"/portfolio/map"} onClick={handleCreateAOIClick}>
                        <img src={DrawingIcon} alt='' /> <span>Create AOI</span>
                    </Link>
                }   
                {
                    <a className={props.isFullScreen ? 'map-fullscreen active' : 'map-fullscreen'}  onClick={() => props.toggleFullScreen()}>
                        <i></i>
                    </a>
                }
            </div>
        </>
    );
}

export default AOIMapActions;