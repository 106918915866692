import {Modal} from 'react-bootstrap';
import React, {useState} from 'react';
import { adminService } from '../../Services/adminService';
import toast from 'react-hot-toast';

const ConfigurationEdit = (props) => {
    const initialValues = {
        name: '',
        valueDefinition: '',
        description: '',
        defaultValue: '',
        type: '',
        config: ''
    };
    const [values, setValues] = useState(initialValues);

    const handleInputChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };
    const isTrue = (val) => {
        return val.toLowerCase() === "true";
    }
    const saveConfig = () => {
        if(!validateFields())
            return;
        const valueDefinition = JSON.parse(values.valueDefinition);
        const defaultValue = valueDefinition.type === "boolean" ? isTrue(values.defaultValue) : (valueDefinition.type === "json" ? JSON.parse(values.defaultValue) : values.defaultValue);
        const config = {
            name: values.name, 
            value_definition: valueDefinition, 
            description: values.description, 
            default_value: defaultValue,
            type: values.type,
            config: values.config.trim() != "" ? JSON.parse(values.config.trim()) : null
        };

        adminService.addConfiguration(config)
        .then(() => {
            props.refreshConfigs();
            props.setShowModal(false);
            toast.success("Configuration has been successfully added");
        }).catch((e) => {
            console.log(e);
            toast.error("Configuration could not be added. Error: " + e);
        });
    }

    const validateFields = () => {
        if(values.name.trim() == "")
        {
            toast.error("Please enter name");
            return false;
        }
        if(values.valueDefinition.trim() == "") {
            toast.error("Please enter value definiton");
            return false;
        }
        try {
            JSON.parse(values.valueDefinition);
        } catch {
            toast.error("Invalid JSON value for value definition");
            return false;
        }
        try {
            if(values.config.trim() != "")
                JSON.parse(values.config.trim());
        } catch {
            toast.error("Invalid JSON value for Config");
            return false;
        }
        if(values.description.trim() == "") {
            toast.error("Please enter description");
            return false;
        }
        if(values.defaultValue.trim() == "") {
            toast.error("Please enter Default Value");
            return false;
        }
        if(values.type.trim() == "") {
            toast.error("Please enter Type (user or admin)");
        }
        return true;
    }

    return (
        <>
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog"
                className="modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Add New Configuration</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Name">Name <span className="required">*</span></label>
                                <input type="text" className="form-control" id="name" name="name" value={values.name} onChange={(e) => handleInputChange(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="ValueDefinition">Value Definition <span className="required">*</span></label>
                                <textarea id="valueDefinition" name="valueDefinition" cols="" rows="4" className="form-control" value={values.valueDefinition} onChange={(e) => handleInputChange(e)}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Description">Description <span className="required">*</span></label>
                                <input type="text" className="form-control" id="Description" name="description" value={values.description} onChange={(e) => handleInputChange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="DefaultValue">Default Value <span className="required">*</span></label>
                                <input type="text" className="form-control" id="DefaultValue" name="defaultValue" value={values.defaultValue} onChange={(e) => handleInputChange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Type">Type <span className="required">*</span></label>
                                <input type="text" className="form-control" id="Type" name="type" value={values.type} onChange={(e) => handleInputChange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="config">Config <span className="required">*</span></label>
                                <textarea id="config" name="config" cols="" rows="4" className="form-control" value={values.config} onChange={(e) => handleInputChange(e)}></textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" onClick={() => props.setShowModal(false)}>CLOSE</button>
                    <button type="button" className="btn btn-primary" onClick={() => saveConfig()}>SUBMIT</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConfigurationEdit;