import { useContext } from 'react';
import DeleteIcon from '../../../Assets/img/icon-red-trash.svg';
import { getFileTypeIcon, openFile } from '../../Utils';
import { UserContext } from '../../../Contexts/UserContext';
import { apiService } from '../../../Services/apiService';

const AOIPropertyDocuments = (props) => {
    const { user } = useContext(UserContext);
    const dataUrl = apiService.getDataUrl(user.region);

    return <div className="documents-list">
            {
                props.files != null && props.files.length > 0 && props.files.map((file) => (
                    <div className="document" key={file.filepath}>
                        <div className="file-row">
                            <div className="file-type">
                                <img src={getFileTypeIcon(file.filetype ?? 'doc')} 
                                    onClick={() => openFile(`${dataUrl}${file.filepath}`)}
                                    className="aoi-img" 
                                    alt={file.filename}
                                    attr-filepath={`${dataUrl}${file.filepath}`}
                                    attr-isimage='false'
                                    ref={props.thumbnailRef}
                                />
                            </div>
                            <div className="file-name">
                                <p>
                                    <a className="test" onClick={() => openFile(`${dataUrl}${file.filepath}`)}>
                                        {file.filename} {file.size && `(${formatBytes(file.size)})`}
                                    </a>
                                </p>
                                <a className="file-delete" onClick={() => props.deleteFile(file.filepath, file.filename)}>
                                    <img src={DeleteIcon} />
                                </a>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>;

};

export default AOIPropertyDocuments;