import "react-phone-input-2/lib/style.css";
import { CountryDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { Link } from 'react-router-dom';
import Timezone from "./Timezone";
import { getUserPreference } from "../Utils";
import { userService } from "../../Services/userService";
import Role from "../../Helpers/Role";
import toast from "react-hot-toast";
import { aoiService } from "../../Services/aoiService";
import { eventService } from "../../Services/eventService";
import Moment from "react-moment";
import EditEvent from "./Events/EditEvent";
import CreateEvent from "./Events/CreateEvent";
import EventDelete from "./EventDelete";


const ManageEvents = (props) => {
    const { user, setUser, showAlert } = useContext(UserContext);
    const [aois, setAois] = useState([]);
    const [aoiParts, setAoiParts] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [editEvent, setEditEvent] = useState(null);
    const [show, setShow] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedAoi, setSelectedAoi] = useState(null);
    const [selectedPart, setSelectedPart] = useState(null);

    useEffect(() => {
        reloadData();
    }, []);

    const reloadData = () => {
        setAllEvents([]);
        aoiService.getAois().then((data) => {
            console.log(data);
            setAois(data);
            data.map((aoi) => {
                eventService.getEventsByAoiId(aoi.aoiGuid).then((eventsData) => {
                    setAllEvents((oldEvents) => [...oldEvents, ...eventsData]);
                    console.log(eventsData);
                })
            });
        });
        aoiService.getAoiParts().then((data) => {
            console.log(data);
            setAoiParts(data);
        });
    };

    const renderAoiPart = (aoi, part) => {
        console.log(part);
        return <div className="profile-row aoipart event-pipeline-name" key={part.aoiPartGuid}>
            {aoi.name} - {part.name}
            <a className="add-btn-link" onClick={() => startCreating(aoi, part)}>Create Event</a>
        </div>;
    };

    const renderAoiParts = (aoi) => {
        const parts = aoiParts.filter((p) => p.aoiGuid = aoi.aoiGuid);
        return parts.map((part) => renderAoiPart(aoi, part));
    };

    const startCreating = (aoi, part) => {
        setSelectedAoi(aoi);
        setSelectedPart(part);
        setShow(2);

        let event = {
            isInternal: true,
            imageCredits: "",
            images: []
        };
        part.aoiPartsInfo.map((part) => {
            event.images.push({
                imageId: part.aoiPartInfoId,
                maxx: part.maxx,
                maxy: part.maxy,
                minx: part.minx,
                miny: part.miny,
                name: part.name,
                imagePath: ""
            });
        });
        setEditEvent(event);
    };

    const startEditing = (event) => {
        setShow(1);
        setEditEvent(event);
    };

    const startDeleting = (event) => {
        setShowDelete(true);
        setEditEvent(event);
    }

    const renderAoiEvents = (aoi) => {
        const events = allEvents.filter((e) => e.aoiGuid == aoi.aoiGuid);
        return <div>
            <h5><b>Events for {aoi.name}</b></h5>
            <div className="event-list-container">
                {
                    events.map((e) => {
                        return <div className="event-list-row">
                            <Moment date={e.eventDate} format={"DD-MMMM-YYYY"}></Moment>
                            <a className="add-btn-link" onClick={() => startEditing(e)}>Edit Event</a>
                            <a className="add-btn-link" onClick={() => startDeleting(e)}>Delete Event</a>
                        </div>;
                    })
                }
            </div>
        </div>;
    };

    const cancelEdit = () => {
        setShow(0);
        setEditEvent(null);
        setSelectedAoi(null);
        setSelectedPart(null);
    };

    const onSave = () => {
        reloadData();
        cancelEdit();
    };

    return (
        <div className="form-content">
            {
                show == 0 &&
                <div>
                    <h3>Add/Edit Event</h3>
                    <div className='edit-event-form'>
                        <div className='form event-form events-pieline-container'>
                            <div className='form-body'>
                                <div className="events-pieline-list">
                                    {
                                        aois.map((aoi) => {
                                            return renderAoiParts(aoi);
                                        })
                                    }
                                </div>
                            </div>
                            <div className='form-body'>
                                <div className="events-list">
                                    {
                                        aois.map((aoi) => {
                                            return renderAoiEvents(aoi);
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                show == 1 && editEvent != null &&
                <EditEvent event={editEvent} cancel={cancelEdit} onSave={onSave} forCreate={false} />
            }
            {
                show == 2 &&
                <EditEvent event={editEvent} aoi={selectedAoi} cancel={cancelEdit} onSave={onSave} forCreate={true} />
            }
            {
                !!showDelete &&
                <EventDelete setShowModal={setShowDelete} event={editEvent} cancel={cancelEdit} onDelete={onSave}></EventDelete>
            }
        </div>
    );
}

export default ManageEvents;