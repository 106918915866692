import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Contexts/UserContext";
import { aoiService } from "../../../Services/aoiService";
import { dateDiffInDays } from "../../Utils";
import toast from 'react-hot-toast';
import { adminService } from "../../../Services/adminService";
import { usecaseService } from "../../../Services/usecaseService";
import ViewLogo from '../../../Assets/img/icon-view-grey.svg';
import ShowJson from "../ShowJson";
import SortIcon from '../../../Assets/img/icon-sort.svg';
import LinkIcon from '../../../Assets/img/icon-link-blue.svg';
import DACockpitMap from "./DACockpitMap";
import { MapUtils } from "../../MapUtils";

const LatestEventDateAttribute = [
    { usecase: 'AoIRGBImage', attribute: 'image-date' },
    { usecase: 'AoIRGBImageV2', attribute: 'image-date' },
    { usecase: 'AoIRGBImageR1', attribute: 'image-date' }
]

let Usecases = []

const DefaultFilter = {
    usecase: '',
    bounds: { W: -180, S: -90, E: 180, N: 90 },
    include_extended_attributes: true,
    latest_event_date_attribute: ""
}

const DACockpit = () => {
    const { domainConfig, usecases, impersonateUser } = useContext(UserContext);
    Usecases = usecases.map(u => { 
        return {
            "name": u.name, 
            "displayName": domainConfig.usecases.find(d => d.name == u.name)?.displayName ?? u.name,
            "isEnabled": domainConfig.usecases.find(d => d.name == u.name)?.isEnabled ?? false
        } 
    });
    Usecases = Object.values(Usecases).sort((a,b) => a.isEnabled ? -1 : 1);
    const initialUsecase = Usecases[0]?.name ?? '';
    const initialLatestEventDateAttr = LatestEventDateAttribute.find(a => a.usecase == initialUsecase)?.attribute ?? '';
    const [filter, setFilter] = useState({...DefaultFilter, usecase: initialUsecase, latest_event_date_attribute: initialLatestEventDateAttr});
    const [aois, setAOIs] = useState([]);
    const [sortBy, setSortBy] = useState('auth-id');
    const [bounds, setBounds] = useState(DefaultFilter.bounds);
    const [boundingBoxError, setBoundingBoxError] = useState('');
    const [displayJson, setDisplayJson] = useState(false);
    const [selectedTab, setSelectedTab] = useState('list');
    const [selectedAOI, setSelectedAOI] = useState(null);
    const [showSelectedAOIPopover, setShowSelectedAOIPopover] = useState(false);
    const [serviceArea, setServiceArea] = useState(null);
    const [json, setJson] = useState({
        title: "",
        value: null
    });

    useEffect(()=>{
        getAOIs(filter);
        initServiceArea(filter.usecase);
    }, []);

    useEffect(()=>{
        sortAois(aois, sortBy);
    }, [sortBy]);

    const getAOIs = async (filter) => {
        const bounds = filter.bounds;
        const payload = {
            geometry: {
                "type": "Polygon",
                "coordinates": [[
                    [bounds.W, bounds.N],
                    [bounds.W, bounds.S],
                    [bounds.E, bounds.S],
                    [bounds.E, bounds.N],
                    [bounds.W, bounds.N]
                ]]
            },
            include_extended_attributes: filter.include_extended_attributes,
            latest_event_date_attribute: filter.latest_event_date_attribute
        }
        let aoiCollection = await adminService.getAOIsInGeometry(filter.usecase, payload).catch((error) => {
            toast.error("Error in getting AOI Details!");
            console.error("Error in getting AOI Details!", error);
        });;
        let aoiData = aoiCollection?.features ?? [];
        sortAois(aoiData, sortBy);
    }

    const sortAois = (aois, sortBy) => {
        let sortedAois = aois;
        if (sortBy == 'auth-id') {
            sortedAois = aois.sort((a1, a2)=>{
                return a1.properties['auth-id'].toUpperCase() > a2.properties['auth-id'].toUpperCase() ? 1 : -1
            })
        } 
        else if (sortBy == 'last-event-desc') {
            sortedAois = aois.sort((a1, a2)=>{
                return getDaysSinceLastEvent(a1.properties['last-event-date']) < getDaysSinceLastEvent(a2.properties['last-event-date']) ? 1 : -1
            })
        }

        setAOIs([...sortedAois]);
    }

    const onChangeUsecase = (usecase) => {
        const latestEventDateAttr = LatestEventDateAttribute.find(a => a.usecase == usecase)?.attribute ?? '';
        setFilter({...filter, usecase: usecase, latest_event_date_attribute: latestEventDateAttr});
    }

    const getDaysSinceLastEvent = (lastEventDate) => {
        if (lastEventDate != 'None') {
            return dateDiffInDays(new Date(lastEventDate), new Date());
        }
        return -1;
    }

    const getEventCost = async (aoi) => {
        const extId = aoi.properties['aoi-ext-id'];
        const usecaseName = filter.usecase;
        let aoiPlans = {};
        let usecasePlans = {};
        usecasePlans[usecaseName] = [{...aoi.properties['subscription-details']}];
        aoiPlans[extId] = usecasePlans;

        let priceData = await getAOIPricing(aoi.properties.mkey, aoi.properties.region, aoiPlans);

        let eventPrice = -1;
        if (priceData) {
            eventPrice = priceData["plans-by-item"][extId][usecaseName][0];
        }

        const aoiIndex = aois.findIndex(a => a.properties['member-aoi-id'] == aoi.properties['member-aoi-id']);
        aois[aoiIndex].properties['event_cost'] = eventPrice;
        setAOIs([...aois]);
    }

    const getAOIPricing = async (mkey, region, aoiPlans) => {
        const postData = {
          "item-type": "aoi",
          "plans-by-item": aoiPlans,
          "items-plans": {}
        };

        var priceData = await aoiService.getPricingForServices(mkey, region, postData)
        .then(data => {
            return data;
        })
        .catch(error => {
            console.log('Error in getting the AOI Price', error);
            toast.error('Failed to AOI Pricing Information!');
        });
        
        return priceData;
    };

    const goToUsecase = (aoi) => {
        adminService.getUser(aoi.properties['auth-id'].toLowerCase()).then(async (data) => {
            await impersonateUser(true, data);
            const usecase = usecaseService.getUsecaseUrl(filter.usecase);
            window.open(`/${usecase}/${aoi.properties['member-aoi-id']}`, '_blank');
        }).catch((e) => {
            toast.error("Unable to retrieve the user. Error: " + e);
            console.error(e);
        });
    }

    const copyGeoJson = (aoi) => {
        navigator.clipboard.writeText(JSON.stringify(aoi.geometry));
        toast.success("GeoJson copied to Clipboard");
    }

    const setBoundingBoxValue = (direction, value) => {   
        let boundValues = {...bounds};
        boundValues[direction] = value;
        setBounds(boundValues);
        if (isValidBounds(boundValues)) {
            setBoundingBoxError('');
        }
    }

    const isValidBounds = (bounds) => {
        if (isNaN(parseInt(bounds.W)) || isNaN(parseInt(bounds.S)) || isNaN(parseInt(bounds.E)) || isNaN(parseInt(bounds.N))) {
            setBoundingBoxError(`Invalid value in bounding box`);
            return false;
        }
        else if (bounds.N > 90) {
            setBoundingBoxError(`North should be <= 90`);
            return false;
        }
        else if (bounds.S < -90) {
            setBoundingBoxError(`South should be >= -9-`);
            return false;
        }
        else if (bounds.W < -180) {
            setBoundingBoxError(`West should be >= -180`);
            return false;
        }
        else if (bounds.E > 180) {
            setBoundingBoxError(`West should be <= 180`);
            return false;
        }
        else if (bounds.N <= bounds.S) {
            setBoundingBoxError(`North should be greater than South`);
            return false;
        }
        else if (bounds.E <= bounds.W) {
            setBoundingBoxError(`East should be greater than West`);
            return false;
        }

        return true;
    }

    const onSearchClick = () => {
        setSelectedAOI(null);
        if (isValidBounds(bounds)) {
            let searchFilter = {...filter, bounds: {W: parseInt(bounds.W), S: parseInt(bounds.S), E: parseInt(bounds.E), N: parseInt(bounds.N)}};
            getAOIs(searchFilter);
            initServiceArea(searchFilter.usecase);
        }
        else {
            toast.error('Invalid Bounding Box values');
        }
    }

    const initServiceArea = (usecaseName) => {
        const selectedUsecase = usecases.find(u => u.name == usecaseName);
        const serviceAreaGeometry = selectedUsecase?.configuration?.geographic_extent;
        if (serviceArea) {
            serviceArea.setMap(null);
        }
        
        setTimeout(()=>{
            if (serviceAreaGeometry) {
                setServiceArea(MapUtils.getServiceArea(serviceAreaGeometry));
            }
        })
    }

    const showJsonValue = (title, value) => {
        setJson({
            title: title,
            value: value
        });
        setDisplayJson(true);
    }

    const handleAOISelect = (aoi) => {
        if (selectedTab == 'list') {
            setSelectedTab('map');
        }
        setSelectedAOI(aoi);
    }

    const renderRow = (aoi) => {
        const lastEventDateTime = aoi.properties['last-event-date'];
        const daysSinceLastEvent = getDaysSinceLastEvent(lastEventDateTime) < 0 ? '-' : getDaysSinceLastEvent(lastEventDateTime);
        const lastEventDate = isNaN(Date.parse(lastEventDateTime)) ? '-' : new Date(lastEventDateTime).toISOString().split('T')[0];
        return <tr key={aoi.properties['member-aoi-id']}>
                    <td>{aoi.properties['auth-id']}</td>
                    <td>{aoi.properties['tag']}</td>
                    <td>{aoi.properties['region']}</td>
                    <td className="center">{aoi.properties['subscription-date']}</td>
                    <td className="center"><img src={ViewLogo} onClick={() => showJsonValue("Subscription Details", aoi.properties['subscription-details'])}/> </td>
                    <td style={{textAlign: 'center'}}><span title={new Date(lastEventDateTime).toUTCString()}>{lastEventDate}</span></td>
                    <td style={{textAlign: 'center'}}>{daysSinceLastEvent}</td>
                    <td style={{textAlign: 'center'}}>$ {aoi.properties['token-amount']}</td>
                    <td style={{textAlign: 'center'}}>
                        {   
                            aoi.properties.event_cost >= 0 
                            ? "$ " + aoi.properties.event_cost 
                            : <button className="btn btn-primary event-cost-btn" onClick={() => getEventCost(aoi)}>Fetch</button>
                        }
                    </td>
                    <td><a className="link" onClick={() => goToUsecase(aoi)}><img src={LinkIcon} /> Follow</a></td>
                    <td><a><div className="geo-json-copy" onClick={() => copyGeoJson(aoi)}></div></a></td>
                    <td><a><div className="marker-icon-yellow" onClick={()=> handleAOISelect(aoi)}></div></a></td>
                </tr>;
    }

    return (
        <div className="form-content da-cockpit">
            <div className="form-head">
                <h3>DA Cockpit</h3>
            </div>
            <div className="da-cockpit-controls">
                <div className="controls-left">
                    <div className='control usecase-list'>
                        <label>
                            <div>Usecase:</div>
                        </label>
                        <select className="usecase-list-options form-select" onChange={(e) => onChangeUsecase(e.target.value)} value={filter.usecase}>
                            {
                                Usecases.map(usecase => <option key={usecase.name} value={usecase.name}>{usecase.displayName}</option>)
                            }
                        </select>
                    </div>
                    <div className="search-btn">
                        <button className="btn btn-primary" onClick={onSearchClick}>Search</button>
                    </div>
                </div>
                <div className="controls-right">
                    <div className="bounding-boxes">
                        <label><b>Bounding Box: &nbsp;</b></label>
                        <table>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td className="bounding-box">N<br/><input type="text" value={bounds.N} onChange={e => setBoundingBoxValue('N', e.target.value)}/></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="bounding-box">W&nbsp; <input type="text" value={bounds.W} onChange={e => setBoundingBoxValue('W', e.target.value)}/></td>
                                    <td></td>
                                    <td className="bounding-box"><input type="text" value={bounds.E} onChange={e => setBoundingBoxValue('E', e.target.value)}/> &nbsp;E</td></tr>
                                <tr>
                                    <td></td>
                                    <td className="bounding-box"><input type="text" value={bounds.S} onChange={e => setBoundingBoxValue('S', e.target.value)}/><br/>S</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {
                        !!boundingBoxError && <div className="bounding-box-error">{boundingBoxError}</div>
                    }
                </div>       
            </div>
            <div className="da-cockpit-data-header">
                <div className="da-table-filter">
                    {
                        selectedTab == 'list' &&
                        <div className="input-group">
                            <span className="input-group-text" htmlFor="sortby">Sort by <img src={SortIcon} /></span>
                            <select className="form-select" id="sortby" value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                                <option value="auth-id">Auth ID</option>
                                <option value="last-event-desc">Days Since Last Event(Desc)</option>
                            </select>
                        </div>  
                    }
                    
                </div>
                <div className="da-cockpit-tabs">
                    <ul className="view-toggle">
                        <li className={`grid-view ${selectedTab == 'list' && 'active'}`}><a onClick={()=>setSelectedTab('list')}><i></i> <span>List</span></a></li>
                        <li className={`map-view ${selectedTab == 'map' && 'active'}`}><a onClick={()=>setSelectedTab('map')}><i></i> <span>Map</span></a></li>
                    </ul>
                </div>
            </div>
            
            <div className="da-cockpit-data">
                {
                    selectedTab == 'list' &&
                    <>
                        <div className="table-responsive">
                            <table className="table table-style">
                                <thead>
                                    <tr>
                                        <th scope="col">Auth-Id</th>
                                        <th scope="col">AOI Tag</th>
                                        <th scope="col">Region</th>
                                        <th scope="col">Subscription<br/> Date</th>
                                        <th scope="col">Subscription<br/> Details</th>
                                        <th scope="col">Date of Last<br/> Event</th>
                                        <th scope="col">Days since<br/> Last Event</th>
                                        <th scope="col">Acc Token<br/> Balance</th>
                                        <th scope="col">Event Cost</th>
                                        <th scope="col">Usecase</th>
                                        <th scope="col">GeoJSON</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        aois && aois.length > 0 && aois.map((aoi) => renderRow(aoi))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                }
                {
                    selectedTab == 'map' &&
                    <>
                        <div className="map-view-responsive">
                            <DACockpitMap aois={aois} goToUsecase={goToUsecase} 
                                selectedAOI={selectedAOI} setSelectedAOI={setSelectedAOI} 
                                showSelectedAOIPopover={showSelectedAOIPopover} setShowSelectedAOIPopover={setShowSelectedAOIPopover}
                                handleAOISelect={handleAOISelect}
                                serviceArea={serviceArea}
                            />
                        </div>
                    </>
                }
            </div>
            {
                displayJson && <ShowJson setShowModal={setDisplayJson} title={json.title} json={json.value} />
            }
        </div>
    );
}

export default DACockpit;