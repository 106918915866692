import {React, useContext, useEffect, useState} from 'react';
import { UserContext } from '../Contexts/UserContext';
import { userService } from '../Services/userService';
import { useNavigate } from 'react-router';
import Cookies from 'js-cookie';

const appConfig = window.config;

const LoadLoginSession = () => {
  const {reloadLoginSession} = useContext(UserContext);
  useEffect(() => {
      reloadLoginSession();
    }, []);
    return <div> <h1>Loading ......</h1> </div>;
  };


  const LoginAuth = (props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const {setUser} = useContext(UserContext);
    const navigate = useNavigate();

    const login = () => {
      userService.login(username, password).then((result) => {
        console.log(result);
        window.authToken = result.accessToken;
        window.expiryTime = result.expiryTime;
        updateUser(result.user);
        Cookies.remove("authtoken");
        Cookies.remove("expiryTime");
        localStorage.removeItem("authtoken");
        localStorage.removeItem("expiryTime");
        Cookies.set("authtoken", result.accessToken);
        Cookies.set("expiryTime", result.expiryTime);
        localStorage.setItem("authtoken", result.accessToken);
        localStorage.setItem("expiryTime", result.expiryTime);
        if(props.complete)
          props.complete();
        navigate("/timelinev2/e759afee-a9a7-4b1b-80b1-9cd535660282");
      }).catch((e) => {
        console.log(e);
        setError(true);
      });
    };

    const updateUser = (result) => {
      setUser({
        email: result.userName,
        mkey: result.userName,
        isAuthenticated: true,
        userSettings: result.settings ? JSON.parse(result.settings) : {},
        ...result
      });
    };

    const ifEnterLogin = (event) => {
      if(event.key == 'Enter') {
        login();
      }
    };

    return (
      <div className="form-box">
            {
                !!error && 
                <div class="alert alert-danger align-items-center" id="signInError" role="alert">
                  Invalid Credentials
                </div>
            }
            <div className="mb-3">
                <label for="username" className="form-label">Email address</label>
                <input type="text" required="" value={username} className="form-control" id="username" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div className="mb-3">
                <label for="password" className="form-label">Password</label>
                <input type="password" required="" value={password} className="form-control" id="password" placeholder="Password"  onChange={(e) => setPassword(e.target.value)} onKeyDown={ifEnterLogin} />
            </div>

            <button type="submit" className="btn btn-primary" id="signInBtn" onClick={login}>Sign In</button>
        </div>
    );
}

export default LoginAuth;