import React, { useContext, useState } from 'react';

import { Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import toast from 'react-hot-toast';
import { UserContext } from '../../../Contexts/UserContext';

import AOIGroupAddEditAOIs from './AOIGroupAddEditAOIs';
import AOIGroupAddEditImport from './AOIGroupAddEditImport';

const AOIGroupAddEdit = (props) => {
    const {reloadAOIs} = useContext(UserContext);
    const [groupName, setGroupName] = useState(props.group?.tag ?? "");
    const [groupDesc, setGroupDesc] = useState(props.group?.description ?? "");
    const [reloadRequired, setReloadRequired] = useState(false);

    const isValidGroupName = () => {
        if(groupName.trim() == "") {
            toast.error("Please enter a name for the group");
            return false;
        }
        if(props.group?.tag != groupName) {
            if(props.userGroups.filter(e => e.tag == groupName).length > 0) {
                toast.error("A group already exists with that name.");
                return false;
            }
        }
        return true;
    };

    const closePopup = () => {
        if(reloadRequired)
            reloadAOIs();
        props.show(false);
    }

    return (
        <>
            <Modal
                show={() => props.show(true)}
                onHide={() => closePopup()}
                backdrop='static'
                keyboard={false}
                dialogClassName=''
                className='aoi-group-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create AOI Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="groupName">Group Name <span className="required">*</span></label>
                                <input type="text" className="form-control" id="groupName" value={groupName} placeholder="Please enter Name" onChange={(e) => setGroupName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="groupDesc">Description</label>
                                <input type="text" className="form-control" id="groupDesc" value={groupDesc} placeholder="Description" onChange={(e) => setGroupDesc(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <Tabs>
                        <Tab eventKey="chooseAOIs" title="Choose AOIs">
                            <AOIGroupAddEditAOIs groupName={groupName} groupDesc={groupDesc} show={props.show} refresh={props.refresh} group={props.group} isValidGroupName={isValidGroupName}/>
                        </Tab>
                        {
                            props.group == null &&
                            <Tab eventKey="import" title="Import">
                            <AOIGroupAddEditImport groupName={groupName} groupDesc={groupDesc} show={props.show} refresh={props.refresh} reloadRequired={setReloadRequired} isValidGroupName={isValidGroupName} />
                        </Tab>
                        }
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AOIGroupAddEdit;