import { useContext } from "react";
import { AOIContext } from "../../Contexts/AOIContext";

const AppliedFilters = (props) => {
    const {clearFilters} = useContext(AOIContext);
    const {filter, setFilter, view} = props;

    const clearText = () => setFilter("filterText", "");
    const clearSubscribe = () => setFilter("subscribed", "all");
    const clearSelectedGroup = () => setFilter("selectedGroup", null);

    const onClearAll = () => {
        clearFilters();
    }

    return (
        <div className="container">
            {
                ((view != "group" && (!!filter.filterText || filter.subscribed != "all")) || !!filter.selectedGroup) &&
                <div className="applied-filters">
                    <b>Applied Filters:</b>
                    {
                        filter.subscribed != "all" &&
                        <div className="selected-filter">
                            <span>Subscription:</span> {filter.subscribed.replace(/^./, (str) => str.toUpperCase())} 
                            <i className="remove" onClick={clearSubscribe}>X</i>
                        </div>
                    }
                    {
                        view != "group" && !!filter.filterText &&
                        <div className="selected-filter">
                            <span>Search Term:</span> {filter.filterText} <i className="remove" onClick={clearText}>X</i>
                        </div>
                    }
                    {
                        !!filter.selectedGroup &&
                        <div className="selected-filter">
                            <span>Group:</span> {filter.selectedGroup?.tag ?? ""} <i className="remove" onClick={clearSelectedGroup}>X</i>
                        </div>
                    }
                    <a className="clear-all" onClick={onClearAll}>Clear All</a>
                </div>
            }
        </div>
    );
}

export default AppliedFilters;