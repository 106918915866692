import { useState } from 'react';
import IconUnsubscribe from '../Assets/img/icon-email-unsubscribe.svg';
import { useNavigate, useParams } from 'react-router';
import { memberService } from '../Services/memberService';
import { toast } from 'react-hot-toast';

const Unsubscribe = () => {
    const [state, setState] = useState(1);
    const navigate = useNavigate();
    let { region, code, type } = useParams();

    const optout = () => {
        memberService.optout(region, code).then((data) => {
            setState(2);
        }).catch((error) => {
            toast.error("Unable to unsubscribe to the service.");
            console.log(error);
        })
    };

    const cancel = () => {
        navigate("/");
    };

    return (
        <div className="email-unsubscribe">
            {
                state == 1 ?
                <>
                    <img src={IconUnsubscribe} alt="" />
                    <h3><span>You will not receive any emails other than for the services you have subscribed to.</span> Do you want to proceed?</h3>
                    <div className="btn-container">
                        <button type="button" className="btn btn-primary" onClick={optout}>YES</button>
                        <button type="button" className="btn btn-secondary" onClick={cancel}>NO</button>
                    </div>
                </>
                :
                <>
                    <img src="img/icon-email-unsubscribe.svg" alt="" />
                    <h3>
                        Your email preferences are changed.
                        <span>You will not receive any email other than for services you purchased (if any).</span>
                    </h3>
                </>
            }
        </div>
    );
}

export default Unsubscribe;