import { useContext } from "react";
import { AOIContext } from "../../Contexts/AOIContext";
import { AOIWizzardContext } from "../../Contexts/AOIWizzardContext";
import { UserContext } from '../../Contexts/UserContext';
import AOIServicesForm from "./AOIServicesForm";
import AOIViewDetails from "./AOIDetails/AOIViewDetails";

const AOIWizzardContent = (props) => {
    const {domainConfig, selectedAOI, showAlert, openAOIWizzardModal } = useContext(AOIContext);
    const { user } = useContext(UserContext);

    const goBack = () => props.setActiveStep(1);
    const goNext = () => props.setActiveStep(2);

    const aoiWizzardContextValues = {
        domainConfig,
        user,
        selectedAOI,
        activeStep: props.activeStep,
        setActiveStep: props.setActiveStep,
        goBack, goNext,
        showAlert,
        openAOIWizzardModal
    }
    return (
        <AOIWizzardContext.Provider value={{...aoiWizzardContextValues}} >
            <>
                {props.activeStep == 1 && <AOIViewDetails mode={props.mode} aoi={selectedAOI} />}
                {props.activeStep == 2 && <AOIServicesForm availableServices={props.availableServices} />}
            </>
        </AOIWizzardContext.Provider>
        
    );
}

export default AOIWizzardContent;