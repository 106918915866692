import React from 'react';
import { Modal } from 'react-bootstrap';
import SettingsEdit from './SettingsEdit';

const SettingsModal = (props) => {

    return (
        <>
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop='static'
                keyboard={false}
                dialogClassName=''
                className='member-settings'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SettingsEdit type="admin" configuration={props.configuration} mkey={props.mkey} region={props.region} 
                        close={() => props.setShowModal(false)} refresh={props.refresh} isReadOnly={props.isReadOnly}>
                    </SettingsEdit>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SettingsModal;