import React, { useState, useEffect } from 'react';
import toast from "react-hot-toast";
import { eventService } from "../../../Services/eventService";
import Moment from "react-moment";
import ReactDatePicker from 'react-datepicker';


const EditEvent = (props) => {
    const [editEvent, setEditEvent] = useState(props.event);
    const [paths, setPaths] = useState({});

    useEffect(() => {
        console.log(props);
        let paths = {};
        props.event.images.map((image) => {
            paths[image.eventImageId] = image.imagePath;
        });
        setPaths(paths);
    }, [props.event]);

    const changeImagePath = (imageId, path) => {
        let updatedPaths = { ...paths, [imageId]: path };
        setPaths(updatedPaths);
    };

    const getUpdatedEvent = () => {
        let updatedEvent = { ...editEvent };
        updatedEvent.images = [];
        editEvent.images.map((image) => {
            updatedEvent.images.push({ ...image, imagePath: paths[image.eventImageId] });
        });
        return updatedEvent;
    };

    const save = () => {
        let updatedEvent = getUpdatedEvent();
        if (props.forCreate)
            createEvent(updatedEvent);
        else
            saveEditEvent(updatedEvent);
    };

    const saveEditEvent = (updatedEvent) => {
        eventService.updateEvent(editEvent.eventGuid, updatedEvent)
            .then((data) => {
                toast.success("Updated the Event Successfully");
                props.onSave();
            });
    };

    const createEvent = (updatedEvent) => {
        eventService.createEvent(props.aoi.aoiGuid, updatedEvent)
            .then((data) => {
                toast.success("Created the Event Successfully");
                props.onSave();
            });
    };

    return (
        <div className="form-content">
            <div>
                {
                    !!props.forCreate
                        ? <h3>Create Event for {props.aoi?.name}</h3>
                        : <h3>Edit Event - <Moment date={editEvent.eventDate} format={"DD-MMMM-YYYY"}></Moment></h3>
                }
                <div className='edit-pipeline-event-container'>
                    {
                        !!props.forCreate &&
                        <div className='edit-pipeline-event-field'>
                            Event Date <ReactDatePicker selected={editEvent.eventDate} onChange={(date) => setEditEvent({ ...editEvent, eventDate: date })} dateFormat="P" />
                        </div>
                    }
                       <div className='edit-pipeline-event-field'>Is Internal Event <input type="checkbox" checked={editEvent.isInternal} onChange={(e) => setEditEvent({ ...editEvent, isInternal: e.target.checked })} /></div>
                       <div className='edit-pipeline-event-field'>Image Credits <input type="text" className="form_input" value={editEvent.imageCredits} onChange={(e) => setEditEvent({ ...editEvent, imageCredits: e.target.value })} /></div>
                </div>
                {
                    editEvent.images.map((image) => {
                        return <div className='event-pipeline-image-row'>
                            <div className='event-pipeline-labels'>
                                <div className='event-pipeline-label-row'><label>Name:</label> {image.name}</div>
                                <div className='event-pipeline-label-row'><label>Maxx:</label> {image.maxx}</div>
                                <div className='event-pipeline-label-row'><label>Maxy:</label> {image.maxy}</div>
                                <div className='event-pipeline-label-row'><label>Minx:</label> {image.minx}</div>
                                <div className='event-pipeline-label-row'><label>Miny:</label> {image.miny}</div>
                            </div>
                            <div className='event-pipeline-input'>
                                <input type="text" value={paths[image.eventImageId]} className="form_input"
                                    onChange={(e) => changeImagePath(image.eventImageId, e.target.value)} />
                            </div>
                        </div>;
                    })
                }
                <div className='btn-area'>
                <button type="button" className="btn btn-outline" onClick={() => props.cancel()}>CANCEL</button>
                <button type="button" className='btn btn-primary ml-auto submit-btn' onClick={() => save()}>SAVE</button>
                </div>
            </div>
        </div>
    );
}

export default EditEvent;