const AOIWizzardSteps = (props) => {

    return (
        <>
            <div className="aoi-wizzard-steps wizzard-steps aoi-wizzard">
                <div className="container">
                    <div className="wizzard-steps">
                        <ul id="wizzard">
                            <li className={`${props.activeStep == 1 && 'active'}`}>
                                <a onClick={() => props.setActiveStep(1)}>
                                    <i></i>
                                    <span>AOI Information</span>
                                </a>
                            </li>
                            <li className={`${props.activeStep == 2 && 'active'}`}>
                                <a onClick={() => props.setActiveStep(2)}>
                                    <i></i>
                                    <span>Services</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AOIWizzardSteps;