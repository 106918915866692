import { useContext, useState } from 'react';
import PlusIcon from '../../../Assets/img/icon-plus-cirlce.png';
import { UserContext } from '../../../Contexts/UserContext';
import AppliedFilters from '../AppliedFilters';
import AOIGroupAddEdit from './AOIGroupAddEdit';
import AOIGroupSingle from './AOIGroupSingle';

const AOIGroupView = (props) => {
    const {user, reloadGroups} = useContext(UserContext);
    const [showCreate, setShowCreate] = useState(false);
    
    const refresh = () => {
        reloadGroups();
    };

    return (
        <>
            <div className="grid-container group-aoi">
              <AppliedFilters view="group" filter={props.filter} setFilter={props.setFilter}/>
              <div className="container">
                {
                    user.isReadOnly == false &&
                    <div className="row">
                        <div className="col-xs-12 col-md-6 col-xl-4">
                            <div className="aoi-card create-group">
                                <a className="add-btn" onClick={() => setShowCreate(true)}>
                                    <img src={PlusIcon} alt="" />
                                    Create AOI Group
                                </a>
                            </div>
                        </div>
                    </div>
                }
                {
                    props.selectedGroup != null
                    ? <AOIGroupSingle group={props.selectedGroup} key={props.selectedGroup.ext_id} refresh={refresh} userGroups={props.userGroups} />
                    : props.userGroups.map((group) => <AOIGroupSingle group={group} key={group.ext_id} refresh={refresh} userGroups={props.userGroups} />)
                }
              </div>
            </div>
            {
                showCreate && <AOIGroupAddEdit show={(show) => setShowCreate(show)} refresh={refresh} userGroups={props.userGroups} />
            }
        </>
    );
}

export default AOIGroupView;