import { useEffect, useState } from "react";


let shamshabadBuildingPromise = null;

const fnFlyTo = (position, orientation) => {
    return {
        destination: Cesium.Cartesian3.fromRadians(position.lon, position.lat, position.height),
        orientation: {
            heading: orientation.heading,
            pitch: orientation.pitch,
            roll: orientation.roll
        },
        duration: 0
    }
}

const CesiumUI = (props) => {

    useEffect(async () => {
        const divElement = document.getElementById('cesiumContainer');
        divElement.innerHTML = '';
        Cesium.Ion.defaultAccessToken = props.accessToken;

        const viewer = new Cesium.Viewer("cesiumContainer", {
            terrain: Cesium.Terrain.fromWorldTerrain(),
            baseLayer: Cesium.ImageryLayer.fromWorldImagery({
              style: props.satelliteImagery.visibility ? Cesium.IonWorldImageryStyle.AERIAL : Cesium.IonWorldImageryStyle.ROAD
            }),
        });
        viewer.animation.container.style.visibility = 'hidden';
        viewer.timeline.container.style.visibility = 'hidden';
        viewer.forceResize();
        props.setViewer(viewer);

        const scene = viewer.scene;
        const globe = scene.globe;
        globe.tileCacheSize = 200000;
        globe.maximumScreenSpaceError = 1.02;

        viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
            function(e) {
                e.cancel = true;
                viewer.scene.camera.flyTo(fnFlyTo(props.homePosition, props.homeOrientation));
            });


        // viewer.camera.changed.addEventListener(function() {
        //     console.log(viewer.camera.positionCartographic);
        //     console.log(viewer.camera.heading);
        //     console.log(viewer.camera.pitch);
        //     console.log(viewer.camera.roll);
        // });

        if (props.rightclickToGoogleMaps) {
            // scene.canvas.addEventListener('contextmenu', (event) => {
            //     event.preventDefault();
            //     const mousePosition  = new Cesium.Cartesian2(event.clientX, event.clientY);
            //     const selectedLocation = viewer.scene.pickPosition(mousePosition);
            //
            //     // setMarkerInPos(Cesium.Cartographic.fromCartesian(selectedLocation));
            //     openInNewTab(Cesium.Cartographic.fromCartesian(selectedLocation));
            // }, false);
            //
            // function setMarkerInPos(positionCartographic){
            //     viewer.pickTranslucentDepth = true;
            //     const locationMarker = viewer.entities.add({
            //         name : 'location',
            //         position : Cesium.Cartesian3.fromRadians(positionCartographic.longitude, positionCartographic.latitude, 10),
            //         point : {
            //             pixelSize : 5,
            //             color : Cesium.Color.RED,
            //             outlineColor : Cesium.Color.WHITE,
            //             outlineWidth : 2,
            //             heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND
            //         },
            //         label : {
            //             text : 'check',
            //             font : '14pt monospace',
            //             style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            //             outlineWidth : 2,
            //             verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
            //             pixelOffset : new Cesium.Cartesian2(0, -9),
            //             heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND
            //         }
            //     });
            // }

            viewer.screenSpaceEventHandler.setInputAction(function onLeftClick(movement) {
                const pickedFeature = viewer.scene.pick(movement.position);
                if (Cesium.defined(pickedFeature)){
                    const ellipsoid = scene.globe.ellipsoid;
                    const cartesian = scene.camera.pickEllipsoid(movement.position, ellipsoid);
                    openInNewTab(ellipsoid.cartesianToCartographic(cartesian));
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            function openInNewTab(positionCartographic){
                window.open(`http://maps.google.com/maps?z=12&t=m&q=loc:${Cesium.Math.toDegrees(positionCartographic.latitude)}+${Cesium.Math.toDegrees(positionCartographic.longitude)}`, "_blank", "noreferrer");
            }
        }

        if (props.runWayLayer.visibility) {
            // const airportElevation = {'Shamshabad': 555, 'Begumpet': 470, 'Nadergul': 483};
            Cesium.GeoJsonDataSource.clampToGround = true;
            const resource = await Cesium.IonResource.fromAssetId(2278043);
            await Cesium.GeoJsonDataSource.load(resource).then(function (dataSource) {
                viewer.dataSources.add(dataSource);
                const entities = dataSource.entities.values;
                for (let i = 0; i < entities.length; i++) {
                    const entity = entities[i];
                    entity.polygon.material = Cesium.Color.fromBytes(255, 0, 0, 255);
                    entity.polygon.classificationType = Cesium.ClassificationType.TERRAIN;
                }
            }).catch(function (error) {
                window.alert(error);
            });
        }



        if (props.terrainOnly) {
            globe.terrainExaggeration = 5.0;
            globe.terrainExaggerationRelativeHeight = 500.0;
            function updateMaterial() {
                if (true) {
                    const height = globe.terrainExaggerationRelativeHeight;
                    const exaggeration = globe.terrainExaggeration;
                    const alpha = Math.min(1.0, exaggeration * 0.25);
                    const layer = {
                        extendUpwards: true,
                        extendDownwards: true,
                        entries: [
                            {
                                height: height + 50.0,
                                color: new Cesium.Color(1.0, 0.0, 0.0, alpha * 0.25),
                            },
                            {
                                height: height + 25.0,
                                color: new Cesium.Color(1.0, 1.0, 1.0, alpha * 0.5),
                            },
                            {
                                height: height,
                                color: new Cesium.Color(1.0, 1.0, 1.0, alpha * 0.5),
                            },
                            {
                                height: height - 25.0,
                                color: new Cesium.Color(1.0, 1.0, 1.0, alpha * 0.5),
                            },
                            {
                                height: height - 50.0,
                                color: new Cesium.Color(0.0, 1.0, 0.0, alpha * 0.25),
                            },
                        ],
                    };
                    scene.globe.material = Cesium.createElevationBandMaterial({
                        scene: scene,
                        layers: [layer],
                    });
                } else {
                    scene.globe.material = undefined;
                }
            }
            updateMaterial();
            viewer.scene.camera.flyTo(
                fnFlyTo(
                    {lon: 1.3700993532150547, lat: 0.3029318226525484, height: 13758.74701674928},
                    {heading: 5.9386883936290795, pitch: -0.7843232730402332, roll: 6.282662689680111}
                ));
            return;
        }

        // const resource = await Cesium.IonResource.fromAssetId(2276547);
        // const dataSource = Cesium.GeoJsonDataSource.load(resource);
        // dataSource.then(function(dataSource) {
        //     viewer.dataSources.add(dataSource);
        //     var entities = dataSource.entities.values;
        //     for (var i = 0; i < entities.length; i++) {
        //         var entity = entities[i];
        //         entity.polygon.extrudedHeight = 616.5 + -77.44 + 3;
        //     }
        // });

        if (props.osmBuildings.visibility) {
            const osmBuildingsTileset = await Cesium.createOsmBuildingsAsync();
            viewer.scene.primitives.add(osmBuildingsTileset);
        }

        if (props.srtm.visibility) {
          const srtmLayer = viewer.imageryLayers.addImageryProvider(
                  await Cesium.IonImageryProvider.fromAssetId(2272870)
          );
          srtmLayer.alpha = props.srtm.alpha;
        }

        if (props.geoid.visibility) {
            const geoidlayer = viewer.imageryLayers.addImageryProvider(
                await Cesium.IonImageryProvider.fromAssetId(2278625)
            );
            geoidlayer.alpha = props.geoid.alpha;
        }

        if (props.permissibleHeights.visibility) {
            const permissibleHeightsLayer = viewer.imageryLayers.addImageryProvider(
                await Cesium.IonImageryProvider.fromAssetId(2273906)
            );
            permissibleHeightsLayer.alpha = props.permissibleHeights.alpha;
        }

        if (props.zones.visibility) {
            const geoJSONURL = await Cesium.IonResource.fromAssetId(2276971);
            const promise = Cesium.GeoJsonDataSource.load(geoJSONURL);
            promise
                .then(function (dataSource) {
                    viewer.dataSources.add(dataSource);

                    const entities = dataSource.entities.values;

                    const zoneAlpha = props.zones.alpha;
                    const colorHash = {
                        'NOC': Cesium.Color.fromBytes(250, 107, 116, zoneAlpha * 255),
                        '565M': Cesium.Color.fromBytes(100, 244, 218, zoneAlpha * 255),
                        '580M': Cesium.Color.fromBytes(234, 226, 80, zoneAlpha * 255),
                        '600M': Cesium.Color.fromBytes(240, 168, 230, zoneAlpha * 255),
                        '620M': Cesium.Color.fromBytes(19, 73, 36, zoneAlpha * 255),
                        '640M': Cesium.Color.fromBytes(125, 122, 194, zoneAlpha * 255),
                        '660M': Cesium.Color.fromBytes(209, 201, 201, zoneAlpha * 255),
                        '675M': Cesium.Color.fromBytes(239, 116, 22, zoneAlpha * 255),
                        '700M': Cesium.Color.fromBytes(226, 163, 27, zoneAlpha * 255),
                        '720M': Cesium.Color.fromBytes(181, 132, 73, zoneAlpha * 255),
                        '740M': Cesium.Color.fromBytes(68, 92, 230, zoneAlpha * 255),
                        '760M': Cesium.Color.fromBytes(118, 212, 111, zoneAlpha * 255)
                    };

                    for (let i = 0; i < entities.length; i++) {
                        const entity = entities[i];
                        const permissible_elev = entity.properties.permissible_elev;
                        entity.polygon.material = colorHash[permissible_elev];
                        entity.polygon.outline = false;
                        entity.polygon.extrudedHeight = entity.properties.ellipsoidal_height * props.extrusionFactor;
                    }
                })
                .catch(function (error) {
                    //Display any errrors encountered while loading.
                    console.log(error);
                });
        }

        if (props.shamshabadBuildings.visibility) {
            if (!shamshabadBuildingPromise) {
                const resource = await Cesium.IonResource.fromAssetId(2277140);
                shamshabadBuildingPromise = Cesium.GeoJsonDataSource.load(resource);
            }

            shamshabadBuildingPromise
                .then(function (dataSource) {
                    viewer.dataSources.add(dataSource);

                    const entities = dataSource.entities.values;

                    const colorHash = {
                        'red': Cesium.Color.fromBytes(255, 0, 0, 1 * 255),
                        'green': Cesium.Color.fromBytes(0, 255, 0, 1 * 255),
                        'orange': Cesium.Color.fromBytes(255, 165, 0, 0.7 * 255),
                        'purple': Cesium.Color.fromBytes(128,0,128, 1 * 255),
                    };

                    for (let i = 0; i < entities.length; i++) {
                        const entity = entities[i];
                        entity.polygon.outline = false;
                        if (entity.properties.original_record_id.toString() ===  '29109' && props.date === 'Sep 15th 2023' && entity.properties.color.toString() === 'orange') {
                            entity.polygon.material = colorHash['red'];
                            entity.polygon.extrudedHeight = entity.properties.extrusion_height + 5;

                            let center = Cesium.BoundingSphere.fromPoints(entity.polygon.hierarchy._value.positions).center;
                            let cartographic = Cesium.Cartographic.fromCartesian(center);
                            viewer.entities.add({
                                name : 'location',
                                position : Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, entity.properties.extrusion_height + 10),
                                point : {
                                    pixelSize : 10,
                                    color : Cesium.Color.YELLOW,
                                    outlineColor : Cesium.Color.BLUE,
                                    outlineWidth : 4
                                },
                                label : {
                                    text : 'New Violation',
                                    font : '14pt monospace',
                                    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                                    outlineWidth : 2,
                                    verticalOrigin : Cesium.VerticalOrigin.BOTTOM,
                                    pixelOffset : new Cesium.Cartesian2(0, -15)
                                }
                            });

                        } else {
                            entity.polygon.material = colorHash[entity.properties.color];
                            entity.polygon.extrudedHeight = entity.properties.extrusion_height;
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }

        viewer.scene.camera.flyTo(fnFlyTo(props.position, props.orientation));

        }, [props.counter]);

    return (
        <div id="cesiumContainer"></div>
    );
}

export default CesiumUI;