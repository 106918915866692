import { useEffect, useState } from "react";

const TimelineBar = (props) => {
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [date, setDate] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [days, setDays] = useState([]);

    useEffect(() => {
        if(props.initialTimeline) {
            props.selectTimeline(props.timelineType, props.initialTimeline);
            const initialDate = new Date(props.initialTimeline.eventDate);

            setYear(initialDate.getFullYear());
            setSelectedYear(initialDate.getFullYear());
            setMonth(initialDate.getMonth());
            setSelectedMonth(initialDate.getMonth());
            setDate(initialDate.getDate());

            var ldays = [];
            var timelines = props.yearMonthToTimeline[initialDate.getFullYear() * 100 + initialDate.getMonth()];
            for(var i in timelines) {
                var timeline = timelines[i];
                if(timeline && timeline.eventDate) {
                    ldays.push(new Date(timeline.eventDate).getDate());
                }
            }
            setDays([...new Set(ldays)]);
        }
    }, [props.initialTimeline]);

    const getMonthName = (monthNo) => {
        switch(monthNo) {
            case 0: return "Jan";
            case 1: return "Feb";
            case 2: return "Mar";
            case 3: return "Apr";
            case 4: return "May";
            case 5: return "Jun";
            case 6: return "Jul";
            case 7: return "Aug";
            case 8: return "Sep";
            case 9: return "Oct";
            case 10: return "Nov";
            case 11: return "Dec";
        }
    }

    const selectYear = (cyear) => {
        setYear(cyear);
        setMonth(props.yearToMonth[cyear][0]);
        props.selectTimeline(props.timelineType, null, null, cyear);
    }

    const selectDate = (timeline, cdate) => {
        setDate(cdate);
        setSelectedYear(year);
        setSelectedMonth(month);
        props.selectTimeline(props.timelineType, timeline);
    }

    const selectMonth = (cmonth) => {
        setMonth(cmonth);
        props.selectTimeline(props.timelineType, null, cmonth, year);
    }

    const getMonthClass = (cmonth) => {
        let mclass = "month";
        if(selectedMonth == cmonth && year == selectedYear) {
            mclass = mclass + " active";
        }
        if(cmonth == month) {
            mclass = mclass + " clicked";
        }
        return mclass;
    };

    const renderMonth = (cmonth) => {
        return <div 
                    className={getMonthClass(cmonth)} 
                    onClick={() => selectMonth(cmonth)}
                    key={cmonth}>
                    {getMonthName(cmonth)}
                </div>;
    }

    const renderDate = (timeline) => {
        const selectedDate = new Date(timeline.eventDate).getDate();
        return <div 
                    className={date == selectedDate && year == selectedYear && month == selectedMonth ? "day active" : "day"}
                    onClick={() => selectDate(timeline, selectedDate)}
                    key={selectedDate}>
                    {selectedDate}
                </div>;
    };

    const renderDates = (year, month) => {
        if(!props.yearMonthToTimeline || !props.yearMonthToTimeline[year*100+month])
            return null;

        var timelines = props.yearMonthToTimeline[year * 100 + month];
        var ldays = [];
        var utimelines = {};
        for(var i in timelines) {
            var timeline = timelines[i];
            if(timeline && timeline.eventDate) {
                const day = new Date(timeline.eventDate).getDate(); 
                if(!ldays.includes(day)) {
                    ldays.push(day);
                    utimelines[day] = timeline;
                }
            }
        }
        var days = [...new Set(ldays)];
        return days.map((day) => renderDate(utimelines[day]));
    };

    return <>
                    <div className="year-selector">
                        <select className="form-select" value={year} onChange={(e) => selectYear(e.target.value)}>
                            {
                                props.years && props.years.slice().reverse().map((year) => <option value={year} key={year}>{year}</option>)
                            }
                        </select>
                    </div>
                    <div className="months-days">
                        <div className="month-list">
                            {
                                props.yearToMonth && 
                                props.yearToMonth[year] &&
                                props.yearToMonth[year].map((month) => renderMonth(month))
                                
                            }
                        </div>
                        <div className="day-list">
                            {
                                renderDates(year, month)
                                // props.yearMonthToTimeline &&
                                // props.yearMonthToTimeline[year * 100 + month] &&
                                // props.yearMonthToTimeline[year * 100 + month].map((timeline) => renderDate(timeline))
                            }
                        </div>
                    </div>
            </>;
};

export default TimelineBar;