import React, { useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { getFileBlobUrl } from '../Utils';
import { useState } from 'react';
import { useCallback } from 'react';

const defaultOptions = {
    images: [],
    showPlayButton: false,
    showGalleryPlayButton: false,
    selectedFilename: ''
}

const ImageGalleryMain = (props) => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const options = {...defaultOptions, ...props.galleryOptions};

    useEffect(async ()=>{
      // Load the blob images
      setIsLoading(true);
      let galleryImages = [];
      for (const image of options.images) {
        if (!!image && image.filepath) {
          let blobUrl = await getFileBlobUrl(image.filepath);
          galleryImages.push({ original: blobUrl, thumbnail: blobUrl, description: image.filename});
        }
      }
      setItems(galleryImages);
      setIsLoading(false);
    }, []);

    const galleryRef = useCallback(ref => {
      if (ref !== null) {
        if (options.selectedFilename) {
          const selectedImageIndex = options.images.findIndex(image => image.filename == options.selectedFilename);
          ref.slideToIndex(selectedImageIndex);
        }
      }
    }, []);

    return (
      <>
        {
          isLoading ? 
          <h3><center>Please wait...</center></h3>
          :
          <>
            {
              items.length > 0 ?
              <ImageGallery 
                ref={galleryRef}
                items={items} 
                showPlayButton={options.showPlayButton}
                showGalleryPlayButton={options.showGalleryPlayButton}
              />
              :
              <p>File could not be loaded</p>
            }
          </>
        }
      </>
    );
}

export default ImageGalleryMain;