import {Modal} from 'react-bootstrap';
import React, {useContext} from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../Contexts/UserContext';
import { eventService } from '../../Services/eventService';

const EventsDelete = (props) => {
    const {user} = useContext(UserContext);

    const deleteEvents = () => {
        eventService.deleteAllEvents(user.region, props.aoi.member_aoi_id, props.usecase).then(() => {
            toast.success(`The events of ${props.aoi.tag} have been deleted`);
            props.show(false);
            props.refresh();
        }).catch((e) => {
            toast.error(`Unable to delete the events of AOI ${props.aoi.tag}. Error: ${e}`);
        });
    };

    return (
        <>
            <Modal
                show={() => props.show(true)}
                onHide={() => props.show(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Delete all the Events</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="delete-member">
                        <h6>Are you sure you want to delete all the events for the AOI <strong>{props.aoi.tag}</strong> and refresh them.</h6>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.show(false)}>CANCEL</button>
                    <button type="button" className="btn btn-danger" onClick={() => deleteEvents()}>DELETE</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EventsDelete;