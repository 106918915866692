import { GoogleMap, InfoWindow, Marker, Polygon, Rectangle, useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import toast from 'react-hot-toast';
import { AOIOptions } from "../../AOIOptions";
import { useRef } from "react";
import { MapUtils } from "../../MapUtils";
import { Link } from "react-router-dom";
import LinkIcon from '../../../Assets/img/icon-link-blue.svg';
import YellowMapPinIcon from '../../../Assets/img/icon-map-pin-yellow.png';
import BlueMapPinIcon from '../../../Assets/img/icon-map-pin-blue.png';
import RectangleIcon from '../../../Assets/img/icon-drawing.svg';

const appConfig = window.config;
const MarkerIcon = { url: YellowMapPinIcon, scaledSize: { width: 32, height: 52 } };
const BoundsMarkerIcon = { url: BlueMapPinIcon, scaledSize: { width: 32, height: 52 } };

const rectangleOptions = {
    fillColor: "lightblue",
    fillOpacity: 0.15,
    strokeColor: "#b901ae",
    strokeOpacity: 1,
    strokeWeight: 10    ,
    clickable: true,
    draggable: true,
    editable: false,
    geodesic: true,
    zIndex: 2
};

const DACockpitMap = (props) => {
    const mapRef = useRef();
    const rectRef = useRef();
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [aois, setAois] = useState([]);
    const [showBounds, setShowBounds] = useState(false);
    const [bounds, setBounds] = useState(null);
    const [boundsCenter, setBoundsCenter] = useState(null);
    const [boundsMarker, setBoundsMarker] = useState(null);
    const [showBoundsPopover, setShowBoundsPopover] = useState(false);
    const [boundsGeoJson, setBoundsGeoJson] = useState({});
    const [boundsInKmSelect, setBoundsInKmSelect] = useState("");
    const [boundsInKmInput, setBoundsInKmInput] = useState("");
    const [markerMap, setMarkerMap] = useState({});
    const [showServiceArea, setShowServiceArea] = useState(false);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: appConfig.mapApiKey,
        libraries
    });

    useEffect(()=>{
        setMarkerMap({});
        props.setShowSelectedAOIPopover(false);
        setAois(props.aois);    
        if (mapRef.current && aois.length > 0) {
            fitBoundsForAOIs(props.aois);
        }    
    }, [props.aois]);

    useEffect(() => {
        if (props.serviceArea) {
            props.serviceArea.setMap(null);
        }
        if (showServiceArea) {
            setTimeout(() => {
                setServiceArea();
            });
        } 
    }, [showServiceArea, props.serviceArea]);

    useEffect(() => {
        if (props.selectedAOI) {
            goToAOI(props.selectedAOI);
        }
    }, [props.selectedAOI])

    const onMapLoad = (map) => {
        // Store the Map reference in mapRef
        mapRef.current = map;
        setIsMapLoaded(true);
        if (!props.selectedAOI && aois.length > 0) {
            fitBoundsForAOIs(aois);
        } 

        if (showServiceArea) {
            setServiceArea();
        }
    }
    
    const goToAOI = (aoi) => {
        if (isMapLoaded) {
            const center = MapUtils.getCenterForGeometry(aoi.geometry);
            mapRef.current.panTo(center);

            props.setShowSelectedAOIPopover(false);
            setTimeout(() => {
                props.setShowSelectedAOIPopover(true);
            });
        }
    }

    const setServiceArea = () => {
        if (mapRef.current && props.serviceArea) {
            props.serviceArea.setMap(mapRef.current);
        }
    }

    const getInitialRectBounds = () => {
        const map = mapRef.current;
        const center = map.getCenter();
        const boundsInKm = boundsInKmInput != "" ? parseFloat(boundsInKmInput) : (boundsInKmSelect != "" ? boundsInKmSelect : 0);
        if (boundsInKm == 0) {
            alert("Please Enter/Select the bounds range");
            return null;
        }
        const size = new google.maps.Size(boundsInKm*1000, boundsInKm*1000);
        const n = google.maps.geometry.spherical.computeOffset(center, size. height/2,0).lat();
        const s = google.maps.geometry.spherical.computeOffset(center, size. height/2,180).lat();
        const e = google.maps.geometry.spherical.computeOffset(center, size. width/2,90).lng();
        const w = google.maps.geometry.spherical.computeOffset(center, size. width/2,270).lng();

        return {north: n, south: s, east: e, west: w};
    }

    const handleShowBounds = () => {
        if (showBounds) {
            setShowBounds(false);
        }
        
        const initalGeometryCoordinates = getInitialRectBounds();
        if (initalGeometryCoordinates != null) {
            setBounds(initalGeometryCoordinates);
            setTimeout(()=>{
                setShowBounds(true);
            })
        }
    }

    const onRectangleLoad = (rectangle) => {
        rectRef.current = rectangle;
        const rectangleBounds = rectangle.getBounds();
        setBoundsCenter(rectangleBounds.getCenter());
        calculateBoundsGeoJson(rectRef.current);
    }

    const onRectangleDrag = () => {
        const rectangleBounds = rectRef.current.getBounds();
        setBoundsCenter(rectangleBounds.getCenter());

        // Calculate GeoJson of the Rectangle
        calculateBoundsGeoJson(rectRef.current);
    }

    const calculateBoundsGeoJson = (rectangle) => {
        let geoJSON = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: []
            },
            properties: {}
        };
        let bounds = rectangle.getBounds();
        let NE = bounds.getNorthEast();
        let SW = bounds.getSouthWest();
        let NW = new google.maps.LatLng(NE.lat(),SW.lng());
        let SE = new google.maps.LatLng(SW.lat(),NE.lng());
        
        let coords = [];
        coords.push([NW.lng(), NW.lat()]);
        coords.push([SW.lng(), SW.lat()]);
        coords.push([SE.lng(), SE.lat()]);
        coords.push([NE.lng(), NE.lat()]);
        coords.push([NW.lng(), NW.lat()]);

        geoJSON.geometry.coordinates.push(coords);
        setBoundsGeoJson(geoJSON);
    }

    const handleBoundsDiscard = () => {
        setShowBounds(false);
        setShowBoundsPopover(false);
    }

    const handleCopyGeoJson = () => {
        navigator.clipboard.writeText(JSON.stringify(boundsGeoJson));
        toast.success('GeoJson Copied to clipboard');
    }

    const fitBoundsForAOIs = (aois) => {
        // Iterate userAOIs to size, center, and zoom map to contain all markers
        let bounds = new window.google.maps.LatLngBounds();
        aois.map(aoi => {
            bounds = MapUtils.extendBounds(bounds, aoi.geometry);
            return !!aoi.geometry.coordinates[0][0] && aoi.geometry.coordinates[0][0][1];
        });
        mapRef.current.fitBounds(bounds);
    };

    const handleBoundsInKmChange = (e) => {
        setBoundsInKmInput("");
        setBoundsInKmSelect(parseInt(e.target.value));
    }

    const handleBoundsInKmInput = (e) => {
        setBoundsInKmSelect("");
        const val = e.target.value;
        setBoundsInKmInput(val);
    }

    const markerLoadHandler = (marker, aoi) => {
        return setMarkerMap(prevState => {
            return { ...prevState, [aoi.properties["member-aoi-id"]]: marker };
        });
    };

    const onMapCompleteLoad = () => {
        if (props.selectedAOI) {
            goToAOI(props.selectedAOI);
        }
    }

    const onInfoCloseWindow = () => {
        props.setSelectedAOI(null);
    }

    const renderMap = () => {
        return (
            <>
                <div className="map-actions">
                    <div className="toggle-switch">
                        <span className="label">Show Service Area : </span>
                        <input type="checkbox" name="service-area-toggle" checked={showServiceArea}/>
                        <label htmlFor="service-area-toggle" onClick={()=>setShowServiceArea(!showServiceArea)}>Toggle</label>
                    </div>
                    <div className="boundsInKm-div">
                        <form className="boundsInKm-form">
                            <input type="number" placeholder="Enter KM" id="boundsInKmValId" value={boundsInKmInput} onInput={(e)=>handleBoundsInKmInput(e)} />
                            <select className="form-select" id="boundsInKmSelectId" value={boundsInKmSelect} onChange={(e)=>handleBoundsInKmChange(e)}>
                                <option value="">Select</option>
                                <option value="1">1 KM</option>
                                <option value="5">5 KM</option>
                                <option value="10">10 KM</option>
                                <option value="15">15 KM</option>
                                <option value="20">20 KM</option>
                                <option value="50">50 KM</option>
                                <option value="100">100 KM</option>
                            </select>
                        </form>
                        <Link className='rectangle-btn btn btn-primary' onClick={()=>handleShowBounds()}>
                            <img src={RectangleIcon} alt='' />
                        </Link>
                    </div>
                </div>
                <div className="map map-container" style={{height: "600px"}}>
                    {
                        <GoogleMap
                            center = {AOIOptions.defaultCenter}
                            zoom={10}
                            mapContainerStyle={AOIOptions.mapContainerStyle}
                            options = {AOIOptions.roadMapOptions}
                            onLoad = {onMapLoad}
                            onTilesLoaded={onMapCompleteLoad}
                        >
                            { 
                                /* AOIs Marker */      
                                aois.map((aoi) => (
                                    <>
                                        <Polygon
                                            key={aoi.member_aoi_id}
                                            paths={MapUtils.geoJsonToCoordinates(aoi.geometry)}
                                            options={AOIOptions.userAOIoptions}
                                        />
                                        <Marker 
                                            icon={MarkerIcon}
                                            key={aoi.properties["member-aoi-id"]}
                                            onLoad={(marker)=>markerLoadHandler(marker, aoi)}
                                            onClick={()=>props.handleAOISelect(aoi)}
                                            position={MapUtils.getCenterForGeometry(aoi.geometry)} 
                                        />
                                    </>
                                    
                                ))
                            }
                            {
                                // AOI's InfoWindow
                                props.showSelectedAOIPopover && markerMap[props.selectedAOI?.properties["member-aoi-id"]] &&
                                <InfoWindow anchor={markerMap[props.selectedAOI.properties["member-aoi-id"]]} onCloseClick={onInfoCloseWindow}>
                                    <div className='da-cockpit-bounds-info'>         
                                        <table >
                                            <tr><td>Auth Id</td><td>{props.selectedAOI.properties["auth-id"]}</td></tr>
                                            <tr><td>AOI Tag</td><td>{props.selectedAOI.properties["tag"]}</td></tr>
                                            <tr><td>Region</td><td>{props.selectedAOI.properties["region"]}</td></tr>
                                            <tr><td>Subscription</td><td>{props.selectedAOI.properties["subscription-date"]}</td></tr>
                                            <tr><td>Token Balance</td><td>{props.selectedAOI.properties["token-amount"]}</td></tr>
                                        </table>
                                        <div className="follow"><a className="link" onClick={() => props.goToUsecase(props.selectedAOI)}><img src={LinkIcon}/>Follow</a></div>
                                    </div>
                                </InfoWindow>
                            }
                            {
                                // Bounds Rectangle
                                showBounds &&
                                <Rectangle 
                                    bounds={bounds}
                                    options={rectangleOptions}
                                    onLoad={onRectangleLoad}
                                    onDrag={onRectangleDrag}
                                />
                            }
                            {
                                // Bounds Marker
                                showBounds &&
                                <Marker
                                    icon={BoundsMarkerIcon}
                                    zIndex={100000}
                                    position={boundsCenter}
                                    onLoad={marker=>setBoundsMarker(marker)}
                                    onClick={()=>setShowBoundsPopover(!showBoundsPopover)}
                                />
                            }

                            {
                                // Bounds info window
                                showBoundsPopover &&
                                <InfoWindow anchor={boundsMarker} onCloseClick={()=>setShowBoundsPopover(false)}>
                                    <div className='da-cockpit-bounds-info'>         
                                        <textarea value={JSON.stringify(boundsGeoJson)}
                                            rows={10}
                                            cols={40}
                                        ></textarea>
                                        <div>
                                            <input type="button" name="discard" value="Discard" className="btn btn-outline" onClick={handleBoundsDiscard} />
                                            <input type="button" name="cancel" value="Copy GeoJson" className="btn btn-outline" onClick={handleCopyGeoJson} />
                                        </div>
                                    </div>
                                </InfoWindow>
                            }       
                        </GoogleMap>
                    }
                </div>
            </>   
        )
    }

    if (loadError) {
        return <div className='map-load-text'>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded ? renderMap() : <div className='map-load-text'><span>Loading Map...</span></div>
}

export default DACockpitMap;