import { fetchWrapper } from '../Helpers/fetchWrapper';
import { apiService } from './apiService';

const login = (username, password) => {
    const data = {
        username: username,
        password: password
    };
    return fetchWrapper.post(`${apiService.getApiUrl()}/login`, data);
};

const getProfile = () => {
    return fetchWrapper.get(`${apiService.getApiUrl()}/user`);
};

const updateSettings = (data) => {
    return fetchWrapper.put(`${apiService.getApiUrl()}/user/settings`, data);
};

const extendSession = () => {
    return fetchWrapper.get(`${apiService.getApiUrl()}/login/extend`);
};

const updateProfile = (data) => {
    return fetchWrapper.put(`${apiService.getApiUrl()}/user`, data);
};

const getAllUsers = (userName) => {
    return fetchWrapper.get(`${apiService.getApiUrl()}/user/allUsers/${userName}`);
};

const createUser = (user) => {
    return fetchWrapper.post(`${apiService.getApiUrl()}/user/create`, user);
};

const deleteUser = (userName) => {
    return fetchWrapper.deleteRequest(`${apiService.getApiUrl()}/user/delete/${userName}`);
};


export const userService = {
    login,
    getProfile,
    updateProfile,
    updateSettings,
    extendSession,
    getAllUsers,
    createUser,
    deleteUser
};

