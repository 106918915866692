import {Modal} from 'react-bootstrap';
import React from 'react';
import ImageGalleryMain from './ImageGallery';

export const ImageGalleryModal = (props) => {
    return (
        <>
            <Modal
                show={() => props.setShowImageGalleryModal(true)}
                onHide={() => props.setShowImageGalleryModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="image-gallery-modal-dialog"
                className="image-gallery-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h5>{props.galleryOptions.modalTitle ?? "Images"}</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ImageGalleryMain galleryOptions={props.galleryOptions}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ImageGalleryModal;