import { useState, useEffect } from 'react';
import SingleTimeline from './SingleTimeline';
import Split from "react-split";

const TwoTimelines = (props) => {
    const [leftMap, setLeftMap] = useState(null);
    const [rightMap, setRightMap] = useState(null);
    const [leftInfo, setLeftInfo] = useState(false);
    const [rightInfo, setRightInfo] = useState(false);
    const [leftPosition, setLeftPosition] = useState({"lat": 0, "lng": 0});
    const [rightPosition, setRightPosition] = useState({"lat": 0, "lng": 0});


    const aoiClickHandler = (openInfo, position)=>{
        if(props.drawInfo) {
            if(openInfo){
                setLeftPosition(position);
                setRightPosition(position);
                setLeftInfo(true);
                setRightInfo(true);
            }else{
                setLeftInfo(false);
                setRightInfo(false);
            }
        }
    }

    const onLeftMapLoad = (map) => {
        setLeftMap(map);
    };

    const onRightMapLoad = (map) => {
        setRightMap(map);
    };

    useEffect(() => {
        if(rightMap != null && leftMap != null) {
            syncMaps(leftMap, rightMap);
            handleContainerResize();
        }
    }, [rightMap, leftMap, props.splitPanel]);

    useEffect(() => {
        window.addEventListener("resize", handleContainerResize);
        return () => window.removeEventListener("resize", handleContainerResize);
      }, []);

    function handleContainerResize() {
        if(!props.splitPanel) {
            const width = document.getElementById("map-split-panel").offsetWidth;
            document.getElementById("map-left").style.width = `${width}px`;
            document.getElementById("map-right").style.width = `${width}px`;    
        }
    }
    
    const syncMaps = (left, right) => {
        let center = null, zoom = null;
        var maps = [left, right];

        const update = (changedMap) => {
            maps.forEach((m) => {
                if (m === changedMap) {
                    return;
                }
                m.setCenter(center);
                m.setZoom(zoom);
            });
        }

        maps.forEach((m) => {
            m.addListener("bounds_changed", () => {
                const changedCenter = m.getCenter();
                const changedZoom = m.getZoom();

                if (changedCenter !== center || changedZoom !== zoom) {
                    center = changedCenter;
                    zoom = changedZoom;
                    update(m);
                }
            });
        });
    }

    return (
        props.splitPanel ? 
            <div id="map-split-panel" className="split comparison">
                <div className="left">
                    <div id="map-left" className="map-left">
                        <SingleTimeline 
                            aoi={props.aoi} 
                            timeline={props.currentTimeline} 
                            onMapLoad={onLeftMapLoad} 
                            showZoom={false}
                            info={rightInfo}
                            position={rightPosition}
                            clickHandler={aoiClickHandler}
                            drawInfo={props.drawInfo}
                            refresh={props.refresh}
                            center={props.center} setCenter={props.setCenter} 
                            zoom={props.zoom} setZoom={props.setZoom}
                        />
                    </div>
                </div>
                <div className="vertical-bar"></div>
                <div className="right">
                    <div id="map-right" className="map-right">
                        <SingleTimeline 
                            aoi={props.aoi} 
                            timeline={props.latestTimeline} 
                            onMapLoad={onRightMapLoad} 
                            showZoom={true}
                            info={rightInfo}
                            position={rightPosition}
                            clickHandler={aoiClickHandler}
                            drawInfo={props.drawInfo}
                            refresh={props.refresh}
                            showToggleOptions={true}
                            hideLeft={true}
                            selectedAnnotationId={props.selectedAnnotationId}
                            setSelectedAnnotationId={props.setSelectedAnnotationId}
                            center={props.center} setCenter={props.setCenter} 
                            zoom={props.zoom} setZoom={props.setZoom}
                        />
                    </div>
                </div>
            </div>
        :
            <Split id="map-split-panel" className="split slider" sizes={[50,50]}>
                <div className="left">
                    <div id="map-left" className="map-left">
                        <SingleTimeline 
                            aoi={props.aoi} 
                            timeline={props.currentTimeline} 
                            onMapLoad={onLeftMapLoad} 
                            showZoom={false}
                            info={rightInfo}
                            position={rightPosition}
                            clickHandler={aoiClickHandler}
                            drawInfo={props.drawInfo}
                            refresh={props.refresh}
                            center={props.center} setCenter={props.setCenter} 
                            zoom={props.zoom} setZoom={props.setZoom}
                        />
                    </div>
                </div>
                <div className="right">
                    <div id="map-right" className="map-right">
                        <SingleTimeline 
                            aoi={props.aoi} 
                            timeline={props.latestTimeline} 
                            onMapLoad={onRightMapLoad} 
                            showZoom={true}
                            info={rightInfo}
                            position={rightPosition}
                            clickHandler={aoiClickHandler}
                            drawInfo={props.drawInfo}
                            refresh={props.refresh}
                            showToggleOptions={true}
                            selectedAnnotationId={props.selectedAnnotationId}
                            setSelectedAnnotationId={props.setSelectedAnnotationId}
                            center={props.center} setCenter={props.setCenter} 
                            zoom={props.zoom} setZoom={props.setZoom}
                        />
                    </div>
                </div>
            </Split>
    );
};
export default TwoTimelines;