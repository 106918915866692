import React, {
  useContext,
  useState,
} from 'react';

import toast from 'react-hot-toast';

import { UserContext } from '../../../Contexts/UserContext';
import { groupService } from '../../../Services/groupService';
import FileUpload from '../../FileUpload';

const AOIGroupAddEditImport = (props) => {
    const {user, reloadAOIs} = useContext(UserContext);
    const [executionId, setExecutionId] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState({
        data: [],
        meta: [],
        files: {}
    });
    const [step, setStep] = useState(0);
    const [importStatus, setImportStatus] = useState({});
    const [selectedField, setSelectedField] = useState(null);

    const geoJsonOptions = {
        id: 'geojson-upload',
        restrictions: { 
            maxNumberOfFiles: 1,
            allowedFileTypes: ['.geojson'] 
        }
    };

    const closeAndRefresh = () => {
        props.show(false);
        reloadAOIs();
    };

    const getStatus = (execId) => {
        groupService.getImportStatus(user.region, execId).then((data) => {
            setImportStatus(data);
            if(data.status == "COMPLETED") {
                setStep(4);
                if(data?.completionStatus == "SUCCESS")
                    props.reloadRequired(true);
                return;
            }
            if(data.status == "RUNNING") {
                setStep(step == 2 ? 3 : 1);
                if(data.isWaitingForCallback == true) {
                    setStep(2);
                    return;
                }
            }
            setTimeout(() => {
                getStatus(execId);
            }, 5000);
        }).catch((error) => {
            console.error(error);
        })
    };

    const submit = () => {
        groupService.startImportGroup(user.mkey, user.region, uploadedFiles.data[0].relativePath).then((data) => {
            setExecutionId(data.execution_id);
            getStatus(data.execution_id);
        }).catch((error) => {
            toast.error("Unable to import the group");
            console.error(error);
        });
    };

    const importGeoJson = () => {
        if(!props.isValidGroupName())
            return;

        groupService.importGeoJson(user.region, executionId, props.groupName, selectedField).then((data) => {
            getStatus(executionId);
        });
    };

    const cancelImportGroup = () => {
        groupService.cancelImport(user.region, executionId).then(() => {
            toast.success("Cancelled the import");
            props.show(false);
        });
    };

    const ShowStatusMessages = () => {
        return <div>
                {
                    importStatus && importStatus.messages && importStatus.messages.map((message) => {
                        return <div key={message}>{message}</div>;
                    })
                }
                {
                    importStatus && importStatus.Errors && importStatus.Errors.map((message) => {
                        return <div key={message}>{message}</div>;
                    })
                }
                </div>;
    };

    const ShowFileUpload = () => {
        return <>
                    <div>
                        <FileUpload options={geoJsonOptions} 
                            uploadedFiles={uploadedFiles}
                            setUploadedFiles={setUploadedFiles} 
                        />
                    </div>
                    <div className='modal-footer'>
                    <button type="button" className="btn btn-outline" onClick={() => props.show(false)}>CANCEL</button>
                    <button type="button" className="btn btn-primary" onClick={() => submit()}>SUBMIT</button>
                    </div>
                </>;
    };

    const ReadyForImport = () => {
        return <>
                    <div>
                        <ShowStatusMessages />
                        <br/>
                        <div className='alert alert-primary'>
                            <ul className='mb-0'>
                                <li>Features in the file do not have any properties.</li>
                                <li>Aois will be named by suffixing group name with a numbe</li>
                                <li>You can change the names after the creation of the Aois.</li>
                            </ul>
                           
                        </div>
                    
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-outline" onClick={cancelImportGroup}>CANCEL</button>
                        <button type="button" className="btn btn-primary" onClick={() => importGeoJson()}>IMPORT</button>
                    </div>
                </>;
    };

    const ShowResults = () => {
        if (Object.keys(importStatus?.Results ?? {}).length === 0) {
            return <ReadyForImport />;
        }
        return <>
                    <ShowStatusMessages />
                    <div className="imported-data-table">
                        <p><b>Found the following fields in the file. Choose which field to use for the Aoi Name:</b></p>
                        <div className="table-responsive">
                            <table className="table table-style">
                                <thead>
                                    <tr>
                                    <th scope="col">Field Name</th>
                                    <th scope="col" width="300">Sample Values</th>
                                    <th scope="col" className="text-center">Unique Column</th>
                                    <th scope="col" className="text-center">Conflicts with exiting tag</th>
                                    <th scope="col" className="action">Select</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.keys(importStatus.Results).map((field) => {
                                            var result = importStatus.Results[field];
                                            return  <tr key={field}>
                                                        <td>{field}</td>
                                                        <td>{result.sample_values?.join(", ")}</td>
                                                        <td className="text-center">{result.is_column_with_unique_values ? "Yes" : "No"}</td>
                                                        <td className="text-center">{result.conflict_with_existing_tag ? "Yes" : "No"}</td>
                                                        <td className="action">
                                                            <label htmlFor={"importname" + field} className="custom-radio1">
                                                                <input type="radio" name="radio" id={"importname" + field} onClick={() => setSelectedField(field)} checked={selectedField == field}/>
                                                                <span>&nbsp;</span>
                                                            </label>
                                                        </td>
                                                    </tr>;
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type="button" className="btn btn-outline" onClick={cancelImportGroup}>CANCEL</button>
                        <button type="button" className="btn btn-primary" onClick={() => importGeoJson()}>IMPORT</button>
                    </div>
                </>;
    };

    const ShowRunning = () => {
        return <div>
                    <ShowStatusMessages />
                    <br/>
                    <div className='processing-alert alert alert-primary'>
                    <div className="processing"></div> 
                    {
                        step == 1 && <>Please wait while the file is imported. </>
                    }
                    {
                        step == 3 && <>Group creation is in progress. </>
                    }
    
                    </div>
                </div>;
    };

    const ShowFinalMessage = () => {
        return <>
                    <div>
                        <div>
                            <ShowStatusMessages />
                            <br/>
                        </div>
                        {
                            importStatus?.completionStatus == "FAILURE" && 
                            <div>
                                <div className='alert alert-danger'>
                                    Error in importing the Geojson file: {importStatus?.failureMessage}
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline" onClick={() => props.show(false)}>CLOSE</button>
                                </div>
                            </div>
                        }
                        {
                            importStatus?.completionStatus == "SUCCESS" &&
                            <div>
                                <div className='alert alert-success'>
                                    The group is successfully created. 
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline" onClick={() => closeAndRefresh()}>CLOSE</button>
                                </div>
                            </div>
                        }
                    </div>
                </>;
    };

    return (
        <>
            {
                step == 0 && <ShowFileUpload />
            }
            {
                (step == 1 || step == 3) && <ShowRunning />
            }
            {
                step == 2 && <ShowResults />
            }
            {
                step == 4 && <ShowFinalMessage />
            }
        </>
    );
}

export default AOIGroupAddEditImport;