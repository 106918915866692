const plainGet = (url, headers = null, isTokenRequired = true) => {
    let requestOptions = {};
    if(!isTokenRequired){
        requestOptions = {
            method: 'GET'
        } 
    } else {
        requestOptions = {
            method: 'GET',
            headers: {'Authorization': `Bearer ${(window.authToken)}`}
        };
    }       
    if (headers) {
        requestOptions.headers = {...requestOptions.headers, ...headers};
    }

    return fetch(url, requestOptions);
};

export const fetchWrapper = {
    plainGet,
    get,
    post,
    put,
    deleteRequest
};

function get(url, headers = null, isTokenRequired = true) { 
    return plainGet(url, headers, isTokenRequired).then(handleResponse);
}


function post(url, body, headers = null, isTokenRequired=true) {
    let requestOptions = {};
    if(!isTokenRequired){
        requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        } 
    } else {
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${(window.authToken)}` },
            body: JSON.stringify(body)
        };
    }     
    if (headers) {
        requestOptions.headers = {...requestOptions.headers, ...headers};
    }    
    return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body, headers = null) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${(window.authToken)}` },
        body: JSON.stringify(body)
    };
    if (headers) {
        requestOptions.headers = {...requestOptions.headers, ...headers};
    }

    return fetch(url, requestOptions).then(handleResponse);    
}

function deleteRequest(url, body, headers = null) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${(window.authToken)}` },
        body: JSON.stringify(body)
    };
    if (headers) {
        requestOptions.headers = {...requestOptions.headers, ...headers};
    }

    return fetch(url, requestOptions).then(handleResponse);    
}

// helper functions
function handleResponse(response) {
    if(response.status === 404)
        return Promise.reject("Record Not Found");
    if(response.status === 500) {
        return Promise.reject("Internal Server Error");
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = data && (data.message || data.error || data.Error) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}