import { Modal } from 'react-bootstrap';
import React, { useContext, useEffect, useRef, useState } from 'react';
import MessageBox from '../SendMessage/MessageBox';
import { UserContext } from '../../Contexts/UserContext';

const SendReportModal = (props) => {
    const messageRef = useRef();
    const subjectRef = useRef();
    const {user} = useContext(UserContext);
    const [mailType, setMailType] = useState("transactional");
    const [body, setBody] = useState("");

    const userAuthId = user.userName;
    const userName = user.name;

    useEffect(() => {
        subjectRef.current.value = "Exception Data";
        messageRef.current.innerHTML = props.mailContent;    
        setBody(messageRef.current.innerHTML);
    }, [props.mailContent])

    return(
        <>
            <Modal
                show={() => props.openSendReportModal(true)}
                onHide={() => props.openSendReportModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-70w send-report-modal-dialog"
                className="send-report-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Send Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="mail-content" ref={messageRef}></div>
                    <div className="user-info">
                        <label className="form-label" htmlFor="user-info-input">User </label>
                        <input className="auth-id" value={`${userName} (${userAuthId})`} disabled={true}/>
                    </div>
                    <MessageBox showSubject={true} messageRef={messageRef} subjectRef={subjectRef} body={body} showEditor={false}/>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" aria-label="Close" onClick={() => props.openSendReportModal(false)}>CANCEL</button>
                    <button type="button" className="btn btn-primary" onClick={()=>props.sendReportEmail(subjectRef.current.value)}>Send</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SendReportModal;