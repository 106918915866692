import {Modal} from 'react-bootstrap';
import React, {useState} from 'react';
import { adminService } from '../../Services/adminService';
import toast from 'react-hot-toast';

const DatasourceEdit = (props) => {
    const initialValues = {
        name: '',
        category: '',
        description: ''
    };
    const [values, setValues] = useState(initialValues);

    const handleInputChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const save = () => {
        if(!validateFields())
            return;

        const config = {name: values.name, category: values.category, description: values.description};
        adminService.addDatasource(config)
        .then(() => {
            props.refresh();
            props.setShowModal(false);
            toast.success("Datasource has been successfully added");
        }).catch((e) => {
            console.log(e);
            toast.error("Datasource could not be added. Error: " + e);
        });
    }

    const validateFields = () => {
        if(values.name.trim() == "")
        {
            toast.error("Please enter Name");
            return false;
        }
        if(values.category == "") {
            toast.error("Please select a Category");
            return false;
        }
        if(values.description.trim() == "") {
            toast.error("Please enter Description");
            return false;
        }
        return true;
    }

    return (
        <>
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog"
                className="modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Add New Datasource</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Name">Name <span className="required">*</span></label>
                                <input type="text" className="form-control" id="name" name="name" value={values.name} onChange={(e) => handleInputChange(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Category">Category <span className="required">*</span></label>
                                <select className="form-select" id="Category" name="category" value={values.imagery} onChange={(e) => handleInputChange(e)}>
                                    <option value="">Select a Category</option>
                                    <option value="Imagery">Imagery</option>
                                    <option value="non-imagery">Non Imagery</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Description">Description <span className="required">*</span></label>
                                <input type="text" className="form-control" id="Description" name="description" value={values.description} onChange={(e) => handleInputChange(e)} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" onClick={() => props.setShowModal(false)}>CLOSE</button>
                    <button type="button" className="btn btn-primary" onClick={() => save()}>SUBMIT</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DatasourceEdit;