import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AOIContext } from '../../Contexts/AOIContext';
import { usecaseService } from '../../Services/usecaseService';

const UsecaseLinks = (props) => {
    const { domainConfig } = useContext(AOIContext);

    return <div className="usecase-links">
            {
                usecaseService.getUsecaseLinks(props.aoi, props.type, domainConfig).map((c) => c && <React.Fragment key={c[0]}>{c[1]}</React.Fragment>)
            }
            </div>;
}

export default UsecaseLinks;