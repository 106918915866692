import { useEffect } from 'react';
import LinkIcon from '../../../Assets/img/icon-link-blue.svg';

const CSInsightsList = (props) => {
    
    useEffect(()=>{
        props.getAOIs(props.filter);
    }, []);

    const renderRow = (aoi) => {
        let otherUsecases = aoi.properties['all_usecases']?.split(',') ?? [];
        return <tr key={aoi.properties['member-aoi-id']}>
                    <td>{aoi.properties['auth-id']}</td>
                    <td>{aoi.properties['tag']}</td>
                    <td>{aoi.properties['region']}</td>
                    <td className="center">{aoi.properties['creation-update-date']}</td>
                    <td className="center">{aoi.properties['service_area']}</td>
                    <td className="center">{aoi.properties['token-amount']}</td>
                    <td className="center">{aoi.properties['subscribed']}</td>
                    <td className="center">{otherUsecases.map(i => <span key={i}>{i}<br /></span>)}</td>
                    <td className="center"><a className="link" onClick={() => props.impersonateAOIUser(aoi)}><img src={LinkIcon} /> Follow</a></td>
                </tr>;
    }

    return (
        <>
            <div className="table-responsive">
                <table className="table table-style">
                    <thead>
                        <tr>
                            <th scope="col">Auth-Id</th>
                            <th scope="col">AOI Tag</th>
                            <th scope="col">Region</th>
                            <th scope="col">Date of<br/> Creation/Update</th>
                            <th scope="col">Service <br/>Area</th>
                            <th scope="col">Acc Token<br/> Balance</th>
                            <th scope="col">Subscribed</th>
                            <th scope="col">Other<br/> Subscribed <br /> Usecases</th>
                            <th scope="col">Mimic User</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.aois && props.aois.length > 0 && props.aois.map((aoi) => renderRow(aoi))
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default CSInsightsList;