import React, {useContext, useEffect, useState} from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { adminService } from '../../Services/adminService';
import UsecaseEdit from './UsecaseEdit';
import EditLogo from '../../Assets/img/icon-edit-grey.svg';
import ViewLogo from '../../Assets/img/icon-view-grey.svg';
import ShowJson from './ShowJson';
import { Link } from 'react-router-dom';

const Usecase = () => {
    const [showViewModal, setShowViewModal] = useState(false);
    const {user, usecases, setUsecases} = useContext(UserContext);
    const [name, setName] = useState("");
    const [json, setJson] = useState("");

    useEffect(() => {
        adminService.getUsecases(user.originalRegion).then((data) => {
            setUsecases(data);
        }).catch((e) => {
            toast.error("Unable to retrieve the usecases. Error: " + e);
        });
    }, []);

    const showJson = (name, json) => {
        setName(name);
        setJson(json);
        setShowViewModal(true);
    };

    const renderRow = (usecase) => {
        return <tr key={usecase.name}>
                    <td><img src={ViewLogo} onClick={() => showJson(usecase.name, usecase.configuration)} /> </td>
                    {
                        user.isSuperAdmin &&
                        <td><Link to={`/usecase/edit/${usecase.name}`}><img src={EditLogo} alt=""/></Link></td>
                    }
                    <td>{usecase.name}</td>
                    <td>{usecase.description}</td>
                    <td>{usecase.type}</td>
                    <td>
                        {
                            usecase.price && 
                            <a className="test" onClick={() => showJson(usecase.name, usecase.price)}>Price</a>
                        }
                    </td>
                </tr>;
    }

    return (
        <div className="form-content">
            <div className="form-head">
                <h3>Usecases</h3>
                {
                    user.isSuperAdmin && 
                    <Link to="/usecase/add" className="add-link">+ Add a Usecase</Link>
                }
            </div>
            <div className="admin-table-responsive">
                <table className="table table-style">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {
                                user.isSuperAdmin &&
                                <th scope="col"></th>
                            }
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                            <th scope="col">Type</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            usecases && 
                            usecases.map((config) => renderRow(config))
                        }
                    </tbody>
                </table>
            </div>
            {
                showViewModal && <ShowJson setShowModal={setShowViewModal} title={"Usecase - " + name} json={json} />
            }
        </div>
    );
}

export default Usecase;