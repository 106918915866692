import React, { useContext, useEffect, useState } from "react";
import { AOIWizzardContext } from "../../Contexts/AOIWizzardContext";
import { AOIContext } from "../../Contexts/AOIContext";
import { UserContext } from "../../Contexts/UserContext";
import Subscribe from "../Usecases/Subscribe";

const AOIServicesFormMultipleUC = (props) => {
    const {user, getAoiPricingForSubscriptions, usecases, getPricesForSubscribedAOIs} = useContext(UserContext);
    const {goBack, goNext, selectedAOI } = useContext(AOIWizzardContext);
    const {domainConfig, allAOIs} = useContext(AOIContext);
    const isGroup = props.type == 'group';
    const availableServices = isGroup
        ? domainConfig.usecases?.filter(usecase=>usecase.type == 'group' && usecase.isEnabled === true) 
        : domainConfig.usecases?.filter(usecase=>usecase.type == 'aoi' && usecase.isEnabled === true);
    const isSingleUsecase = domainConfig.isSingleUsecase;
    const [isSubscribed, setIsSubscribed] = useState(false);
    const existingAoiSubscriptions = allAOIs.filter((a) => a.subscribed_usecases?.some(item=>item.usecase.type == 'aoi')).length;

    const [subscriptions, setSubscriptions] = useState({});
    const [subscriptionDetails, setSubscriptionDetails] = useState({});
    const [pricingDetails, setPricingDetails] = useState({});

    const isUsecaseSubscribed = (allUsecases) => {
        if(allUsecases == null || allUsecases.length == 0)
            return false;
        return allUsecases.some(item => availableServices.some(s => s.name == item.usecase.name));
    };

    const getSubscribedUsecases = (allUsecases) => {
        if(allUsecases == null || allUsecases.length == 0)
            return [];
        return allUsecases.filter(item => availableServices.some(s => s.name == item.usecase.name)).map(item => item.usecase.name);
    };

    useEffect(() => {
        setIsSubscribed(isUsecaseSubscribed(props.type == "group" ? props.group?.subscribed_usecases : selectedAOI?.subscribed_usecases));
        var usersUsecases = getSubscribedUsecases(props.type == "group" ? props.group?.subscribed_usecases : selectedAOI?.subscribed_usecases);
        let sub = {};
        availableServices.map((service) => {
            sub[service.name] = usersUsecases && usersUsecases.find(serviceName => serviceName == service.name) ? true : false;
        });
        setSubscriptions(sub);

        let subDetails = {};
        if(props.type != "group") {
            selectedAOI?.subscribed_usecases?.map((usecase) => {
                subDetails[usecase.usecase.name] = usecase.subscription_details;
            });
            getAoiPricingForSubscriptions(selectedAOI, (data) => {
                setPricingDetails({...pricingDetails, ...data});
            });
        }
        setSubscriptionDetails(subDetails);
    }, [props]);

    useEffect(() => {
        getPricesForSubscribedAOIs(user.mkey, user.region, allAOIs, null);
    }, [allAOIs])

    const subscribe = (serviceName, isSubscribe, subDetails, displayName) => {
        if(!user.canSubscribe)
            return;
        if(isSubscribe)
            addService(serviceName, subDetails, displayName);
        else
            removeService(serviceName, displayName);
    };

    const addService = (serviceName, subDetails, displayName) => {
        if(!isGroup && !isSubscribed && existingAoiSubscriptions >= parseInt(user.userPreferences.MaxAoiSubscriptionCount))
            return;

        setSubscriptions({...subscriptions, [serviceName]: true});
        if(subDetails)
            setSubscriptionDetails({...subscriptionDetails, [serviceName]: subDetails});

        props.subscribeUsecase(serviceName, subDetails, displayName);
    };

    const removeService = (serviceName, displayName) => {
        setSubscriptions({...subscriptions, [serviceName]: false});
        props.unsubscribeUsecase(serviceName, displayName);
    };

    const RenderSingleUsecase = () => {
        return <div className="aoi-singleusecase">
                    <RenderUsecases />
                </div>;
    };

    const RenderMultiUsecase = () => {
        return <div className="aoi-form aoi-services">
                    <div className="form-group">
                        <label htmlFor="landType" className="form-label">Services</label>
                        <div className="checkbox-group">
                            <div className="row">
                                <RenderUsecases />
                            </div>
                        </div>
                    </div>
                </div>;
    };

    const RenderUsecases = () => {
        return availableServices.map((service) => {
                    var usecase = usecases.find((u) => u.name == service.name);
                    const canSubscribe = user.canSubscribe && (isGroup || isSubscribed || existingAoiSubscriptions < parseInt(user.userPreferences.MaxAoiSubscriptionCount));
                    return <Subscribe key={service.name} service={usecase} displayName={service.displayName} isSubscribed={subscriptions[service.name]} subscribe={subscribe} 
                        subscriptionDetails={subscriptionDetails[service.name]} pricingDetails={pricingDetails} aoi={selectedAOI} canSubscribe={canSubscribe}
                        isSingleUsecase={isSingleUsecase} close={props.close}/>
                });
    };

    return (
        <>
            <div className={`aoi-wizzard-content wizzard-content ${isGroup ? 'group' : 'aoi'}`}>
                <div className="container">
                    {
                        !isGroup && !isSubscribed && existingAoiSubscriptions >= parseInt(user.userPreferences.MaxAoiSubscriptionCount) && 
                        <div className="aoi-limit-message">
                            You cannot subscribe to any more AOIs, as you have reached the limit of {user.userPreferences.MaxAoiSubscriptionCount} subscriptions.
                        </div>
                    }
                    {
                        isSingleUsecase ? <RenderSingleUsecase /> : <RenderMultiUsecase />
                    }

                </div>
            </div>
            <div className="wizzard-footer">
                <div className="container">
                    <div className="btn-area">
                        {
                            isGroup 
                            ? <button type="button" className="btn btn-primary ml-auto" onClick={() => props.close()}>Close</button>
                            : <>
                                <button type="button" className="btn btn-outline" onClick={goBack}>Back</button>
                                <button type="button" className="btn btn-primary ml-auto" onClick={() => props.close()}>Close</button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default AOIServicesFormMultipleUC;