import "bootstrap/dist/css/bootstrap.min.css";
import './Assets/Css/datadeli-styles.css';
import "./Assets/Css/custom-styles.css";
import "./Assets/Css/group-styles.css";
import "./Assets/Css/billing.css";
import "./Assets/Css/gmr-demo.css";
import "./Assets/Css/services.css";
import "./Assets/Css/resources.css";
import "./Assets/Css/email-unsubscribe.css";
import "./Assets/Css/aoi-details-styles.css";
import "./Assets/Css/tour.css";
import "./Assets/Css/cs-cockpit.css";
import "./Assets/Css/gailpoc.css";
import "./Assets/Css/dashboard.css";
import "./Assets/Css/timeline.css";
import "./Assets/Css/home-page.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import "./Assets/Css/aos.css";
import {Amplify, Auth, Hub} from "aws-amplify";
import {BrowserRouter as Router, Routes, Route, Outlet} from "react-router-dom";
import {React, useEffect, useState, lazy, Suspense} from "react";
import {UserContext} from "./Contexts/UserContext";
import {memberService} from "./Services/memberService";
import {adminService} from "./Services/adminService";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import LoginModal from "./Components/LoginModal";
import EditProfileModal from "./Components/EditProfileModal";
import Snackbar from "./Components/Snackbar";
import Dashboard from "./Pages/Dashboard";
import TermsOfService from "./Pages/TermsOfService";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import { siteService } from "./Services/siteService";
import toast from 'react-hot-toast';
import UserProfile from "./Pages/UserProfile";
import Payment from "./Pages/Payment";
import { usecaseService } from "./Services/usecaseService";
import { aoiService } from "./Services/aoiService";
import { MapUtils } from "./Components/MapUtils";
import { dbService } from "./Services/dbService";
import { aoiPricingUtil } from "./Utils/aoiPricingUtil";
import { getUserPreference } from "./Components/Utils";
import PaymentSuccess from "./Pages/PaymentSuccess";
import PaymentCancel from "./Pages/PaymentCancel";
import { Buffer } from "buffer";
import Member from "./Components/UserProfile/Member";
import ImageGalleryModal from "./Components/ImageGallery/ImageGalleryModal";
import Services from "./Pages/Services";
import Resources from "./Pages/Resources";
import Unsubscribe from "./Pages/Unsubscribe";
import Contact from "./Pages/Contact";
import CustomerPayment from "./Pages/CustomerPayment";
import Help from "./Pages/Help";
import { fetchWrapper } from "./Helpers/fetchWrapper";
import * as turf from '@turf/turf';
import DashboardGail from "./Pages/DashboardGail";
import TimelineLandDef from "./Pages/TimelineLandDef";
import TimelineLeakage from "./Pages/TimelineLeakage";
import Copyright from "./Components/Copyright";
import Cookies from 'js-cookie';
import { userService } from "./Services/userService";
import Role from "./Helpers/Role";

const Home = lazy(() => import('./Pages/Home'));
const Login = lazy(() => import('./Pages/Login'));
const Error = lazy(() => import('./Pages/Error'));
const Swagger = lazy(() => import('./Pages/Swagger'));

Amplify.configure(window.aws_exports);
const appConfig = window.config;

const unAuthUser = {
  email: null,
  mkey: null,
  isAuthenticated: false,
  profile: {
    name: null,
    communication_phone_number: null,
    communication_email: null,
    checked_terms_of_service: null,
    address: {
      address1: null,
      address2: null,
      address3: null,
      city: null,
      state: null,
      country: null,
      pin: null,
    },
  },
};

const initialFilters = { filterText: "", subscribed: "all", selectedGroup: null }

function App() {
  const [domainConfig, setDomainConfig] = useState(null);
  const [user, setUser] = useState(unAuthUser);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const [siteCache, setSiteCache] = useState({});
  const [redirectToPath, setRedirectToPath] = useState(null);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [openImpersonationModal, setOpenImpersonationModal] = useState(false);
  const impersonatedUserListInSession = sessionStorage.getItem("impersonatedUserList");
  const impersonatedUserObjInSession = sessionStorage.getItem("impersonatedUser");
  const [impersonatedUserList, setImpersonatedUserList] = useState(JSON.parse(impersonatedUserListInSession) ?? []);
  const [impersonatedUserObj, setImpersonatedUserObj] = useState(JSON.parse(impersonatedUserObjInSession) ?? null);
  const [counter, setCounter] = useState(0);
  const [allAOIs, setAllAOIs] = useState(null);
  const [groups, setGroups] = useState([]);
  const [originalAllAOIs, setOriginalAllAOIs] = useState([]);
  const [aoiThumbnails, setAoiThumbnails] = useState({});
  const [isAOIDataLoading, setIsAOIDataLoading] = useState(false);
  const [db, setDb] = useState(null);
  const [isAuthInProgress, setIsAuthInProgress] = useState(false);
  const [filter, setFilter] = useState(initialFilters);
  const [aoiPrices, setAoiPrices] = useState({});
  const [usecases, setUsecases] = useState([]);
  const [expensesPerMonth, setExpensesPerMonth] = useState(0);
  const [originalUserExpensesPerMonth, setOriginalUserExpensesPerMonth] = useState(0);
  const [showImageGalleryModal, setShowImageGalleryModal] = useState(false);
  const [galleryOptions, setGalleryOptions] = useState({});
  const [showTour, setShowTour] = useState(false);
  const [isLoggedInNow, setIsLoggedInNow] = useState(false);
  const [landuse, setLanduse] = useState(null);
  //const [rou50mLine, setRou50mLine] = useState(null);
  //const [rou50mGeometry, setRou50mGeometry] = useState(null);
  const [pipelineGeometry, setPipelineGeometry] = useState(null);
  const [timelineView, setTimelineView] = useState("single");
  //const [rou1kmGeometry, setRou1kmGeometry] = useState(null);
  //const [timelineEvents, setTimelineEvents] = useState({});
  //const [milestones, setMilestones] = useState([]);

  const [showMapMoveOptions, setShowMapMoveOptions] = useState(false);
  // 0 - Stopped , 1 - Started, 2 - Paused.
  const [mapMoveState, setMapMoveState] = useState(0);
  const [mapMovementPoints, setMapMovementPoints] = useState([]);
  const [mapMovementPointPosition, setMapMovementPointPosition] = useState(0);
  const [resetTimeline, setResetTimeline] = useState(0);

  const AOI_ID = "e759afee-a9a7-4b1b-80b1-9cd535660282";
  const USER_KEY = "081df3ef475240e1d975725850cb97d9";

  useEffect(async () => {
    dbService.initializeDB((database) => setDb(database));

    setIsAuthInProgress(true);
    let token = Cookies.get("authtoken");
    if(token == null)
      token = localStorage.getItem("authtoken");
    let expiryTime = Cookies.get("expiryTime");
    if(expiryTime == null)
      expiryTime = localStorage.getItem("expiryTime");

    if(token != null && expiryTime != null) {
      window.authToken = token;
      window.expiryTime = expiryTime;
      userService.getProfile().then((result) => {
        console.log("User is authenticated", result);
        setUser({
          email: result.userName,
          mkey: result.userName,
          isAuthenticated: true,
          userSettings: result.settings ? JSON.parse(result.settings) : {},
          ...result
        });
        setIsAuthInProgress(false);
      }).catch((e) => {
        console.log(e);
        console.log("User is not authenticated");
        setIsAuthInProgress(false);
      });
    } else {
      setIsAuthInProgress(false);
    }

    //reloadLoginSession();
  }, []);

  const reloadLoginSession = () => {
    try {
      setBasicConfigs();
      Auth.currentAuthenticatedUser().then((currentUser) => {
        setIsAuthInProgress(true);
        setOpenLoginModal(false);
        setUserInfo(currentUser, true);
      });
    } catch {
      setOpenLoginModal(false);
      setUser(unAuthUser);
    }
  };

  // useEffect(() => {
  //   const id = setInterval(() => {
  //     if(user != null && user.isAuthenticated == true) {
  //       reloadTokens();
  //     }
  //   }, appConfig.refreshTokensFrequency * 1000);
  //   return () => clearInterval(id);
  // }, [user]);

  useEffect(() => {
    if(isAOIDataLoading == true)
      setCounter(counter+1);
  }, [isAOIDataLoading]);

  // useEffect(() => {
  //   const unsubscribe = Hub.listen("auth", ({payload: {event, data}}) => {
  //     switch (event) {
  //       case "signIn":
  //         setIsLoggedInNow(true);
  //         setImpersonatedUserObj(null);
  //         reloadLoginSession();
  //         break;
  //       case "signOut":
  //         clearUserStates();
  //         break;
  //       case "customOAuthState":
  //         const redirectToPath = decodeURIComponent(data);
  //         redirectToPath && redirectToPath.trim() && setRedirectToPath(redirectToPath.trim());
  //         break;
  //     }
  //   });
  //   return unsubscribe;
  // }, []);

  // useEffect(() => {
  //   if(!!isLoggedInNow && !!user?.originalUserPreference?.ShowTourOnLogin && !!user.profile.checked_terms_of_service) {
  //     setShowTour(true);
  //     setIsLoggedInNow(false);
  //   }
  // }, [isLoggedInNow, user, user?.profile?.checked_terms_of_service]);

  //useEffect(() => {
    // fetchWrapper.get("/geometry/50m.json").then((data) => {
    //   setRou50mGeometry(data);
    // });
    // fetchWrapper.get("/geometry/50mline.json").then((data) => {
    //   setRou50mLine(data);
    // });
    // fetchWrapper.get("/geometry/pipeline.json").then((data) => {
    //   setPipelineGeometry(data);
    // });
    // fetchWrapper.get("/geometry/pipeline-1km-rou.json").then((data) => {
    //   setRou1kmGeometry(data);
    // });
    // fetchWrapper.get("/geometry/timelineevents.json").then((data) => {
    //   setTimelineEvents(data);
    // });
    // fetchWrapper.get("/geometry/milestones.json").then((data) => {
    //   setMilestones(data.milestones);
    // });
  //}, []);

  const setBasicConfigs = () => {
    window.apiBaseUrl = appConfig.apiBaseUrl;
  }

  const getDomainBasedConfigs = (region, domain) => {
    return siteService.domainConfig(region, domain)
    .catch((error) => {
      console.error("Error in getting Domain Based Configurations!", error)
    });
  }

  const setUserInfo = async (currentUser, isAuthenticated) => {
    window.defaultGuiConfig = {};
    if (currentUser != null) {
      const idToken = currentUser.signInUserSession?.idToken;
      const authToken = idToken.jwtToken;
      const payload = idToken.payload;
      const userEmail = payload.email;
      const userPhoneNumber = payload.phone_number;
      const expiryTime = payload.exp;
      const mkey = payload.mkey;
      const phone_number_verified = payload.phone_number_verified ?? false;
      const roles= payload.roles.split(',');
      const isRegistered = roles.indexOf("R") >= 0;
      const isDA = roles.indexOf("DA") >= 0;
      const isCustomerService = roles.indexOf('CS') >= 0;
      const isAdmin = roles.indexOf("A") >= 0;
      const isSuperAdmin = roles.indexOf("SA") >= 0;

      window.authToken = authToken;
      window.accessToken = currentUser.signInUserSession?.accessToken.jwtToken;
      window.expiryTime = expiryTime;
      window.roles = roles;

      let region;
      if(payload.newuser === "true"){
         let requestBody = { 
          "auth-id": payload["auth-typ"] === "email" ? userEmail : userPhoneNumber,
          "roles": roles.join(','),
          "name": payload.name,
          "auth-typ": payload["auth-typ"],
          "region": payload.region,
          "cognito:username": payload["cognito:username"]
        };
        try {
          let regionObj = await memberService.create(requestBody);
          region = regionObj["region"];  
        } catch(e){
          //signOut();
          console.error(e);
        }
      }
      else{
        region = payload.region;
      }

      // Set App Config Values
      const host = window.location.protocol + '//' + window.location.host;
      const apiBaseUrl = window.config["apiBaseUrl"]; 
      window.apiBaseUrl = apiBaseUrl || `${host}/api/${region}`;
      window.memberApiBaseUrl = `${window.apiBaseUrl}/member`;
      let hostUrl = window.config["cloudFrontUrl"] || host;
      window.adminApiBaseUrl = `${hostUrl}/admin`;
      window.dataAccessUrl =`${hostUrl}/data/${region}`;

      // Set Domain based Configs
      const domain = appConfig.configHostname ?? window.location.hostname;
      const domainBasedConfigs = await getDomainBasedConfigs(region, domain);
      if (domainBasedConfigs) {
        setDomainConfig(domainBasedConfigs);
      }

      adminService.getUsecases(region).then((data) => {
          setUsecases(data);
      }).catch((e) => {
          console.log("Unable to retrieve the usecases. Error: ", e);
      });

      if(!!domainBasedConfigs.landuse) {
        adminService.getLanduse(region, domainBasedConfigs.landuse.name).then((data) => {
          setLanduse(data);
        }).catch((e) => {
          console.log("Unable to retrieve landuse ", e);
        });
      }
      
      getConfigDetails(region).then((guiConfig) =>{
        guiConfig.forEach((item)=>{
          defaultGuiConfig[item["name"]] = item["default_value"]
        })
      });
  
      getMemberDetails(mkey, region).then((member) => {
        let currentMkey = impersonatedUserObj ? impersonatedUserObj.mkey : mkey;
        let currentRegion = impersonatedUserObj ? impersonatedUserObj.region : region;
        let originalUserPreference = getUserPreference(member.configuration);
        let currentUserPreferences = impersonatedUserObj ? getUserPreference(impersonatedUserObj.configuration) : originalUserPreference;
        setUser({
          ...user,
          email: userEmail,
          originalmKey: mkey,
          originalRegion: region,
          originalUserPreference: originalUserPreference,
          authId: member["auth-id"],
          isAuthenticated: true,
          isRegistered: isRegistered,
          isDA: isDA,
          isCustomerService: isCustomerService,
          isAdmin: isAdmin,
          isSuperAdmin: isSuperAdmin,
          // Super user can edit anything. Customer Service can edit only if the corresponding setting is enabled in the config. 
          isReadOnly: impersonatedUserObj ? !(isSuperAdmin || isCustomerService && appConfig.canCustomerServiceEditAOIs) : false,
          canSubscribe: impersonatedUserObj ? isSuperAdmin : true,
          canImpersonate: (isDA || isAdmin || isSuperAdmin || isCustomerService),
          phone_number_verified: phone_number_verified,
          ...member,
          userPreferences: currentUserPreferences,
          mkey: currentMkey,
          region: currentRegion,
          isImpersonated: !!impersonatedUserObj,
          impersonatedUser: impersonatedUserObj,
          roles: roles
        });

        setSiteCache({});
        getFirstTimeAOIs(currentMkey, currentRegion, domainBasedConfigs, currentUserPreferences.PaymentCurrency);
        
        if(currentUserPreferences.NotificationIcon) 
          populateNotifications(mkey, region);

        if(window.location.pathname == "/") {
          setRedirectToPath("/timelinev2/e759afee-a9a7-4b1b-80b1-9cd535660282");
        }
        setIsAuthInProgress(false);
      }).catch((e) => {
        setIsAuthInProgress(false);
        console.error(e);
        if(window.location.pathname != "/")
          window.location = "/";
      });
    }
  };

  const getFirstTimeAOIs = (mkey, region, domainBasedConfigs, currency) => {
    dbService.initializeDB((database) => {
      setDb(database);
      getAOIsWithDB(database, mkey, region, domainBasedConfigs, currency);
    });
  };

  const getAOIs = (mkey, region) => {
    getAOIsWithDB(db, mkey, region, domainConfig, user.userPreferences?.PaymentCurrency);
  };

  const getAOIsWithDB = (db, mkey, region, domainConfig, currency) => {
    setIsAOIDataLoading(true);
    dbService.getAOIGeometry(db, mkey, (dbaois) => {
      let extIds = dbaois.map(aoi => aoi.ext_id);
      aoiService.queryAOIs(mkey, region, extIds).then((data) => {
        let aois = data.aois ?? [];
        let groups = data.groups ?? [];
        let addDbAOIs = [];
        aois.forEach((aoi, index) => {
          aois[index].groups = groups.filter(group => group.member_aoi_ids?.some(aoiId => aoiId == aoi.member_aoi_id));  
          if(aois[index].geometry == null) {
            let dbaoi = dbaois.find(a => a.ext_id == aois[index].ext_id);
            if(dbaoi != null) {
              aois[index].geometry = dbaoi.aoi.geometry;
            }
          } else {
            addDbAOIs.push(aois[index]);
          }
        });
        setAllAOIs(aois);
        setGroups(groups);
        let toDeleteDBAOIs = dbaois.filter((dbaoi) => aois.find((aoi) => aoi.ext_id == dbaoi.ext_id) == null).map((aoi) => aoi.ext_id);
        dbService.storeAOIGeometry(db, mkey, aois);
        dbService.deleteAOIGeometry(db, toDeleteDBAOIs);
        getPricesForSubscribedAOIsWithDB(db, mkey, region, aois, usecases, (d) => {});
        if(domainConfig.isSingleUsecase == true) {
          aoiPricingUtil.getAoiPricingForGridView(db, mkey, region, aois, domainConfig.usecases, usecases, (priceData) => {
            setAoiPrices({...aoiPrices, ...priceData});
          });  
        }
      }).catch((error) => {
          console.error("Error in getting AOI Details!", error);
      });
    });
  };

  const getThumbnailUrls = (aois, mkey) => {
    dbService.getAOIThumbnails(db, mkey, (dbaois) => {
      let toDeleteDBAOIs = dbaois.filter((dbaoi) => aois?.find((aoi) => aoi.ext_id == dbaoi.ext_id) == null).map((aoi) => aoi.ext_id);
      dbService.deleteAOIThumbnails(db, toDeleteDBAOIs);
      
      if (!!aois) {
        Promise.all(aois?.map((aoi) => {
          let dbaoi = dbaois.find(a => a.ext_id == aoi.ext_id);
          if(dbaoi == null) {
            return fetchAndStoreThumbnail(db, mkey, aoi);
          } else {
            const blobUrl = window.URL.createObjectURL(dbaoi.image);
            return {ext_id: aoi.ext_id, blobUrl: blobUrl};
          }
        })).then((result) => {
          let thumbnails = {};
          result.forEach((aoi) => {
            thumbnails[aoi.ext_id] = aoi.blobUrl;
          })
          setAoiThumbnails(thumbnails);
          setIsAOIDataLoading(false);
        });
      }
    });
  };
  
  const fetchAndStoreThumbnail = (db, mkey, aoi) => {
    var url = MapUtils.getThumbnailUrl(aoi);
    return fetch(url).then((response)=>{
      return response.blob().then(blob => {
        dbService.storeAOIThumbnail(db, mkey, aoi.ext_id, blob);
        const blobUrl = window.URL.createObjectURL(blob);
        return {ext_id: aoi.ext_id, blobUrl: blobUrl};
      });
    });
  };
  
  const reloadAOIs = () => {
    getAOIs(user.mkey, user.region);
  };

  const reloadGroups = () => {
    aoiService.getGroups(user.mkey, user.region)
      .then((data) => {
        let groups = data.groups ?? [];
        setGroups(groups);
        let aois = [...allAOIs];
        aois.forEach((aoi, index) => {
          aois[index].groups = groups.filter(group => group.member_aoi_ids?.some(aoiId => aoiId == aoi.member_aoi_id));  
        });
        setAllAOIs(aois);
      });
  };

  const reloadAOI = (member_aoi_id, ext_id) => {
    aoiService.get(user.mkey, user.region, member_aoi_id)
      .then((data) => {
        data.groups = groups.filter(group => group.member_aoi_ids?.some(aoiId => aoiId == data.member_aoi_id));  
        dbService.storeAOIGeometry(db, user.mkey, [data]);
        let changedAOIs = [...allAOIs.filter(aoi => aoi.member_aoi_id != member_aoi_id), data];
        fetchAndStoreThumbnail(db, user.mkey, data).then((imagedata) => {
          var thumbnails = {...aoiThumbnails};
          thumbnails[data.ext_id] = imagedata.blobUrl;
          setAoiThumbnails(thumbnails);
          setAllAOIs(changedAOIs);
          if(domainConfig.isSingleUsecase == true) {
            aoiPricingUtil.getAoiPricingForGridView(db, user.mkey, user.region, [data], domainConfig.usecases, usecases, (priceData) => {
             setAoiPrices({...aoiPrices, ...priceData});
            });
          }
          if(ext_id != null && data.ext_id != ext_id) {
            dbService.deleteAOIGeometry(db, [ext_id]);
            dbService.deleteAOIThumbnails(db, [ext_id]);
          }
        });
      });
  };

  const removeAOIFromDB = (ext_id) => {
    dbService.deleteAOIGeometry(db, [ext_id]);
    dbService.deleteAOIThumbnails(db, [ext_id]);
    let changedAOIs = [...allAOIs.filter(aoi => aoi.ext_id != ext_id)];
    let thumbnails = {...aoiThumbnails};
    delete thumbnails[ext_id];
    setAoiThumbnails(thumbnails);
    setAllAOIs(changedAOIs);
  };

  const getAoiPricingForSubscriptions = (aoi, callback) => {
    aoiPricingUtil.getAoiPricingForSubscriptions(db, user.mkey, user.region, aoi, domainConfig.usecases, usecases, callback);
  };

  const getPricesForSubscribedAOIs = (mkey, region, aois, callback) => {
    getPricesForSubscribedAOIsWithDB(db, mkey, region, aois, usecases, callback);
  };

  const getPricesForSubscribedAOIsWithDB = (db, mkey, region, aois, usecases, callback) => {
    aoiPricingUtil.getPricesForSubscribedAOIs(db, mkey, region, aois, usecases, (data) => {
      const amount = getExpensesPerMonth(data);
      setExpensesPerMonth(amount);
      if(mkey == user.originalmKey) 
        setOriginalUserExpensesPerMonth(amount);
      !!callback && callback(data);
    });
  };

  const getExpensesPerMonth = (data) => {
    let amount = 0;
    Object.keys(data).map((extId) => {
        var subData = data[extId];
        if(subData != null) {
            Object.keys(subData).map((s) => {
                amount += subData[s];
            });
        }
    });
    return amount;
  };

  const populateNotifications = (mkey, region) => {
    memberService.getNotifications(mkey, region).then((data) => {
      var sortedData = data.sort((a, b) => Date.parse(new Date(b.message_timestamp)) - Date.parse(new Date(a.message_timestamp)));
      setNotifications(sortedData);
      setNotificationsCount(sortedData.length);
    }).catch((error) => {
      console.error("Error in retrieving notifications", error);
    });
  };

  const refreshNotifications = () => {
    populateNotifications(user.originalmKey, user.originalRegion);
  };

  const getConfigDetails = (region) => {
    return adminService
      .getConfig(region)
      .catch((error)=>
        console.error("Error in getting Config Details!", error)
      );
  };

  const getMemberDetails = (mkey, region) => {
    return memberService.get(mkey, region);
  };

  const reloadTokens = () => {
    memberService.getTokens(user.originalmKey, user.originalRegion).then((data) => {
      setUser({...user, tokens: data.tokens});
    });
  };

  const signOut = async () => {
    clearUserStates();
    //await Auth.signOut();
  };

  const showAlert = (message, type = null) => {
    if(type == "success")
      toast.success(message);
    else if(type == "fail")
      toast.error(message);
    else
      toast(message);
  };

  const isValidUserObj = (userObj) => {
    return userObj && userObj.mkey && userObj["auth-id"];
  }

  const addUserToImpersonatedUserList = (userObj) => {
    let userList = impersonatedUserList;
    if (userList.find(user=>user["auth-id"] == userObj["auth-id"])) {
      // If the user data already present, update it with new user Object
      userList = userList.filter(user=>user["auth-id"] != userObj["auth-id"]);
    }
    userList.push(userObj);

    // Store recent 5 Impersonated Users List in Session storage
    userList.sort((a, b) => {
      return +new Date(b.impersonatedTime) - +new Date(a.impersonatedTime);
    });
    userList = userList.slice(0, 5);

    setImpersonatedUserList(userList);
    sessionStorage.setItem("impersonatedUserList", JSON.stringify(userList));
  };

  const removeUserFromImpersonatedUserList = (authId) => {
    let userList = impersonatedUserList.filter(user=>user["auth-id"] != authId);
    setImpersonatedUserList(userList);
    sessionStorage.setItem("impersonatedUserList", JSON.stringify(userList));
  };

  const impersonateUser = async (enableImpersonate, userObj = null) => {
    setSiteCache({});
    setGroups([]);
    if (enableImpersonate) {
      if(user.mkey == user.originalmKey)
        setOriginalAllAOIs(allAOIs);
      setAllAOIs(null);
      if (isValidUserObj(userObj)) {
        userObj = {...userObj, impersonatedTime: new Date().toISOString()}
        setImpersonatedUserObj(userObj);
        // Store the impersonate User Id in session storage in order to retain the impersonation in page refresh
        sessionStorage.setItem("impersonatedUser", JSON.stringify(userObj));
        addUserToImpersonatedUserList(userObj);
        
        setUser({
          ...user, 
          mkey: userObj.mkey,
          region: userObj.region,
          isImpersonated: true,
          impersonatedUser: userObj,
          isReadOnly: !(user.isSuperAdmin || user.isCustomerService && appConfig.canCustomerServiceEditAOIs),
          canSubscribe: user.isSuperAdmin,
          userPreferences: getUserPreference(userObj.configuration)
        });
        getAOIs(userObj.mkey, userObj.region);
      }
    }
    else {
      setAllAOIs(null);
      sessionStorage.removeItem("impersonatedUser");
      setUser({
        ...user, 
        mkey: user.originalmKey,
        region: user.originalRegion,
        isImpersonated: false,
        impersonatedUser: null,
        isReadOnly: false,
        canSubscribe: true,
        userPreferences: user.originalUserPreference
      });
      getAOIs(user.originalmKey, user.originalRegion);
    }
    setCounter(counter+1);
  }

  const clearUserStates = () => {
    // sessionStorage.removeItem("impersonatedUser");
    // sessionStorage.removeItem("impersonatedUserList");
    // setImpersonatedUserList([]);
    localStorage.removeItem("authtoken");
    localStorage.getItem("expiryTime");
    Cookies.remove("authtoken");
    Cookies.remove("expiryTime");
    setUser(unAuthUser);
    setAllAOIs(null);
    setGroups([]);
    setFilter(initialFilters);
  }

  const sendNotification = (search_criteria, subject = "", message = "", notificationType = "", statusCallback = null, successMgs = null) => {
    const notification_msg = {
      subject: subject,
      body: Buffer.from(message).toString('base64')
    }
    adminService.sendNotification(search_criteria, notification_msg, notificationType)
    .then(data => {
      toast.success(successMgs ?? "Message Sent Successfully");
      statusCallback && statusCallback(true);
    })
    .catch(error => {
      console.error("Error in Sending Notification", error);
      toast.error("Error in Sending Message");
      statusCallback && statusCallback(false);
    });
  };

  const hideTour = (always) => {
    setShowTour(false);
    if(!!always) {
      memberService.updateSetting(user.originalmKey, user.originalRegion, "user", "ShowTourOnLogin", false)
      .then((data) => {
          var preferences = getUserPreference(data);
          var updatedUser = {
              ...user, 
              userPreferences:  user.mkey == user.originalmKey ? preferences : user.userPreferences,
              originalUserPreference: preferences
          };
          setUser(updatedUser);
      })
      .catch((error) => {
          console.log(error);
      });
    }
  };

  const getImageFromDb = (url, callback) => {
    dbService.getImageFromDb(db, url, callback);
  };

  const storeImageInDb = (url, blob) => {
    dbService.storeImageInDb(db, url, blob);
  };

  // TODO: Remove hardcoding here. 
  const getPointsAsDistance = (distance) => {
    const lineGeoJson = {
        "type": "Feature",
        "properties": {},
        "geometry": {
            "type": "LineString",
            "coordinates": pipelineGeometry.coordinates[0]
        }
      };
    const points = [];
    const length = turf.length(lineGeoJson, {units: 'meters'});

    for(let i=0; i<= length; i+= distance) {
        const point = turf.along(lineGeoJson, i, {units: 'meters'});
        points.push(point);
    }
    return points;
  };

  const deleteImageCache = (pattern) => {
    dbService.deleteImages(db, pattern);
    toast.success("The requested cache would be cleared in a few seconds");
  }

  const startMapMove = (distance) => {
    setMapMovementPoints(getPointsAsDistance(parseInt(distance)));
    setMapMovementPointPosition(0);
    setMapMoveState(1);
  };

  const userContextValues = {
    domainConfig,
    usecases, setUsecases, landuse,
    user,
    reloadLoginSession,
    setUser,
    signOut,
    openLoginModal: setOpenLoginModal,
    openEditProfileModal: setOpenEditProfileModal,
    showAlert,
    siteCache, setSiteCache,
    notifications,
    notificationsCount,
    refreshNotifications,
    impersonatedUserList, setImpersonatedUserList, impersonateUser,
    removeUserFromImpersonatedUserList,
    openImpersonationModal, setOpenImpersonationModal,
    reloadAOIs, reloadAOI, removeAOIFromDB, allAOIs, aoiPrices, originalAllAOIs, getThumbnailUrls,
    groups, reloadGroups, isAOIDataLoading,
    aoiThumbnails, isAuthInProgress,
    filter, setFilter, reloadTokens,
    getAoiPricingForSubscriptions, getPricesForSubscribedAOIs,
    sendNotification, expensesPerMonth, originalUserExpensesPerMonth,
    setShowImageGalleryModal, galleryOptions, setGalleryOptions, 
    showTour, hideTour, getImageFromDb, storeImageInDb,
    pipelineGeometry, setPipelineGeometry,
    mapMovementPoints, setMapMovementPoints,
    mapMovementPointPosition, setMapMovementPointPosition,
    mapMoveState, setMapMoveState,
    startMapMove, timelineView, setTimelineView,
    showMapMoveOptions, setShowMapMoveOptions, deleteImageCache,
    resetTimeline, setResetTimeline, AOI_ID, USER_KEY
  };

  return (
    <Router>
      <UserContext.Provider value={{...userContextValues}}>
        <NavBar mapMoveState={mapMoveState} startMapMove={startMapMove} changeMapMoveState={setMapMoveState}/>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route element={<><Outlet/><Footer /></>}>
              <Route path="/" element={<Home redirectToPath={redirectToPath} setRedirectToPath={setRedirectToPath} />} />
              <Route path="/contact-us" element={<Contact/>} />
              <Route path="/services" element={<Services />} />
              <Route path="/resources" element={<Resources/>} />
              <Route path="/help" element={user.isAuthenticated ? <Help/> : <Login />} />
              <Route path="/opt-out/:region/:code/:type" element={<Unsubscribe/>} />
              <Route path="/login" element={user.isAuthenticated ? <Home /> : <Login />} />
              <Route
                path="/profile"
                element={user.isAuthenticated ? <UserProfile section="profile" /> : <Login />}
              />
              <Route
                path="/settings"
                element={user.isAuthenticated ? <UserProfile section="settings" /> : <Login />}
              />
              <Route
                path="/manageevents"
                element={user.isAuthenticated ? <UserProfile section="manageevents" /> : <Login />}
              />
              <Route
                path="/mfa"
                element={user.isAuthenticated ? <UserProfile section="mfa" /> : <Login />}
              />
              <Route
                path="/billing"
                element={user.isAuthenticated ? <UserProfile section="billing" /> : <Login />}
              />
              <Route
                path="/subscriptions"
                element={user.isAuthenticated ? <UserProfile section="subscriptions" /> : <Login />}
              />
              <Route
                path="/help"
                element={user.isAuthenticated ? <UserProfile section="help" /> : <Login />}
              />
              <Route
                path="/clearcache"
                element={user.isAuthenticated ? <UserProfile section="clearcache" /> : <Login />}
              />
              <Route
                path="/configuration"
                element={user.isAdmin ? <UserProfile section="configuration" /> : <Home />}
              />
              <Route
                path="/domainconfig"
                element={user.isAdmin ? <UserProfile section="domainconfig" /> : <Home />}
              />
              <Route
                path="/datasource"
                element={user.isAdmin ? <UserProfile section="datasource" /> : <Home />}
              />
              <Route
                path="/landuse"
                element={user.isAdmin ? <UserProfile section="landuse" /> : <Home />}
              />
              <Route
                path="/usecase/add"
                element={user.isAdmin ? <UserProfile section="usecaseAdd" /> : <Home />}
              />
              <Route
                path="/usecase/edit/:name"
                element={user.isAdmin ? <UserProfile section="usecaseEdit" /> : <Home />}
              />
              <Route
                path="/usecase"
                element={user.isAdmin ? <UserProfile section="usecase" /> : <Home />}
              />
              <Route
                path="/roles"
                element={user.isAdmin ? <UserProfile section="roles" /> : <Home />}
              />
              <Route
                path="/addmember"
                element={Role.isAdmin(user.role) ? <UserProfile section="addmember" /> : <Home />}
              />
              <Route
                path="/members"
                element={Role.isAdmin(user.role) ? <UserProfile section="members" /> : <Home />}
              />
              <Route
                path="/send-message-admin"
                element={user.isDA || user.isCustomerService || user.isAdmin ? <UserProfile section="send-message-admin" /> : <Home />}
              />
              <Route
                path="/da-cockpit"
                element={user.isDA || user.isCustomerService || user.isAdmin ? <UserProfile section="da-cockpit" /> : <Home />}
              />
              <Route
                path="/cs-insights"
                element={user.isDA || user.isCustomerService || user.isAdmin ? <UserProfile section="cs-insights" /> : <Home />}
              />
              {appConfig.isPlatformApi && 
              <Route
                path="/swagger"
                element={user.isAuthenticated ? <Swagger /> : <Login />}
              /> }
              <Route path="/termsofservice" element={<TermsOfService/>} />
              <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
              <Route path="/payment/success" element={user.isAuthenticated ? <><PaymentSuccess /></> : <><Login /><Footer/></>}>
                <Route path=":paymentId" element={user.isAuthenticated ? <><PaymentSuccess /></> : <><Login /><Footer/></>} />
              </Route>
              <Route path="/payment/cancel" element={user.isAuthenticated ? <><PaymentCancel /></> : <><Login /><Footer/></>}></Route>
              <Route path="/payment/:paramAmount" element={user.isAuthenticated ? <><Payment /></> : <><Login /><Footer/></>} />
              <Route path="/payment" element={user.isAuthenticated ? <><Payment /></> : <><Login /><Footer/></>} />
              <Route path="/customer-payment" element={user.isAuthenticated ? <><CustomerPayment /></> : <><Login /><Footer/></>} />
              <Route path="/member/:authId" element={user.isDA || user.isCustomerService || user.isAdmin ? <Member /> : <Home />} />
              <Route path="*" element={<Error />} />
            </Route> 
            <Route
              path="/portfolio"
              element={user.isAuthenticated ? <><Dashboard key={counter} /></> : <><Login /><Footer/></>}
            >
              <Route path=":dashboardView" element={user.isAuthenticated ? <><Dashboard key={counter} /></> : <><Login /><Footer/></>} >
                <Route path=":singlegroup" element={user.isAuthenticated ? <><Dashboard key={counter} /></> : <><Login /><Footer/></>}>
                  <Route path=":aoiId" element={user.isAuthenticated ? <><Dashboard key={counter} /></> : <><Login /><Footer/></>} />
                </Route>
              </Route>
            </Route>
            <Route path="/dashboard" element={user.isAuthenticated ? <DashboardGail /> : <><Login /><Footer/></>} />
            {
              usecaseService.getUsecaseRoutes(user.isAuthenticated)
            }
            <Route path="/land-deformation" element={user.isAuthenticated ? <><TimelineLandDef /><Copyright/></> : <><Login /><Footer/></>} />
            <Route path="/leakage" element={user.isAuthenticated ? <><TimelineLeakage/><Copyright/></> : <><Login /><Footer/></>} />
          </Routes> 
        </Suspense>
        { openEditProfileModal && <EditProfileModal /> }
        { openLoginModal && <LoginModal /> }
        { showImageGalleryModal && <ImageGalleryModal setShowImageGalleryModal={setShowImageGalleryModal} galleryOptions={galleryOptions}/> }
        <Snackbar></Snackbar>
      </UserContext.Provider>
    </Router>
  );
}

export default App;