import React from 'react';
import { useNavigate } from 'react-router';
import CancelIcon from '../Assets/img/icon-transaction-cancelled.svg';

const PaymentCancel = (props) => {
    const navigate = useNavigate();

    return (
        <div className="page-body payments">
            <div className="container">
                <div className="form-content">
                    <div className="form-head">
                        <h3>Purchase Token(s)</h3>
                    </div>
                    <div className="payments-section">
                        <div className="failed-section">
                            <img src={CancelIcon} />
                            <h2>Transaction Canceled</h2>
                            <button type="button" className="btn btn-primary" onClick={() => navigate("/portfolio")}>Go to Dashboard</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PaymentCancel;