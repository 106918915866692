import {Modal} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import { adminService } from '../../Services/adminService';
import toast from 'react-hot-toast';

const MemberEdit = (props) => {
    const [roles, setRoles] = useState(null);
    const [userRoles, setUserRoles] = useState({});
    
    useEffect(() => {
        adminService.getRoles(props.user.region).then((data) => {
            setRoles(data);
            var luserRoles = {};
            data.map((role) => luserRoles[role.name] = false);
            props.user.roles.map((role) => luserRoles[role] = true);
            setUserRoles(luserRoles);
        }).catch((e) => {
            toast.error("Unable to get roles. Error: " + e);
            console.log("Unable to get roles", e);
        });
    }, [props.user]);

    const saveUser = () => {
        var postRoles = [];
        roles.map((role) => {
            if(userRoles[role.name] == true) {
                postRoles.push(role.name);
            }
        });
        adminService.updateUserRoles(props.mkey, props.region, postRoles).then((data) => {
            toast.success("Roles have been successfully updated");
            props.refresh();
            props.setShowModal(false);
        }).catch((e) => {
            toast.error("Unable to update the roles. Error: " + e);
            console.log(e);
        });
    };

    const handleInputChange = (event) => {
        setUserRoles({
          ...userRoles,
          [event.target.name]: event.target.checked
        });
    };

    const renderRole = (role) => {
        return <div className="custom-check" key={role.name}>
                    <label for={role.name}>
                        <input type="checkbox" checked={userRoles[role.name]} name={role.name} id={role.name} onChange={(e) => handleInputChange(e)}/>
                        <span>{role.name}</span>
                    </label>
                </div>;
    }

    return (
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName=""
                className="member-edit-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Edit Roles</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="AuthID">Auth ID: </label>
                                <div>{props.user["auth-id"]}</div>
                            </div>
                        </div>
                    </div>
                    <label className="form-label">Role</label>

                    <div className="row role-details">
                        <div className="col-md-6">
                            <div className="form-group">
                                <div className="roles-checkbox-group">
                                    {
                                        roles && roles.map((role) => renderRole(role))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.setShowModal(false)}>CANCEL</button>
                    <button type="button" className="btn btn-danger" onClick={() => saveUser()}>SUBMIT</button>
                </Modal.Footer>
            </Modal>
    );
}

export default MemberEdit;