import { useContext } from "react";
import DeleteIcon from '../../../Assets/img/icon-red-trash.svg';
import { getFileTypeIcon } from '../../Utils';
import { UserContext } from "../../../Contexts/UserContext";
import { apiService } from "../../../Services/apiService";

const AOIPropertyImages = (props) => {
    const { setShowImageGalleryModal, galleryOptions, setGalleryOptions, user } = useContext(UserContext);
    const dataUrl = apiService.getDataUrl(user.region);

    const showPropertyImagesGallery = (selectedFile) => {
        setShowImageGalleryModal(true);
        const galleryImages = props.files.map(file => {
            return { filepath: `${dataUrl}${file.filepath}`, filename: file.filename }
        });
        setGalleryOptions({
            ...galleryOptions,
            modalTitle: "Property Images",
            images: galleryImages,
            selectedFilename: selectedFile.filename
        });
    };

    return <div className="images-list">
                {
                    props.files != null && props.files.length > 0 && props.files.map((file) => (
                        <div className="img-card" key={file.filepath}>
                            <a className="delete-img" onClick={() => props.deleteFile(file.filepath, file.filename)}>
                                <img src={DeleteIcon} />
                            </a>
                            <img src={getFileTypeIcon(file.filetype ?? 'doc')} 
                                onClick={() => showPropertyImagesGallery(file)}
                                className="aoi-img" 
                                alt={file.filename}
                                attr-filepath={`${dataUrl}${file.filepath}`}
                                attr-isimage="true"
                                ref={props.thumbnailRef}
                                />
                        </div>
                    ))
                }
            </div>;
};

export default AOIPropertyImages;