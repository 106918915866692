import { useContext } from "react";
import { AOIContext } from "../../Contexts/AOIContext";

const RenderTag = (props) => {
    const {filter} = useContext(AOIContext);

    var firstIndex = props.tag.toLowerCase().indexOf(filter.filterText.toLowerCase());
    if(filter.filterText == "" || firstIndex < 0)
        return <h3>{props.tag}</h3>;
    
    const textLength = filter.filterText.length;

    return <h3 className="tag">
                <span>{props.tag.substring(0, firstIndex)}</span>
                <span className="highlight">{props.tag.substring(firstIndex, firstIndex + textLength)}</span>
                <span>{props.tag.substring(firstIndex + textLength)}</span>
            </h3>;
};

export default RenderTag;