import {Modal} from 'react-bootstrap';
import React, {useState} from 'react';
import toast from 'react-hot-toast';

const DomainConfigEdit = (props) => {
    const initialValues = {
        name: props.name,
        value: props.value
    };
    const [values, setValues] = useState(initialValues);

    const handleInputChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const save = () => {
        if(!validateFields())
            return;

        props.save(values.name, values.value);
    }

    const validateFields = () => {
        if(values.name.trim() == "")
        {
            toast.error("Please enter Name");
            return false;
        }
        if(values.value.trim() == "") {
            toast.error("Please enter Domain Configuration");
            return false;
        }
        try {
            JSON.parse(values.value);
        } catch {
            toast.error("Invalid JSON value for Attribute Schema");
            return false;
        }
        return true;
    }

    return (
        <>
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog"
                className="modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>
                            {
                                props.name == "" ? <>Add Domain Config</> : <>Update Domain Config</>
                            }
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="name">Domain Name <span className="required">*</span></label>
                                <input type="text" className="form-control" id="name" name="name" value={values.name} onChange={(e) => handleInputChange(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="value">Domain Config <span className="required">*</span></label>
                                <textarea id="value" name="value" cols="" rows="10" className="form-control" value={values.value} onChange={(e) => handleInputChange(e)}></textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" onClick={() => props.setShowModal(false)}>CLOSE</button>
                    <button type="button" className="btn btn-primary" onClick={() => save()}>SUBMIT</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DomainConfigEdit;