const allTimeZones = {
	"Europe/Andorra": {
		"name": "Europe/Andorra",
		"lat": 42.5,
		"long": 1.5167,
		"countries": [
			"AD"
		],
		"comments": ""
	},
	"Asia/Dubai": {
		"name": "Asia/Dubai",
		"lat": 25.3,
		"long": 55.3,
		"countries": [
			"AE",
			"OM",
			"RE",
			"SC",
			"TF"
		],
		"comments": "Crozet, Scattered Is"
	},
	"Asia/Kabul": {
		"name": "Asia/Kabul",
		"lat": 34.5167,
		"long": 69.2,
		"countries": [
			"AF"
		],
		"comments": ""
	},
	"Europe/Tirane": {
		"name": "Europe/Tirane",
		"lat": 41.3333,
		"long": 19.8333,
		"countries": [
			"AL"
		],
		"comments": ""
	},
	"Asia/Yerevan": {
		"name": "Asia/Yerevan",
		"lat": 40.1833,
		"long": 44.5,
		"countries": [
			"AM"
		],
		"comments": ""
	},
	"Antarctica/Casey": {
		"name": "Antarctica/Casey",
		"lat": -65.7167,
		"long": 110.5167,
		"countries": [
			"AQ"
		],
		"comments": "Casey"
	},
	"Antarctica/Davis": {
		"name": "Antarctica/Davis",
		"lat": -67.4167,
		"long": 77.9667,
		"countries": [
			"AQ"
		],
		"comments": "Davis"
	},
	"Antarctica/Mawson": {
		"name": "Antarctica/Mawson",
		"lat": -66.4,
		"long": 62.8833,
		"countries": [
			"AQ"
		],
		"comments": "Mawson"
	},
	"Antarctica/Palmer": {
		"name": "Antarctica/Palmer",
		"lat": -63.2,
		"long": -63.9,
		"countries": [
			"AQ"
		],
		"comments": "Palmer"
	},
	"Antarctica/Rothera": {
		"name": "Antarctica/Rothera",
		"lat": -66.4333,
		"long": -67.8667,
		"countries": [
			"AQ"
		],
		"comments": "Rothera"
	},
	"Antarctica/Troll": {
		"name": "Antarctica/Troll",
		"lat": -71.9886,
		"long": 2.535,
		"countries": [
			"AQ"
		],
		"comments": "Troll"
	},
	"America/Argentina/Buenos_Aires": {
		"name": "America/Argentina/Buenos_Aires",
		"lat": -33.4,
		"long": -57.55,
		"countries": [
			"AR"
		],
		"comments": "Buenos Aires (BA, CF)"
	},
	"America/Argentina/Cordoba": {
		"name": "America/Argentina/Cordoba",
		"lat": -30.6,
		"long": -63.8167,
		"countries": [
			"AR"
		],
		"comments": "most areas: CB, CC, CN, ER, FM, MN, SE, SF"
	},
	"America/Argentina/Salta": {
		"name": "America/Argentina/Salta",
		"lat": -23.2167,
		"long": -64.5833,
		"countries": [
			"AR"
		],
		"comments": "Salta (SA, LP, NQ, RN)"
	},
	"America/Argentina/Jujuy": {
		"name": "America/Argentina/Jujuy",
		"lat": -23.8167,
		"long": -64.7,
		"countries": [
			"AR"
		],
		"comments": "Jujuy (JY)"
	},
	"America/Argentina/Tucuman": {
		"name": "America/Argentina/Tucuman",
		"lat": -25.1833,
		"long": -64.7833,
		"countries": [
			"AR"
		],
		"comments": "Tucumán (TM)"
	},
	"America/Argentina/Catamarca": {
		"name": "America/Argentina/Catamarca",
		"lat": -27.5333,
		"long": -64.2167,
		"countries": [
			"AR"
		],
		"comments": "Catamarca (CT); Chubut (CH)"
	},
	"America/Argentina/La_Rioja": {
		"name": "America/Argentina/La_Rioja",
		"lat": -28.5667,
		"long": -65.15,
		"countries": [
			"AR"
		],
		"comments": "La Rioja (LR)"
	},
	"America/Argentina/San_Juan": {
		"name": "America/Argentina/San_Juan",
		"lat": -30.4667,
		"long": -67.4833,
		"countries": [
			"AR"
		],
		"comments": "San Juan (SJ)"
	},
	"America/Argentina/Mendoza": {
		"name": "America/Argentina/Mendoza",
		"lat": -31.1167,
		"long": -67.1833,
		"countries": [
			"AR"
		],
		"comments": "Mendoza (MZ)"
	},
	"America/Argentina/San_Luis": {
		"name": "America/Argentina/San_Luis",
		"lat": -32.6833,
		"long": -65.65,
		"countries": [
			"AR"
		],
		"comments": "San Luis (SL)"
	},
	"America/Argentina/Rio_Gallegos": {
		"name": "America/Argentina/Rio_Gallegos",
		"lat": -50.3667,
		"long": -68.7833,
		"countries": [
			"AR"
		],
		"comments": "Santa Cruz (SC)"
	},
	"America/Argentina/Ushuaia": {
		"name": "America/Argentina/Ushuaia",
		"lat": -53.2,
		"long": -67.7,
		"countries": [
			"AR"
		],
		"comments": "Tierra del Fuego (TF)"
	},
	"Pacific/Pago_Pago": {
		"name": "Pacific/Pago_Pago",
		"lat": -13.7333,
		"long": -169.3,
		"countries": [
			"AS",
			"UM"
		],
		"comments": "Midway"
	},
	"Europe/Vienna": {
		"name": "Europe/Vienna",
		"lat": 48.2167,
		"long": 16.3333,
		"countries": [
			"AT"
		],
		"comments": ""
	},
	"Australia/Lord_Howe": {
		"name": "Australia/Lord_Howe",
		"lat": -30.45,
		"long": 159.0833,
		"countries": [
			"AU"
		],
		"comments": "Lord Howe Island"
	},
	"Antarctica/Macquarie": {
		"name": "Antarctica/Macquarie",
		"lat": -53.5,
		"long": 158.95,
		"countries": [
			"AU"
		],
		"comments": "Macquarie Island"
	},
	"Australia/Hobart": {
		"name": "Australia/Hobart",
		"lat": -41.1167,
		"long": 147.3167,
		"countries": [
			"AU"
		],
		"comments": "Tasmania"
	},
	"Australia/Melbourne": {
		"name": "Australia/Melbourne",
		"lat": -36.1833,
		"long": 144.9667,
		"countries": [
			"AU"
		],
		"comments": "Victoria"
	},
	"Australia/Sydney": {
		"name": "Australia/Sydney",
		"lat": -32.1333,
		"long": 151.2167,
		"countries": [
			"AU"
		],
		"comments": "New South Wales (most areas)"
	},
	"Australia/Broken_Hill": {
		"name": "Australia/Broken_Hill",
		"lat": -30.05,
		"long": 141.45,
		"countries": [
			"AU"
		],
		"comments": "New South Wales (Yancowinna)"
	},
	"Australia/Brisbane": {
		"name": "Australia/Brisbane",
		"lat": -26.5333,
		"long": 153.0333,
		"countries": [
			"AU"
		],
		"comments": "Queensland (most areas)"
	},
	"Australia/Lindeman": {
		"name": "Australia/Lindeman",
		"lat": -19.7333,
		"long": 149,
		"countries": [
			"AU"
		],
		"comments": "Queensland (Whitsunday Islands)"
	},
	"Australia/Adelaide": {
		"name": "Australia/Adelaide",
		"lat": -33.0833,
		"long": 138.5833,
		"countries": [
			"AU"
		],
		"comments": "South Australia"
	},
	"Australia/Darwin": {
		"name": "Australia/Darwin",
		"lat": -11.5333,
		"long": 130.8333,
		"countries": [
			"AU"
		],
		"comments": "Northern Territory"
	},
	"Australia/Perth": {
		"name": "Australia/Perth",
		"lat": -30.05,
		"long": 115.85,
		"countries": [
			"AU"
		],
		"comments": "Western Australia (most areas)"
	},
	"Australia/Eucla": {
		"name": "Australia/Eucla",
		"lat": -30.2833,
		"long": 128.8667,
		"countries": [
			"AU"
		],
		"comments": "Western Australia (Eucla)"
	},
	"Asia/Baku": {
		"name": "Asia/Baku",
		"lat": 40.3833,
		"long": 49.85,
		"countries": [
			"AZ"
		],
		"comments": ""
	},
	"America/Barbados": {
		"name": "America/Barbados",
		"lat": 13.1,
		"long": -58.3833,
		"countries": [
			"BB"
		],
		"comments": ""
	},
	"Asia/Dhaka": {
		"name": "Asia/Dhaka",
		"lat": 23.7167,
		"long": 90.4167,
		"countries": [
			"BD"
		],
		"comments": ""
	},
	"Europe/Brussels": {
		"name": "Europe/Brussels",
		"lat": 50.8333,
		"long": 4.3333,
		"countries": [
			"BE",
			"LU",
			"NL"
		],
		"comments": ""
	},
	"Europe/Sofia": {
		"name": "Europe/Sofia",
		"lat": 42.6833,
		"long": 23.3167,
		"countries": [
			"BG"
		],
		"comments": ""
	},
	"Atlantic/Bermuda": {
		"name": "Atlantic/Bermuda",
		"lat": 32.2833,
		"long": -63.2333,
		"countries": [
			"BM"
		],
		"comments": ""
	},
	"America/La_Paz": {
		"name": "America/La_Paz",
		"lat": -15.5,
		"long": -67.85,
		"countries": [
			"BO"
		],
		"comments": ""
	},
	"America/Noronha": {
		"name": "America/Noronha",
		"lat": -2.15,
		"long": -31.5833,
		"countries": [
			"BR"
		],
		"comments": "Atlantic islands"
	},
	"America/Belem": {
		"name": "America/Belem",
		"lat": -0.55,
		"long": -47.5167,
		"countries": [
			"BR"
		],
		"comments": "Pará (east); Amapá"
	},
	"America/Fortaleza": {
		"name": "America/Fortaleza",
		"lat": -2.2833,
		"long": -37.5,
		"countries": [
			"BR"
		],
		"comments": "Brazil (northeast: MA, PI, CE, RN, PB)"
	},
	"America/Recife": {
		"name": "America/Recife",
		"lat": -7.95,
		"long": -33.1,
		"countries": [
			"BR"
		],
		"comments": "Pernambuco"
	},
	"America/Araguaina": {
		"name": "America/Araguaina",
		"lat": -6.8,
		"long": -47.8,
		"countries": [
			"BR"
		],
		"comments": "Tocantins"
	},
	"America/Maceio": {
		"name": "America/Maceio",
		"lat": -8.3333,
		"long": -34.2833,
		"countries": [
			"BR"
		],
		"comments": "Alagoas, Sergipe"
	},
	"America/Bahia": {
		"name": "America/Bahia",
		"lat": -11.0167,
		"long": -37.4833,
		"countries": [
			"BR"
		],
		"comments": "Bahia"
	},
	"America/Sao_Paulo": {
		"name": "America/Sao_Paulo",
		"lat": -22.4667,
		"long": -45.3833,
		"countries": [
			"BR"
		],
		"comments": "Brazil (southeast: GO, DF, MG, ES, RJ, SP, PR, SC, RS)"
	},
	"America/Campo_Grande": {
		"name": "America/Campo_Grande",
		"lat": -19.55,
		"long": -53.3833,
		"countries": [
			"BR"
		],
		"comments": "Mato Grosso do Sul"
	},
	"America/Cuiaba": {
		"name": "America/Cuiaba",
		"lat": -14.4167,
		"long": -55.9167,
		"countries": [
			"BR"
		],
		"comments": "Mato Grosso"
	},
	"America/Santarem": {
		"name": "America/Santarem",
		"lat": -1.5667,
		"long": -53.1333,
		"countries": [
			"BR"
		],
		"comments": "Pará (west)"
	},
	"America/Porto_Velho": {
		"name": "America/Porto_Velho",
		"lat": -7.2333,
		"long": -62.1,
		"countries": [
			"BR"
		],
		"comments": "Rondônia"
	},
	"America/Boa_Vista": {
		"name": "America/Boa_Vista",
		"lat": 2.8167,
		"long": -59.3333,
		"countries": [
			"BR"
		],
		"comments": "Roraima"
	},
	"America/Manaus": {
		"name": "America/Manaus",
		"lat": -2.8667,
		"long": -59.9833,
		"countries": [
			"BR"
		],
		"comments": "Amazonas (east)"
	},
	"America/Eirunepe": {
		"name": "America/Eirunepe",
		"lat": -5.3333,
		"long": -68.1333,
		"countries": [
			"BR"
		],
		"comments": "Amazonas (west)"
	},
	"America/Rio_Branco": {
		"name": "America/Rio_Branco",
		"lat": -8.0333,
		"long": -66.2,
		"countries": [
			"BR"
		],
		"comments": "Acre"
	},
	"Asia/Thimphu": {
		"name": "Asia/Thimphu",
		"lat": 27.4667,
		"long": 89.65,
		"countries": [
			"BT"
		],
		"comments": ""
	},
	"Europe/Minsk": {
		"name": "Europe/Minsk",
		"lat": 53.9,
		"long": 27.5667,
		"countries": [
			"BY"
		],
		"comments": ""
	},
	"America/Belize": {
		"name": "America/Belize",
		"lat": 17.5,
		"long": -87.8,
		"countries": [
			"BZ"
		],
		"comments": ""
	},
	"America/St_Johns": {
		"name": "America/St_Johns",
		"lat": 47.5667,
		"long": -51.2833,
		"countries": [
			"CA"
		],
		"comments": "Newfoundland; Labrador (southeast)"
	},
	"America/Halifax": {
		"name": "America/Halifax",
		"lat": 44.65,
		"long": -62.4,
		"countries": [
			"CA"
		],
		"comments": "Atlantic - NS (most areas); PE"
	},
	"America/Glace_Bay": {
		"name": "America/Glace_Bay",
		"lat": 46.2,
		"long": -58.05,
		"countries": [
			"CA"
		],
		"comments": "Atlantic - NS (Cape Breton)"
	},
	"America/Moncton": {
		"name": "America/Moncton",
		"lat": 46.1,
		"long": -63.2167,
		"countries": [
			"CA"
		],
		"comments": "Atlantic - New Brunswick"
	},
	"America/Goose_Bay": {
		"name": "America/Goose_Bay",
		"lat": 53.3333,
		"long": -59.5833,
		"countries": [
			"CA"
		],
		"comments": "Atlantic - Labrador (most areas)"
	},
	"America/Toronto": {
		"name": "America/Toronto",
		"lat": 43.65,
		"long": -78.6167,
		"countries": [
			"CA",
			"BS"
		],
		"comments": "Eastern - ON, QC (most areas)"
	},
	"America/Iqaluit": {
		"name": "America/Iqaluit",
		"lat": 63.7333,
		"long": -67.5333,
		"countries": [
			"CA"
		],
		"comments": "Eastern - NU (most areas)"
	},
	"America/Winnipeg": {
		"name": "America/Winnipeg",
		"lat": 49.8833,
		"long": -96.85,
		"countries": [
			"CA"
		],
		"comments": "Central - ON (west); Manitoba"
	},
	"America/Resolute": {
		"name": "America/Resolute",
		"lat": 74.6956,
		"long": -93.1708,
		"countries": [
			"CA"
		],
		"comments": "Central - NU (Resolute)"
	},
	"America/Rankin_Inlet": {
		"name": "America/Rankin_Inlet",
		"lat": 62.8167,
		"long": -91.9169,
		"countries": [
			"CA"
		],
		"comments": "Central - NU (central)"
	},
	"America/Regina": {
		"name": "America/Regina",
		"lat": 50.4,
		"long": -103.35,
		"countries": [
			"CA"
		],
		"comments": "CST - SK (most areas)"
	},
	"America/Swift_Current": {
		"name": "America/Swift_Current",
		"lat": 50.2833,
		"long": -106.1667,
		"countries": [
			"CA"
		],
		"comments": "CST - SK (midwest)"
	},
	"America/Edmonton": {
		"name": "America/Edmonton",
		"lat": 53.55,
		"long": -112.5333,
		"countries": [
			"CA"
		],
		"comments": "Mountain - AB; BC (E); NT (E); SK (W)"
	},
	"America/Cambridge_Bay": {
		"name": "America/Cambridge_Bay",
		"lat": 69.1139,
		"long": -104.9472,
		"countries": [
			"CA"
		],
		"comments": "Mountain - NU (west)"
	},
	"America/Inuvik": {
		"name": "America/Inuvik",
		"lat": 68.3497,
		"long": -132.2833,
		"countries": [
			"CA"
		],
		"comments": "Mountain - NT (west)"
	},
	"America/Dawson_Creek": {
		"name": "America/Dawson_Creek",
		"lat": 55.7667,
		"long": -119.7667,
		"countries": [
			"CA"
		],
		"comments": "MST - BC (Dawson Cr, Ft St John)"
	},
	"America/Fort_Nelson": {
		"name": "America/Fort_Nelson",
		"lat": 58.8,
		"long": -121.3,
		"countries": [
			"CA"
		],
		"comments": "MST - BC (Ft Nelson)"
	},
	"America/Whitehorse": {
		"name": "America/Whitehorse",
		"lat": 60.7167,
		"long": -134.95,
		"countries": [
			"CA"
		],
		"comments": "MST - Yukon (east)"
	},
	"America/Dawson": {
		"name": "America/Dawson",
		"lat": 64.0667,
		"long": -138.5833,
		"countries": [
			"CA"
		],
		"comments": "MST - Yukon (west)"
	},
	"America/Vancouver": {
		"name": "America/Vancouver",
		"lat": 49.2667,
		"long": -122.8833,
		"countries": [
			"CA"
		],
		"comments": "Pacific - BC (most areas)"
	},
	"Europe/Zurich": {
		"name": "Europe/Zurich",
		"lat": 47.3833,
		"long": 8.5333,
		"countries": [
			"CH",
			"DE",
			"LI"
		],
		"comments": "Büsingen"
	},
	"Africa/Abidjan": {
		"name": "Africa/Abidjan",
		"lat": 5.3167,
		"long": -3.9667,
		"countries": [
			"CI",
			"BF",
			"GH",
			"GM",
			"GN",
			"IS",
			"ML",
			"MR",
			"SH",
			"SL",
			"SN",
			"TG"
		],
		"comments": ""
	},
	"Pacific/Rarotonga": {
		"name": "Pacific/Rarotonga",
		"lat": -20.7667,
		"long": -158.2333,
		"countries": [
			"CK"
		],
		"comments": ""
	},
	"America/Santiago": {
		"name": "America/Santiago",
		"lat": -32.55,
		"long": -69.3333,
		"countries": [
			"CL"
		],
		"comments": "most of Chile"
	},
	"America/Punta_Arenas": {
		"name": "America/Punta_Arenas",
		"lat": -52.85,
		"long": -69.0833,
		"countries": [
			"CL"
		],
		"comments": "Region of Magallanes"
	},
	"Pacific/Easter": {
		"name": "Pacific/Easter",
		"lat": -26.85,
		"long": -108.5667,
		"countries": [
			"CL"
		],
		"comments": "Easter Island"
	},
	"Asia/Shanghai": {
		"name": "Asia/Shanghai",
		"lat": 31.2333,
		"long": 121.4667,
		"countries": [
			"CN"
		],
		"comments": "Beijing Time"
	},
	"Asia/Urumqi": {
		"name": "Asia/Urumqi",
		"lat": 43.8,
		"long": 87.5833,
		"countries": [
			"CN",
			"AQ"
		],
		"comments": "Xinjiang Time, Vostok"
	},
	"America/Bogota": {
		"name": "America/Bogota",
		"lat": 4.6,
		"long": -73.9167,
		"countries": [
			"CO"
		],
		"comments": ""
	},
	"America/Costa_Rica": {
		"name": "America/Costa_Rica",
		"lat": 9.9333,
		"long": -83.9167,
		"countries": [
			"CR"
		],
		"comments": ""
	},
	"America/Havana": {
		"name": "America/Havana",
		"lat": 23.1333,
		"long": -81.6333,
		"countries": [
			"CU"
		],
		"comments": ""
	},
	"Atlantic/Cape_Verde": {
		"name": "Atlantic/Cape_Verde",
		"lat": 14.9167,
		"long": -22.4833,
		"countries": [
			"CV"
		],
		"comments": ""
	},
	"Asia/Nicosia": {
		"name": "Asia/Nicosia",
		"lat": 35.1667,
		"long": 33.3667,
		"countries": [
			"CY"
		],
		"comments": "most of Cyprus"
	},
	"Asia/Famagusta": {
		"name": "Asia/Famagusta",
		"lat": 35.1167,
		"long": 33.95,
		"countries": [
			"CY"
		],
		"comments": "Northern Cyprus"
	},
	"Europe/Prague": {
		"name": "Europe/Prague",
		"lat": 50.0833,
		"long": 14.4333,
		"countries": [
			"CZ",
			"SK"
		],
		"comments": ""
	},
	"Europe/Berlin": {
		"name": "Europe/Berlin",
		"lat": 52.5,
		"long": 13.3667,
		"countries": [
			"DE",
			"DK",
			"NO",
			"SE",
			"SJ"
		],
		"comments": "most of Germany"
	},
	"America/Santo_Domingo": {
		"name": "America/Santo_Domingo",
		"lat": 18.4667,
		"long": -68.1,
		"countries": [
			"DO"
		],
		"comments": ""
	},
	"Africa/Algiers": {
		"name": "Africa/Algiers",
		"lat": 36.7833,
		"long": 3.05,
		"countries": [
			"DZ"
		],
		"comments": ""
	},
	"America/Guayaquil": {
		"name": "America/Guayaquil",
		"lat": -1.8333,
		"long": -78.1667,
		"countries": [
			"EC"
		],
		"comments": "Ecuador (mainland)"
	},
	"Pacific/Galapagos": {
		"name": "Pacific/Galapagos",
		"lat": 0.9,
		"long": -88.4,
		"countries": [
			"EC"
		],
		"comments": "Galápagos Islands"
	},
	"Europe/Tallinn": {
		"name": "Europe/Tallinn",
		"lat": 59.4167,
		"long": 24.75,
		"countries": [
			"EE"
		],
		"comments": ""
	},
	"Africa/Cairo": {
		"name": "Africa/Cairo",
		"lat": 30.05,
		"long": 31.25,
		"countries": [
			"EG"
		],
		"comments": ""
	},
	"Africa/El_Aaiun": {
		"name": "Africa/El_Aaiun",
		"lat": 27.15,
		"long": -12.8,
		"countries": [
			"EH"
		],
		"comments": ""
	},
	"Europe/Madrid": {
		"name": "Europe/Madrid",
		"lat": 40.4,
		"long": -2.3167,
		"countries": [
			"ES"
		],
		"comments": "Spain (mainland)"
	},
	"Africa/Ceuta": {
		"name": "Africa/Ceuta",
		"lat": 35.8833,
		"long": -4.6833,
		"countries": [
			"ES"
		],
		"comments": "Ceuta, Melilla"
	},
	"Atlantic/Canary": {
		"name": "Atlantic/Canary",
		"lat": 28.1,
		"long": -14.6,
		"countries": [
			"ES"
		],
		"comments": "Canary Islands"
	},
	"Europe/Helsinki": {
		"name": "Europe/Helsinki",
		"lat": 60.1667,
		"long": 24.9667,
		"countries": [
			"FI",
			"AX"
		],
		"comments": ""
	},
	"Pacific/Fiji": {
		"name": "Pacific/Fiji",
		"lat": -17.8667,
		"long": 178.4167,
		"countries": [
			"FJ"
		],
		"comments": ""
	},
	"Atlantic/Stanley": {
		"name": "Atlantic/Stanley",
		"lat": -50.3,
		"long": -56.15,
		"countries": [
			"FK"
		],
		"comments": ""
	},
	"Pacific/Kosrae": {
		"name": "Pacific/Kosrae",
		"lat": 5.3167,
		"long": 162.9833,
		"countries": [
			"FM"
		],
		"comments": "Kosrae"
	},
	"Atlantic/Faroe": {
		"name": "Atlantic/Faroe",
		"lat": 62.0167,
		"long": -5.2333,
		"countries": [
			"FO"
		],
		"comments": ""
	},
	"Europe/Paris": {
		"name": "Europe/Paris",
		"lat": 48.8667,
		"long": 2.3333,
		"countries": [
			"FR",
			"MC"
		],
		"comments": ""
	},
	"Europe/London": {
		"name": "Europe/London",
		"lat": 51.5083,
		"long": 0.1253,
		"countries": [
			"GB",
			"GG",
			"IM",
			"JE"
		],
		"comments": ""
	},
	"Asia/Tbilisi": {
		"name": "Asia/Tbilisi",
		"lat": 41.7167,
		"long": 44.8167,
		"countries": [
			"GE"
		],
		"comments": ""
	},
	"America/Cayenne": {
		"name": "America/Cayenne",
		"lat": 4.9333,
		"long": -51.6667,
		"countries": [
			"GF"
		],
		"comments": ""
	},
	"Europe/Gibraltar": {
		"name": "Europe/Gibraltar",
		"lat": 36.1333,
		"long": -4.65,
		"countries": [
			"GI"
		],
		"comments": ""
	},
	"America/Nuuk": {
		"name": "America/Nuuk",
		"lat": 64.1833,
		"long": -50.2667,
		"countries": [
			"GL"
		],
		"comments": "most of Greenland"
	},
	"America/Danmarkshavn": {
		"name": "America/Danmarkshavn",
		"lat": 76.7667,
		"long": -17.3333,
		"countries": [
			"GL"
		],
		"comments": "National Park (east coast)"
	},
	"America/Scoresbysund": {
		"name": "America/Scoresbysund",
		"lat": 70.4833,
		"long": -20.0333,
		"countries": [
			"GL"
		],
		"comments": "Scoresbysund/Ittoqqortoormiit"
	},
	"America/Thule": {
		"name": "America/Thule",
		"lat": 76.5667,
		"long": -67.2167,
		"countries": [
			"GL"
		],
		"comments": "Thule/Pituffik"
	},
	"Europe/Athens": {
		"name": "Europe/Athens",
		"lat": 37.9667,
		"long": 23.7167,
		"countries": [
			"GR"
		],
		"comments": ""
	},
	"Atlantic/South_Georgia": {
		"name": "Atlantic/South_Georgia",
		"lat": -53.7333,
		"long": -35.4667,
		"countries": [
			"GS"
		],
		"comments": ""
	},
	"America/Guatemala": {
		"name": "America/Guatemala",
		"lat": 14.6333,
		"long": -89.4833,
		"countries": [
			"GT"
		],
		"comments": ""
	},
	"Pacific/Guam": {
		"name": "Pacific/Guam",
		"lat": 13.4667,
		"long": 144.75,
		"countries": [
			"GU",
			"MP"
		],
		"comments": ""
	},
	"Africa/Bissau": {
		"name": "Africa/Bissau",
		"lat": 11.85,
		"long": -14.4167,
		"countries": [
			"GW"
		],
		"comments": ""
	},
	"America/Guyana": {
		"name": "America/Guyana",
		"lat": 6.8,
		"long": -57.8333,
		"countries": [
			"GY"
		],
		"comments": ""
	},
	"Asia/Hong_Kong": {
		"name": "Asia/Hong_Kong",
		"lat": 22.2833,
		"long": 114.15,
		"countries": [
			"HK"
		],
		"comments": ""
	},
	"America/Tegucigalpa": {
		"name": "America/Tegucigalpa",
		"lat": 14.1,
		"long": -86.7833,
		"countries": [
			"HN"
		],
		"comments": ""
	},
	"America/Port-au-Prince": {
		"name": "America/Port-au-Prince",
		"lat": 18.5333,
		"long": -71.6667,
		"countries": [
			"HT"
		],
		"comments": ""
	},
	"Europe/Budapest": {
		"name": "Europe/Budapest",
		"lat": 47.5,
		"long": 19.0833,
		"countries": [
			"HU"
		],
		"comments": ""
	},
	"Asia/Jakarta": {
		"name": "Asia/Jakarta",
		"lat": -5.8333,
		"long": 106.8,
		"countries": [
			"ID"
		],
		"comments": "Java, Sumatra"
	},
	"Asia/Pontianak": {
		"name": "Asia/Pontianak",
		"lat": 0.0333,
		"long": 109.3333,
		"countries": [
			"ID"
		],
		"comments": "Borneo (west, central)"
	},
	"Asia/Makassar": {
		"name": "Asia/Makassar",
		"lat": -4.8833,
		"long": 119.4,
		"countries": [
			"ID"
		],
		"comments": "Borneo (east, south); Sulawesi/Celebes, Bali, Nusa Tengarra; Timor (west)"
	},
	"Asia/Jayapura": {
		"name": "Asia/Jayapura",
		"lat": -1.4667,
		"long": 140.7,
		"countries": [
			"ID"
		],
		"comments": "New Guinea (West Papua / Irian Jaya); Malukus/Moluccas"
	},
	"Europe/Dublin": {
		"name": "Europe/Dublin",
		"lat": 53.3333,
		"long": -5.75,
		"countries": [
			"IE"
		],
		"comments": ""
	},
	"Asia/Jerusalem": {
		"name": "Asia/Jerusalem",
		"lat": 31.7806,
		"long": 35.2239,
		"countries": [
			"IL"
		],
		"comments": ""
	},
	"Asia/Kolkata": {
		"name": "Asia/Kolkata",
		"lat": 22.5333,
		"long": 88.3667,
		"countries": [
			"IN"
		],
		"comments": ""
	},
	"Asia/Calcutta": {
		"name": "Asia/Calcutta",
		"lat": 22.5333,
		"long": 88.3667,
		"countries": [
			"IN"
		],
		"comments": ""
	},
	"Indian/Chagos": {
		"name": "Indian/Chagos",
		"lat": -6.6667,
		"long": 72.4167,
		"countries": [
			"IO"
		],
		"comments": ""
	},
	"Asia/Baghdad": {
		"name": "Asia/Baghdad",
		"lat": 33.35,
		"long": 44.4167,
		"countries": [
			"IQ"
		],
		"comments": ""
	},
	"Asia/Tehran": {
		"name": "Asia/Tehran",
		"lat": 35.6667,
		"long": 51.4333,
		"countries": [
			"IR"
		],
		"comments": ""
	},
	"Europe/Rome": {
		"name": "Europe/Rome",
		"lat": 41.9,
		"long": 12.4833,
		"countries": [
			"IT",
			"SM",
			"VA"
		],
		"comments": ""
	},
	"America/Jamaica": {
		"name": "America/Jamaica",
		"lat": 17.9681,
		"long": -75.2067,
		"countries": [
			"JM"
		],
		"comments": ""
	},
	"Asia/Amman": {
		"name": "Asia/Amman",
		"lat": 31.95,
		"long": 35.9333,
		"countries": [
			"JO"
		],
		"comments": ""
	},
	"Asia/Tokyo": {
		"name": "Asia/Tokyo",
		"lat": 35.6544,
		"long": 139.7447,
		"countries": [
			"JP"
		],
		"comments": ""
	},
	"Africa/Nairobi": {
		"name": "Africa/Nairobi",
		"lat": -0.7167,
		"long": 36.8167,
		"countries": [
			"KE",
			"DJ",
			"ER",
			"ET",
			"KM",
			"MG",
			"SO",
			"TZ",
			"UG",
			"YT"
		],
		"comments": ""
	},
	"Asia/Bishkek": {
		"name": "Asia/Bishkek",
		"lat": 42.9,
		"long": 74.6,
		"countries": [
			"KG"
		],
		"comments": ""
	},
	"Pacific/Tarawa": {
		"name": "Pacific/Tarawa",
		"lat": 1.4167,
		"long": 173,
		"countries": [
			"KI",
			"MH",
			"TV",
			"UM",
			"WF"
		],
		"comments": "Gilberts, Marshalls, Wake"
	},
	"Pacific/Kanton": {
		"name": "Pacific/Kanton",
		"lat": -1.2167,
		"long": -170.2833,
		"countries": [
			"KI"
		],
		"comments": "Phoenix Islands"
	},
	"Pacific/Kiritimati": {
		"name": "Pacific/Kiritimati",
		"lat": 1.8667,
		"long": -156.6667,
		"countries": [
			"KI"
		],
		"comments": "Line Islands"
	},
	"Asia/Pyongyang": {
		"name": "Asia/Pyongyang",
		"lat": 39.0167,
		"long": 125.75,
		"countries": [
			"KP"
		],
		"comments": ""
	},
	"Asia/Seoul": {
		"name": "Asia/Seoul",
		"lat": 37.55,
		"long": 126.9667,
		"countries": [
			"KR"
		],
		"comments": ""
	},
	"Asia/Almaty": {
		"name": "Asia/Almaty",
		"lat": 43.25,
		"long": 76.95,
		"countries": [
			"KZ"
		],
		"comments": "most of Kazakhstan"
	},
	"Asia/Qyzylorda": {
		"name": "Asia/Qyzylorda",
		"lat": 44.8,
		"long": 65.4667,
		"countries": [
			"KZ"
		],
		"comments": "Qyzylorda/Kyzylorda/Kzyl-Orda"
	},
	"Asia/Qostanay": {
		"name": "Asia/Qostanay",
		"lat": 53.2,
		"long": 63.6167,
		"countries": [
			"KZ"
		],
		"comments": "Qostanay/Kostanay/Kustanay"
	},
	"Asia/Aqtobe": {
		"name": "Asia/Aqtobe",
		"lat": 50.2833,
		"long": 57.1667,
		"countries": [
			"KZ"
		],
		"comments": "Aqtöbe/Aktobe"
	},
	"Asia/Aqtau": {
		"name": "Asia/Aqtau",
		"lat": 44.5167,
		"long": 50.2667,
		"countries": [
			"KZ"
		],
		"comments": "Mangghystaū/Mankistau"
	},
	"Asia/Atyrau": {
		"name": "Asia/Atyrau",
		"lat": 47.1167,
		"long": 51.9333,
		"countries": [
			"KZ"
		],
		"comments": "Atyraū/Atirau/Gur'yev"
	},
	"Asia/Oral": {
		"name": "Asia/Oral",
		"lat": 51.2167,
		"long": 51.35,
		"countries": [
			"KZ"
		],
		"comments": "West Kazakhstan"
	},
	"Asia/Beirut": {
		"name": "Asia/Beirut",
		"lat": 33.8833,
		"long": 35.5,
		"countries": [
			"LB"
		],
		"comments": ""
	},
	"Asia/Colombo": {
		"name": "Asia/Colombo",
		"lat": 6.9333,
		"long": 79.85,
		"countries": [
			"LK"
		],
		"comments": ""
	},
	"Africa/Monrovia": {
		"name": "Africa/Monrovia",
		"lat": 6.3,
		"long": -9.2167,
		"countries": [
			"LR"
		],
		"comments": ""
	},
	"Europe/Vilnius": {
		"name": "Europe/Vilnius",
		"lat": 54.6833,
		"long": 25.3167,
		"countries": [
			"LT"
		],
		"comments": ""
	},
	"Europe/Riga": {
		"name": "Europe/Riga",
		"lat": 56.95,
		"long": 24.1,
		"countries": [
			"LV"
		],
		"comments": ""
	},
	"Africa/Tripoli": {
		"name": "Africa/Tripoli",
		"lat": 32.9,
		"long": 13.1833,
		"countries": [
			"LY"
		],
		"comments": ""
	},
	"Africa/Casablanca": {
		"name": "Africa/Casablanca",
		"lat": 33.65,
		"long": -6.4167,
		"countries": [
			"MA"
		],
		"comments": ""
	},
	"Europe/Chisinau": {
		"name": "Europe/Chisinau",
		"lat": 47,
		"long": 28.8333,
		"countries": [
			"MD"
		],
		"comments": ""
	},
	"Pacific/Kwajalein": {
		"name": "Pacific/Kwajalein",
		"lat": 9.0833,
		"long": 167.3333,
		"countries": [
			"MH"
		],
		"comments": "Kwajalein"
	},
	"Asia/Yangon": {
		"name": "Asia/Yangon",
		"lat": 16.7833,
		"long": 96.1667,
		"countries": [
			"MM",
			"CC"
		],
		"comments": ""
	},
	"Asia/Ulaanbaatar": {
		"name": "Asia/Ulaanbaatar",
		"lat": 47.9167,
		"long": 106.8833,
		"countries": [
			"MN"
		],
		"comments": "most of Mongolia"
	},
	"Asia/Hovd": {
		"name": "Asia/Hovd",
		"lat": 48.0167,
		"long": 91.65,
		"countries": [
			"MN"
		],
		"comments": "Bayan-Ölgii, Govi-Altai, Hovd, Uvs, Zavkhan"
	},
	"Asia/Choibalsan": {
		"name": "Asia/Choibalsan",
		"lat": 48.0667,
		"long": 114.5,
		"countries": [
			"MN"
		],
		"comments": "Dornod, Sükhbaatar"
	},
	"Asia/Macau": {
		"name": "Asia/Macau",
		"lat": 22.1972,
		"long": 113.5417,
		"countries": [
			"MO"
		],
		"comments": ""
	},
	"America/Martinique": {
		"name": "America/Martinique",
		"lat": 14.6,
		"long": -60.9167,
		"countries": [
			"MQ"
		],
		"comments": ""
	},
	"Europe/Malta": {
		"name": "Europe/Malta",
		"lat": 35.9,
		"long": 14.5167,
		"countries": [
			"MT"
		],
		"comments": ""
	},
	"Indian/Mauritius": {
		"name": "Indian/Mauritius",
		"lat": -19.8333,
		"long": 57.5,
		"countries": [
			"MU"
		],
		"comments": ""
	},
	"Indian/Maldives": {
		"name": "Indian/Maldives",
		"lat": 4.1667,
		"long": 73.5,
		"countries": [
			"MV",
			"TF"
		],
		"comments": "Kerguelen, St Paul I, Amsterdam I"
	},
	"America/Mexico_City": {
		"name": "America/Mexico_City",
		"lat": 19.4,
		"long": -98.85,
		"countries": [
			"MX"
		],
		"comments": "Central Mexico"
	},
	"America/Cancun": {
		"name": "America/Cancun",
		"lat": 21.0833,
		"long": -85.2333,
		"countries": [
			"MX"
		],
		"comments": "Quintana Roo"
	},
	"America/Merida": {
		"name": "America/Merida",
		"lat": 20.9667,
		"long": -88.3833,
		"countries": [
			"MX"
		],
		"comments": "Campeche, Yucatán"
	},
	"America/Monterrey": {
		"name": "America/Monterrey",
		"lat": 25.6667,
		"long": -99.6833,
		"countries": [
			"MX"
		],
		"comments": "Durango; Coahuila, Nuevo León, Tamaulipas (most areas)"
	},
	"America/Matamoros": {
		"name": "America/Matamoros",
		"lat": 25.8333,
		"long": -96.5,
		"countries": [
			"MX"
		],
		"comments": "Coahuila, Nuevo León, Tamaulipas (US border)"
	},
	"America/Chihuahua": {
		"name": "America/Chihuahua",
		"lat": 28.6333,
		"long": -105.9167,
		"countries": [
			"MX"
		],
		"comments": "Chihuahua (most areas)"
	},
	"America/Ciudad_Juarez": {
		"name": "America/Ciudad_Juarez",
		"lat": 31.7333,
		"long": -105.5167,
		"countries": [
			"MX"
		],
		"comments": "Chihuahua (US border - west)"
	},
	"America/Ojinaga": {
		"name": "America/Ojinaga",
		"lat": 29.5667,
		"long": -103.5833,
		"countries": [
			"MX"
		],
		"comments": "Chihuahua (US border - east)"
	},
	"America/Mazatlan": {
		"name": "America/Mazatlan",
		"lat": 23.2167,
		"long": -105.5833,
		"countries": [
			"MX"
		],
		"comments": "Baja California Sur, Nayarit (most areas), Sinaloa"
	},
	"America/Bahia_Banderas": {
		"name": "America/Bahia_Banderas",
		"lat": 20.8,
		"long": -104.75,
		"countries": [
			"MX"
		],
		"comments": "Bahía de Banderas"
	},
	"America/Hermosillo": {
		"name": "America/Hermosillo",
		"lat": 29.0667,
		"long": -109.0333,
		"countries": [
			"MX"
		],
		"comments": "Sonora"
	},
	"America/Tijuana": {
		"name": "America/Tijuana",
		"lat": 32.5333,
		"long": -116.9833,
		"countries": [
			"MX"
		],
		"comments": "Baja California"
	},
	"Asia/Kuching": {
		"name": "Asia/Kuching",
		"lat": 1.55,
		"long": 110.3333,
		"countries": [
			"MY",
			"BN"
		],
		"comments": "Sabah, Sarawak"
	},
	"Africa/Maputo": {
		"name": "Africa/Maputo",
		"lat": -24.0333,
		"long": 32.5833,
		"countries": [
			"MZ",
			"BI",
			"BW",
			"CD",
			"MW",
			"RW",
			"ZM",
			"ZW"
		],
		"comments": "Central Africa Time"
	},
	"Africa/Windhoek": {
		"name": "Africa/Windhoek",
		"lat": -21.4333,
		"long": 17.1,
		"countries": [
			"NA"
		],
		"comments": ""
	},
	"Pacific/Noumea": {
		"name": "Pacific/Noumea",
		"lat": -21.7333,
		"long": 166.45,
		"countries": [
			"NC"
		],
		"comments": ""
	},
	"Pacific/Norfolk": {
		"name": "Pacific/Norfolk",
		"lat": -28.95,
		"long": 167.9667,
		"countries": [
			"NF"
		],
		"comments": ""
	},
	"Africa/Lagos": {
		"name": "Africa/Lagos",
		"lat": 6.45,
		"long": 3.4,
		"countries": [
			"NG",
			"AO",
			"BJ",
			"CD",
			"CF",
			"CG",
			"CM",
			"GA",
			"GQ",
			"NE"
		],
		"comments": "West Africa Time"
	},
	"America/Managua": {
		"name": "America/Managua",
		"lat": 12.15,
		"long": -85.7167,
		"countries": [
			"NI"
		],
		"comments": ""
	},
	"Asia/Kathmandu": {
		"name": "Asia/Kathmandu",
		"lat": 27.7167,
		"long": 85.3167,
		"countries": [
			"NP"
		],
		"comments": ""
	},
	"Pacific/Nauru": {
		"name": "Pacific/Nauru",
		"lat": 0.5167,
		"long": 166.9167,
		"countries": [
			"NR"
		],
		"comments": ""
	},
	"Pacific/Niue": {
		"name": "Pacific/Niue",
		"lat": -18.9833,
		"long": -168.0833,
		"countries": [
			"NU"
		],
		"comments": ""
	},
	"Pacific/Auckland": {
		"name": "Pacific/Auckland",
		"lat": -35.1333,
		"long": 174.7667,
		"countries": [
			"NZ",
			"AQ"
		],
		"comments": "New Zealand time"
	},
	"Pacific/Chatham": {
		"name": "Pacific/Chatham",
		"lat": -42.05,
		"long": -175.45,
		"countries": [
			"NZ"
		],
		"comments": "Chatham Islands"
	},
	"America/Panama": {
		"name": "America/Panama",
		"lat": 8.9667,
		"long": -78.4667,
		"countries": [
			"PA",
			"CA",
			"KY"
		],
		"comments": "EST - ON (Atikokan), NU (Coral H)"
	},
	"America/Lima": {
		"name": "America/Lima",
		"lat": -11.95,
		"long": -76.95,
		"countries": [
			"PE"
		],
		"comments": ""
	},
	"Pacific/Tahiti": {
		"name": "Pacific/Tahiti",
		"lat": -16.4667,
		"long": -148.4333,
		"countries": [
			"PF"
		],
		"comments": "Society Islands"
	},
	"Pacific/Marquesas": {
		"name": "Pacific/Marquesas",
		"lat": -9,
		"long": -138.5,
		"countries": [
			"PF"
		],
		"comments": "Marquesas Islands"
	},
	"Pacific/Gambier": {
		"name": "Pacific/Gambier",
		"lat": -22.8667,
		"long": -133.05,
		"countries": [
			"PF"
		],
		"comments": "Gambier Islands"
	},
	"Pacific/Port_Moresby": {
		"name": "Pacific/Port_Moresby",
		"lat": -8.5,
		"long": 147.1667,
		"countries": [
			"PG",
			"AQ",
			"FM"
		],
		"comments": "Papua New Guinea (most areas), Chuuk, Yap, Dumont d'Urville"
	},
	"Pacific/Bougainville": {
		"name": "Pacific/Bougainville",
		"lat": -5.7833,
		"long": 155.5667,
		"countries": [
			"PG"
		],
		"comments": "Bougainville"
	},
	"Asia/Manila": {
		"name": "Asia/Manila",
		"lat": 14.5833,
		"long": 121,
		"countries": [
			"PH"
		],
		"comments": ""
	},
	"Asia/Karachi": {
		"name": "Asia/Karachi",
		"lat": 24.8667,
		"long": 67.05,
		"countries": [
			"PK"
		],
		"comments": ""
	},
	"Europe/Warsaw": {
		"name": "Europe/Warsaw",
		"lat": 52.25,
		"long": 21,
		"countries": [
			"PL"
		],
		"comments": ""
	},
	"America/Miquelon": {
		"name": "America/Miquelon",
		"lat": 47.05,
		"long": -55.6667,
		"countries": [
			"PM"
		],
		"comments": ""
	},
	"Pacific/Pitcairn": {
		"name": "Pacific/Pitcairn",
		"lat": -24.9333,
		"long": -129.9167,
		"countries": [
			"PN"
		],
		"comments": ""
	},
	"America/Puerto_Rico": {
		"name": "America/Puerto_Rico",
		"lat": 18.4683,
		"long": -65.8939,
		"countries": [
			"PR",
			"AG",
			"CA",
			"AI",
			"AW",
			"BL",
			"BQ",
			"CW",
			"DM",
			"GD",
			"GP",
			"KN",
			"LC",
			"MF",
			"MS",
			"SX",
			"TT",
			"VC",
			"VG",
			"VI"
		],
		"comments": "AST"
	},
	"Asia/Gaza": {
		"name": "Asia/Gaza",
		"lat": 31.5,
		"long": 34.4667,
		"countries": [
			"PS"
		],
		"comments": "Gaza Strip"
	},
	"Asia/Hebron": {
		"name": "Asia/Hebron",
		"lat": 31.5333,
		"long": 35.095,
		"countries": [
			"PS"
		],
		"comments": "West Bank"
	},
	"Europe/Lisbon": {
		"name": "Europe/Lisbon",
		"lat": 38.7167,
		"long": -8.8667,
		"countries": [
			"PT"
		],
		"comments": "Portugal (mainland)"
	},
	"Atlantic/Madeira": {
		"name": "Atlantic/Madeira",
		"lat": 32.6333,
		"long": -15.1,
		"countries": [
			"PT"
		],
		"comments": "Madeira Islands"
	},
	"Atlantic/Azores": {
		"name": "Atlantic/Azores",
		"lat": 37.7333,
		"long": -24.3333,
		"countries": [
			"PT"
		],
		"comments": "Azores"
	},
	"Pacific/Palau": {
		"name": "Pacific/Palau",
		"lat": 7.3333,
		"long": 134.4833,
		"countries": [
			"PW"
		],
		"comments": ""
	},
	"America/Asuncion": {
		"name": "America/Asuncion",
		"lat": -24.7333,
		"long": -56.3333,
		"countries": [
			"PY"
		],
		"comments": ""
	},
	"Asia/Qatar": {
		"name": "Asia/Qatar",
		"lat": 25.2833,
		"long": 51.5333,
		"countries": [
			"QA",
			"BH"
		],
		"comments": ""
	},
	"Europe/Bucharest": {
		"name": "Europe/Bucharest",
		"lat": 44.4333,
		"long": 26.1,
		"countries": [
			"RO"
		],
		"comments": ""
	},
	"Europe/Belgrade": {
		"name": "Europe/Belgrade",
		"lat": 44.8333,
		"long": 20.5,
		"countries": [
			"RS",
			"BA",
			"HR",
			"ME",
			"MK",
			"SI"
		],
		"comments": ""
	},
	"Europe/Kaliningrad": {
		"name": "Europe/Kaliningrad",
		"lat": 54.7167,
		"long": 20.5,
		"countries": [
			"RU"
		],
		"comments": "MSK-01 - Kaliningrad"
	},
	"Europe/Moscow": {
		"name": "Europe/Moscow",
		"lat": 55.7558,
		"long": 37.6178,
		"countries": [
			"RU"
		],
		"comments": "MSK+00 - Moscow area"
	},
	"Europe/Simferopol": {
		"name": "Europe/Simferopol",
		"lat": 44.95,
		"long": 34.1,
		"countries": [
			"RU",
			"UA"
		],
		"comments": "Crimea"
	},
	"Europe/Kirov": {
		"name": "Europe/Kirov",
		"lat": 58.6,
		"long": 49.65,
		"countries": [
			"RU"
		],
		"comments": "MSK+00 - Kirov"
	},
	"Europe/Volgograd": {
		"name": "Europe/Volgograd",
		"lat": 48.7333,
		"long": 44.4167,
		"countries": [
			"RU"
		],
		"comments": "MSK+00 - Volgograd"
	},
	"Europe/Astrakhan": {
		"name": "Europe/Astrakhan",
		"lat": 46.35,
		"long": 48.05,
		"countries": [
			"RU"
		],
		"comments": "MSK+01 - Astrakhan"
	},
	"Europe/Saratov": {
		"name": "Europe/Saratov",
		"lat": 51.5667,
		"long": 46.0333,
		"countries": [
			"RU"
		],
		"comments": "MSK+01 - Saratov"
	},
	"Europe/Ulyanovsk": {
		"name": "Europe/Ulyanovsk",
		"lat": 54.3333,
		"long": 48.4,
		"countries": [
			"RU"
		],
		"comments": "MSK+01 - Ulyanovsk"
	},
	"Europe/Samara": {
		"name": "Europe/Samara",
		"lat": 53.2,
		"long": 50.15,
		"countries": [
			"RU"
		],
		"comments": "MSK+01 - Samara, Udmurtia"
	},
	"Asia/Yekaterinburg": {
		"name": "Asia/Yekaterinburg",
		"lat": 56.85,
		"long": 60.6,
		"countries": [
			"RU"
		],
		"comments": "MSK+02 - Urals"
	},
	"Asia/Omsk": {
		"name": "Asia/Omsk",
		"lat": 55,
		"long": 73.4,
		"countries": [
			"RU"
		],
		"comments": "MSK+03 - Omsk"
	},
	"Asia/Novosibirsk": {
		"name": "Asia/Novosibirsk",
		"lat": 55.0333,
		"long": 82.9167,
		"countries": [
			"RU"
		],
		"comments": "MSK+04 - Novosibirsk"
	},
	"Asia/Barnaul": {
		"name": "Asia/Barnaul",
		"lat": 53.3667,
		"long": 83.75,
		"countries": [
			"RU"
		],
		"comments": "MSK+04 - Altai"
	},
	"Asia/Tomsk": {
		"name": "Asia/Tomsk",
		"lat": 56.5,
		"long": 84.9667,
		"countries": [
			"RU"
		],
		"comments": "MSK+04 - Tomsk"
	},
	"Asia/Novokuznetsk": {
		"name": "Asia/Novokuznetsk",
		"lat": 53.75,
		"long": 87.1167,
		"countries": [
			"RU"
		],
		"comments": "MSK+04 - Kemerovo"
	},
	"Asia/Krasnoyarsk": {
		"name": "Asia/Krasnoyarsk",
		"lat": 56.0167,
		"long": 92.8333,
		"countries": [
			"RU"
		],
		"comments": "MSK+04 - Krasnoyarsk area"
	},
	"Asia/Irkutsk": {
		"name": "Asia/Irkutsk",
		"lat": 52.2667,
		"long": 104.3333,
		"countries": [
			"RU"
		],
		"comments": "MSK+05 - Irkutsk, Buryatia"
	},
	"Asia/Chita": {
		"name": "Asia/Chita",
		"lat": 52.05,
		"long": 113.4667,
		"countries": [
			"RU"
		],
		"comments": "MSK+06 - Zabaykalsky"
	},
	"Asia/Yakutsk": {
		"name": "Asia/Yakutsk",
		"lat": 62,
		"long": 129.6667,
		"countries": [
			"RU"
		],
		"comments": "MSK+06 - Lena River"
	},
	"Asia/Khandyga": {
		"name": "Asia/Khandyga",
		"lat": 62.6564,
		"long": 135.5539,
		"countries": [
			"RU"
		],
		"comments": "MSK+06 - Tomponsky, Ust-Maysky"
	},
	"Asia/Vladivostok": {
		"name": "Asia/Vladivostok",
		"lat": 43.1667,
		"long": 131.9333,
		"countries": [
			"RU"
		],
		"comments": "MSK+07 - Amur River"
	},
	"Asia/Ust-Nera": {
		"name": "Asia/Ust-Nera",
		"lat": 64.5603,
		"long": 143.2267,
		"countries": [
			"RU"
		],
		"comments": "MSK+07 - Oymyakonsky"
	},
	"Asia/Magadan": {
		"name": "Asia/Magadan",
		"lat": 59.5667,
		"long": 150.8,
		"countries": [
			"RU"
		],
		"comments": "MSK+08 - Magadan"
	},
	"Asia/Sakhalin": {
		"name": "Asia/Sakhalin",
		"lat": 46.9667,
		"long": 142.7,
		"countries": [
			"RU"
		],
		"comments": "MSK+08 - Sakhalin Island"
	},
	"Asia/Srednekolymsk": {
		"name": "Asia/Srednekolymsk",
		"lat": 67.4667,
		"long": 153.7167,
		"countries": [
			"RU"
		],
		"comments": "MSK+08 - Sakha (E); N Kuril Is"
	},
	"Asia/Kamchatka": {
		"name": "Asia/Kamchatka",
		"lat": 53.0167,
		"long": 158.65,
		"countries": [
			"RU"
		],
		"comments": "MSK+09 - Kamchatka"
	},
	"Asia/Anadyr": {
		"name": "Asia/Anadyr",
		"lat": 64.75,
		"long": 177.4833,
		"countries": [
			"RU"
		],
		"comments": "MSK+09 - Bering Sea"
	},
	"Asia/Riyadh": {
		"name": "Asia/Riyadh",
		"lat": 24.6333,
		"long": 46.7167,
		"countries": [
			"SA",
			"AQ",
			"KW",
			"YE"
		],
		"comments": "Syowa"
	},
	"Pacific/Guadalcanal": {
		"name": "Pacific/Guadalcanal",
		"lat": -8.4667,
		"long": 160.2,
		"countries": [
			"SB",
			"FM"
		],
		"comments": "Pohnpei"
	},
	"Africa/Khartoum": {
		"name": "Africa/Khartoum",
		"lat": 15.6,
		"long": 32.5333,
		"countries": [
			"SD"
		],
		"comments": ""
	},
	"Asia/Singapore": {
		"name": "Asia/Singapore",
		"lat": 1.2833,
		"long": 103.85,
		"countries": [
			"SG",
			"MY"
		],
		"comments": "peninsular Malaysia"
	},
	"America/Paramaribo": {
		"name": "America/Paramaribo",
		"lat": 5.8333,
		"long": -54.8333,
		"countries": [
			"SR"
		],
		"comments": ""
	},
	"Africa/Juba": {
		"name": "Africa/Juba",
		"lat": 4.85,
		"long": 31.6167,
		"countries": [
			"SS"
		],
		"comments": ""
	},
	"Africa/Sao_Tome": {
		"name": "Africa/Sao_Tome",
		"lat": 0.3333,
		"long": 6.7333,
		"countries": [
			"ST"
		],
		"comments": ""
	},
	"America/El_Salvador": {
		"name": "America/El_Salvador",
		"lat": 13.7,
		"long": -88.8,
		"countries": [
			"SV"
		],
		"comments": ""
	},
	"Asia/Damascus": {
		"name": "Asia/Damascus",
		"lat": 33.5,
		"long": 36.3,
		"countries": [
			"SY"
		],
		"comments": ""
	},
	"America/Grand_Turk": {
		"name": "America/Grand_Turk",
		"lat": 21.4667,
		"long": -70.8667,
		"countries": [
			"TC"
		],
		"comments": ""
	},
	"Africa/Ndjamena": {
		"name": "Africa/Ndjamena",
		"lat": 12.1167,
		"long": 15.05,
		"countries": [
			"TD"
		],
		"comments": ""
	},
	"Asia/Bangkok": {
		"name": "Asia/Bangkok",
		"lat": 13.75,
		"long": 100.5167,
		"countries": [
			"TH",
			"CX",
			"KH",
			"LA",
			"VN"
		],
		"comments": "north Vietnam"
	},
	"Asia/Dushanbe": {
		"name": "Asia/Dushanbe",
		"lat": 38.5833,
		"long": 68.8,
		"countries": [
			"TJ"
		],
		"comments": ""
	},
	"Pacific/Fakaofo": {
		"name": "Pacific/Fakaofo",
		"lat": -8.6333,
		"long": -170.7667,
		"countries": [
			"TK"
		],
		"comments": ""
	},
	"Asia/Dili": {
		"name": "Asia/Dili",
		"lat": -7.45,
		"long": 125.5833,
		"countries": [
			"TL"
		],
		"comments": ""
	},
	"Asia/Ashgabat": {
		"name": "Asia/Ashgabat",
		"lat": 37.95,
		"long": 58.3833,
		"countries": [
			"TM"
		],
		"comments": ""
	},
	"Africa/Tunis": {
		"name": "Africa/Tunis",
		"lat": 36.8,
		"long": 10.1833,
		"countries": [
			"TN"
		],
		"comments": ""
	},
	"Pacific/Tongatapu": {
		"name": "Pacific/Tongatapu",
		"lat": -20.8667,
		"long": -174.8,
		"countries": [
			"TO"
		],
		"comments": ""
	},
	"Europe/Istanbul": {
		"name": "Europe/Istanbul",
		"lat": 41.0167,
		"long": 28.9667,
		"countries": [
			"TR"
		],
		"comments": ""
	},
	"Asia/Taipei": {
		"name": "Asia/Taipei",
		"lat": 25.05,
		"long": 121.5,
		"countries": [
			"TW"
		],
		"comments": ""
	},
	"Europe/Kyiv": {
		"name": "Europe/Kyiv",
		"lat": 50.4333,
		"long": 30.5167,
		"countries": [
			"UA"
		],
		"comments": "most of Ukraine"
	},
	"America/New_York": {
		"name": "America/New_York",
		"lat": 40.7142,
		"long": -73.9936,
		"countries": [
			"US"
		],
		"comments": "Eastern (most areas)"
	},
	"America/Detroit": {
		"name": "America/Detroit",
		"lat": 42.3314,
		"long": -82.9542,
		"countries": [
			"US"
		],
		"comments": "Eastern - MI (most areas)"
	},
	"America/Kentucky/Louisville": {
		"name": "America/Kentucky/Louisville",
		"lat": 38.2542,
		"long": -84.2406,
		"countries": [
			"US"
		],
		"comments": "Eastern - KY (Louisville area)"
	},
	"America/Kentucky/Monticello": {
		"name": "America/Kentucky/Monticello",
		"lat": 36.8297,
		"long": -83.1508,
		"countries": [
			"US"
		],
		"comments": "Eastern - KY (Wayne)"
	},
	"America/Indiana/Indianapolis": {
		"name": "America/Indiana/Indianapolis",
		"lat": 39.7683,
		"long": -85.8419,
		"countries": [
			"US"
		],
		"comments": "Eastern - IN (most areas)"
	},
	"America/Indiana/Vincennes": {
		"name": "America/Indiana/Vincennes",
		"lat": 38.6772,
		"long": -86.4714,
		"countries": [
			"US"
		],
		"comments": "Eastern - IN (Da, Du, K, Mn)"
	},
	"America/Indiana/Winamac": {
		"name": "America/Indiana/Winamac",
		"lat": 41.0514,
		"long": -85.3969,
		"countries": [
			"US"
		],
		"comments": "Eastern - IN (Pulaski)"
	},
	"America/Indiana/Marengo": {
		"name": "America/Indiana/Marengo",
		"lat": 38.3756,
		"long": -85.6553,
		"countries": [
			"US"
		],
		"comments": "Eastern - IN (Crawford)"
	},
	"America/Indiana/Petersburg": {
		"name": "America/Indiana/Petersburg",
		"lat": 38.4919,
		"long": -86.7214,
		"countries": [
			"US"
		],
		"comments": "Eastern - IN (Pike)"
	},
	"America/Indiana/Vevay": {
		"name": "America/Indiana/Vevay",
		"lat": 38.7478,
		"long": -84.9328,
		"countries": [
			"US"
		],
		"comments": "Eastern - IN (Switzerland)"
	},
	"America/Chicago": {
		"name": "America/Chicago",
		"lat": 41.85,
		"long": -86.35,
		"countries": [
			"US"
		],
		"comments": "Central (most areas)"
	},
	"America/Indiana/Tell_City": {
		"name": "America/Indiana/Tell_City",
		"lat": 37.9531,
		"long": -85.2386,
		"countries": [
			"US"
		],
		"comments": "Central - IN (Perry)"
	},
	"America/Indiana/Knox": {
		"name": "America/Indiana/Knox",
		"lat": 41.2958,
		"long": -85.375,
		"countries": [
			"US"
		],
		"comments": "Central - IN (Starke)"
	},
	"America/Menominee": {
		"name": "America/Menominee",
		"lat": 45.1078,
		"long": -86.3858,
		"countries": [
			"US"
		],
		"comments": "Central - MI (Wisconsin border)"
	},
	"America/North_Dakota/Center": {
		"name": "America/North_Dakota/Center",
		"lat": 47.1164,
		"long": -100.7008,
		"countries": [
			"US"
		],
		"comments": "Central - ND (Oliver)"
	},
	"America/North_Dakota/New_Salem": {
		"name": "America/North_Dakota/New_Salem",
		"lat": 46.845,
		"long": -100.5892,
		"countries": [
			"US"
		],
		"comments": "Central - ND (Morton rural)"
	},
	"America/North_Dakota/Beulah": {
		"name": "America/North_Dakota/Beulah",
		"lat": 47.2642,
		"long": -100.2222,
		"countries": [
			"US"
		],
		"comments": "Central - ND (Mercer)"
	},
	"America/Denver": {
		"name": "America/Denver",
		"lat": 39.7392,
		"long": -103.0158,
		"countries": [
			"US"
		],
		"comments": "Mountain (most areas)"
	},
	"America/Boise": {
		"name": "America/Boise",
		"lat": 43.6136,
		"long": -115.7975,
		"countries": [
			"US"
		],
		"comments": "Mountain - ID (south); OR (east)"
	},
	"America/Phoenix": {
		"name": "America/Phoenix",
		"lat": 33.4483,
		"long": -111.9267,
		"countries": [
			"US",
			"CA"
		],
		"comments": "MST - AZ (most areas), Creston BC"
	},
	"America/Los_Angeles": {
		"name": "America/Los_Angeles",
		"lat": 34.0522,
		"long": -117.7572,
		"countries": [
			"US"
		],
		"comments": "Pacific"
	},
	"America/Anchorage": {
		"name": "America/Anchorage",
		"lat": 61.2181,
		"long": -148.0997,
		"countries": [
			"US"
		],
		"comments": "Alaska (most areas)"
	},
	"America/Juneau": {
		"name": "America/Juneau",
		"lat": 58.3019,
		"long": -133.5803,
		"countries": [
			"US"
		],
		"comments": "Alaska - Juneau area"
	},
	"America/Sitka": {
		"name": "America/Sitka",
		"lat": 57.1764,
		"long": -134.6981,
		"countries": [
			"US"
		],
		"comments": "Alaska - Sitka area"
	},
	"America/Metlakatla": {
		"name": "America/Metlakatla",
		"lat": 55.1269,
		"long": -130.4236,
		"countries": [
			"US"
		],
		"comments": "Alaska - Annette Island"
	},
	"America/Yakutat": {
		"name": "America/Yakutat",
		"lat": 59.5469,
		"long": -138.2728,
		"countries": [
			"US"
		],
		"comments": "Alaska - Yakutat"
	},
	"America/Nome": {
		"name": "America/Nome",
		"lat": 64.5011,
		"long": -164.5936,
		"countries": [
			"US"
		],
		"comments": "Alaska (west)"
	},
	"America/Adak": {
		"name": "America/Adak",
		"lat": 51.88,
		"long": -175.3419,
		"countries": [
			"US"
		],
		"comments": "Alaska - western Aleutians"
	},
	"Pacific/Honolulu": {
		"name": "Pacific/Honolulu",
		"lat": 21.3069,
		"long": -156.1417,
		"countries": [
			"US"
		],
		"comments": "Hawaii"
	},
	"America/Montevideo": {
		"name": "America/Montevideo",
		"lat": -33.0908,
		"long": -55.7875,
		"countries": [
			"UY"
		],
		"comments": ""
	},
	"Asia/Samarkand": {
		"name": "Asia/Samarkand",
		"lat": 39.6667,
		"long": 66.8,
		"countries": [
			"UZ"
		],
		"comments": "Uzbekistan (west)"
	},
	"Asia/Tashkent": {
		"name": "Asia/Tashkent",
		"lat": 41.3333,
		"long": 69.3,
		"countries": [
			"UZ"
		],
		"comments": "Uzbekistan (east)"
	},
	"America/Caracas": {
		"name": "America/Caracas",
		"lat": 10.5,
		"long": -65.0667,
		"countries": [
			"VE"
		],
		"comments": ""
	},
	"Asia/Ho_Chi_Minh": {
		"name": "Asia/Ho_Chi_Minh",
		"lat": 10.75,
		"long": 106.6667,
		"countries": [
			"VN"
		],
		"comments": "south Vietnam"
	},
	"Pacific/Efate": {
		"name": "Pacific/Efate",
		"lat": -16.3333,
		"long": 168.4167,
		"countries": [
			"VU"
		],
		"comments": ""
	},
	"Pacific/Apia": {
		"name": "Pacific/Apia",
		"lat": -12.1667,
		"long": -170.2667,
		"countries": [
			"WS"
		],
		"comments": ""
	},
	"Africa/Johannesburg": {
		"name": "Africa/Johannesburg",
		"lat": -25.75,
		"long": 28,
		"countries": [
			"ZA",
			"LS",
			"SZ"
		],
		"comments": ""
	},
	"America/Antigua": {
		"name": "America/Antigua",
		"lat": 17.05,
		"long": -60.2,
		"countries": [
			"AG"
		],
		"comments": ""
	},
	"America/Anguilla": {
		"name": "America/Anguilla",
		"lat": 18.2,
		"long": -62.9333,
		"countries": [
			"AI"
		],
		"comments": ""
	},
	"Africa/Luanda": {
		"name": "Africa/Luanda",
		"lat": -7.2,
		"long": 13.2333,
		"countries": [
			"AO"
		],
		"comments": ""
	},
	"Antarctica/McMurdo": {
		"name": "Antarctica/McMurdo",
		"lat": -76.1667,
		"long": 166.6,
		"countries": [
			"AQ"
		],
		"comments": "New Zealand time - McMurdo, South Pole"
	},
	"Antarctica/DumontDUrville": {
		"name": "Antarctica/DumontDUrville",
		"lat": -65.3333,
		"long": 140.0167,
		"countries": [
			"AQ"
		],
		"comments": "Dumont-d'Urville"
	},
	"Antarctica/Syowa": {
		"name": "Antarctica/Syowa",
		"lat": -68.9939,
		"long": 39.59,
		"countries": [
			"AQ"
		],
		"comments": "Syowa"
	},
	"Antarctica/Vostok": {
		"name": "Antarctica/Vostok",
		"lat": -77.6,
		"long": 106.9,
		"countries": [
			"AQ"
		],
		"comments": "Vostok"
	},
	"America/Aruba": {
		"name": "America/Aruba",
		"lat": 12.5,
		"long": -68.0333,
		"countries": [
			"AW"
		],
		"comments": ""
	},
	"Europe/Mariehamn": {
		"name": "Europe/Mariehamn",
		"lat": 60.1,
		"long": 19.95,
		"countries": [
			"AX"
		],
		"comments": ""
	},
	"Europe/Sarajevo": {
		"name": "Europe/Sarajevo",
		"lat": 43.8667,
		"long": 18.4167,
		"countries": [
			"BA"
		],
		"comments": ""
	},
	"Africa/Ouagadougou": {
		"name": "Africa/Ouagadougou",
		"lat": 12.3667,
		"long": -0.4833,
		"countries": [
			"BF"
		],
		"comments": ""
	},
	"Asia/Bahrain": {
		"name": "Asia/Bahrain",
		"lat": 26.3833,
		"long": 50.5833,
		"countries": [
			"BH"
		],
		"comments": ""
	},
	"Africa/Bujumbura": {
		"name": "Africa/Bujumbura",
		"lat": -2.6167,
		"long": 29.3667,
		"countries": [
			"BI"
		],
		"comments": ""
	},
	"Africa/Porto-Novo": {
		"name": "Africa/Porto-Novo",
		"lat": 6.4833,
		"long": 2.6167,
		"countries": [
			"BJ"
		],
		"comments": ""
	},
	"America/St_Barthelemy": {
		"name": "America/St_Barthelemy",
		"lat": 17.8833,
		"long": -61.15,
		"countries": [
			"BL"
		],
		"comments": ""
	},
	"Asia/Brunei": {
		"name": "Asia/Brunei",
		"lat": 4.9333,
		"long": 114.9167,
		"countries": [
			"BN"
		],
		"comments": ""
	},
	"America/Kralendijk": {
		"name": "America/Kralendijk",
		"lat": 12.1508,
		"long": -67.7233,
		"countries": [
			"BQ"
		],
		"comments": ""
	},
	"America/Nassau": {
		"name": "America/Nassau",
		"lat": 25.0833,
		"long": -76.65,
		"countries": [
			"BS"
		],
		"comments": ""
	},
	"Africa/Gaborone": {
		"name": "Africa/Gaborone",
		"lat": -23.35,
		"long": 25.9167,
		"countries": [
			"BW"
		],
		"comments": ""
	},
	"America/Blanc-Sablon": {
		"name": "America/Blanc-Sablon",
		"lat": 51.4167,
		"long": -56.8833,
		"countries": [
			"CA"
		],
		"comments": "AST - QC (Lower North Shore)"
	},
	"America/Atikokan": {
		"name": "America/Atikokan",
		"lat": 48.7586,
		"long": -90.3783,
		"countries": [
			"CA"
		],
		"comments": "EST - ON (Atikokan); NU (Coral H)"
	},
	"America/Creston": {
		"name": "America/Creston",
		"lat": 49.1,
		"long": -115.4833,
		"countries": [
			"CA"
		],
		"comments": "MST - BC (Creston)"
	},
	"Indian/Cocos": {
		"name": "Indian/Cocos",
		"lat": -11.8333,
		"long": 96.9167,
		"countries": [
			"CC"
		],
		"comments": ""
	},
	"Africa/Kinshasa": {
		"name": "Africa/Kinshasa",
		"lat": -3.7,
		"long": 15.3,
		"countries": [
			"CD"
		],
		"comments": "Dem. Rep. of Congo (west)"
	},
	"Africa/Lubumbashi": {
		"name": "Africa/Lubumbashi",
		"lat": -10.3333,
		"long": 27.4667,
		"countries": [
			"CD"
		],
		"comments": "Dem. Rep. of Congo (east)"
	},
	"Africa/Bangui": {
		"name": "Africa/Bangui",
		"lat": 4.3667,
		"long": 18.5833,
		"countries": [
			"CF"
		],
		"comments": ""
	},
	"Africa/Brazzaville": {
		"name": "Africa/Brazzaville",
		"lat": -3.7333,
		"long": 15.2833,
		"countries": [
			"CG"
		],
		"comments": ""
	},
	"Africa/Douala": {
		"name": "Africa/Douala",
		"lat": 4.05,
		"long": 9.7,
		"countries": [
			"CM"
		],
		"comments": ""
	},
	"America/Curacao": {
		"name": "America/Curacao",
		"lat": 12.1833,
		"long": -69,
		"countries": [
			"CW"
		],
		"comments": ""
	},
	"Indian/Christmas": {
		"name": "Indian/Christmas",
		"lat": -9.5833,
		"long": 105.7167,
		"countries": [
			"CX"
		],
		"comments": ""
	},
	"Europe/Busingen": {
		"name": "Europe/Busingen",
		"lat": 47.7,
		"long": 8.6833,
		"countries": [
			"DE"
		],
		"comments": "Busingen"
	},
	"Africa/Djibouti": {
		"name": "Africa/Djibouti",
		"lat": 11.6,
		"long": 43.15,
		"countries": [
			"DJ"
		],
		"comments": ""
	},
	"Europe/Copenhagen": {
		"name": "Europe/Copenhagen",
		"lat": 55.6667,
		"long": 12.5833,
		"countries": [
			"DK"
		],
		"comments": ""
	},
	"America/Dominica": {
		"name": "America/Dominica",
		"lat": 15.3,
		"long": -60.6,
		"countries": [
			"DM"
		],
		"comments": ""
	},
	"Africa/Asmara": {
		"name": "Africa/Asmara",
		"lat": 15.3333,
		"long": 38.8833,
		"countries": [
			"ER"
		],
		"comments": ""
	},
	"Africa/Addis_Ababa": {
		"name": "Africa/Addis_Ababa",
		"lat": 9.0333,
		"long": 38.7,
		"countries": [
			"ET"
		],
		"comments": ""
	},
	"Pacific/Chuuk": {
		"name": "Pacific/Chuuk",
		"lat": 7.4167,
		"long": 151.7833,
		"countries": [
			"FM"
		],
		"comments": "Chuuk/Truk, Yap"
	},
	"Pacific/Pohnpei": {
		"name": "Pacific/Pohnpei",
		"lat": 6.9667,
		"long": 158.2167,
		"countries": [
			"FM"
		],
		"comments": "Pohnpei/Ponape"
	},
	"Africa/Libreville": {
		"name": "Africa/Libreville",
		"lat": 0.3833,
		"long": 9.45,
		"countries": [
			"GA"
		],
		"comments": ""
	},
	"America/Grenada": {
		"name": "America/Grenada",
		"lat": 12.05,
		"long": -60.25,
		"countries": [
			"GD"
		],
		"comments": ""
	},
	"Europe/Guernsey": {
		"name": "Europe/Guernsey",
		"lat": 49.4547,
		"long": -1.4639,
		"countries": [
			"GG"
		],
		"comments": ""
	},
	"Africa/Accra": {
		"name": "Africa/Accra",
		"lat": 5.55,
		"long": 0.2167,
		"countries": [
			"GH"
		],
		"comments": ""
	},
	"Africa/Banjul": {
		"name": "Africa/Banjul",
		"lat": 13.4667,
		"long": -15.35,
		"countries": [
			"GM"
		],
		"comments": ""
	},
	"Africa/Conakry": {
		"name": "Africa/Conakry",
		"lat": 9.5167,
		"long": -12.2833,
		"countries": [
			"GN"
		],
		"comments": ""
	},
	"America/Guadeloupe": {
		"name": "America/Guadeloupe",
		"lat": 16.2333,
		"long": -60.4667,
		"countries": [
			"GP"
		],
		"comments": ""
	},
	"Africa/Malabo": {
		"name": "Africa/Malabo",
		"lat": 3.75,
		"long": 8.7833,
		"countries": [
			"GQ"
		],
		"comments": ""
	},
	"Europe/Zagreb": {
		"name": "Europe/Zagreb",
		"lat": 45.8,
		"long": 15.9667,
		"countries": [
			"HR"
		],
		"comments": ""
	},
	"Europe/Isle_of_Man": {
		"name": "Europe/Isle_of_Man",
		"lat": 54.15,
		"long": -3.5333,
		"countries": [
			"IM"
		],
		"comments": ""
	},
	"Atlantic/Reykjavik": {
		"name": "Atlantic/Reykjavik",
		"lat": 64.15,
		"long": -20.15,
		"countries": [
			"IS"
		],
		"comments": ""
	},
	"Europe/Jersey": {
		"name": "Europe/Jersey",
		"lat": 49.1836,
		"long": -1.8933,
		"countries": [
			"JE"
		],
		"comments": ""
	},
	"Asia/Phnom_Penh": {
		"name": "Asia/Phnom_Penh",
		"lat": 11.55,
		"long": 104.9167,
		"countries": [
			"KH"
		],
		"comments": ""
	},
	"Indian/Comoro": {
		"name": "Indian/Comoro",
		"lat": -10.3167,
		"long": 43.2667,
		"countries": [
			"KM"
		],
		"comments": ""
	},
	"America/St_Kitts": {
		"name": "America/St_Kitts",
		"lat": 17.3,
		"long": -61.2833,
		"countries": [
			"KN"
		],
		"comments": ""
	},
	"Asia/Kuwait": {
		"name": "Asia/Kuwait",
		"lat": 29.3333,
		"long": 47.9833,
		"countries": [
			"KW"
		],
		"comments": ""
	},
	"America/Cayman": {
		"name": "America/Cayman",
		"lat": 19.3,
		"long": -80.6167,
		"countries": [
			"KY"
		],
		"comments": ""
	},
	"Asia/Vientiane": {
		"name": "Asia/Vientiane",
		"lat": 17.9667,
		"long": 102.6,
		"countries": [
			"LA"
		],
		"comments": ""
	},
	"America/St_Lucia": {
		"name": "America/St_Lucia",
		"lat": 14.0167,
		"long": -61,
		"countries": [
			"LC"
		],
		"comments": ""
	},
	"Europe/Vaduz": {
		"name": "Europe/Vaduz",
		"lat": 47.15,
		"long": 9.5167,
		"countries": [
			"LI"
		],
		"comments": ""
	},
	"Africa/Maseru": {
		"name": "Africa/Maseru",
		"lat": -28.5333,
		"long": 27.5,
		"countries": [
			"LS"
		],
		"comments": ""
	},
	"Europe/Luxembourg": {
		"name": "Europe/Luxembourg",
		"lat": 49.6,
		"long": 6.15,
		"countries": [
			"LU"
		],
		"comments": ""
	},
	"Europe/Monaco": {
		"name": "Europe/Monaco",
		"lat": 43.7,
		"long": 7.3833,
		"countries": [
			"MC"
		],
		"comments": ""
	},
	"Europe/Podgorica": {
		"name": "Europe/Podgorica",
		"lat": 42.4333,
		"long": 19.2667,
		"countries": [
			"ME"
		],
		"comments": ""
	},
	"America/Marigot": {
		"name": "America/Marigot",
		"lat": 18.0667,
		"long": -62.9167,
		"countries": [
			"MF"
		],
		"comments": ""
	},
	"Indian/Antananarivo": {
		"name": "Indian/Antananarivo",
		"lat": -17.0833,
		"long": 47.5167,
		"countries": [
			"MG"
		],
		"comments": ""
	},
	"Pacific/Majuro": {
		"name": "Pacific/Majuro",
		"lat": 7.15,
		"long": 171.2,
		"countries": [
			"MH"
		],
		"comments": "most of Marshall Islands"
	},
	"Europe/Skopje": {
		"name": "Europe/Skopje",
		"lat": 41.9833,
		"long": 21.4333,
		"countries": [
			"MK"
		],
		"comments": ""
	},
	"Africa/Bamako": {
		"name": "Africa/Bamako",
		"lat": 12.65,
		"long": -8,
		"countries": [
			"ML"
		],
		"comments": ""
	},
	"Pacific/Saipan": {
		"name": "Pacific/Saipan",
		"lat": 15.2,
		"long": 145.75,
		"countries": [
			"MP"
		],
		"comments": ""
	},
	"Africa/Nouakchott": {
		"name": "Africa/Nouakchott",
		"lat": 18.1,
		"long": -14.05,
		"countries": [
			"MR"
		],
		"comments": ""
	},
	"America/Montserrat": {
		"name": "America/Montserrat",
		"lat": 16.7167,
		"long": -61.7833,
		"countries": [
			"MS"
		],
		"comments": ""
	},
	"Africa/Blantyre": {
		"name": "Africa/Blantyre",
		"lat": -14.2167,
		"long": 35,
		"countries": [
			"MW"
		],
		"comments": ""
	},
	"Asia/Kuala_Lumpur": {
		"name": "Asia/Kuala_Lumpur",
		"lat": 3.1667,
		"long": 101.7,
		"countries": [
			"MY"
		],
		"comments": "Malaysia (peninsula)"
	},
	"Africa/Niamey": {
		"name": "Africa/Niamey",
		"lat": 13.5167,
		"long": 2.1167,
		"countries": [
			"NE"
		],
		"comments": ""
	},
	"Europe/Amsterdam": {
		"name": "Europe/Amsterdam",
		"lat": 52.3667,
		"long": 4.9,
		"countries": [
			"NL"
		],
		"comments": ""
	},
	"Europe/Oslo": {
		"name": "Europe/Oslo",
		"lat": 59.9167,
		"long": 10.75,
		"countries": [
			"NO"
		],
		"comments": ""
	},
	"Asia/Muscat": {
		"name": "Asia/Muscat",
		"lat": 23.6,
		"long": 58.5833,
		"countries": [
			"OM"
		],
		"comments": ""
	},
	"Indian/Reunion": {
		"name": "Indian/Reunion",
		"lat": -19.1333,
		"long": 55.4667,
		"countries": [
			"RE"
		],
		"comments": ""
	},
	"Africa/Kigali": {
		"name": "Africa/Kigali",
		"lat": -0.05,
		"long": 30.0667,
		"countries": [
			"RW"
		],
		"comments": ""
	},
	"Indian/Mahe": {
		"name": "Indian/Mahe",
		"lat": -3.3333,
		"long": 55.4667,
		"countries": [
			"SC"
		],
		"comments": ""
	},
	"Europe/Stockholm": {
		"name": "Europe/Stockholm",
		"lat": 59.3333,
		"long": 18.05,
		"countries": [
			"SE"
		],
		"comments": ""
	},
	"Atlantic/St_Helena": {
		"name": "Atlantic/St_Helena",
		"lat": -14.0833,
		"long": -4.3,
		"countries": [
			"SH"
		],
		"comments": ""
	},
	"Europe/Ljubljana": {
		"name": "Europe/Ljubljana",
		"lat": 46.05,
		"long": 14.5167,
		"countries": [
			"SI"
		],
		"comments": ""
	},
	"Arctic/Longyearbyen": {
		"name": "Arctic/Longyearbyen",
		"lat": 78,
		"long": 16,
		"countries": [
			"SJ"
		],
		"comments": ""
	},
	"Europe/Bratislava": {
		"name": "Europe/Bratislava",
		"lat": 48.15,
		"long": 17.1167,
		"countries": [
			"SK"
		],
		"comments": ""
	},
	"Africa/Freetown": {
		"name": "Africa/Freetown",
		"lat": 8.5,
		"long": -12.75,
		"countries": [
			"SL"
		],
		"comments": ""
	},
	"Europe/San_Marino": {
		"name": "Europe/San_Marino",
		"lat": 43.9167,
		"long": 12.4667,
		"countries": [
			"SM"
		],
		"comments": ""
	},
	"Africa/Dakar": {
		"name": "Africa/Dakar",
		"lat": 14.6667,
		"long": -16.5667,
		"countries": [
			"SN"
		],
		"comments": ""
	},
	"Africa/Mogadishu": {
		"name": "Africa/Mogadishu",
		"lat": 2.0667,
		"long": 45.3667,
		"countries": [
			"SO"
		],
		"comments": ""
	},
	"America/Lower_Princes": {
		"name": "America/Lower_Princes",
		"lat": 18.0514,
		"long": -62.9528,
		"countries": [
			"SX"
		],
		"comments": ""
	},
	"Africa/Mbabane": {
		"name": "Africa/Mbabane",
		"lat": -25.7,
		"long": 31.1,
		"countries": [
			"SZ"
		],
		"comments": ""
	},
	"Indian/Kerguelen": {
		"name": "Indian/Kerguelen",
		"lat": -48.6472,
		"long": 70.2175,
		"countries": [
			"TF"
		],
		"comments": ""
	},
	"Africa/Lome": {
		"name": "Africa/Lome",
		"lat": 6.1333,
		"long": 1.2167,
		"countries": [
			"TG"
		],
		"comments": ""
	},
	"America/Port_of_Spain": {
		"name": "America/Port_of_Spain",
		"lat": 10.65,
		"long": -60.4833,
		"countries": [
			"TT"
		],
		"comments": ""
	},
	"Pacific/Funafuti": {
		"name": "Pacific/Funafuti",
		"lat": -7.4833,
		"long": 179.2167,
		"countries": [
			"TV"
		],
		"comments": ""
	},
	"Africa/Dar_es_Salaam": {
		"name": "Africa/Dar_es_Salaam",
		"lat": -5.2,
		"long": 39.2833,
		"countries": [
			"TZ"
		],
		"comments": ""
	},
	"Africa/Kampala": {
		"name": "Africa/Kampala",
		"lat": 0.3167,
		"long": 32.4167,
		"countries": [
			"UG"
		],
		"comments": ""
	},
	"Pacific/Midway": {
		"name": "Pacific/Midway",
		"lat": 28.2167,
		"long": -176.6333,
		"countries": [
			"UM"
		],
		"comments": "Midway Islands"
	},
	"Pacific/Wake": {
		"name": "Pacific/Wake",
		"lat": 19.2833,
		"long": 166.6167,
		"countries": [
			"UM"
		],
		"comments": "Wake Island"
	},
	"Europe/Vatican": {
		"name": "Europe/Vatican",
		"lat": 41.9022,
		"long": 12.4531,
		"countries": [
			"VA"
		],
		"comments": ""
	},
	"America/St_Vincent": {
		"name": "America/St_Vincent",
		"lat": 13.15,
		"long": -60.7667,
		"countries": [
			"VC"
		],
		"comments": ""
	},
	"America/Tortola": {
		"name": "America/Tortola",
		"lat": 18.45,
		"long": -63.3833,
		"countries": [
			"VG"
		],
		"comments": ""
	},
	"America/St_Thomas": {
		"name": "America/St_Thomas",
		"lat": 18.35,
		"long": -63.0667,
		"countries": [
			"VI"
		],
		"comments": ""
	},
	"Pacific/Wallis": {
		"name": "Pacific/Wallis",
		"lat": -12.7,
		"long": -175.8333,
		"countries": [
			"WF"
		],
		"comments": ""
	},
	"Asia/Aden": {
		"name": "Asia/Aden",
		"lat": 12.75,
		"long": 45.2,
		"countries": [
			"YE"
		],
		"comments": ""
	},
	"Indian/Mayotte": {
		"name": "Indian/Mayotte",
		"lat": -11.2167,
		"long": 45.2333,
		"countries": [
			"YT"
		],
		"comments": ""
	},
	"Africa/Lusaka": {
		"name": "Africa/Lusaka",
		"lat": -14.5833,
		"long": 28.2833,
		"countries": [
			"ZM"
		],
		"comments": ""
	},
	"Africa/Harare": {
		"name": "Africa/Harare",
		"lat": -16.1667,
		"long": 31.05,
		"countries": [
			"ZW"
		],
		"comments": ""
	}
};

const isdCodes = {
	"AD": {"name": "Andorra", "dialCode": "376"},
	"AE": {"name": "United Arab Emirates", "dialCode": "971"},
	"AF": {"name": "Afghanistan", "dialCode": "93"},
	"AG": {"name": "Antigua and Barbuda", "dialCode": "1268"},
	"AL": {"name": "Albania", "dialCode": "355"},
	"AM": {"name": "Armenia", "dialCode": "374"},
	"AO": {"name": "Angola", "dialCode": "244"},
	"AR": {"name": "Argentina", "dialCode": "54"},
	"AT": {"name": "Austria", "dialCode": "43"},
	"AU": {"name": "Australia", "dialCode": "61"},
	"AW": {"name": "Aruba", "dialCode": "297"},
	"AZ": {"name": "Azerbaijan", "dialCode": "994"},
	"BA": {"name": "Bosnia and Herzegovina", "dialCode": "387"},
	"BB": {"name": "Barbados", "dialCode": "1246"},
	"BD": {"name": "Bangladesh", "dialCode": "880"},
	"BE": {"name": "Belgium", "dialCode": "32"},
	"BF": {"name": "Burkina Faso", "dialCode": "226"},
	"BG": {"name": "Bulgaria", "dialCode": "359"},
	"BH": {"name": "Bahrain", "dialCode": "973"},
	"BI": {"name": "Burundi", "dialCode": "257"},
	"BJ": {"name": "Benin", "dialCode": "229"},
	"BN": {"name": "Brunei", "dialCode": "673"},
	"BO": {"name": "Bolivia", "dialCode": "591"},
	"BQ": {"name": "Caribbean Netherlands", "dialCode": "599"},
	"BR": {"name": "Brazil", "dialCode": "55"},
	"BS": {"name": "Bahamas", "dialCode": "1242"},
	"BT": {"name": "Bhutan", "dialCode": "975"},
	"BW": {"name": "Botswana", "dialCode": "267"},
	"BY": {"name": "Belarus", "dialCode": "375"},
	"BZ": {"name": "Belize", "dialCode": "501"},
	"CA": {"name": "Canada", "dialCode": "1"},
	"CD": {"name": "Congo", "dialCode": "243"},
	"CF": {"name": "Central African Republic", "dialCode": "236"},
	"CG": {"name": "Congo", "dialCode": "242"},
	"CH": {"name": "Switzerland", "dialCode": "41"},
	"CI": {"name": "Côte d’Ivoire", "dialCode": "225"},
	"CL": {"name": "Chile", "dialCode": "56"},
	"CM": {"name": "Cameroon", "dialCode": "237"},
	"CN": {"name": "China", "dialCode": "86"},
	"CO": {"name": "Colombia", "dialCode": "57"},
	"CR": {"name": "Costa Rica", "dialCode": "506"},
	"CU": {"name": "Cuba", "dialCode": "53"},
	"CV": {"name": "Cape Verde", "dialCode": "238"},
	"CW": {"name": "Curaçao", "dialCode": "599"},
	"CY": {"name": "Cyprus", "dialCode": "357"},
	"CZ": {"name": "Czech Republic", "dialCode": "420"},
	"DE": {"name": "Germany", "dialCode": "49"},
	"DJ": {"name": "Djibouti", "dialCode": "253"},
	"DK": {"name": "Denmark", "dialCode": "45"},
	"DM": {"name": "Dominica", "dialCode": "1767"},
	"DO": {"name": "Dominican Republic", "dialCode": "1"},
	"DZ": {"name": "Algeria", "dialCode": "213"},
	"EC": {"name": "Ecuador", "dialCode": "593"},
	"EE": {"name": "Estonia", "dialCode": "372"},
	"EG": {"name": "Egypt", "dialCode": "20"},
	"ER": {"name": "Eritrea", "dialCode": "291"},
	"ES": {"name": "Spain", "dialCode": "34"},
	"ET": {"name": "Ethiopia", "dialCode": "251"},
	"FI": {"name": "Finland", "dialCode": "358"},
	"FJ": {"name": "Fiji", "dialCode": "679"},
	"FM": {"name": "Micronesia", "dialCode": "691"},
	"FR": {"name": "France", "dialCode": "33"},
	"GA": {"name": "Gabon", "dialCode": "241"},
	"GB": {"name": "United Kingdom", "dialCode": "44"},
	"GD": {"name": "Grenada", "dialCode": "1473"},
	"GE": {"name": "Georgia", "dialCode": "995"},
	"GF": {"name": "French Guiana", "dialCode": "594"},
	"GH": {"name": "Ghana", "dialCode": "233"},
	"GM": {"name": "Gambia", "dialCode": "220"},
	"GN": {"name": "Guinea", "dialCode": "224"},
	"GP": {"name": "Guadeloupe", "dialCode": "590"},
	"GQ": {"name": "Equatorial Guinea", "dialCode": "240"},
	"GR": {"name": "Greece", "dialCode": "30"},
	"GT": {"name": "Guatemala", "dialCode": "502"},
	"GU": {"name": "Guam", "dialCode": "1671"},
	"GW": {"name": "Guinea-Bissau", "dialCode": "245"},
	"GY": {"name": "Guyana", "dialCode": "592"},
	"HK": {"name": "Hong Kong", "dialCode": "852"},
	"HN": {"name": "Honduras", "dialCode": "504"},
	"HR": {"name": "Croatia", "dialCode": "385"},
	"HT": {"name": "Haiti", "dialCode": "509"},
	"HU": {"name": "Hungary", "dialCode": "36"},
	"ID": {"name": "Indonesia", "dialCode": "62"},
	"IE": {"name": "Ireland", "dialCode": "353"},
	"IL": {"name": "Israel", "dialCode": "972"},
	"IN": {"name": "India", "dialCode": "91"},
	"IO": {"name": "British Indian Ocean Territory", "dialCode": "246"},
	"IQ": {"name": "Iraq", "dialCode": "964"},
	"IR": {"name": "Iran", "dialCode": "98"},
	"IS": {"name": "Iceland", "dialCode": "354"},
	"IT": {"name": "Italy", "dialCode": "39"},
	"JM": {"name": "Jamaica", "dialCode": "1876"},
	"JO": {"name": "Jordan", "dialCode": "962"},
	"JP": {"name": "Japan", "dialCode": "81"},
	"KE": {"name": "Kenya", "dialCode": "254"},
	"KG": {"name": "Kyrgyzstan", "dialCode": "996"},
	"KH": {"name": "Cambodia", "dialCode": "855"},
	"KI": {"name": "Kiribati", "dialCode": "686"},
	"KM": {"name": "Comoros", "dialCode": "269"},
	"KN": {"name": "Saint Kitts and Nevis", "dialCode": "1869"},
	"KP": {"name": "North Korea", "dialCode": "850"},
	"KR": {"name": "South Korea", "dialCode": "82"},
	"KW": {"name": "Kuwait", "dialCode": "965"},
	"KZ": {"name": "Kazakhstan", "dialCode": "7"},
	"LA": {"name": "Laos", "dialCode": "856"},
	"LB": {"name": "Lebanon", "dialCode": "961"},
	"LC": {"name": "Saint Lucia", "dialCode": "1758"},
	"LI": {"name": "Liechtenstein", "dialCode": "423"},
	"LK": {"name": "Sri Lanka", "dialCode": "94"},
	"LR": {"name": "Liberia", "dialCode": "231"},
	"LS": {"name": "Lesotho", "dialCode": "266"},
	"LT": {"name": "Lithuania", "dialCode": "370"},
	"LU": {"name": "Luxembourg", "dialCode": "352"},
	"LV": {"name": "Latvia", "dialCode": "371"},
	"LY": {"name": "Libya", "dialCode": "218"},
	"MA": {"name": "Morocco", "dialCode": "212"},
	"MC": {"name": "Monaco", "dialCode": "377"},
	"MD": {"name": "Moldova", "dialCode": "373"},
	"ME": {"name": "Montenegro", "dialCode": "382"},
	"MG": {"name": "Madagascar", "dialCode": "261"},
	"MH": {"name": "Marshall Islands", "dialCode": "692"},
	"MK": {"name": "Macedonia", "dialCode": "389"},
	"ML": {"name": "Mali", "dialCode": "223"},
	"MM": {"name": "Myanmar", "dialCode": "95"},
	"MN": {"name": "Mongolia", "dialCode": "976"},
	"MO": {"name": "Macau", "dialCode": "853"},
	"MQ": {"name": "Martinique", "dialCode": "596"},
	"MR": {"name": "Mauritania", "dialCode": "222"},
	"MT": {"name": "Malta", "dialCode": "356"},
	"MU": {"name": "Mauritius", "dialCode": "230"},
	"MV": {"name": "Maldives", "dialCode": "960"},
	"MW": {"name": "Malawi", "dialCode": "265"},
	"MX": {"name": "Mexico", "dialCode": "52"},
	"MY": {"name": "Malaysia", "dialCode": "60"},
	"MZ": {"name": "Mozambique", "dialCode": "258"},
	"NA": {"name": "Namibia", "dialCode": "264"},
	"NC": {"name": "New Caledonia", "dialCode": "687"},
	"NE": {"name": "Niger", "dialCode": "227"},
	"NG": {"name": "Nigeria", "dialCode": "234"},
	"NI": {"name": "Nicaragua", "dialCode": "505"},
	"NL": {"name": "Netherlands", "dialCode": "31"},
	"NO": {"name": "Norway", "dialCode": "47"},
	"NP": {"name": "Nepal", "dialCode": "977"},
	"NR": {"name": "Nauru", "dialCode": "674"},
	"NZ": {"name": "New Zealand", "dialCode": "64"},
	"OM": {"name": "Oman", "dialCode": "968"},
	"PA": {"name": "Panama", "dialCode": "507"},
	"PE": {"name": "Peru", "dialCode": "51"},
	"PF": {"name": "French Polynesia", "dialCode": "689"},
	"PG": {"name": "Papua New Guinea", "dialCode": "675"},
	"PH": {"name": "Philippines", "dialCode": "63"},
	"PK": {"name": "Pakistan", "dialCode": "92"},
	"PL": {"name": "Poland", "dialCode": "48"},
	"PR": {"name": "Puerto Rico", "dialCode": "1"},
	"PS": {"name": "Palestine", "dialCode": "970"},
	"PT": {"name": "Portugal", "dialCode": "351"},
	"PW": {"name": "Palau", "dialCode": "680"},
	"PY": {"name": "Paraguay", "dialCode": "595"},
	"QA": {"name": "Qatar", "dialCode": "974"},
	"RE": {"name": "Réunion", "dialCode": "262"},
	"RO": {"name": "Romania", "dialCode": "40"},
	"RS": {"name": "Serbia", "dialCode": "381"},
	"RU": {"name": "Russia", "dialCode": "7"},
	"RW": {"name": "Rwanda", "dialCode": "250"},
	"SA": {"name": "Saudi Arabia", "dialCode": "966"},
	"SB": {"name": "Solomon Islands", "dialCode": "677"},
	"SC": {"name": "Seychelles", "dialCode": "248"},
	"SD": {"name": "Sudan", "dialCode": "249"},
	"SE": {"name": "Sweden", "dialCode": "46"},
	"SG": {"name": "Singapore", "dialCode": "65"},
	"SI": {"name": "Slovenia", "dialCode": "386"},
	"SK": {"name": "Slovakia", "dialCode": "421"},
	"SL": {"name": "Sierra Leone", "dialCode": "232"},
	"SM": {"name": "San Marino", "dialCode": "378"},
	"SN": {"name": "Senegal", "dialCode": "221"},
	"SO": {"name": "Somalia", "dialCode": "252"},
	"SR": {"name": "Suriname", "dialCode": "597"},
	"SS": {"name": "South Sudan", "dialCode": "211"},
	"ST": {"name": "São Tomé and Príncipe", "dialCode": "239"},
	"SV": {"name": "El Salvador", "dialCode": "503"},
	"SY": {"name": "Syria", "dialCode": "963"},
	"SZ": {"name": "Swaziland", "dialCode": "268"},
	"TD": {"name": "Chad", "dialCode": "235"},
	"TG": {"name": "Togo", "dialCode": "228"},
	"TH": {"name": "Thailand", "dialCode": "66"},
	"TJ": {"name": "Tajikistan", "dialCode": "992"},
	"TL": {"name": "Timor-Leste", "dialCode": "670"},
	"TM": {"name": "Turkmenistan", "dialCode": "993"},
	"TN": {"name": "Tunisia", "dialCode": "216"},
	"TO": {"name": "Tonga", "dialCode": "676"},
	"TR": {"name": "Turkey", "dialCode": "90"},
	"TT": {"name": "Trinidad and Tobago", "dialCode": "1868"},
	"TV": {"name": "Tuvalu", "dialCode": "688"},
	"TW": {"name": "Taiwan", "dialCode": "886"},
	"TZ": {"name": "Tanzania", "dialCode": "255"},
	"UA": {"name": "Ukraine", "dialCode": "380"},
	"UG": {"name": "Uganda", "dialCode": "256"},
	"US": {"name": "United States", "dialCode": "1"},
	"UY": {"name": "Uruguay", "dialCode": "598"},
	"UZ": {"name": "Uzbekistan", "dialCode": "998"},
	"VA": {"name": "Vatican City", "dialCode": "39"},
	"VC": {"name": "Saint Vincent and the Grenadines", "dialCode": "1784"},
	"VE": {"name": "Venezuela", "dialCode": "58"},
	"VN": {"name": "Vietnam", "dialCode": "84"},
	"VU": {"name": "Vanuatu", "dialCode": "678"},
	"WS": {"name": "Samoa", "dialCode": "685"},
	"XK": {"name": "Kosovo", "dialCode": "383"},
	"YE": {"name": "Yemen", "dialCode": "967"},
	"ZA": {"name": "South Africa", "dialCode": "27"},
	"ZM": {"name": "Zambia", "dialCode": "260"},
	"ZW": {"name": "Zimbabwe", "dialCode": "263"},
};

const getISDCode = () => {
	var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	var details = allTimeZones[timeZone];
	if(details?.countries != null && details.countries.length > 0) {
		var countryCode = details.countries[0];
		return isdCodes[countryCode]?.dialCode ?? "";
	}
	return "";
};

export const timeZoneUtil = {
	getISDCode
};
