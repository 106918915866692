import 'react-datepicker/dist/react-datepicker.css';

import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import DatePicker from 'react-datepicker';

const AddEventBounds = (props) => {
    const [values, setValues] = useState({});

    useEffect(() => {
        var bounds = {};
        if(props.data?.Results != null) {
            Object.keys(props.data?.Results.images_details).map((field) => {
                bounds[field] = {};
                bounds[field].source = props.data.Results.images_details[field].source ?? "";
                bounds[field].timestamp = props.data.Results.images_details[field].date ? moment(props.data.Results.images_details[field].date, 'DD-MM-YYYY h:mm:ss.000+00').toDate() : "";
                bounds[field].east = props.data.Results.images_details[field].bounds?.maxx ?? "";
                bounds[field].west = props.data.Results.images_details[field].bounds?.minx ?? "";
                bounds[field].north = props.data.Results.images_details[field].bounds?.maxy ?? "";
                bounds[field].south = props.data.Results.images_details[field].bounds?.miny ?? "";
            });
        }
        setValues(bounds);
    }, []);

    const handleInputChange = (event, key) => {
        onChange(key, event.target.name, event.target.value);
    };

    const onChange = (key, name, value) => {
        values[key][name] = value;
        setValues({
            ...values
        });
    };

    const renderRow = (key) => {
        if(values[key] == null)
            return null;

        return <div key={key}>
                        <h3>
                        {key}
                        </h3>
                        <table width="100%" border="1" cellSpacing="0" cellPadding="0" className="table table-style">
                            <tbody>
                            <tr>
                                <th colSpan="1" width="200">Source</th>
                                <td colSpan="3">
                                    <input type="text" className="form-control" name="source" value={values[key].source} onChange={(e) => handleInputChange(e, key)} />
                                </td>
                            </tr>
                            <tr>
                                <th colSpan="1" width="200">Timestamp (in UTC)</th>
                                <td colSpan="3">
                                    <DatePicker selected={values[key].timestamp} onChange={(date) => onChange(key, "timestamp", date)} showTimeSelect dateFormat="Pp"/>
                                </td>
                            </tr>
                            <tr>
                                <th rowSpan="4">Bounds</th>
                                <td>East</td>
                                <td>
                                    <input type="text" className="form-control" name="east" value={values[key].east} onChange={(e) => handleInputChange(e, key)}/>
                                </td>
                            </tr>
                            <tr>
                                <td>West</td>
                                <td>
                                    <input type="text" className="form-control" name="west" value={values[key].west} onChange={(e) => handleInputChange(e, key)}/>
                                </td>
                            </tr>
                            <tr>
                                <td>North</td>
                                <td>
                                    <input type="text" className="form-control" name="north" value={values[key].north} onChange={(e) => handleInputChange(e, key)}/>
                                </td>
                            </tr>
                            <tr>
                                <td>South</td>
                                <td>
                                    <input type="text" className="form-control" name="south" value={values[key].south} onChange={(e) => handleInputChange(e, key)}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                </div>;
    }

    return <>
                <div className="row">
                    {
                        props.messages &&
                        props.messages.map((message) => <div>{message}</div>)
                    }
                </div>
                <div className="row">
                    <div className="timeline-images">
                        {
                            props.data?.Results?.images_details &&
                            Object.keys(props.data.Results.images_details).map((field) => renderRow(field))
                        }
                    </div>
                </div>
                <div className='modal-footer'>
                    <button type="button" className="btn btn-outline" onClick={() => props.cancel()}>CANCEL</button>
                    <button type="button" className="btn btn-primary" onClick={() => props.submit(values)}>SUBMIT</button>
                </div>
            </>;
};

export default AddEventBounds;