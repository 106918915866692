import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { MapContext } from '../../Contexts/MapContext';
import AOIMap from './AOIMap';
import AOIMapActions from './AOIMapActions';
import LocationSearch from './LocationSearch';
import { AOIContext } from '../../Contexts/AOIContext';
import AOIPanel from './AOIPanel';
import InsetMap from "./InsetMap";
import { MapUtils } from '../MapUtils';
import { UserContext } from '../../Contexts/UserContext';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const AOIMapView = (props) => {
    const drawingManagerRef = useRef();
    const [locationPoint, setLocationPoint] = useState(null);
    const [showCreatePolygon, setShowCreatePolygon] = useState(false);
    const [mapState, setMapState] = useState(0);
    const [gotoSearch, setGotoSearch] = useState(0);

    const {isMapLoaded, loadError, showAlert, searchLocation, setSearchLocation, emptyAOI, aoi, setAOI, aoiRef, 
        shapes, setShapes, deleteShapes, mapRef,
        userAOIs,
        aoiMode, setAOIMode,
        saveAOI,
        selectedAOI, setSelectedAOI,
        savePolygonPaths, setSavePolygonPaths,
        discardAOICreate, discardAOIEdit,
        showAOIPopover, setShowAOIPopover,
        showEditAttributes,
        center,
        viewAOIDetails,
        clearFilters,
        zoomToFit, setZoomToFit,
        allAOIs, 
        currentLocation, setCurrentLocationCoords,
        showCurrentLocation, setShowCurrentLocation,
        serviceArea, serviceAreaGeometry,
        forCreateAOI, setForCreateAOI
    } = useContext(AOIContext);
    
    const {user, domainConfig, siteCache, setSiteCache } = useContext(UserContext);
    const isInsetMapRequired = user.userPreferences?.InsetMap != undefined ? user.userPreferences.InsetMap : defaultGuiConfig.InsetMap;
    const isAOISidePanelRequired = user.userPreferences?.AOIListSidePanel != undefined ? user.userPreferences.AOIListSidePanel : defaultGuiConfig.AOIListSidePanel;

    const fullScreenHandle = useFullScreenHandle();

    useEffect(()=>{
        setLocationInMap(searchLocation);
    }, [searchLocation, gotoSearch]);

    useEffect(()=>{
        if (showCurrentLocation && currentLocation) {
            setCenter(currentLocation);
        }
    }, [showCurrentLocation]);

    const gotoSearchLocation = (location) => {
        setSearchLocation(location);
        setGotoSearch(gotoSearch + 1);
    };

    useEffect(() => {
        if (mapRef && mapRef.current) {
            if(userAOIs && userAOIs.length > 0 && aoiMode == "view")
            {
                if(zoomToFit) {
                    fitBounds();
                    setZoomToFit(false);
                }
            }
            else if (!!serviceArea) {
                fitBoundsForServiceArea();
            }
            else {
                fitToMaximum();
            }
        }
    }, [mapRef, mapRef.current, serviceArea]);

    const setLocationInMap = useCallback(async (searchLocation) => {
        try {
            if (searchLocation) {
                clearFilters(true);
                const results = await getGeocode({address: searchLocation});
                if (results.length > 0) {
                    const points = await getLatLng(results[0]);
                    if (points) {
                        setCenter(points);
                        setLocationPoint(points);
                    } 
                    const bounds = results[0].geometry?.bounds;
                    if(bounds) {
                        mapRef.current.fitBounds(bounds);
                    } else if(results[0].geometry?.viewport) {
                        mapRef.current.fitBounds(results[0].geometry?.viewport);
                    }
                }
            }
            else if (locationPoint == null) {
                // Remove location marker in Map
                setLocationPoint(null);
            }
        }
        catch (e) {
            console.log(`Error in Setting location in Map. Search location: ${searchLocation} Error: ${e}`);
            if (e.toString() == 'ZERO_RESULTS') {
                // No matching location(s) available for the given search Location
                showAlert('Please Enter a Valid location', 'fail');
            }
        }  
    }, []);

    const setCenter = (points = null) => {
        if (points) {
            mapRef.current?.panTo(points);
        }
        else if (locationPoint){
            mapRef.current?.panTo(locationPoint)
        }
    }

    const fitBounds = useCallback(() => {
        if (userAOIs.length > 0) {
            fitBoundsForAOIs(userAOIs);
        }
    }, [userAOIs]);

    const fitBoundsForAOIs = (aois) => {
        // Iterate userAOIs to size, center, and zoom map to contain all markers
        let bounds = new window.google.maps.LatLngBounds();
        aois.map(aoi => {
            bounds = MapUtils.extendBounds(bounds, aoi.geometry);
            return !!aoi.geometry.coordinates[0][0] && aoi.geometry.coordinates[0][0][1];
        });
        setTimeout(() => {
            mapRef.current.fitBounds(bounds);
            if(forCreateAOI)
            {
                if(mapRef.current.getZoom() > 14)
                    mapRef.current.setZoom(14);
                setForCreateAOI(false);
            }
        }, 50);
    };

    const fitBoundsForServiceArea = () => {
        let bounds = MapUtils.getBounds(serviceAreaGeometry);
        setTimeout(() => {
            mapRef.current.fitBounds(bounds);
        }, 50);
    };

    const fitToMaximum = () => {
        setTimeout(() => {
            mapRef.current.setZoom(2);
        }, 50);
    }

    const truncateString = (tag, length) => {
        tag = tag.substring(0, length);
        if(tag.length == length) {
            const lastSpace = tag.lastIndexOf(" ");
            const lastComma = tag.lastIndexOf(",");
            const lastChar = lastSpace > lastComma ? lastSpace : lastComma;
            tag = tag.substring(0, lastChar);    
        }
        tag = tag.replace(/(^[,\s]+)|([,\s]+$)/g, ''); // remove leading and trailing comma
        tag = tag.replace(/\s\s+/g, ' ');
        return tag;
    }

    const generateUniqueTag = (tag, length) => {
        tag = truncateString(tag, length);
        if(!allAOIs.find(aoi => aoi.tag == tag))
            return tag;
        tag = truncateString(tag, length - 6);
        if(!allAOIs.find(aoi => aoi.tag == tag))
            return tag;
        for(var i=1; i<999; i++) {
            let ntag = tag + " - " + i;
            if(!allAOIs.find(aoi => aoi.tag == ntag))
                return ntag;
        }
        return tag;
    }

    const generateTag = (fields, length) => {
        var tag = "";
        for(var i=0; i<fields.length; i++) {
            var str = fields[i].replace(/([a-zA-Z ])/g, '');
            if(str.length == 0) {
                tag = tag + " " + fields[i];
            }
        }
        return generateUniqueTag(tag, length);
    }

    const setAddressTagForAOI = async (bounds) => {
        if (aoiMode == 'create') {
            const aoiGeoLocation = await getGeoLocation(bounds);
            let tag='',address1='',address2='',address3='',city='',state='',country='',pin='';

            if (aoiGeoLocation != null) {
                const adrs_comp = aoiGeoLocation.address_components;
                for(let  i = 0; i < adrs_comp.length; i++) {
                    if(address1 == '' && adrs_comp[i].types.some(c=> ['street_number','premise', 'subpremise'].indexOf(c) >= 0)) {
                        address1 += adrs_comp[i].long_name + " ";
                    }
                    if(address2 == '' && adrs_comp[i].types.some(c=> ['route', 'sublocality_level_2', 'sublocality_level_3', 'landmark'].indexOf(c) >= 0)) {
                        address2 += adrs_comp[i].long_name + " ";
                    }
                    if(address3 == '' && adrs_comp[i].types.some(c=> ['neighborhood', 'sublocality_level_1'].indexOf(c) >= 0)) {
                        address3 += adrs_comp[i].long_name  + " ";
                    }
                    if(city == '' && adrs_comp[i].types.some(c=> ['locality','administrative_area_level_2'].indexOf(c) >= 0)) {
                        city = adrs_comp[i].long_name;
                    }   
                    if(state == '' && adrs_comp[i].types.some(c=> ['administrative_area_level_1'].indexOf(c) >= 0)) {
                        state = adrs_comp[i].long_name;
                    }
                    if(country == '' && adrs_comp[i].types.some(c=> ['country'].indexOf(c) >= 0)) {
                        country = adrs_comp[i].long_name;
                    }
                    if(pin == '' && adrs_comp[i].types.some(c=> ['postal_code'].indexOf(c) >= 0)) {
                        pin = adrs_comp[i].long_name;
                    }
                }
                tag = generateTag([address1, address2, address3, city, state, country], 50);
            }

            const address = {
                address1: address1,
                address2: address2,
                address3: address3,
                city: city,
                state: state,
                country: country,
                pin: pin
            };

            setAOI({
                ...aoi, 
                tag: tag,
                address : address,
                geometry: {type: 'Polygon', coordinates: bounds}
            });
        }
    }

    const getGeoLocation = async (aoiBounds) => {
        const center = MapUtils.getCenterFromCoordinates(aoiBounds);
        const geoLocation = await getGeocode({ location: center });
        return geoLocation[0] ?? null;
    }

    const initCreateAOI = (isDrawing = false) => {
        if(allAOIs.length >= parseInt(user.userPreferences.MaxAoiCount)) {
            showAlert(`You have already reached the maximum number of AOIs (${user.userPreferences.MaxAoiCount}). You cannot create any more AOIs`, "fail");
            return;
        }
        setAOIMode('create');
        deleteShapes();
        clearFilters(false);
        setAOI(emptyAOI);
        setSavePolygonPaths([]);
        setShowAOIPopover(false);
        if (isDrawing == false) {
            const createAOIPaths = getCreatePolygonPaths();
            setSavePolygonPaths(createAOIPaths);
        }
    }

    const onCreateAOIPolygonLoad = (polygon) => {
        // Store the Polygon Reference in aoiRef
        aoiRef.current = polygon;
    }

    const getCreatePolygonPaths = useCallback(() => {
        const map = mapRef.current;
        const _centerpoint = map.getCenter()
        const _mapZoom = map.getZoom();
        const centWCord = map.getProjection().fromLatLngToPoint(_centerpoint);

        //convert to pixel coordinate
        const centPix = new google.maps.Point(centWCord.x * Math.pow(2, _mapZoom), centWCord.y * Math.pow(2, _mapZoom));

        //create 4 boundary points
        let ne = new google.maps.Point(centPix.x + 35, centPix.y + 35); //north east
        let sw = new google.maps.Point(centPix.x - 35, centPix.y - 35); //south west
        let nw = new google.maps.Point(centPix.x - 35, centPix.y + 35); //north west
        let se = new google.maps.Point(centPix.x + 35, centPix.y - 35); //south east

        //convert back to world coordinates
        ne.x = ne.x / Math.pow(2, _mapZoom);
        ne.y = ne.y / Math.pow(2, _mapZoom);

        sw.x = sw.x / Math.pow(2, _mapZoom);
        sw.y = sw.y / Math.pow(2, _mapZoom);

        nw.x = nw.x / Math.pow(2, _mapZoom);
        nw.y = nw.y / Math.pow(2, _mapZoom);

        se.x = se.x / Math.pow(2, _mapZoom);
        se.y = se.y / Math.pow(2, _mapZoom);

        // convert world coordinates to lat long
        ne = map.getProjection().fromPointToLatLng(ne);
        sw = map.getProjection().fromPointToLatLng(sw);
        nw = map.getProjection().fromPointToLatLng(nw);
        se = map.getProjection().fromPointToLatLng(se);

        // set the path for the polygon
        var _paths = [];
        _paths.push(ne);
        _paths.push(se);
        _paths.push(sw);
        _paths.push(nw);
        return _paths;
    }, []);

    const handleAOINameInputChange = (e) => {
        const value = e.target.value;
        setAOI({...aoi, tag: value});
    }

    const getPolygonRefBounds = () => {
        let polygonBounds = aoiRef.current.getPath();
        let bounds = [];
        for (let i = 0; i < polygonBounds.length; i++) {
            let point = {
                lat: polygonBounds.getAt(i).lat(),
                lng: polygonBounds.getAt(i).lng()
            };
            bounds.push(point);
        }
        return bounds;
    }

    const handleSaveAOI = () => {
        if (aoiMode == 'create') { 
            deleteShapes(); 
            if(drawingManagerRef && drawingManagerRef.current)
                drawingManagerRef.current.setDrawingMode(null);
            const bounds = getPolygonRefBounds();
            if (bounds.length > 0) {
                setSavePolygonPaths(bounds);
                setAddressTagForAOI(bounds);
                setShowAOIPopover(true);
            }
        }
        else {
            let geometry = {};
            mapRef.current.data.toGeoJson(function (data) {
                geometry = data.features.find(f => f.id == selectedAOI.tag).geometry;
                if(drawingManagerRef && drawingManagerRef.current)
                    drawingManagerRef.current.setDrawingMode(null);
        
                setAOI({
                    ...aoi,
                    geometry: geometry
                });
                setSavePolygonPaths(MapUtils.getPolygonPaths(geometry));
                setShowAOIPopover(true);
            });    
        }
    }

    const toggleFullScreen = () => {
        const boxes = document.getElementsByClassName('aoi-map');
        if(fullScreenHandle.active)
        {
            fullScreenHandle.exit();
            boxes[0].classList.remove('fullscreen');
        }
        else 
        {
            fullScreenHandle.enter();
            boxes[0].classList.add('fullscreen');
        }
    }

    const editAOI = useCallback((selectedAOI, polygon) => {
        if(drawingManagerRef && drawingManagerRef.current)
            drawingManagerRef.current.setDrawingMode(null);
        // Discard if there is any Polygon in Edit or Create mode
        if (aoiMode == 'create') {
            discardAOICreate();
        }
        if (aoiMode == 'edit') {
            discardAOIEdit();
        }

        aoiRef.current = polygon;
        if (aoiRef && aoiRef.current) {
            aoiRef.current.setOptions({editable: true, draggable: true});
        }
        setAOI({...aoi, tag: selectedAOI.tag, geometry: selectedAOI.geometry});
        setAOIMode('edit');
        setShowAOIPopover(false);
    }, [aoiMode, aoiRef]);

    const onDrawingComplete = event => {
        if (aoiMode == 'edit') {
            discardAOIEdit();
        }
        initCreateAOI(true);
        let shape = event.overlay;
        if (event.type == 'rectangle') {
            shape = getPolygonShape(shape);
        }
        // Set New Shape as AOI
        shapes.push(shape);
        setShapes(shapes);
        
        // Store the Polygon Reference in aoiRef
        aoiRef.current = shape;
    }

    const getPolygonShape = (shape) => {
        let p = shape.getBounds();
        let paths = [];
        paths.push({ lat: p.getNorthEast().lat(), lng: p.getNorthEast().lng() });
        paths.push({ lat: p.getSouthWest().lat(), lng: p.getNorthEast().lng() });
        paths.push({ lat: p.getSouthWest().lat(), lng: p.getSouthWest().lng() });
        paths.push({ lat: p.getNorthEast().lat(), lng: p.getSouthWest().lng() });
        
        const polygon = new google.maps.Polygon({
            paths: paths,
            strokeColor: "black",
            strokeOpacity: 1,
            strokeWeight: 3,
            fillOpacity: 0.3,
            map: shape.map
        });
        shape.setMap(null);
        return polygon;
    }

    const mapChanged = () => {
        setMapState(mapState + 1);
        setSiteCache({...siteCache, mapCenter: mapRef?.current?.getCenter(), mapZoom: mapRef?.current?.getZoom()});
        if(mapRef?.current?.getTilt() != 0)
            mapRef?.current?.setTilt(0);
    };

    const discardAOI = () => {
        if (aoiMode == 'create') {
            discardAOICreate();
        }
        else if (aoiMode == 'edit') {
            discardAOIEdit();  
        }
        // drawingManagerRef would be null, when it is disbaled in the user's config
        if(drawingManagerRef && drawingManagerRef.current)
            drawingManagerRef.current.setDrawingMode(null);
    }

    const mapContextValues = {
        mapRef, drawingManagerRef,
        isMapLoaded, 
        loadError, 
        center, setCenter,
        locationPoint,
        setLocationPoint,
        showEditAttributes,
        aoi, setAOI, aoiMode, setAOIMode,
        userAOIs,
        emptyAOI,
        fitBounds,
        shapes, setShapes, deleteShapes,
        savePolygonPaths,
        selectedAOI, setSelectedAOI,
        showAOIPopover, setShowAOIPopover,
        user,
        viewAOIDetails,
        clearFilters,
        zoomToFit, setZoomToFit,
        currentLocation, showCurrentLocation,
        discardAOI
    }

    return (
        <FullScreen handle={fullScreenHandle}>
            <div className='map-view-container'>
                <MapContext.Provider value={{...mapContextValues}} >
                    {   
                        isMapLoaded && 
                        <LocationSearch 
                            gotoSearchLocation={gotoSearchLocation} 
                            setCurrentLocationCoords={setCurrentLocationCoords} 
                            showCurrentLocation={showCurrentLocation} 
                            setShowCurrentLocation={setShowCurrentLocation}
                        />
                    } 
                    <AOIMapActions 
                        initCreateAOI={initCreateAOI}
                        handleSaveAOI={handleSaveAOI}
                        aoiMode={aoiMode}
                        toggleFullScreen={toggleFullScreen}
                        isFullScreen={fullScreenHandle.active}
                    />
                    {isAOISidePanelRequired && <AOIPanel />}
                    <AOIMap 
                        onCreateAOIPolygonLoad={onCreateAOIPolygonLoad} 
                        showCreatePolygon={showCreatePolygon}
                        editAOI={editAOI}
                        aoiMode={aoiMode}
                        saveAOI={saveAOI}
                        handleAOINameInputChange={handleAOINameInputChange}
                        mapChanged={mapChanged}
                        onDrawingComplete={onDrawingComplete}
                    />  
                    <div className="inset-map-container">
                        {isInsetMapRequired && isMapLoaded && <InsetMap mapState={mapState}/>}
                    </div>
                </MapContext.Provider>
            </div>
        </FullScreen>
    );
}

export default AOIMapView;