import { useContext } from 'react';
import { AOIContext } from '../../Contexts/AOIContext';
import { Link } from 'react-router-dom';
import SortIcon from '../../Assets/img/icon-sort.svg';
import ClearIcon from '../../Assets/img/icon-clear.svg';
import { UserContext } from '../../Contexts/UserContext';

const AOIHeader = (props) => {
    const { view, groups } = useContext(AOIContext);
    const { domainConfig } = useContext(UserContext);

    return (
        <div className='tabs-switch-section my-aois-container'>
            <div className='container'>
                <div className='tabs-switch'>
                    <div className='aoi-status'>
                        <select className="form-select custom-select" onChange={(e) => props.setFilter("subscribed", e.target.value)} value={props.filter.subscribed}>
                            <option value="all">All</option>
                            <option value="subscribed">Subscribed</option>
                            <option value="unsubscribed">Unsubscribed</option>
                        </select>
                    </div>
                    {
                        view != "map" &&
                        <div className="sort-by">
                            <div className="sorting-ddl">
                                <label>
                                    <img src={SortIcon} />
                                </label>
                                <select className="form-select" onChange={(e) => props.changeSortBy(e.target.value)} value={props.sortBy}>
                                    <option value="lastModifiedDate">Date</option>
                                    <option value="name">Name</option>
                                </select>
                            </div>
                        </div>
                    }
                    {
                        !domainConfig.hideGroupView && groups.length > 0 &&
                        <div className="sort-by">
                            <div className="sorting-ddl">
                                <select className="form-select" onChange={(e) => props.changeGroup(e.target.value)} value={props.selectedGroupId}>
                                    <option value="">All Groups</option>
                                    {
                                        groups.map((group) => <option value={group.ext_id} key={group.ext_id}>{group.tag}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    }
                    {
                        view != "group" &&
                        <div className="aoi-search">
                            <input className="search" id="search" type="search" name="query" placeholder="Enter AOI name" required data-form-type=""
                                value={props.filter.filterText} onChange={(e) => props.setFilter("filterText", e.target.value)} />
                            <i className="clear" onClick={() => { props.setFilter("filterText", "") }}>
                                <img src={ClearIcon} />
                            </i>
                        </div>
                    }
                    <ul className='view-toggle'>
                        {
                            !domainConfig.hideListView &&
                            <li className={`grid-view ${view == 'list' && 'active'}`}>
                                <Link to={"/portfolio/list"} >
                                    <i></i> <span>List</span>
                                </Link>
                            </li>
                        }
                        {
                            !domainConfig.hideGroupView &&
                            <li className={`group-view ${view == 'group' && 'active'}`}>
                                <Link to={"/portfolio/group"} >
                                    <i></i> <span>Group</span>
                                </Link>
                            </li>
                        }
                        {
                            !domainConfig.hideMapView &&
                            <li className={`map-view ${view == 'map' && 'active'}`}>
                                <Link to={"/portfolio/map"} >
                                    <i></i> <span>Map</span>
                                </Link>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default AOIHeader;