import {fetchWrapper} from "../Helpers/fetchWrapper";
import { apiService } from './apiService';
import { stateMachineService } from "./stateMachineService";

const STATE_MACHINE_GROUPS = "ImportGroupFromGeojson";

const addUpdateGroup = (mkey, region, groupName, description, aoiIds, groupExtId) => {
    const group = {
        tag: groupName,
        description: description,
        member_aoi_ids: aoiIds
    };
    if(groupExtId)
        return fetchWrapper.put(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/group/${groupExtId}`, group);
    return fetchWrapper.post(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/group`, group);
};

const getGroups = (mkey, region) => {
    return fetchWrapper.get(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/groups`);
};

const deleteGroup = (mkey, region, groupId, deleteAOIs) => {
    const body = {
        'is_delete_aois': deleteAOIs
    };
    return fetchWrapper.deleteRequest(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/group/${groupId}`, body);
};

const subscribeUsecase = (mkey, region, groupId, usecaseName, data) => {
    const postData = (data == null ? {} : data);
    return fetchWrapper.post(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/group/${groupId}/usecase/${usecaseName}/subscribe`, postData);
};

const unsubscribeUsecase = (mkey, region, groupId, usecaseName) => {
    return fetchWrapper.post(`${apiService.getMemberApiBaseUrl(region)}/${mkey}/group/${groupId}/usecase/${usecaseName}/unsubscribe`, {});
};

const startImportGroup = (mkey, region, filename) => {
    const data = {
        "mkey": mkey,
        "geojson_temp_key": filename
    };
    return stateMachineService.run(region, STATE_MACHINE_GROUPS, data);
};

const getImportStatus = (region, executionId) => {
    return stateMachineService.getStatus(region, STATE_MACHINE_GROUPS, executionId);
};

const cancelImport = (region, executionId) => {
    return stateMachineService.stop(region, STATE_MACHINE_GROUPS, executionId);
};

const importGeoJson = (region, executionId, name, column) => {
    const data = {
        "group_name": name,
        "tag_column": column
    };
    return stateMachineService.callback(region, STATE_MACHINE_GROUPS, executionId, data);
};

export const groupService = {
    addUpdateGroup,
    getGroups,
    deleteGroup,
    subscribeUsecase,
    unsubscribeUsecase,
    startImportGroup,
    getImportStatus,
    importGeoJson,
    cancelImport
};
