import React, {useContext, useEffect, useState} from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { usecaseService } from '../../Services/usecaseService';
import { Link } from 'react-router-dom';

const Subscriptions = (props) => {
    const {getPricesForSubscribedAOIs, domainConfig, usecases, expensesPerMonth} = useContext(UserContext);
    const [subscriptionData, setSubscriptionData] = useState({});

    useEffect(() => {
        refresh();
    }, [props.aois]);

    const refresh = () => {
        getPricesForSubscribedAOIs(props.mkey, props.region, props.aois, (data) => {
            setSubscriptionData(data);
        });
    };

    const renderSubscription = (aoi, name, price, url) => {
        return <tr key={aoi + name}>
                    <td>{aoi}</td>
                    {
                        props.hideLinks 
                        ? <td>{name}</td>
                        : <td><Link to={url}>{name}</Link></td>
                    }
                    <td>{price}</td>
                </tr>;
    };

    const renderSubscriptions = () => {
        var subscriptions = [];
        Object.keys(subscriptionData).map((extId) => {
            var aoi = props.aois.find((a) => a.ext_id == extId);
            var subData = subscriptionData[extId];
            if(aoi != null && subData != null) {
                Object.keys(subData).map((s) => {
                    let url = `/${usecaseService.getUsecaseUrl(s)}/${aoi.member_aoi_id}`;
                    var usecase = domainConfig.usecases.find((u) => u.name == s);
                    var usecaseName = usecase != null ? usecase.displayName : s;
                    var configUsecase = usecases.find((u) => u.name == s);
                    var price = configUsecase?.price != null ? `\$ ${subData[s]} /month` : "Free";
                    subscriptions.push([aoi.tag, usecaseName, price, url, configUsecase?.price == null ? -1 : subData[s]]);
                });
            }
        });
        subscriptions.sort((a, b) => {
            return b[4] - a[4];
        })
        return subscriptions.map((s) => {
            return renderSubscription(s[0], s[1], s[2], s[3]);
        });
    };

    return (
            <div>
                <div><b>Monthly Subscription Amount: </b>$ {expensesPerMonth} /month</div>
                <div>&nbsp;</div>
                <div className="table-responsive">
                    <table className="table table-style">
                        <thead>
                            <tr>
                                <th scope="col">AOI Name</th>
                                <th scope="col">Service</th>
                                <th scope="col">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            renderSubscriptions()
                        }
                        </tbody>
                    </table>
                </div>
            </div>
    );
}

export default Subscriptions;