import React, { useContext, useEffect, useState } from 'react';
import { billingService } from "../Services/billingService";
import { UserContext } from "../Contexts/UserContext";
import InfoIcon from '../Assets/img/icon-info.svg';
import BonusIcon from '../Assets/img/icon-bonus.svg';
import PoweredByStripeIcon from '../Assets/img/img-powered-by-stripe.png';
import toast from 'react-hot-toast';
import { isValidInteger } from '../Components/Utils';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

const Payment = (props) => {
    let { paramAmount } = useParams();
    const [amount, setAmount] = useState(paramAmount ?? "");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [creditMessage, setCreditMessage] = useState("");
    const { user, expensesPerMonth } = useContext(UserContext);

    useEffect(() => {
        if (paramAmount != null)
            changeAmount(paramAmount);
    }, [paramAmount]);

    const changeAmount = (newAmount) => {
        setAmount(newAmount);
        setMessage("");
        setError("");
        setCreditMessage("");
        if (isValidInteger(newAmount)) {
            const intAmount = parseInt(newAmount);
            if (intAmount > 10000) {
                setError("Please enter amount less than or equal to $ 10,000 ");
                return;
            }
            var updatedCredits = parseInt(intAmount);
            var bonus = 0;
            if (intAmount >= 150 && intAmount < 1500) {
                bonus = Math.ceil(intAmount * 0.1);
            } else if (intAmount >= 1500) {
                bonus = Math.ceil(intAmount * 0.2);
            }
            updatedCredits = intAmount + bonus;
            if(bonus > 0) {
                setCreditMessage(`\$${intAmount} + \$${bonus} (Bonus) = \$${updatedCredits} will be added to your wallet`);
            } else {
                setCreditMessage(`\$${intAmount} will be added to your wallet`);
            }

            if (120 <= intAmount && intAmount < 150) {
                setMessage(`Add \$${150 - intAmount} more to unlock bonus of 10%`)
            } else if (1200 <= intAmount && intAmount < 1500) {
                setMessage(`Add \$${1500 - intAmount} more to unlock bonus of 20%`)
            }
        } else if (newAmount != "") {
            setError("Please enter a valid number without fractions");
        }
    };

    const gotoPayment = () => {
        if (!isValidInteger(amount)) {
            toast.error("Please enter a valid number without fractions");
            return;
        }

        let postData = {
            customer_entered_amount: parseInt(amount),
            success_url: window.location.origin + "/payment/success",
            cancel_url: window.location.origin + "/payment/" + amount
        };
        billingService.stripeCheckoutSession(user.originalRegion, user.originalmKey, postData).then((data) => {
            window.location = data.url;
        });
    };

    return (
        <div className="page-body payments">
            <div className="container">
                <div className="form-content">
                    <div className="form-head">
                        <h3>Purchase</h3>
                    </div>
                    <div class="purchase-section">
                        <div class="purchase-left">
                            <div class="purchase-row">
                                <label>Enter the amount you want to add to your wallet:</label>
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <input id="amount" class="form-control" type="amount" name="amount" 
                                        placeholder="Amount" value={amount} onChange={(e) => changeAmount(e.target.value)}/>
                                </div>
                            </div>
                            {
                                error != "" && 
                                <div class="alert alert-danger" role="alert">
                                    {error}
                                </div>
                            }
                            {
                                message != "" && 
                                <div class="alert alert-success" role="alert">
                                    {message}
                                </div>
                            }
                            {
                                creditMessage != "" && 
                                <div class="purchase-row-single">
                                    {creditMessage}
                                </div>
                            }
                            <div class="purchase-btn-area">
                                <div class="checkout-btn">
                                    <button type="button" class="btn btn-primary" onClick={gotoPayment}>Checkout &rarr;</button>
                                    <img src={PoweredByStripeIcon} class="stripe" />
                                </div>
                            </div>
                        </div>
                        <div class="purchase-right">
                            <div class="purchase-right-row">
                                <label>Monthly Subscription Amount</label>
                                <p><span>$ {expensesPerMonth}</span> /month &nbsp;
                                    <img src={InfoIcon}
                                        alt="" data-bs-trigger="hover focus" data-bs-container="body"
                                        data-bs-toggle="popover" data-bs-placement="top"
                                        data-bs-content="Monthly subscription amount implies total amount we deduct in a month from their wallet. And it can sometimes be less but not more." />
                                        <br/><Link to="/subscriptions">Subscriptions</Link>
                                    </p>
                            </div>
                            <div class="purchase-right-row">
                                <label>Amount in Wallet</label>
                                <p><span>$ {user.tokens?.amount}</span></p>
                            </div>
                        </div>
                    </div>



                    <div class="form-head">
                        <h3>Pricing Schemes</h3>
                    </div>
                    <div class="purchase-plans">
                        <div class="purchase-plan-box">
                            <div class="plan-head">
                                Scheme I
                            </div>
                            <div class="plan-details">
                                <div class="purchase-plan-row">
                                    <label>Dollar Range</label>
                                    <p><span>$0 - $149</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="purchase-plan-box">
                            <div class="plan-head">
                                Scheme II
                            </div>
                            <div class="plan-details">
                                <div class="purchase-plan-row">
                                    <label>Dollar Range</label>
                                    <p><span>$150 - $1499</span></p>
                                </div>
                                <div class="purchase-plan-row">
                                    <img src={BonusIcon} alt="" />
                                    <p><span>10%</span> of purchased dollar amount </p>
                                </div>
                                <div class="purchase-plan-row">
                                    <label>Example Purchase</label>
                                    <p>If you add $150 to your wallet, we
                                        will add additional $15 for a total of $165.</p>
                                </div>
                            </div>
                        </div>
                        <div class="purchase-plan-box">
                            <div class="plan-head">
                                Scheme III
                            </div>
                            <div class="plan-details">
                                <div class="purchase-plan-row">
                                    <label>Dollar Range</label>
                                    <p><span>$1500 - $9999</span></p>
                                </div>
                                <div class="purchase-plan-row">
                                    <img src={BonusIcon} alt="" />
                                    <p><span>20%</span> of purchased dollar amount </p>
                                </div>
                                <div class="purchase-plan-row">
                                    <label>Example Purchase</label>
                                    <p>If you add $1500 to your wallet, we
                                        will add additional $300 for a total of $1800.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Payment;
