import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    return (
        <footer>
            <div class="container">
                <Link to="/PrivacyPolicy">Privacy Policy</Link>  <span>&bull;</span> <Link to="/termsofservice">Terms and Conditions</Link> <span>&bull;</span>
                Copyright © 2024 DataDeli,
                Inc. All rights Reserved.
            </div>

        </footer>
    );
}

export default Footer;