const defaultCenter = {
    lat: 18.67542,
    lng: 73.91153
};

const defaultZoom = 13;

const colorCodes = {
    violet: "#8F00FF",
    indigo: "#4B0082",
    blue: "#0000FF",
    green: "#00FF00",
    yellow: "#FFC700",
    orange: "#FFA500",
    red: "#FF0000"    
}

const zoomAssociatedStrokeWeight = {
    10: 3,
    11: 3,
    12: 3,
    13: 3,
    14: 3,
    15: 3,
    16: 3,
    17: 3,
    18: 3,
    19: 3,
    20: 3,
    21: 3
}

const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeId: 'hybrid',
    mapTypeControl: true,
    mapTypeControlOptions: {
        position: 6
    },
    options: {
        gestureHandling: 'greedy'
    },
    zoomControlOptions: {
        position: 4
    },
};

const serviceAreaOptions = {
    strokeColor: '#6259ca',
    strokeOpacity: 1,
    strokeWeight: 4,
    fillColor: '#000000',
    fillOpacity: 0.7
};

const satelliteMapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeId: 'hybrid',
    options: {
        gestureHandling: 'greedy'
    }
};

const roadMapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeId: 'roadmap',
    options: {
        gestureHandling: 'greedy'
    },
    styles: [
        {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ "visibility": "off" }]
        },
        {
            featureType: "road",
            elementType: "labels",
            stylers: [{ "visibility": "off" }]
        },
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ "visibility": "off" }]
        },
        {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ "visibility": "off" }]
        },
        {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [{ color: "#94b4ba" }],
        },
        {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ color: "#faf7d2" }],
        }
    ]
};

const mapContainerStyle = {
    width: '100%', 
    height: '100%'
};

const defaultDrawingManagerOptions = {
    drawingControl: false,
    strokeColor: "red"
};

const usecaseDrawingManagerOptions = {
    ...defaultDrawingManagerOptions,
    drawingControl: false
};

const userAOIoptions = {
    fillColor: "lightblue",
    fillOpacity: 0,
    strokeColor: "#FFC700",
    strokeOpacity: 1,
    strokeWeight: 3,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
};

const createAOIPolygonoptions = {
    fillColor: "lightblue",
    fillOpacity: 0.15,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 5,
    clickable: true,
    draggable: true,
    editable: true,
    geodesic: true,
    zIndex: 2
};

const createRectangleAnnotationOptions = {
    fillColor: "lightblue",
    fillOpacity: 0.15,
    strokeColor: "#b901ae",
    strokeOpacity: 1,
    strokeWeight: 5,
    clickable: true,
    draggable: true,
    editable: true,
    geodesic: true,
    zIndex: 2
};

const rectangleAnnotationOptions = {
    fillColor: "lightblue",
    fillOpacity: 0,
    strokeColor: "#b901ae",
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: true,
    zIndex: 2
};

const defaultPipelineOptions = {
    fillColor: "lightblue",
    fillOpacity: 0,
    strokeColor: "#FFC700",
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
};

const getDefaultDashboardSettings = () => {
    return {
        "aoiBorderColor": "#073091",
        "aoi50mBorderColor": "#f6dc07",
        "pipelineColor": "#fa0523",
        "lineThickness": 3,
        "isDotted": true,
        "is50MDotted": false,
        "thickness": [
          5,
          11,
          16,
          22
        ],
        "zoomLevel": 18,
        "pipelineSettings": {
          "style": "dotted",
          "firstColor": "#f9071b",
          "secondColor": "#d10808"
        },
        "rou50mSettings": {
          "style": "straightBorder",
          "firstColor": "#f8e71c",
          "secondColor": "#0f185c"
        },
        "mapSettings": {
          "landscape": "#faf7d2",
          "water": "#94b4ba"
        },
        "landPipelineSettings": {
          "style": "dotted",
          "firstColor": "#d0021b",
          "secondColor": "#ff0000"
        },
        "landRouSettings": {
          "style": "straightBorder",
          "firstColor": "#f8e71c",
          "secondColor": "#000000"
        },
        "deformationLineThickness": 3
    };
}

const getPipelineSettings = (settings) => {
    let options = {
        firstOptions: {...defaultPipelineOptions},
        secondOptions: {...defaultPipelineOptions}
    };
    if(settings == null)
        return options;

    options.firstOptions.strokeColor = settings.firstColor;
    options.secondOptions.strokeColor = settings.secondColor;
    
    switch(settings.style) {
        case "dotted":
            options.secondLine = false;
            options.secondOptions.additionalThickness = 0;
            options.firstOptions.strokeOpacity = 0,
            options.firstOptions.icons = [{
                                            icon: {
                                            path: 'M 0,0 0,2',  // Use a circle symbol
                                            strokeOpacity: 1,
                                            scale: 2  // Adjust size of the dots
                                            },
                                            offset: '0',
                                            repeat: '10px'  // Adjust spacing between the dots
                                        }];
            
            break;
        case "twoDotted":
            options.secondLine = true;
            options.secondOptions.additionalThickness = 0;
            options.firstOptions.strokeOpacity = 0,
            options.firstOptions.icons = [{
                                            icon: {
                                            path: 'M 0,0 0,2',  // Use a circle symbol
                                            strokeOpacity: 1,
                                            scale: 2  // Adjust size of the dots
                                            },
                                            offset: '0',
                                            repeat: '10px'  // Adjust spacing between the dots
                                        }];
            options.secondOptions.strokeOpacity = 0,
            options.secondOptions.icons = [{
                                            icon: {
                                            path: 'M 0,-2 0,0',  // Use a circle symbol
                                            strokeOpacity: 1,
                                            scale: 2  // Adjust size of the dots
                                            },
                                            offset: '0',
                                            repeat: '10px'  // Adjust spacing between the dots
                                        }];
            break;
        case "dottedBorder":
            options.secondLine = true;
            options.secondOptions.additionalThickness = 2;
            options.firstOptions.strokeOpacity = 0,
            options.firstOptions.icons = [{
                                            icon: {
                                            path: 'M 0,0 0,2',  // Use a circle symbol
                                            strokeOpacity: 1,
                                            scale: 2  // Adjust size of the dots
                                            },
                                            offset: '0',
                                            repeat: '10px'  // Adjust spacing between the dots
                                        }];
            break;
        case "straightBorder":
            options.secondLine = true;
            options.secondOptions.additionalThickness = 2;
            break;
        case "straight":
        default:
            options.secondLine = false;
            options.secondOptions.additionalThickness = 0;
            break;
    }
    return options;
};

const getPipelineSettingsWithDefaults = (settings, defaultOptions) => {
    let options = {
        firstOptions: {...defaultOptions},
        secondOptions: {...defaultOptions}
    };
    if(settings == null)
        return options;

    options.firstOptions.strokeColor = settings.firstColor;
    options.secondOptions.strokeColor = settings.secondColor;

    switch(settings.style) {
        case "dotted":
            options.secondLine = false;
            options.secondOptions.additionalThickness = 0;
            options.firstOptions.strokeOpacity = 0,
            options.firstOptions.icons = [{
                                            icon: {
                                            path: 'M 0,0 0,2',  // Use a circle symbol
                                            strokeOpacity: 1,
                                            scale: 2  // Adjust size of the dots
                                            },
                                            offset: '0',
                                            repeat: '10px'  // Adjust spacing between the dots
                                        }];
            
            break;
        case "twoDotted":
            options.secondLine = true;
            options.secondOptions.additionalThickness = 0;
            options.firstOptions.strokeOpacity = 0,
            options.firstOptions.icons = [{
                                            icon: {
                                            path: 'M 0,0 0,2',  // Use a circle symbol
                                            strokeOpacity: 1,
                                            scale: 2  // Adjust size of the dots
                                            },
                                            offset: '0',
                                            repeat: '10px'  // Adjust spacing between the dots
                                        }];
            options.secondOptions.strokeOpacity = 0,
            options.secondOptions.icons = [{
                                            icon: {
                                            path: 'M 0,-2 0,0',  // Use a circle symbol
                                            strokeOpacity: 1,
                                            scale: 2  // Adjust size of the dots
                                            },
                                            offset: '0',
                                            repeat: '10px'  // Adjust spacing between the dots
                                        }];
            break;
        case "dottedBorder":
            options.secondLine = true;
            options.secondOptions.additionalThickness = 2;
            options.secondOptions.strokeWeight += 2;
            options.firstOptions.strokeOpacity = 0,
            options.firstOptions.icons = [{
                                            icon: {
                                            path: 'M 0,0 0,2',  // Use a circle symbol
                                            strokeOpacity: 1,
                                            scale: 2  // Adjust size of the dots
                                            },
                                            offset: '0',
                                            repeat: '10px'  // Adjust spacing between the dots
                                        }];
            break;
        case "straightBorder":
            options.secondLine = true;
            options.secondOptions.strokeWeight += 2;
            options.secondOptions.additionalThickness = 2;
            break;
        case "straight":
        default:
            options.secondLine = false;
            options.secondOptions.additionalThickness = 0;
            break;
    }
    return options;
};


export const AOIOptions = {
    mapOptions,
    serviceAreaOptions,
    roadMapOptions,
    satelliteMapOptions,
    mapContainerStyle,
    defaultDrawingManagerOptions,
    usecaseDrawingManagerOptions,
    userAOIoptions,
    createAOIPolygonoptions,
    defaultCenter,
    defaultZoom,
    colorCodes,
    zoomAssociatedStrokeWeight,
    createRectangleAnnotationOptions,
    rectangleAnnotationOptions,
    defaultPipelineOptions,
    getPipelineSettings,
    getPipelineSettingsWithDefaults,
    getDefaultDashboardSettings
};
