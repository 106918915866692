import React, { useEffect, useState } from 'react';
import moment from "moment";

const momentTimezone = require('moment-timezone');

const Timezone = (props) => {
    const [allTimezones, setAllTimezones] = useState([]);
    const [selectedTimezone, setSelectedTimezone] = useState("");

    useEffect(() => {
        var timezones = momentTimezone.tz.names();
        setAllTimezones(timezones);
        if(timezones.includes(props.timezone)) {
            setSelectedTimezone(props.timezone);
        } else {
            const currentTimeZone = Intl.DateTimeFormat().resolvedOptions();
            setSelectedTimezone(currentTimeZone.timeZone);
        }
    }, [props.timezone]);

    const getDisplayOffSet = (minutes) => {
        const offsetHours = Math.floor(Math.abs(minutes) / 60);
        const offsetSign = minutes < 0 ? '-' : '+';
      
        return `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(Math.abs(minutes % 60)).padStart(2, '0')}`;
    };

    return (
        <select onChange={(e) => props.onTimezoneChange(e.target.value)} value={selectedTimezone}>
            <option value="">Select Timezone</option>
            {
                allTimezones.map((timezone) => {
                    const now = moment.tz(timezone);
                    const offset = now.utcOffset();
                    return <option value={timezone} key={timezone}>{timezone} {getDisplayOffSet(offset)}</option>
                })
            }
        </select>
    );
}

export default Timezone;