import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../Contexts/UserContext';
import { AOIContext } from '../../Contexts/AOIContext';
import PlusIcon from '../../Assets/img/icon-plus-cirlce.png';
import NoDataIcon from '../../Assets/img/img-no-data.svg';
import AOIGridCard from './AOIGridCard';
import AppliedFilters from './AppliedFilters';


const AOIGridView = (props) => {
    const {user, isAOIDataLoading} = useContext(UserContext);
    const {userAOIs, allAOIs} = useContext(AOIContext);

    return (
        <>
            <div className="grid-container">
              <AppliedFilters view="grid" filter={props.filter} setFilter={props.setFilter} />
              <div className="container">
                {
                    isAOIDataLoading == false && allAOIs?.length == 0 &&
                    <div className="no-data">
                        <h2>You do not have any existing AOI</h2>
                        <h2>To get started, create a new AOI.</h2>
                        <Link className="add-btn" to={"/portfolio/map/create"}>
                            <img src={PlusIcon} alt="" />
                            Create AOI
                        </Link>
                    </div>
                }
                {
                    isAOIDataLoading == false && allAOIs?.length != 0 && userAOIs.length == 0 &&
                    <div className="no-data">
                        <h2>There are no AOIs matching the filter criteria.</h2>
                        <h2>Please clear the filters to see all the AOIs.</h2>
                    </div>
                }
                {
                    userAOIs?.length > 0 &&
                    <div className="aoi-lists unsubscribed">
                        <div className="row">
                            {
                                user.isReadOnly == false &&
                                <div className="col-xs-12 col-md-6 col-xl-4">
                                    <div className="aoi-card create-aoi">
                                        <Link className="add-btn" to={"/portfolio/map/create"}>
                                            <img src={PlusIcon} alt="" />
                                            Create AOI
                                        </Link>
                                    </div>
                                </div>
                            }
                            {
                                userAOIs.map((aoi) => <div className="col-xs-12 col-md-6 col-xl-4" key={aoi.ext_id}>
                                                            <AOIGridCard aoi={aoi} view="grid" show="all" />
                                                        </div>)
                            }
                        </div>
                    </div>
                }
              </div>
            </div>
        </>
    );
}

export default AOIGridView;