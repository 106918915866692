import { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { UserContext } from "../../Contexts/UserContext";
import SendMessageIcon from "../../Assets/img/icon-send-message.svg";
import MessageBox from "../SendMessage/MessageBox";
import { apiService } from "../../Services/apiService";

const SendUsecaseMessageModal = (props) => {
    const {user, sendNotification} = useContext(UserContext);
    const [body, setBody] = useState("");
    const [template, setTemplate] = useState("none");
    const messageRef = useRef();
    const subjectRef = useRef();
    const [mailType, setMailType] = useState("transactional");
    const userAuthId = user.isImpersonated ? user.impersonatedUser["auth-id"] : user["auth-id"];
    const userName = user.isImpersonated ? user.impersonatedUser?.profile?.name : user.profile?.name;

    const handleSendMessage = () => {
        const search_criteria = {auth_ids: [userAuthId]}
        const subject = subjectRef.current.value ?? "";
        const message = messageRef.current.value ?? "";

        const onSuccess = (isSuccess) => {
            isSuccess && props.setShowSendUsecaseMessageModal(false);
        };

        sendNotification(search_criteria, subject, message, mailType, onSuccess);
    };

    useEffect(() => {
        if(template == "none") {
            subjectRef.current.defaultValue = "";
            setBody("");
        } else if(template == "newimage") {
            subjectRef.current.defaultValue = `Latest image is uploaded for your AOI ${props.aoi?.tag}`;
            setBody("Latest image is uploaded for your " + props.aoi?.tag + " AOI. There are no notable changes in this AOI. \n" +
             "Here is the link to your latest image: " + apiService.getHostUrl() + props.url + "\n" +
             "Login to DataDeli and go to Timeline to view latest captured image in all the three standard views (Single View, Slider View and Comparison View).");

        } else if(template == "newannotation") {
            subjectRef.current.defaultValue = `Latest image is uploaded for your AOI ${props.aoi?.tag} with notable changes`;
            setBody("Latest image is uploaded for your " + props.aoi?.tag + " AOI. There are changes noticed in your AOI. \n" +
            "Here is the link to your latest image: " + apiService.getHostUrl() + props.comparisonUrl + "\n" +
            "Login to DataDeli and go to Timeline to view annotations with associated dates for detected changes.\n" + 
            "There are three standard views: \n" + 
            "Single View displays the latest AOI image, with annotations available via the toggle button.\n " + 
            "In Slider and Comparison Views, annotations appear on the right with specified data for each compared date.\n " +
            "Click on the date in the annotation on the left for visual comparison in these views.\n " + 
            "To hide annotations in Slider and Comparison Views, simply deselect the toggle button.");
        }
    }, [template]);

    return (
        <>
            <Modal
                show={() => props.setShowSendUsecaseMessageModal(true)}
                onHide={() => props.setShowSendUsecaseMessageModal(false)}
                backdrop="static"
                keyboard={false}
                size='lg'
                dialogClassName="send-msg-modal-dialog"
                className="send-msg-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title><img src={SendMessageIcon}/> Send Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="user-info">
                            <label className="form-label" htmlFor="user-info-input">User </label>
                            <input className="auth-id" value={`${userName} (${userAuthId})`} disabled={true}/>
                        </div>
                        <div className="user-info">
                            <label className="form-label">Template</label>
                            <select className="form-select" onChange={(e) => setTemplate(e.target.value)} value={template}>
                                <option value="none">Select Template</option>
                                <option value="newimage">New Image is added without Annotations</option>
                                <option value="newannotation">New Image is added with Annotations</option>
                            </select>
                        </div>
                        <MessageBox showSubject={true} messageRef={messageRef} subjectRef={subjectRef} mailType={mailType} setMailType={setMailType} body={body}/>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" aria-label="Close" onClick={() => props.setShowSendUsecaseMessageModal(false)}>CANCEL</button>
                    <button type="button" className="btn btn-primary" onClick={handleSendMessage}>SEND</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SendUsecaseMessageModal;