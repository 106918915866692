import React, {useContext, useEffect, useState} from 'react';
import { adminService } from '../../Services/adminService';
import toast from 'react-hot-toast';
import { UserContext } from '../../Contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import UsecaseEditMap from './UsecaseEditMap';
import { useJsApiLoader } from '@react-google-maps/api';

const UsecaseEdit = (props) => {
    const navigate = useNavigate();
    const {user, usecases, setUsecases} = useContext(UserContext);
    const [showForm, setShowForm] = useState(true);
    const [values, setValues] = useState({
        name: "",
        description: "",
        type: "",
        configuration: "",
        price: ""
    });
    const [originalConfiguration, setOriginalConfiguration] = useState(null);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: window.config.mapApiKey,
        libraries
    });

    useEffect(() => {
        const usecase = usecases?.find(u => u.name == props.name);
        const config = usecase?.configuration != null ? JSON.stringify(usecase?.configuration, null, 4) : "";
        const initialValues = {
            name: usecase?.name,
            description: usecase?.description,
            type: usecase?.type,
            configuration: config,
            price: usecase?.price != null ? JSON.stringify(usecase?.price, null, 4) : ""
        };
        setOriginalConfiguration(config);
        setValues(initialValues);
    }, [props.name, usecases]);

    useEffect(() => {
        adminService.getUsecases(user.originalRegion).then((data) => {
            setUsecases(data);
        }).catch((e) => {
            toast.error("Unable to retrieve the usecases. Error: " + e);
        });
    }, []);

    const updateMap = (map) => {
        setValues({...values, configuration: JSON.stringify(getConfigWithMap(map))});
    };

    const reset = () => {
        setValues({...values, configuration: originalConfiguration});
    }

    const getGeometry = (configuration) => {
        if(!!configuration)
        {
            const config = JSON.parse(configuration);
            if(!!config?.geographic_extent)
                return config.geographic_extent;
        }
        return null;
    };

    const handleInputChange = (event) => {
        if(props.name != null && event.target.name == "name")
            return;
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const backToUsecases = () => {
        window.scrollTo(0, 0);
        navigate("/usecase");
    };

    const getConfigWithMap = (map) => {
        const config = !!values.configuration ? JSON.parse(values.configuration) : (map != null ? {} : null);
        if(map != null)
            config.geographic_extent = map;
        return config;
    };

    const save = () => {
        if(!validateFields())
            return;

        const usecase = {
            description: values.description, 
            type: values.type,
            configuration: !!values.configuration ? JSON.parse(values.configuration) : null,
            price: values.price.trim() != "" ? JSON.parse(values.price) : null
        };
        if(props.name == null) 
            usecase.name = values.name;

        addUpdate(values.name, usecase)
            .then(() => {
                backToUsecases();
                toast.success("Usecase has been successfully added/updated");
            }).catch((e) => {
                console.error(e);
                toast.error("Unable to add/update Usecase. Error: " + e);
            });
    };

    const addUpdate = (name, usecase) => {
        if(props.name != null) 
            return adminService.updateUsecase(name, usecase);
        return adminService.addUsecase(usecase);
    };

    const validateFields = () => {
        if(!user.isSuperAdmin) 
        {
            toast.error("You do not have access to add/edit usecase");
            return false;
        }
        if(values.name.trim() == "")
        {
            toast.error("Please enter Name");
            return false;
        }
        if(values.description.trim() == "") {
            toast.error("Please enter Description");
            return false;
        }
        if(values.type == "") {
            toast.error("Please select Type");
            return false;
        }
        try {
            if(values.configuration.trim() != "")
                JSON.parse(values.configuration);
        } catch {
            toast.error("Invalid JSON value for Configuration");
            return false;
        }
        try {
            if(values.price.trim() != "")
                JSON.parse(values.price);
        } catch {
            toast.error("Invalid JSON value for Price");
            return false;
        }

        return true;
    };

    return (
            <div className="form-content usecase-edit">
            <div className="form-head">
                    <h3>{props.name != "" ? "Edit " : "Add a " } Usecase</h3>
                </div>
                <div className='form-body'>
                    <div className="usecase-edit-row">
                        <label className="form-label" htmlFor="Name">Name <span className="required">*</span></label>
                        <input type="text" className="form-control" id="name" name="name" value={values.name} onChange={(e) => handleInputChange(e)}/>
                    </div>
                    <div className="usecase-edit-row">
                        <label className="form-label" htmlFor="Description">Description <span className="required">*</span></label>
                        <input type="text" className="form-control" id="Description" name="description" value={values.description} onChange={(e) => handleInputChange(e)} />
                    </div>
                    <div className="usecase-edit-row">
                        <label className="form-label" htmlFor="type">Type <span className="required">*</span></label>
                        <select className="form-select" id="type" name="type" value={values.type} onChange={(e) => handleInputChange(e)}>
                            <option value="">Select a Type</option>
                            <option value="aoi">AOI</option>
                            <option value="group">Group</option>
                        </select>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="configuration">Configuration <span className="required">*</span> <a className="test" onClick={() => setShowForm(!showForm)}>{showForm ? "Show Map" : "Show JSON"}</a></label>
                                {
                                    showForm
                                    ? <textarea id="configuration" name="configuration" cols="" rows="10" className="form-control" value={values.configuration} onChange={(e) => handleInputChange(e)}></textarea>
                                    : <UsecaseEditMap geometry={getGeometry(values.configuration)} savedGeometry={getGeometry(originalConfiguration)} updateMap={updateMap} reset={reset} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="price">Price</label>
                                <textarea id="price" name="price" cols="" rows="10" className="form-control" value={values.price} onChange={(e) => handleInputChange(e)}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-area">
                    <button type="button" className="btn btn-outline" onClick={backToUsecases}>CANCEL</button>
                    <button type="button" className="btn btn-primary" onClick={save}>SUBMIT</button>
                </div>
            </div>
    );
}

export default UsecaseEdit;