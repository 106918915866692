import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useJsApiLoader } from '@react-google-maps/api';
import { AOIContext } from '../Contexts/AOIContext';
import { UserContext } from '../Contexts/UserContext';
import { aoiService } from '../Services/aoiService';
import { MapUtils } from '../Components/MapUtils';
import { AOIOptions } from '../Components/AOIOptions';
import AOIMapView from '../Components/AOI/AOIMapView';
import AOIGridView from '../Components/AOI/AOIGridView';
import AOIGroupView from '../Components/AOI/Group/AOIGroupView';
import AOIHeader from '../Components/AOI/AOIHeader';
import AOIWizzardModal from '../Components/AOIWizzard/AOIWizzardModal';
import Copyright from '../Components/Copyright';
import GMRInnovexDemo from './GMRInnovexDemo';
import SubscriptionSuccessModal from '../Components/AOIWizzard/SubscriptionSuccessModal';
import TourModal from '../Components/TourModal';

const appConfig = window.config;

const emptyAddress = {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    country: '',
    pin: ''
}

const emptyAOI = {
    tag: '',
    address: emptyAddress,
    area: '',
    circumference: '',
    geometry: {
        type: '',
        coordinates: []
    } 
}

const defaultAOIWizzardOptions = {
    mode: 'edit',
    activeStep: 1
}

const defaultView = 'list';

const Dashboard = () => {
    const navigate = useNavigate();
    let { dashboardView, singlegroup, aoiId } = useParams();
    const aoiRef = useRef();
    const mapRef = useRef(); 
    const {domainConfig, usecases, user, showAlert, siteCache, setSiteCache, allAOIs, groups, reloadAOI, filter, setFilter, getThumbnailUrls} = useContext(UserContext);
    const {showTour, hideTour} = useContext(UserContext);
    const [center, setCenter] = useState(AOIOptions.defaultCenter);
    const [view, setView] = useState(siteCache?.dashboardView ?? defaultView);
    const [openAOIWizzardModal, setOpenAOIWizzardModal] = useState(false);
    const [aoiWizzardOptions, setAOIWizzardOptions] = useState(defaultAOIWizzardOptions);
    const [showAOIPopover, setShowAOIPopover] = useState(false);
    const [searchLocation, setSearchLocation] = useState(null);
    const [aoi, setAOI] = useState(emptyAOI);
    const [aoiMode, setAOIMode] = useState('view');
    const [shapes, setShapes] = useState([]);
    const [userAOIs, setUserAOIs] = useState([]);
    const [selectedAOI, setSelectedAOI] = useState(null);
    const [savePolygonPaths, setSavePolygonPaths] = useState([]);
    const [viewDetails, showViewDetails] = useState(false);
    const [zoomToFit, setZoomToFit] = useState(true);
    const [sortBy, setSortBy] = useState("lastModifiedDate");
    const [currentLocation, setCurrentLocation] = useState(null);
    const [showCurrentLocation, setShowCurrentLocation] = useState(false);
    const [userGroups, setUserGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(filter.selectedGroup?.ext_id ?? "");
    const [counter, setCounter] = useState(0);
    const [showAttributes, setShowAttributes] = useState(false);
    const [selectedAOIId, setSelectedAOIId] = useState(null);
    const [serviceAreaGeometry, setServiceAreaGeometry] = useState([]);
    const [serviceArea, setServiceArea] = useState(null);
    const [forCreateAOI, setForCreateAOI] = useState(false);
    const [openSubscriptionSuccessModal, setOpenSubscriptionSuccessModal] = useState(false);

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: appConfig.mapApiKey,
        libraries
    });

    useEffect(() => { 
        if(domainConfig.portfolioComponent == null || domainConfig.portfolioComponent != "GMRInnovexDemo") {
            window.scrollTo(0, 0);
            const isValidView = ['list','map','group'].some(view => view == dashboardView?.trim().toLowerCase());
            if (isValidView) {
                handleSetView(dashboardView?.trim().toLowerCase());
            }
            else {
                navigate(`/portfolio/${siteCache?.dashboardView ?? getDefaultView()}`, {replace: true});
            }    
        }
    }, [dashboardView]);

    const getDefaultView = () => {
        if(!domainConfig?.hideListView)
            return "list";
        if(!domainConfig?.hideMapView)
            return "map";
        if(!domainConfig?.hideGroupView)
            return "group";
        return "list";
    };

    useEffect(() => { 
        if(singlegroup == "aoi") {
            setSelectedAOIId(aoiId);
            if (allAOIs.length > 0 && aoiId && aoiId.trim()) {
                const aoi = allAOIs.find(aoi => aoi.member_aoi_id == aoiId);
                if (aoi?.member_aoi_id != selectedAOI?.member_aoi_id) {
                    if (aoiMode == 'edit') 
                        discardAOIEdit();
                }
                if (aoi) {
                    setSearchLocation(null);
                    setSelectedAOI(aoi);
                    const aoiCenter = MapUtils.getCenterForGeometry(aoi.geometry);
                    setCenter({lat: aoiCenter?.lat(), lng: aoiCenter?.lng()});
                }
                else {
                    showAlert('AOI Not Found');
                    navigate(`/portfolio/${view}`);
                } 
            }
            setSelectedGroupId("");
        } 
        else if(singlegroup == "group") {
            setSelectedGroupId(aoiId);
        } else if(singlegroup == "create") {
            setForCreateAOI(true);
        }
    }, [aoiId, singlegroup]);

    useEffect(() => {
          window.history.replaceState(null, null, location.pathname);
    }, [user.mkey]);

    useEffect(() => {
        setSelectedGroupId(filter.selectedGroup?.ext_id ?? "");
    }, [filter.selectedGroup]);

    const changeSelectedGroup = (groupId) => {
        setSelectedGroupId(groupId);
        if(view == "map") {
            setZoomToFit(true);
            if(groupId == "") {
                navigate(`/portfolio/map`);
            } else {
                navigate(`/portfolio/map/group/${groupId}`);
            }
        }
    };

    const clearSelectedGroup = () => {
        setSelectedGroupId("");
    };

    const handleSetView = useCallback((view) => {
        setView(view);
        setSelectedAOI(null);
        setSelectedAOIId(null);
        discardAOICreate();
        discardAOIEdit();

        // Store the selected Dashboard view in cache
        setSiteCache({...siteCache, dashboardView: view});

        if (siteCache?.mapCenter && !selectedAOI) {
            setZoomToFit(false);
            setCenter({lat: siteCache?.mapCenter?.lat(), lng: siteCache?.mapCenter?.lng()});
        } else {
            setZoomToFit(true);
        }
    }, [view]);

    useEffect(() =>{ 
        if(selectedGroupId != "") {
            var group = groups.find(g => g.ext_id == selectedGroupId);
            if(group != null) {
                setFilter({...filter, selectedGroup: group});
            }
        } else {
            setFilter({...filter, selectedGroup: null});
        }
    }, [selectedGroupId]);

    useEffect(() => {
        let filteredAOIs = allAOIs ?? [];
        if(filter)
        {
            if(filter.selectedGroup?.ext_id != "") {
                var group = groups.find(g => g.ext_id == filter.selectedGroup?.ext_id);
                if(group != null) {
                    var aoisInGroup = [];
                    if(group.member_aoi_ids != null)
                        group.member_aoi_ids.map((aoiId) => aoisInGroup.push(allAOIs.find(a => a.member_aoi_id == aoiId)));
                    filteredAOIs = aoisInGroup;
                }
                setZoomToFit(true);
            }

            if(filter.filterText) {
                filteredAOIs = filteredAOIs.filter((a) => a.tag.toUpperCase().includes(filter.filterText.toUpperCase()));
            }
            if(filter.subscribed) {
                const usecaseNames = domainConfig.usecases.filter(u => !!u.isEnabled).map(u => u.name);
                if(filter.subscribed == "subscribed") {
                    filteredAOIs = filteredAOIs.filter((a) => a.subscribed_usecases?.some(u=>u.usecase.type == 'aoi' && usecaseNames.indexOf(u.usecase.name) >= 0));
                } else if(filter.subscribed == "unsubscribed") {
                    filteredAOIs = filteredAOIs.filter((a) => a.subscribed_usecases == null || a.subscribed_usecases.length == 0 
                            || !a.subscribed_usecases?.some(u=>u.usecase.type == 'aoi' && usecaseNames.indexOf(u.usecase.name) >= 0));
                }
            }
        }
        filteredAOIs.sort((a, b) => compareAoi(a, b, sortBy));
        let sortedGroups = groups;
        sortedGroups.sort((a, b) => compareAoi(a, b, sortBy));
        setUserGroups(sortedGroups);
        setUserAOIs([...filteredAOIs]);
    }, [filter, allAOIs, groups, sortBy]);
 
    useEffect(() => {
        if (selectedAOIId && selectedAOIId.trim()) {
            const aoi = userAOIs.find(aoi => aoi.member_aoi_id == selectedAOIId);
            if (aoi) {
                setSelectedAOI(aoi);
                if(showAttributes) {
                    showEditAttributes(aoi);
                    setShowAttributes(false);
                } 
            }
            else {
                setSelectedAOI(null);
            }
        }
    }, [userAOIs, selectedAOIId]);

    useEffect(() => {
        getThumbnailUrls(allAOIs, user.mkey);
    }, [isLoaded, user.mkey, allAOIs]);

    useEffect(()=>{
        const filteredUsecases = domainConfig.usecases.filter(u => !!u.isEnabled);
        const usecaseName = (filteredUsecases && filteredUsecases.length > 0) ? filteredUsecases[0].name : "";

        const serviceAreaGeometry = usecases.find(x => x.name == usecaseName)?.configuration?.geographic_extent;
        if (!!serviceAreaGeometry) {
          setServiceAreaGeometry(serviceAreaGeometry);
        }

        if (domainConfig.isSingleUsecase == true && !!isLoaded && serviceAreaGeometry) {          
            //Load Service Area
            setServiceArea(MapUtils.getServiceArea(serviceAreaGeometry));
        }
    }, [usecases, domainConfig, isLoaded]);

    const saveAOI = async (showAttributes = false) => {
        if (aoi.tag == null || aoi.tag.trim() == '') {
            showAlert('Please Enter a valid AOI Name');
            return;
        }
        if(aoiMode == 'create' && allAOIs.find(a => aoi.tag == a.tag)) {
            showAlert('An AOI already exists with the name ' + aoi.tag + ".", 'fail');
            return;
        }

        if (aoi.tag && aoi.tag.trim() && aoi.geometry.type && aoi.geometry.coordinates.length > 0) {
            let aoiPayload = JSON.parse(JSON.stringify(aoi));
            
            let aoiArea = 0;
            if (aoiMode == 'create') {
                aoiArea = MapUtils.calculateArea(aoiRef.current);
                aoiPayload.area = `${aoiArea} acres`;
                aoiPayload.circumference = `${MapUtils.calculateCircumference(aoiRef.current)} m`;
                aoiPayload.geometry.coordinates = MapUtils.coordinatesToGeoJson(aoiPayload.geometry.coordinates);
            }
            else if (aoiMode == 'edit') {  
                aoiArea = MapUtils.calculateGeometryArea(aoi.geometry);
                aoiPayload.area = `${aoiArea} acres`;
                aoiPayload.circumference = `${MapUtils.calculateGeometryCircumference(aoi.geometry)} m`;
                aoiPayload.geometry = aoi.geometry;
            }

            if(domainConfig.isSingleUsecase) {
                const filteredUsecases = domainConfig.usecases.filter(u => !!u.isEnabled);
                const usecaseName = (filteredUsecases && filteredUsecases.length > 0) ? filteredUsecases[0].name : "";
                const MAX_AOI_AREA = usecases.find(x => x.name == usecaseName)?.configuration?.max_aoi_area ?? Number.MAX_VALUE;
                if (aoiArea <= MAX_AOI_AREA) {
                    saveAOIInternal(aoiPayload, showAttributes);
                }
                else {
                    showAlert(`Provided AOI area exceeds the permissible limit of ${MAX_AOI_AREA} acres`, "fail");
                }
            } else {
                saveAOIInternal(aoiPayload, showAttributes);
            }

            if (user.aois == undefined || user.aois == null) {
                user.aois = [];
            }
        }
    };

    const saveAOIInternal = async (aoiPayload, showAttributes = false) => {
        if (aoiMode == 'create') {
            await aoiService.create(user.mkey, user.region, aoiPayload)
            .then((data) => { 
                showAlert('Congratulations! You have created your AoI!', 'success');
                discardAOICreate();
                setShowAttributes(showAttributes);
                setSelectedAOIId(data['member-aoi-id']);
                reloadAOI(data['member-aoi-id'], null);
            })
            .catch(error => {
                console.log('Error in Creating AOI', error, aoiPayload);
                showAlert ('AOI Creation Failed! ' + error, 'fail');
            });
        }
        else if (aoiMode == 'edit') {     
            await aoiService.update(user.mkey, user.region, selectedAOI.member_aoi_id, aoiPayload)
            .then((data) => { 
                setShowAOIPopover(false);
                showAlert('AOI Saved Successfully!', 'success');
                discardAOIEdit(false);
                setShowAttributes(showAttributes);
                setSelectedAOIId(data['member-aoi-id']);
                reloadAOI(selectedAOI.member_aoi_id, selectedAOI.ext_id);
            })
            .catch(error => {
                console.log('Error in Updating AOI', error, aoiPayload);
                showAlert ('AOI Update Failed! ' + error, 'fail');
            });
        }
    }

    const deleteShapes = () => {
        shapes.forEach(s => s.setMap(null));
    }

    const discardAOICreate = () => {
        setSavePolygonPaths([]);
        setShowAOIPopover(false);
        deleteShapes();
        setAOIMode('view');
    };

    const discardAOIEdit = (reset = true) => {
        if (aoiRef && aoiRef.current) {
            aoiRef.current.setOptions({editable: false, draggable: false});
            if (reset && selectedAOI && selectedAOI.geometry) {
                //Reset the correct polygon coordinates
                aoiRef.current.setPath(MapUtils.geoJsonToCoordinates(selectedAOI.geometry));
            }
        }
        if(mapRef && mapRef.current && selectedAOI && selectedAOI.tag) {
            setCounter(counter+1);
            mapRef.current.data.overrideStyle(mapRef.current.data.getFeatureById(selectedAOI.tag), {editable: false});
        }
        setAOIMode('view');
        setShowAOIPopover(false);
    };

    const handleClearLocationSearch = () => {
        setSearchLocation(null);
    };

    const showEditAttributes = (aoi, mode = defaultAOIWizzardOptions.mode, step = defaultAOIWizzardOptions.activeStep) => {
        setOpenAOIWizzardModal(true);
        setSelectedAOI(aoi);
        setAOIWizzardOptions({mode: mode, activeStep: step});
    };

    const viewAOIDetails = (aoi, step = defaultAOIWizzardOptions.activeStep) => {
        setSelectedAOI(aoi);
        setAOIWizzardOptions({mode: 'view', activeStep: step});
        setOpenAOIWizzardModal(true);
    };

    const clearFilters = (zoomToFit = false) => {
        setZoomToFit(zoomToFit);
        setFilter({filterText: "", subscribed: "all", selectedGroup: null});
    };

    const setFilterValue = (field, value) => {
        setZoomToFit(true);
        setSearchLocation(null);
        if(field == "filterText") {
            setFilter({...filter, filterText: value});
        } else if(field == "subscribed") {
            setFilter({...filter, subscribed: value});
        }
        else if(field == "selectedGroup") {
            setFilter({...filter, selectedGroup: value});
        }
    };

    const compareAoi = (a, b, field) => {
        if(field == "lastModifiedDate") {
            var diff = Date.parse(new Date(b.timestamp)) - Date.parse(new Date(a.timestamp)); 
            if(diff != 0)
                return diff;
        }
        return a.tag.toUpperCase() < b.tag.toUpperCase() ? -1 : (a.tag.toUpperCase() > b.tag.toUpperCase() ? 1 : 0); 
    };

    const setCurrentLocationCoords = () => {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function(position) {
                    setCurrentLocation({lat: position.coords.latitude, lng: position.coords.longitude});
                },
                function error(e){
                    toast.error("Please enable your device GPS.");
                    console.error(e?.message);
                },
                {enableHighAccuracy: true}
            );
        }    
    };

    const isServiceAvailable = (aoi) => {
        if (!serviceArea) {
            return true;
        }
        const polygonPath = MapUtils.getPolygonPaths(aoi.geometry);
        if (!!polygonPath && polygonPath.length > 0) {
            const polygon = new google.maps.Polygon({paths: polygonPath});
            let pointsInside = 0;
            let pointsOutside = 0;
            polygon.getPath().getArray().map(function (x) { 
                (google.maps.geometry.poly.containsLocation(x, serviceArea)) ? pointsInside++ : pointsOutside++;
            });
            return (pointsOutside > 0) ? false : true;
        }
        return false;
    };

    const isAreaTooLargeToService = (aoi, usecaseName) => {
        const area = MapUtils.calculateGeometryArea(aoi.geometry);
        const usecase = usecases.find((u) => u.name == usecaseName);
        let areaTooLarge = false;
        if(usecase != null && usecase.configuration != null && usecase.configuration.max_aoi_area != null) {
            if(area > usecase.configuration.max_aoi_area)
                areaTooLarge = true;
        }
        return areaTooLarge;
    };

    const aoiContextValues = {
        domainConfig,
        showAlert,
        view, setView,
        searchLocation, setSearchLocation, 
        isMapLoaded: isLoaded, 
        loadError, 
        openAOIWizzardModal: setOpenAOIWizzardModal,
        showAOIPopover, setShowAOIPopover,
        emptyAOI,
        aoi, setAOI, aoiRef, mapRef,
        aoiMode, setAOIMode,
        saveAOI,
        shapes, setShapes, deleteShapes,
        userAOIs, setUserAOIs, setSelectedAOIId,
        handleClearLocationSearch,
        selectedAOI, setSelectedAOI,
        savePolygonPaths, setSavePolygonPaths,
        discardAOICreate, discardAOIEdit,
        showEditAttributes,
        center,
        viewAOIDetails,
        filter, clearFilters,
        zoomToFit, setZoomToFit,
        allAOIs, groups,
        currentLocation, setCurrentLocationCoords,
        showCurrentLocation, setShowCurrentLocation,
        selectedGroupId, clearSelectedGroup,
        serviceArea, serviceAreaGeometry,
        isServiceAvailable, isAreaTooLargeToService,
        forCreateAOI, setForCreateAOI,
        openSubscriptionSuccessModal: setOpenSubscriptionSuccessModal
    };

    if(domainConfig.portfolioComponent != null && domainConfig.portfolioComponent == "GMRInnovexDemo")
        return <GMRInnovexDemo></GMRInnovexDemo>;

    return (
        <AOIContext.Provider value={{...aoiContextValues}}>
            {
                isLoaded ? 
                    <>
                        <div className='page-body dashboard'>
                            <AOIHeader filter={filter} setFilter={setFilterValue} siteCache={siteCache} setSiteCache={setSiteCache} sortBy={sortBy} changeSortBy={(field) => setSortBy(field)} changeGroup={changeSelectedGroup} selectedGroupId={selectedGroupId}/>
                            {
                                view == 'map' && <AOIMapView groups={groups} key={counter}/> 
                            }
                            {
                                view == 'group' && <AOIGroupView groups={groups} userGroups={userGroups} selectedGroup={filter.selectedGroup} filter={filter} setFilter={setFilterValue}/> 
                            }
                            {
                                view == 'list' && <AOIGridView filter={filter} setFilter={setFilterValue} />
                            }
                            { openAOIWizzardModal && <AOIWizzardModal options={aoiWizzardOptions} /> }
                            { openSubscriptionSuccessModal && <SubscriptionSuccessModal />}
                            { showTour && <TourModal show={hideTour}/> }
                        </div>
                        {
                            view != 'map' &&
                            <Copyright/>
                        }
                    </>
                : <></>
            }
        </AOIContext.Provider>
    );
}

export default Dashboard;