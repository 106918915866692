import {Modal} from 'react-bootstrap';
import React from 'react';

const ShowJson = (props) => {
    return (
        <>
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog"
                className="member-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>{props.title}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="member-details">
                        <code>
                            <pre>
                                {JSON.stringify(props.json, null, 4)}
                            </pre>
                        </code>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ShowJson;