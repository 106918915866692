import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

import { AOIContext } from '../../../Contexts/AOIContext';
import { UserContext } from '../../../Contexts/UserContext';
import { groupService } from '../../../Services/groupService';

const AOIGroupDelete = (props) => {
    const {user, reloadAOIs} = useContext(UserContext);
    const {allAOIs, clearSelectedGroup} = useContext(AOIContext);
    const [deleteAOIs, setDeleteAOIs] = useState(false);
    const [activeSubscriptions, setActiveSubscriptions] = useState(false);
    const [belongToOtherGroups, setBelongToOtherGroups] = useState(false);

    useEffect(() => {
        if(props.group.member_aoi_ids) {
            props.group.member_aoi_ids.map((aoiId) => {
                var aoi = allAOIs.find(a => a.member_aoi_id == aoiId);
                if(aoi.groups && aoi.groups.length > 1) {
                    setBelongToOtherGroups(true);
                }
                if(aoi.subscribed_usecases && aoi.subscribed_usecases.length >= 1) {
                    setActiveSubscriptions(true);
                }
            });    
        }
    }, []);

    const deleteGroup = () => {
        groupService.deleteGroup(user.mkey, user.region, props.group.ext_id, deleteAOIs).then(() => {
            toast.success("Deleted the group " + props.group.tag);
            props.show(false);
            clearSelectedGroup();
            if(deleteAOIs == true)
                reloadAOIs();
            else
                props.refresh();
        }).catch((e) => {
            console.error(e);
            toast.error(e);
        });
    };

    const onChecboxClick = (event) => {
        setDeleteAOIs(event.target.checked);
    };

    const getMessage = () => {
        var str = "";
        if(activeSubscriptions)
            str += "have active subscriptions";
        if(activeSubscriptions && belongToOtherGroups)
            str += " and ";
        if(belongToOtherGroups)
            str += "belong to some other group";
        return str;
    }

    return (
        <>
            <Modal
                show={() => props.show(true)}
                onHide={() => props.show(false)}
                backdrop='static'
                keyboard={false}
                dialogClassName=''
                className='aoi-group-delete-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to Delete the Group <span>{props.group.tag}</span> ?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label htmlFor={"deleteAOIs"} className="custom-check">
                        {
                            activeSubscriptions || belongToOtherGroups
                            ? <input type="checkbox" name={"deleteAOIs"} id={"deleteAOIs"} disabled="disabled" />
                            : <input type="checkbox" name={"deleteAOIs"} id={"deleteAOIs"} checked={deleteAOIs} onChange={(e) => onChecboxClick(e)}/>
                        }
                        <span>Delete the AOIs in this group</span>
                    </label>
                    {
                        (activeSubscriptions || belongToOtherGroups) && 
                        <div className='alert alert-danger'>
                            <div>
                                The AOIs in this group cannot be deleted, since one or more AOIs {getMessage()}.
                            </div>
                            <br/>
                            <div>
                                However, you can delete the group without deleting the AOIs. 
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" aria-label="Close" onClick={() => props.show(false)}>CANCEL</button> &nbsp; 
                    <button type="button" className="btn btn-danger" onClick={() => deleteGroup()}>DELETE</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AOIGroupDelete;