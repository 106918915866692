import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import React, { useCallback } from 'react';
import { useContext } from 'react';
import { UserContext } from '../Contexts/UserContext';
import Uppy from '@uppy/core';
import { DragDrop, ProgressBar } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload'
import * as uuid from "uuid";
import DeleteIcon from '../Assets/img/icon-delete.svg';
import { getFileBlobUrl, getFileTypeIcon, openFile } from './Utils';
import { apiService } from '../Services/apiService';

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const defaultOptions = {
    id: 'file-upload-dd',
    locale: {
        strings: {
            dropHereOr: 'Drag & Drop to Upload File OR %{browse}',
            browse: 'Choose files'
        }
    },
    restrictions: { 
        maxNumberOfFiles: 2,
        allowedFileTypes: ['.pdf', '.jpg', '.jpeg', '.png','.tiff', '.tif'] 
    },
    autoProceed: true,
    debug: false
}

const FileUpload = (props) => {
    const {user, showAlert, setShowImageGalleryModal, galleryOptions, setGalleryOptions} = useContext(UserContext);
    const dataUrl = apiService.getDataUrl(user.region);

    const handleDeleteFile = (file) => {
        props.uploadedFiles.meta = props.uploadedFiles.meta.filter(f=>f.filename!=file.filename);
        props.uploadedFiles.data = props.uploadedFiles.data.filter(f=>f.filename!=file.filename);
        delete props.uploadedFiles.files[file.filename];
        props.setUploadedFiles({...props.uploadedFiles});       
    };

    const uppy = new Uppy(
        {
            ...defaultOptions,
            ...props.options,
            onBeforeFileAdded: (currentFile, files) => {
                if (props.uploadedFiles.data.find(f=>f.filename == currentFile.name)){
                    showAlert(currentFile.name + ' already uploaded !', 'fail');
                    return false;
                }
                return true;
            }
        }
    );
    
    let uppyUploadedFiles = {};
    const headers = {'Authorization': `Bearer ${authToken}`}
    uppy.use(XHRUpload, {
        endpoint: `${dataUrl}/${user.mkey}/temp`,
    });
    uppy
    .on("upload",()=> {
        let addedFiles = uppy.getFiles();
        addedFiles.forEach((file)=>{
            let temp_Id = uuid.v4();
            uppyUploadedFiles[file.meta.name] = temp_Id;
            let modHeaders = {...headers, 'Content-Type': file.type}
            uppy.setFileState(file.id, {
                xhrUpload: {
                    ...file.xhrUpload,
                    formData: false,
                    method: "PUT",
                    headers: modHeaders,                    
                    endpoint: `${dataUrl}/${user.mkey}/temp/${temp_Id}`
                },
                url: `${dataUrl}/${user.mkey}/temp/${temp_Id}`,
                tempId: temp_Id,
                type: file.type
            })
        });  
    })
    .on('upload-success', (file, response)=>{
        let updatedFiles = {data: [], meta: [], files: {}}; 
        updatedFiles.data = [...props.uploadedFiles.data, {filename:file.name, filepath: file.url, filetype: file.type.includes('/') ? file.type.split('/')[1]: file.type, size: file.size, relativePath: "temp/" + file.tempId}];
        updatedFiles.meta = [...props.uploadedFiles.meta, {filename: file.name}];
        updatedFiles.files = {...props.uploadedFiles.files};
        updatedFiles.files[file.name] = file.tempId;
        props.setUploadedFiles({...updatedFiles});
    });

    const showPropertyImagesGallery = (selectedFile) => {
        setShowImageGalleryModal(true);
        const galleryImages = props.uploadedFiles.data.map(file => { return { filepath: file.filepath, filename: file.filename} } );
        setGalleryOptions({
            ...galleryOptions,
            modalTitle: "Property Images",
            images: galleryImages,
            selectedFilename: selectedFile.filename
        });
    }

    const onUploadedFileClick = (file) => {
        if (props.isPropertyImages)
            showPropertyImagesGallery(file);
        else
            openFile(file.filepath);
    }

    const thumbnailRef = useCallback(async ref => {
        if (ref !== null) {
            const filepath = ref.attributes['attr-filepath'].value;
            if (props.isPropertyImages && !!filepath) {    
                const blobUrl = await getFileBlobUrl(filepath);
                if (!!blobUrl)
                    ref.src = blobUrl;
            }
        }
    }, []);
    
    return (
        <div className='row'>
            <div className='col-12 col-md-4'>
                <div>
                    <DragDrop uppy={uppy} />
                </div>
                <p className="notes">Accepted Formats: 
                    {
                        props?.options?.restrictions?.allowedFileTypes
                            ? props.options.restrictions.allowedFileTypes.join(', ')
                            : defaultOptions.restrictions.allowedFileTypes.join(', ')
                    }
                </p>
            </div>
            <div className='col-12 col-md-8'>
                <div className="upload-status">
                    <div className='uploaded-files-list'>         
                        {
                            props.uploadedFiles.data?.map(file=>(
                                <div className="file-row" key={file.filename}>
                                    <div className="file-type" onClick={()=> onUploadedFileClick(file)}>
                                        <img src={getFileTypeIcon(file.filetype ?? 'doc')} alt={file.filename} attr-filepath={file.filepath} ref={thumbnailRef}/>
                                    </div>
                                    <div className="file-name">
                                        <a className="file-delete">
                                            <img src={DeleteIcon} alt="" onClick={()=>handleDeleteFile(file)} />
                                        </a>
                                        <p><a onClick={()=> onUploadedFileClick(file)}>{file.filename}</a> <span>{file.size && `(${formatBytes(file.size)})`}</span></p>                                        
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='fileupload-progress-bar'>
                        <ProgressBar uppy={uppy} /> 
                    </div>
                </div>
            </div>                    
        </div>
    )
}

export default FileUpload;