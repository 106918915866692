import {Modal} from 'react-bootstrap';
import React, {useContext, useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import { billingService } from '../../Services/billingService';
import { UserContext } from '../../Contexts/UserContext';
import Moment from 'react-moment';

const BillingRefund = (props) => {
    const {user} = useContext(UserContext);
    const [tokenData, setTokenData] = useState(
        {
            amount: props.transaction.details.amount / 100,
            tokens: props.transaction.qty,
            description: ""
        }
    );
    const [refundTransactions, setRefundTransactions] = useState([]);

    useEffect(() => {
        let amount = props.transaction.details.amount;
        let tokens = props.transaction.qty;
        if(props.transaction?.details?.payment_intent != null) 
        {
            const rTransactions = props.allTransactions.filter(t => t?.details?.payment_intent == props.transaction.details.payment_intent && t.type == "D");
            setRefundTransactions(rTransactions);
            rTransactions.map((t) => {
                amount -= t.details.amount;
                tokens -= t.qty; 
            });
            setTokenData({
                ...tokenData,
                amount: amount / 100,
                tokens: tokens
            });
        }
    }, [props.transaction]);

    const handleInputChange = (event) => {
        const target = event.target;

        setTokenData({
          ...tokenData,
          [target.name]: target.value
        });
    };

    const submit = () => {
        const amount = Number(tokenData.amount);
        if(isNaN(amount)) {
            toast.error("Please enter a valid value for the amount");
            return;
        }
        const tokens = parseInt(tokenData.tokens);
        if(tokens != tokenData.tokens) {
            toast.error("Please enter a valid integer for the amount");
            return;
        }
        var postData = {
            "refund-amount": parseInt(amount * 100),
            "token-amount": tokens,
            "description": tokenData.description
        };
        billingService.refund(props.region, props.mkey, props.transaction.details.payment_intent, user.authId, postData).then((data) => {
            toast.success("Refunded the amount successfully");
            props.refresh();
            props.setShowModal(false);
        }).catch((error) => {
            if(error.includes("has already been refunded."))
                toast.error("Unable to refund the amount. Full amount of the original purchase has already been refunded.");
            else 
                toast.error("Unable to refund the amount " + error);
            console.error(error);
        });
    };

    const renderRefundedTransactions = () => {
        if(refundTransactions.length == 0)
            return <></>;
        return <div>
            <div><b>Previous Refunds</b></div>
            <table className="table table-style">
                <thead>
                    <tr>
                        <th scope="col">Amount</th>
                        <th scope="col">Credits</th>
                        <th scope="col">Date</th>
                        <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        refundTransactions.map((t) => <tr>
                            <td>{t.details.amount / 100}</td>
                            <td>{t.qty}</td>
                            <td><Moment date={t.timestamp} format={"YYYY-MM-DD"} /></td>
                            <td>{t.details.description}</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div>;
    }

    return (
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName=""
                className="member-create-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Refund</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="amount">Amount <span className="required">*</span></label>
                                <input type="text" className="form-control" id="amount" name="amount" value={tokenData.amount} onChange={(e) => handleInputChange(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="tokens">Credits <span className="required">*</span></label>
                                <input type="text" className="form-control" id="tokens" name="tokens" value={tokenData.tokens} onChange={(e) => handleInputChange(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="description">Description</label>
                                <input type="text" className="form-control" id="description" name="description" value={tokenData.description} onChange={(e) => handleInputChange(e)}/>
                            </div>
                        </div>
                    </div>
                    <div>
                    {
                        renderRefundedTransactions()
                    }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.setShowModal(false)}>CANCEL</button>
                    <button type="button" className="btn btn-danger" onClick={() => submit()}>SUBMIT</button>
                </Modal.Footer>
            </Modal>
    );
}

export default BillingRefund;