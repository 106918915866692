import {fetchWrapper} from "../Helpers/fetchWrapper";
import { apiService } from './apiService';

const getPricing = (region) => {
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/billing/stripe-pricing-config`);
};

const stripeCheckoutSession = (region, mkey, data) => {
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/billing/${mkey}/stripe-checkout-session`, data);
};

const stripePaymentStatus = (region, mkey, paymentId) => {
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/billing/${mkey}/payment-session/${paymentId}`);
};

const getTokenTransactions = (region, mkey) => {
    return fetchWrapper.get(`${apiService.getApiBaseUrl(region)}/billing/${mkey}/token-transactions`);
};

const refund = (region, mkey, payment_intent, authId, data) => {
    return fetchWrapper.post(`${apiService.getApiBaseUrl(region)}/billing/${mkey}/refund/${payment_intent}`, data, {"auth-id": authId});
};

export const billingService = {
    getPricing,
    getTokenTransactions,
    stripeCheckoutSession,
    stripePaymentStatus,
    refund
};
