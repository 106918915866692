import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = (props) => {

    return (
        <>
            <div>

                <section class="main-banner small">
                    <div class="container">
                        <div class="banner-content">
                            <h2 data-aos="fade-right">Terms and Conditions</h2>
                        </div>
                    </div>
                </section>
                <div class="privacy-terms">
                    <div class="container">
                        <h4>Acceptance Of Terms</h4>
                        <div>By subscribing to the Services provided by Data Deli, you, the user, agree that you are above the age
                            of 18 years and consent to the Terms of Service outlined below. These terms may be updated by DataDeli,
                            Inc. and its affiliated entities, referred to collectively as 'DataDeli' or 'We' or 'Us' or 'Our,' from
                            time to time. Your continued access or use of the Site and any of Data Deli’s Services implies your
                            acceptance of any modifications. You must follow the most current version of the Terms, rules, and
                            guidelines posted on the Site when using it.</div>
                        <h4>General Terms</h4>
                        <div>DataDeli operates through its website, found at <a
                            href="https://www.datadeli.com">https://www.datadeli.com</a> (referred to as the “Site”), which
                            includes various content types (text, images, audio, code, etc.) collectively known as “Content,” and
                            all services and features provided. The Site and any additional features, tools, materials, or services
                            offered are collectively termed the “Service.”</div>
                        <div><b>Prohibited Content:</b> Posting inappropriate or harmful content (e.g., violent, nude,
                            discriminatory) is strictly prohibited on the Service.</div>
                        <div><b>Account Responsibility:</b> You're responsible for all activities on your account and cannot
                            transfer or assign them without DataDeli's written permission. DataDeli isn't responsible for posted
                            Content, and you use the Service at your own risk.</div>
                        <div><b>Legal Compliance:</b> As a user, you agree to obey all laws, including copyrights.You agree to not
                            alter the Service or imply an association with DataDeli.</div>
                        <div><b>Interference:</b> Disrupting the Service or its networks with harmful code is prohibited. You agree
                            to not interfere with how DataDeli pages are displayed.</div>
                        <div><b>Access Blocking:</b> DataDeli can block your Service access for violation of these Terms or any
                            applicable law.</div>
                        <div><b>Service Modification:</b> DataDeli can modify or terminate the Service, or your access, without
                            notice or liability.</div>
                        <div><b>Account Deactivation:</b> You can deactivate your account, but DataDeli may retain data for legal
                            compliance.</div>
                        <div><b>Termination Consequences:</b> Upon Service termination, your rights under these Terms end. DataDeli
                            can deny access to anyone and remove content violating the Terms.</div>
                        <div><b>User Interactions:</b> Interactions with other Users are your responsibility, and DataDeli is not
                            liable for their behavior.</div>
                        <div><b>Data Charges:</b> You're responsible for data charges. Automated content access is prohibited,
                            except with DataDeli's consent.</div>
                        <div><b>Communication:</b> DataDeli may use your registered email for communication. If we can't reach you
                            and have security concerns, your account may be temporarily on hold. We may also collect registration
                            information like IP Address and country.</div>
                        <h4>Content</h4>
                        <div><b>DataDeli Content</b></div>
                        <div>DataDeli has sole discretion over data and services. The Service has DataDeli Content, protected by
                            various legal rights. You can't alter or exploit DataDeli Content or its trademarks without written
                            permission. DataDeli's name, logo, and related marks can't be used without written consent from
                            DataDeli. Page headers, graphics, and scripts are also protected.</div>
                        <div><b>User Content</b></div>
                        <div>You confirm you have rights to the User Content you post on the Service and that it doesn't violate
                            third-party rights. You also agree to cover any financial obligations related to your User Content and
                            assert your legal capacity to accept these Terms. User Content includes various media formats.</div>
                        <div>You are responsible for your User Content on DataDeli and affirm it complies with regulations. DataDeli
                            doesn't claim ownership but gets a license to use it on the site. DataDeli may remove content without
                            notice but may store it for legal reasons. The Service isn't a backup for User Content.</div>
                        <div>DataDeli isn't liable for Service changes or Content loss. You acknowledge internet security risks and
                            that DataDeli doesn't endorse or prescreen Content.</div>
                        <h4>License</h4>
                        <div>DataDeli grants Licensee a limited, revocable license for authorized use to access Licensed Materials
                            per this Agreement and Service/s. Licensee must not misuse or alter materials and assume responsibility
                            for its users. DataDeli retains all rights to Licensed Materials. Occasionally, Evaluation Data is
                            offered for internal testing, with a limited, non-transferable license. DataDeli retains intellectual
                            property rights.</div>
                        <div>Your payment for services will be processed as applicable, and DataDeli can change prices at any time.
                            Users will be notified incase of any impact to their current subscription amount. When purchasing
                            Content, you enter into a licensing agreement and don't own the content. You accept the content "as is"
                            with no warranties. License conditions apply to User Content and Third-Party Content unless stated
                            otherwise.</div>
                        <h4>Third-party Content and Licenses</h4>
                        <div>DataDeli has no responsibility for or guarantees regarding Third-Party Content, which is subject to
                            separate terms and conditions set by third parties. Third-Party Content may include various elements
                            like base maps, satellite images, and weather data, and its availability depends on factors beyond
                            DataDeli's control.</div>
                        <h4>Payment for DataDeli Services</h4>
                        <div>Most DataDeli services require payment and may auto-renew subscriptions. You can cancel services at
                            your discretion, keeping data until the subscription ends. No refunds for unused features or services.
                        </div>
                        <h4>Replacements and Refunds</h4>
                        <div>DataDeli shall provide a pro-rata refund of any pre-paid but unused amount. We are not liable to refund
                            any amount that is already used or bonus amount that you receive as part of the payment scheme.</div>
                        <h4>Reporting Copyright and Other IP Violations</h4>
                        <div>If you find your content posted by someone else, inform DataDeli. DataDeli removes infringing content
                            but doesn't assume responsibility for third-party postings.</div>
                        <h4>Confidential Information </h4>
                        <div>"Confidential Information" means information marked as "Confidential" disclosed by one party to another
                            in any form, including trade secrets, business data, personnel details, and contracts. It doesn't
                            include information that's publicly known, known to the receiving party before disclosure, from a third
                            party not bound by confidentiality, or independently created without breaching this Agreement. You must
                            keep it confidential, not share it with others, and only use it as necessary under these Terms. These
                            obligations last during the subscription of Services and for an extra five (5) years. Note that Content
                            follows separate license terms not subject to these expiration or termination provisions.</div>
                        <h4>Indemnity</h4>
                        <div>You and any third party using your account on the Service agree to defend, indemnify, and hold DataDeli
                            harmless from claims, liabilities, damages, losses, and expenses, including legal fees. This includes
                            matters related to:</div>
                        <div>
                            <ul>
                                <li>Your User Content, Service use, or access.</li>
                                <li>Alleged violations of these Terms.</li>
                                <li>Infringement of third-party rights, like intellectual property, privacy, or confidentiality.
                                </li>
                                <li>Violations of laws or orders from authorities.</li>
                                <li>Any misrepresentations you make.</li>
                                <li>You will cooperate with DataDeli in defense, and DataDeli can take control of defense for
                                    indemnified claims. You will also indemnify DataDeli, its officers, directors, affiliates,
                                    partners, and employees (the "DataDeli Indemnitees") from costs arising due to:</li>
                                <li>Your use of Licensed Materials against this Agreement.</li>
                                <li>Violation of laws or regulations.</li>
                                <li>Third-party copyright infringement claims due to Content use, except claims DataDeli should
                                    indemnify.</li>
                            </ul>
                        </div>
                        <h4>Disclaimer of Warranties</h4>
                        <div>The Service, including DataDeli Content, is provided "as is" and "as available." Certain features may
                            not be available in your region, and continuous access is not guaranteed.</div>
                        <div>To the fullest extent permitted by law, DataDeli and its employees, managers, officers, or agents make
                            no express or implied warranties, including but not limited to:</div>
                        <div>
                            <ul>
                                <li>The Service</li>
                                <li>Security</li>
                                <li>DataDeli Content</li>
                                <li>User Content</li>
                                <li>Third-Party Content</li>
                            </ul>
                        </div>
                        <div>DataDeli disclaims all warranties, including merchantability, fitness for a particular purpose,
                            non-infringement, and freedom from computer viruses. DataDeli does not guarantee error-free,
                            uninterrupted service or the accuracy, completeness, or usefulness of information.</div>
                        <div>Your use of the Service is at your own risk, and DataDeli does not warrant its legality in any specific
                            jurisdiction. DataDeli disclaims liability for content and any related losses, damages, injuries, or
                            claims.</div>
                        <div>You confirm your compliance with local laws when using the Service. Some jurisdictions may not allow
                            such disclaimers, so they may not apply to you.</div>
                        <h4>Liability Limitation</h4>
                        <div>DataDeli is not liable for any losses or damages, including direct, indirect, or consequential damages,
                            related to Content, Service/s, Security, your actions, or errors. This includes lost profits, data, or
                            computer malfunctions, even if foreseeable or advised.</div>
                        <div>DataDeli is not liable for personal injury or damages, and you agree that such damages do not warrant
                            an injunction. You have no right to seek an injunction against DataDeli's websites, services, or
                            content.</div>
                        <div>DataDeli is not responsible for third-party actions, content, or data, and you release us from any
                            claims against such third parties.</div>
                        <h4>Compliance with Laws</h4>
                        <div>This clause outlines the Licensee's obligations for compliance with applicable laws, including
                            anti-corruption laws, economic sanctions, and export controls (collectively referred to as "Trade
                            Control Laws").</div>
                        <div>Licensee must ensure that:</div>
                        <div>
                            <ul>
                                <li>Content is not used in transactions with Sanctioned Jurisdictions or Restricted Parties unless
                                    authorized under Trade Control Laws.</li>
                                <li>Content is not exported or used in violation of Trade Control Laws.</li>
                                <li>Content is not used for restricted end-uses, such as nuclear or biological weapons, without
                                    proper authorization under Trade Control Laws.</li>
                            </ul>
                        </div>
                        <div>"Sanctioned Jurisdiction" refers to locations under U.S. economic sanctions, and "Restricted Party"
                            means individuals or entities in the U.S. Government restricted lists.</div>
                        <div>DataDeli may suspend or terminate the access if there are suspicions of intentional harm to civilians
                            or indiscriminate weapon use.</div>
                        <h4>Limitations on Operations </h4>
                        <div>At times, DataDeli may need to provide access to Licensed Materials for regulatory compliance. DataDeli
                            might also have to limit its operations in certain areas temporarily. These limitations in operations
                            should not be seen as a breach of this Agreement.</div>
                        <h4>Claims</h4>
                        <div>You acknowledge that any claim arising from or connected to your relationship with DataDeli must be
                            submitted within one (1) year from the date on which the cause of the claim occurred; otherwise, your
                            claim will be permanently ineligible for consideration.</div>
                        <h4>Governing Law</h4>
                        <div>This Agreement shall be governed by the laws of the State of Delaware, without regard to its conflicts
                            of law provisions.</div>
                        <h4>Territorial Restrictions</h4>
                        <div>The information on the Service is not for distribution or use where it violates the law. We can limit
                            access to the Service at our discretion and control content quantities.</div>
                        <div>Your comments are welcome, and you can reach out to us at contactus@datadeli.com.</div>
                        <div>—------------</div>
                        <div>Changes and notifications to the best possible accuracy. DD can't be held accountable for 100%
                            accuracy.</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TermsOfService;