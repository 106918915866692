import {Modal} from 'react-bootstrap';
import React, {useState} from 'react';
import { adminService } from '../../Services/adminService';
import toast from 'react-hot-toast';

const LanduseEdit = (props) => {
    const initialValues = {
        name: props.landuse?.name,
        description: props.landuse?.description,
        attributeSchema: JSON.stringify(props.landuse?.attribute_schema, null, 4)
    };
    const [values, setValues] = useState(initialValues);

    const handleInputChange = (event) => {
        if(props.landuse?.name != null && event.target.name == "name")
            return;

        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const save = () => {
        if(!validateFields())
            return;

        const landuse = {description: values.description, attribute_schema: JSON.parse(values.attributeSchema)};
        if(props.landuse?.name == null)
            landuse.name = values.name;
        addUpdate(values.name, landuse)
        .then(() => {
            props.refresh();
            props.setShowModal(false);
            toast.success("Landuse has been successfully added/updated");
        }).catch((e) => {
            console.log(e);
            toast.error("Landuse could not be added/updated. Error: " + e);
        });
    };

    const addUpdate = (name, landuse) => {
        if(props.landuse?.name != null)
            return adminService.updateLanduse(name, landuse);
        return adminService.addLanduse(landuse);
    };

    const validateFields = () => {
        if(values.name.trim() == "")
        {
            toast.error("Please enter Name");
            return false;
        }
        if(values.description.trim() == "") {
            toast.error("Please enter Description");
            return false;
        }
        try {
            JSON.parse(values.attributeSchema);
        } catch {
            toast.error("Invalid JSON value for Attribute Schema");
            return false;
        }
        return true;
    }

    return (
        <>
            <Modal
                show={() => props.setShowModal(true)}
                onHide={() => props.setShowModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog"
                className="modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Add New Landuse</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Name">Name <span className="required">*</span></label>
                                <input type="text" className="form-control" id="name" name="name" value={values.name} onChange={(e) => handleInputChange(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                            <label className="form-label" htmlFor="Description">Description <span className="required">*</span></label>
                                <input type="text" className="form-control" id="Description" name="description" value={values.description} onChange={(e) => handleInputChange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="AttributeSchema">Attribute Schema <span className="required">*</span></label>
                                <textarea id="AttributeSchema" name="attributeSchema" cols="" rows="4" className="form-control" value={values.attributeSchema} onChange={(e) => handleInputChange(e)}></textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline" onClick={() => props.setShowModal(false)}>CLOSE</button>
                    <button type="button" className="btn btn-primary" onClick={() => save()}>SUBMIT</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default LanduseEdit;