import {Modal} from 'react-bootstrap';
import React, {useContext} from 'react';
import { UserContext } from '../Contexts/UserContext';
import LoginAuth from './LoginAuth';

const LoginModal = () => {
    const {openLoginModal} = useContext(UserContext);

    const complete = () => {
        openLoginModal(false);
    };

    return (
        <>
            <Modal
                show={() => openLoginModal(true)}
                onHide={() => openLoginModal(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName="signin-modal-dialog"
                className="signin-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title><h5>Log in to SpaceAeye.</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <LoginAuth complete={complete} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default LoginModal;