import { useState } from 'react';

import FileUpload from '../FileUpload';
import IconInputAdd from '../../Assets/img/icon-input-add.svg';
import IconInputRemove from '../../Assets/img/icon-input-remove.svg';

const AddEventFileUpload = (props) => {
    const [s3urls, setS3urls] = useState(['']);

    const handleChange = (index, event) => {
      const newValues = [...s3urls];
      newValues[index] = event.target.value;
      setS3urls(newValues);
    };
  
    const handleAdd = () => {
      setS3urls([...s3urls, '']);
    };
  
    const handleRemove = (index) => {
      const newValues = [...s3urls];
      newValues.splice(index, 1);
      setS3urls(newValues);
    };

    const [uploadedImages, setUploadedImages] = useState({
        data: [],
        meta: [],
        files: {}
    });

    const [uploadedMetadata, setuploadedMetadata] = useState({
        data: [],
        meta: [],
        files: {}
    });

    const imageOptions = {
        id: 'image-upload',
        restrictions: {
            maxNumberOfFiles: 10,
            allowedFileTypes: ['.jpg', '.jpeg', '.png', '.tiff', '.geotiff', '.tif']
        }
    };

    const metaDataOptions = {
        id: 'metadata-upload',
        restrictions: {
            maxNumberOfFiles: 1,
            allowedFileTypes: ['.json']
        }
    };

    return <>
        <div className="images-section">
            <h3>Images</h3>
            <div className='content'>
                <FileUpload options={imageOptions}
                    uploadedFiles={uploadedImages}
                    setUploadedFiles={setUploadedImages}
                />
                <div className="document-input-container">
                    {
                        s3urls.map((value, index) => (
                            <div className="input-row" key={index}>
                                <input type="text" value={value} className="form-control" placeholder="Please enter S3 URL" onChange={(event) => handleChange(index, event)} />
                                <img src={IconInputRemove} title="Delete Row" onClick={() => handleRemove(index)}/>
                            </div>
                        ))
                    }
                    <div className="input-row">
                        <img src={IconInputAdd} title="Add Row" onClick={handleAdd} />
                    </div>
                </div>
            </div>
        </div>

        <div className="metadata-section">
            <h3>Metadata</h3>
            <div className='content'>
                <FileUpload options={metaDataOptions}
                    uploadedFiles={uploadedMetadata}
                    setUploadedFiles={setuploadedMetadata}
                />
            </div>
        </div>
        <div className='modal-footer'>
            <button type="button" className="btn btn-outline" onClick={() => props.cancel()}>CANCEL</button>
            <button type="button" className="btn btn-primary" onClick={() => props.submit(uploadedImages, uploadedMetadata, s3urls)}>UPLOAD</button>
        </div>
    </>;
};

export default AddEventFileUpload;