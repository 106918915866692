import LeakageMap from "../Components/Timeline/LeakageMap";
import { useEffect, useState } from "react";
import { fetchWrapper } from "../Helpers/fetchWrapper";

const TimelineLeakage = () => {
    const [leftMap, setLeftMap] = useState(null);
    const [rightMap, setRightMap] = useState(null);
    const [data, setData] = useState({});
    const [isGasLeakage, setIsGasLeakage] = useState(true);

    useEffect(() => {
        fetchWrapper.get("/geometry/leakage.json").then((res) => {
            setData(res);
        });
    }, []);

    const onLeftMapLoad = (map) => {
        setLeftMap(map);
    };

    const onRightMapLoad = (map) => {
        setRightMap(map);
    };

    useEffect(() => {
        if (rightMap != null && leftMap != null) {
            syncMaps(leftMap, rightMap);
        }
    }, [rightMap, leftMap]);

    const syncMaps = (left, right) => {
        let center = null, zoom = null;
        var maps = [left, right];

        const update = (changedMap) => {
            maps.forEach((m) => {
                if (m === changedMap) {
                    return;
                }
                m.setCenter(center);
                m.setZoom(zoom);
            });
        }

        maps.forEach((m) => {
            m.addListener("bounds_changed", () => {
                const changedCenter = m.getCenter();
                const changedZoom = m.getZoom();

                if (changedCenter !== center || changedZoom !== zoom) {
                    center = changedCenter;
                    zoom = changedZoom;
                    update(m);
                }
            });
        });
    }

    return (
        <div className="page-body timeline-view">
            <div className='timeline-map-container'>
                <div className='timeline-map'>
                    <div id="map-split-panel" className="split comparison">
                        <div className="dates-container dates-container-right">
                            <div class="before-date date-ddl">
                                <div className="selected-date">30th April 2024</div>
                            </div>
                        </div>
                        <div className="leakage-toggle">
                            <div class="custom-radio">
                                <input type="radio" id="Gas" name="leakage" value="true" onChange={() => setIsGasLeakage(true)} checked />
                                <label for="Gas">Gas Leakage</label>
                            </div>
                            <div class="custom-radio">
                                <input type="radio" id="Oil" name="leakage" value="false" onChange={() => setIsGasLeakage(true)} disabled />
                                <label for="Oil" className="disabled">Oil Leakage</label>
                            </div>
                        </div>
                        <div className="left">
                            <div id="map-left" className="map-left">
                                <LeakageMap data={data} bounds={data.basebounds} onMapLoad={onLeftMapLoad} showZoom={false} />
                            </div>
                        </div>
                        <div className="vertical-bar"></div>
                        <div className="right">
                            <div id="map-right" className="map-right">
                                <LeakageMap data={data} bounds={data.gasbounds} imageFile={data.gasLeakage} onMapLoad={onRightMapLoad} showZoom={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TimelineLeakage;