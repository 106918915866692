import SubscribeNoPlans from "./SubscribeNoPlans";
import SubscribeTimelineR1 from "./SubscribeTimelineR1";
import SubscribeTimelineV2 from "./SubscribeTimelineV2";

const Subscribe = (props) => {
    if(props.service.name == "AoIRGBImageV2" && props.service.price != null) 
        return <SubscribeTimelineV2 {...props}></SubscribeTimelineV2>;
    
    if(props.service.name == "AoIRGBImageR1") 
        return <SubscribeTimelineR1 {...props}></SubscribeTimelineR1>;

    return <SubscribeNoPlans {...props}></SubscribeNoPlans>;
}

export default Subscribe;