import { useContext, useEffect, useState } from 'react';
import TokensIcon from '../../Assets/img/icon-tokens.png';
import { UserContext } from '../../Contexts/UserContext';
import SubscriptionConfirmation from '../AOIWizzard/SubscriptionConfirmation';

const SubscribeTimelineV2 = (props) => {
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [planType, setPlanType] = useState("");
    const [showPlans, setShowPlans] = useState(false);
    const {user} = useContext(UserContext);
    const [showSubscriptionConfirmation, setShowSubscriptionConfirmation] = useState(false);

    useEffect(() => {
        setIsSubscribed(props.isSubscribed);
        setShowPlans(props.isSubscribed);
        setPlanType(props.isSubscribed || props.isSingleUsecase == false ? (props.subscriptionDetails?.plan_type ?? "") : "");
    }, [props]);

    const subscribeService = (subscribe) => {
        if(!user.canSubscribe)
            return;
        props.subscribe(props.service.name, subscribe, {
            "plan_type": planType
        }, props.displayName);
        setIsSubscribed(subscribe);
        setShowPlans(subscribe);
    };

    const changePlan = (type) => {
        if(!user.canSubscribe)
            return;
        setPlanType(type);
        if (props.isSingleUsecase) {
            setShowSubscriptionConfirmation(true);
        }
        else {
            props.subscribe(props.service.name, true, {
                "plan_type": type
            }, props.displayName);
        }
    };

    const handleSubscriptionConfirmationCancel = () => {
        setPlanType(props.isSubscribed || props.isSingleUsecase == false ? (props.subscriptionDetails?.plan_type ?? "") : "");
        setShowSubscriptionConfirmation(false)
    }

    const RenderPlans = () => {
        if(props.pricingDetails[props.service.name] == null) {
            return <div>Loading ...</div>;
        }
        if(props.pricingDetails[props.service.name]?.basic == -1) {
            return <div>
                        Area too large or service is not available in the region
                    </div>;
        }
        return <div className="subscription-plans">
                    <div className={planType == "basic" ? "plan-box selected" : "plan-box"}>
                        <div className="plan-head">
                            Basic
                        </div>
                        <div className="plan-details">
                            Low Resolution
                        </div>
                        <div className="plan-tokens">
                            <img src={TokensIcon} alt="" /> 
                            $ {props.pricingDetails && props.pricingDetails[props.service.name] && props.pricingDetails[props.service.name].basic} 
                            <div className="month">/month</div>
                        </div>
                        {   
                            planType == "basic" ? 
                            <span className="choose-link">Selected</span> :
                            <a className="choose-link" onClick={() => changePlan("basic")}>Choose</a>
                        }
                    </div>
                    <div className={planType == "standard" ? "plan-box selected" : "plan-box"}>
                        <div className="plan-head">
                            Standard
                        </div>
                        <div className="plan-details">
                            Low Resolution + <br />Medium Resolution
                        </div>
                        <div className="plan-tokens">
                            <img src={TokensIcon} alt="" /> 
                            $ {props.pricingDetails && props.pricingDetails[props.service.name] && props.pricingDetails[props.service.name].standard} 
                            <div className="month">/month</div>
                        </div>
                        {   
                            planType == "standard" ? 
                            <span className="choose-link">Selected</span> :
                            <a className="choose-link" onClick={() => changePlan("standard")}>Choose</a>
                        }
                    </div>
                    <div className={planType == "premium" ? "plan-box selected" : "plan-box"}>
                        <div className="plan-head">
                            Premium
                        </div>
                        <div className="plan-details">
                            Low Resolution +
                            <br />Medium Resolution +
                            <br />High Resolution
                        </div>
                        <div className="plan-tokens">
                            <img src={TokensIcon} alt="" /> 
                            $ {props.pricingDetails && props.pricingDetails[props.service.name] && props.pricingDetails[props.service.name].premium} 
                            <div className="month">/month</div>
                        </div>
                        {   
                            planType == "premium" ? 
                            <span className="choose-link">Selected</span> :
                            <a className="choose-link" onClick={() => changePlan("premium")}>Choose</a>
                        }
                    </div>̦
                </div>;
    };

    const RenderSingleUsecase = () => {
        return <div>
                    {
                        showSubscriptionConfirmation 
                        ? <SubscriptionConfirmation 
                                handleConfirm={()=>subscribeService(true)} 
                                handleCancel={handleSubscriptionConfirmationCancel}
                                pricingDetails={props.pricingDetails}
                                subscriptionRequest={{
                                    serviceName: props.service.name,
                                    isSubscribe: true,
                                    subDetails: {
                                        "plan_type": planType
                                    }
                                }}
                            />
                        : <>
                            <RenderPlans />
                            {
                                isSubscribed && user.canSubscribe &&
                                <button type="button" className="btn btn-primary ml-auto" onClick={() => subscribeService(false)}>
                                    Unsubscribe 
                                </button>
                            }
                          </>
                    }
                </div>;
    };

    if(props.isSingleUsecase)
        return <RenderSingleUsecase />;

    return  <div className="col-12" key={props.service.name}>
                <div className="service-row">
                    <div className='left-content'>
                        <label className="service-label"> {props.displayName}
                        </label>
                        <p>{`${props.displayName} Information`}</p>
                    </div>
                    <div className='right-content'>
                    {
                        props.pricingDetails[props.service.name] == null
                        ? <div>Loading .... </div>
                        : (
                            props.pricingDetails[props.service.name]?.basic == -1 
                            ? <div> Area too large or service is not available in the region </div>
                            : 
                            <div className="subscribe-switch">
                                <input type="radio" className="switch-input" name={props.service.name} value="Subscribe" id={"Subscribe" + props.service.name} 
                                    checked={isSubscribed} onClick={() => setShowPlans(true)} onChange={() => {}} />
                                <label htmlFor={"Subscribe" + props.service.name} className="switch-label switch-label-off">Subscribe</label>
                                <input type="radio" className="switch-input" name={props.service.name} value="Unsubscribe" id={"UnSubscribe" + props.service.name} 
                                    checked={!isSubscribed} onClick={() => subscribeService(false)} onChange={() => {}} />
                                <label htmlFor={"UnSubscribe" + props.service.name} className="switch-label switch-label-on">Unsubscribe</label>
                                <span className="switch-selection"></span>
                            </div>
                        )
                    }
                </div>
                {
                    showPlans && <RenderPlans></RenderPlans>
                }
                </div>
            </div>;
}

export default SubscribeTimelineV2;