import React, { useContext, useEffect, useState } from 'react';
import { billingService } from "../Services/billingService";
import { UserContext } from "../Contexts/UserContext";
import { useNavigate, useParams } from 'react-router';
import SuccessIcon from '../Assets/img/icon-success-tick.svg';

const PaymentSuccess = (props) => {
    let { paymentId } = useParams();
    const [inProgress, setInProgress] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const {user, reloadTokens} = useContext(UserContext);
    const [paymentData, setPaymentData] = useState({});
    const navigate = useNavigate();
    const MAX_RETRIES = 12;

    useEffect(() => {
        getStatus(MAX_RETRIES);
    }, []);

    const getStatus = (retries) => {
        if(paymentId == null)
            return;
        if(retries <= 0)
        {
            setInProgress(false);
            return;
        }
        billingService.stripePaymentStatus(user.originalRegion, user.originalmKey, paymentId).then((data) => {
            reloadTokens();
            setPaymentData(data);
            setIsSuccess(true);
            setInProgress(false);
        }).catch((error) => {
            console.error(error);
            setTimeout(() => getStatus(retries - 1), 5000);
        });
    };

    const PaymentInProgress = () => {
        return <div className="loader-section">
                    <div className="loader"></div>
                    <h2>Processing Payment</h2>
                    <h4>Please do not close. It might take upto 1 minute to process.</h4>
                </div>;
    };

    const PaymentSuccessful = () => {
        return <div className="success-section">
                    <img src={SuccessIcon} />
                    <h2>You've successfully added ${paymentData.qty} to your wallet. </h2>
                    <h4>You now have ${user.tokens.amount} in your wallet.</h4>
                    <button type="button" className="btn btn-primary" onClick={() => navigate("/portfolio")}>Go to Dashboard</button>
                </div>;
    };

    const PaymentPending = () => {
        return <div className="success-section">
                    <h2>We are unable to confirm the Payment transaction.</h2>
                    <h5>If the transaction is successful, we will update the amount in the wallet. Otherwise, you would get your money back. In case of any questions, please contact the Support team.</h5>
                    <button type="button" className="btn btn-primary" onClick={() => navigate("/portfolio")}>Go to Dashboard</button>
                </div>;
    };

    return (
        <div className="page-body payments">
            <div className="container">
                <div className="form-content">
                    <div className="form-head">
                        <h3>Add to Wallet</h3>
                    </div>
                    <div className="payments-section">
                        {
                            inProgress == true && 
                            <PaymentInProgress />
                        }
                        {
                            inProgress == false && isSuccess == true &&
                            <PaymentSuccessful />
                        }
                        {
                            inProgress == false && isSuccess == false &&
                            <PaymentPending />
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}
export default PaymentSuccess;