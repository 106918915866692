import React, {useContext, useEffect, useState} from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { adminService } from '../../Services/adminService';
import DatasourceEdit from './DatasourceEdit';

const Datasource = () => {
    const [datasources, setDatasources] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const {user} = useContext(UserContext);

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        adminService.getDatasources(user.originalRegion).then((data) => {
            setDatasources(data);
        }).catch((e) => {
            toast.error("Unable to retrieve Datasources. Error: " + e);
        });
    };

    const renderRow = (config) => {
        return <tr key={config.name}>
                    <td>{config.name}</td>
                    <td>{config.category}</td>
                    <td>{config.description}</td>
                </tr>;
    }

    return (
        <div className="form-content">
            <div className="form-head">
                <h3>Data Source</h3>
                {
                    user.isSuperAdmin && 
                    <a className="add-link" onClick={() => setShowModal(true)}>+ Add a Data Source</a>
                }
            </div>
            <div className="admin-table-responsive">
                <table className="table table-style">
                    <thead>
                        <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Category</th>
                        <th scope="col">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            datasources && 
                            datasources.map((config) => renderRow(config))
                        }
                    </tbody>
                </table>
            </div>
            {
                showModal && <DatasourceEdit setShowModal={setShowModal} refresh={() => refresh()} />
            }
        </div>
    );
}

export default Datasource;