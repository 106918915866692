import { adminService } from "../Services/adminService";
import { aoiService } from "../Services/aoiService";
import { dbService } from "../Services/dbService";
import { usecaseService } from "../Services/usecaseService";

const getAOIPriceFromAPIForGridView = (db, mkey, region, defaultAoiExtIds, aoiOtherPlans, usecaseName, usecaseVersion, defaultPlanName) => {
  let plans = {};
  if(defaultPlanName == null || defaultPlanName == "")
    plans = {[usecaseName]: [{}]};
  else 
    plans = {[usecaseName]: [{"plan_type": defaultPlanName}]};
  const postData = {
    "item-type": "aoi",
    "plans-by-item": aoiOtherPlans,
    "items-plans": {
      "items": defaultAoiExtIds,
      "plans": plans
    }
  };
  var priceData = {};
  return aoiService.getPricingForServices(mkey, region, postData).then((data) => {
    const itemsPlans = data["items-plans"];
    Object.keys(itemsPlans).map((ext_id) => {
      priceData[ext_id] = itemsPlans[ext_id][usecaseName][0];
      dbService.storeAOIPrice(db, mkey, ext_id, usecaseName, usecaseVersion, defaultPlanName, itemsPlans[ext_id][usecaseName][0]);
    });
    const plansByItem = data["plans-by-item"];
    Object.keys(plansByItem).map((ext_id) => {
      priceData[ext_id] = plansByItem[ext_id][usecaseName][0];
      dbService.storeAOIPrice(db, mkey, ext_id, usecaseName, usecaseVersion, aoiOtherPlans[ext_id][usecaseName][0].plan_type, plansByItem[ext_id][usecaseName][0]);
    });
    return priceData;
  });
};

const getAllAOIPricesFromDBForGridView = (db, mkey, region, defaultAoiExtIds, aoiOtherPlans, usecaseName, usecaseVersion, defaultPlanName, callback) => {
  dbService.getAOIPrice(db, mkey, (dbdata) => {
    var toDeleteDBAOIs = dbdata.filter((dbaoi) => dbaoi.expires < new Date()).map((aoi) => aoi.ext_id);
    dbService.deleteAOIPrice(db, toDeleteDBAOIs);

    let planPrice = {};
    let defaultAois = [];
    let otherAoiData = {};

    defaultAoiExtIds.map((ext_id) => {
      let plan = dbdata.filter((dbaoi) => dbaoi.ext_id == ext_id && 
                                          dbaoi.usecaseName == usecaseName && 
                                          dbaoi.usecaseVersion == usecaseVersion && 
                                          dbaoi.plan == defaultPlanName && 
                                          dbaoi.expires > new Date());
      if(plan != null && plan.length > 0) {
        planPrice[ext_id] = plan[0].tokenPrice;
      } else {
        defaultAois.push(ext_id);
      }
    });

    Object.keys(aoiOtherPlans).map((ext_id) => {
      let plan = dbdata.filter((dbaoi) => dbaoi.ext_id == ext_id && 
                                          dbaoi.usecaseName == usecaseName && 
                                          dbaoi.usecaseVersion == usecaseVersion && 
                                          dbaoi.plan == aoiOtherPlans[ext_id] && 
                                          dbaoi.expires > new Date());
      if(plan != null && plan.length > 0) {
        planPrice[ext_id] = plan[0].tokenPrice;
      } else {
        otherAoiData[ext_id] = {[usecaseName]: [{"plan_type": aoiOtherPlans[ext_id]}]};
      }
    });

    if(defaultAois.length > 0 || Object.keys(otherAoiData).length > 0) {
      getAOIPriceFromAPIForGridView(db, mkey, region, defaultAois, otherAoiData, usecaseName, usecaseVersion, defaultPlanName).then((data) => {
        planPrice = {...planPrice, ...data};
        callback(planPrice);
      });  
    } else {
      callback(planPrice);
    }
  });
};

const getAoiPricingForGridView = (db, mkey, region, aois, allUsecases, availableUsecases, callback) => {
  const usecaseName = allUsecases.filter(u => !!u.isEnabled)[0].name;
  const defaultPlanName = usecaseService.getDefaultPlanName(usecaseName);

  let defaultAois = [];
  let aoiOtherPlans = {};
  aois.map((aoi) => {
    let subscription = aoi.subscribed_usecases?.find(s => s.usecase?.name == usecaseName && s.subscription_details != null && s.subscription_details.plan_type != null);
    if(subscription != null && subscription.subscription_details.plan_type != defaultPlanName) {
      aoiOtherPlans[aoi.ext_id] = subscription.subscription_details.plan_type;
    } else {
      defaultAois.push(aoi.ext_id);
    }
  });

  adminService.getUsecases(region).then((data) => {
    const usecaseVersion = data.find((u) => u.name == usecaseName)?.price?.version;
    getAllAOIPricesFromDBForGridView(db, mkey, region, defaultAois, aoiOtherPlans, usecaseName, usecaseVersion, defaultPlanName, (data) => {
      callback(data);
    });  
  }).catch((e) => {
      toast.error("Unable to retrieve the usecases. Error: " + e);
  });
};

const getAOIPriceFromAPI = (db, mkey, region, aoiData, usecaseNameVersion) => {
  const postData = {
    "item-type": "aoi",
    "plans-by-item": aoiData,
    "items-plans": {}
  };
  var priceData = {};
  return aoiService.getPricingForServices(mkey, region, postData).then((data) => {
    Object.keys(data["plans-by-item"]).map((extId) => {
      priceData[extId] = {};
      Object.keys(data["plans-by-item"][extId]).map((usecaseName) => {
        const prices = data["plans-by-item"][extId][usecaseName];
        priceData[extId][usecaseName] = {};
        for(var i=0; i<aoiData[extId][usecaseName].length; i++) {
          const planName = aoiData[extId][usecaseName][i].plan_type ?? "";
          priceData[extId][usecaseName][planName] = prices[i];
          dbService.storeAOIPrice(db, mkey, extId, usecaseName, usecaseNameVersion[usecaseName], planName, prices[i]);
        }
      });
    });
    return priceData;
  });
};

const getAoiPricingForSubscriptions = (db, mkey, region, aoi, domainUsecases, allUsecases, callback) => {
  const subscriptionChoices = usecaseService.getSubscriptionChoices(domainUsecases, allUsecases);
  if(subscriptionChoices == null) {
    callback({});
    return;
  }

  getAoiData(db, mkey, region, allUsecases, {[aoi.ext_id]: subscriptionChoices}, (data) => {
    callback(data[aoi.ext_id]);
  });
};

const getAllAOIPricesFromDB = (db, mkey, region, aoiData, usecases, callback) => {
  dbService.getAOIPrice(db, mkey, (dbdata) => {
    var toDeleteDBAOIs = dbdata.filter((dbaoi) => dbaoi.expires < new Date()).map((aoi) => aoi.ext_id);
    dbService.deleteAOIPrice(db, toDeleteDBAOIs);
    var planPrice = {};
    var nodata_aois = {};
    Object.keys(aoiData).map((ext_id) => {
      planPrice[ext_id] = {};
      let usecaseNames = Object.keys(aoiData[ext_id]);
      usecaseNames.map((usecaseName) => {
        planPrice[ext_id][usecaseName] = {};
        let plans = aoiData[ext_id][usecaseName];
        plans.map((plan) => {
          let planType = plan.plan_type ?? "";
          var priceData = dbdata.filter((dbaoi) => dbaoi.ext_id == ext_id && dbaoi.usecaseName == usecaseName && dbaoi.usecaseVersion == usecases[usecaseName] && dbaoi.plan == planType);
          if(priceData == null || priceData.length == 0) {
            if(nodata_aois[ext_id] == null)
              nodata_aois[ext_id] = {};
            if(nodata_aois[ext_id][usecaseName] == null)
              nodata_aois[ext_id][usecaseName] = [];
            if(planType != "")
              nodata_aois[ext_id][usecaseName].push({"plan_type": plan.plan_type});
            else
              nodata_aois[ext_id][usecaseName].push({});
          } else {
              planPrice[ext_id][usecaseName][planType] = priceData[0].tokenPrice;
          }
        });
      });
    });

    if(Object.keys(nodata_aois).length > 0) {
      getAOIPriceFromAPI(db, mkey, region, nodata_aois, usecases).then((data) => {
        Object.keys(data).map((ext_id) => {
          if(planPrice[ext_id] == null)
            planPrice[ext_id] = {};
          Object.keys(data[ext_id]).map((usecaseName) => {
            if(planPrice[ext_id][usecaseName] == null)
              planPrice[ext_id][usecaseName] = {};
            Object.keys(data[ext_id][usecaseName]).map((planName) => {
              planPrice[ext_id][usecaseName][planName] = data[ext_id][usecaseName][planName];
            });
          });
        });
        callback(planPrice);
      });
    } else {
      callback(planPrice);
    }
  });
};

const getAoiData = (db, mkey, region, availableUsecases, aoiData, callback) => {
  const usecases = {};
  availableUsecases.map((usecase) => {
    if(usecase.price)
      usecases[usecase.name] = usecase.price.version;
  });
  getAllAOIPricesFromDB(db, mkey, region, aoiData, usecases, (data) => {
    callback(data);
  });
};

const getPricesForSubscribedAOIs = (db, mkey, region, aois, availableUsecases, callback) => {
  let subscribedAois = aois.filter((aoi) => aoi.subscribed_usecases != null);
  let aoiData = {};
  subscribedAois.map((aoi) => {
    let usecases = {};
    aoi.subscribed_usecases.map((sub) => {
      if(sub.subscription_details != null) {
        usecases[sub.usecase.name] = [sub.subscription_details];
      } else {
        usecases[sub.usecase.name] = [{}];
      }
    });
    aoiData[aoi.ext_id] = usecases;
  });
  getAoiData(db, mkey, region, availableUsecases, aoiData, (data) => {
    var planPrice = {};
    Object.keys(data).map((extId) => {
      var aoi = subscribedAois.find((a) => a.ext_id == extId);
      planPrice[aoi.ext_id] = {};
      aoi?.subscribed_usecases.map((u) => {
        if(data[extId] && data[extId][u.usecase?.name])
          planPrice[extId][u.usecase.name] = data[extId][u.usecase.name][u.subscription_details?.plan_type ?? usecaseService.getDefaultPlanName(u.usecase.name)] ?? 0;
      });
    });
    callback(planPrice);
  });
};

export const aoiPricingUtil = {
  getAoiPricingForGridView,
  getAoiPricingForSubscriptions,
  getPricesForSubscribedAOIs
};