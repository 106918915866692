import { useContext, useEffect, useState } from "react";
import { getFileBlobUrl } from '../../Utils';
import FileUpload from "../../FileUpload";
import { aoiService } from "../../../Services/aoiService";
import { toast } from "react-hot-toast";
import { UserContext } from "../../../Contexts/UserContext";
import AOIPropertyDocuments from "./AOIPropertyDocuments";
import AOIPropertyImages from "./AOIPropertyImages";
import { useCallback } from "react";

const propertyDocumentsOptions = {
    id: 'prop-documents',
    restrictions: { 
        maxNumberOfFiles: 10,
        allowedFileTypes: ['.doc', '.docx', '.odt', '.csv', '.pdf'] 
    }
};

const propertyImageOptions = {
    id: 'prop-images',
    restrictions: { 
        maxNumberOfFiles: 10,
        allowedFileTypes: ['.jpg', '.jpeg', '.png','.tiff'] 
    }
};

const AOIPropertyFiles = (props) => {
    const { user, landuse } = useContext(UserContext);
    const [expand, setExpand] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState({ data: [], meta: [], files: {} });
    const [inProgress, setInProgress] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteFileName, setDeleteFileName] = useState("");
    const [deleteFilePath, setDeleteFilePath] = useState("");

    const attribute = props.isImages ? "property-images" : "property-documents";
    const fileType = props.isImages ? "Image" : "Document";
    const files = props.aoi?.attributes?.member_landuse_attributes != null ? props.aoi?.attributes?.member_landuse_attributes[attribute] : null;

    useEffect(() => {
        if (Object.keys(uploadedFiles.files).length > 0) {
            setInProgress(true);
            saveFiles(getRequest(), "Successfully uploaded the " + fileType);
        }
    }, [uploadedFiles]);

    const getRequest = () => {
        let request = {
            aoiAttributes: {
                landuse: landuse?.name,
                member_landuse_attributes: props.aoi?.attributes?.member_landuse_attributes ?? {},
                notes: props.aoi?.attributes?.notes
            },
            uploadedFiles: {
                [attribute]: uploadedFiles.files
            }
        };
        if(request.aoiAttributes.member_landuse_attributes[attribute] != null)
            request.aoiAttributes.member_landuse_attributes[attribute] = [...request.aoiAttributes.member_landuse_attributes[attribute], ...uploadedFiles.meta];
        else 
            request.aoiAttributes.member_landuse_attributes[attribute] = uploadedFiles.meta;
        return request;
    };

    const deleteFile = (filePath) => {
        let request = {
            aoiAttributes: {
                landuse: landuse?.name,
                member_landuse_attributes: props.aoi?.attributes?.member_landuse_attributes ?? {},
                notes: props.aoi?.attributes?.notes
            }
        };
        request.aoiAttributes.member_landuse_attributes[attribute] = request.aoiAttributes.member_landuse_attributes[attribute].filter(item => item.filepath != filePath);
        setConfirmDelete(false);
        saveFiles(request, "Successfully deleted the " + fileType);
    };

    const confirmDeleteFile = (filePath, fileName) => {
        setDeleteFilePath(filePath);
        setDeleteFileName(fileName);
        setConfirmDelete(true);
    };

    const saveFiles = (request, message) => {
        aoiService.attributes(user.mkey, user.region, props.aoi?.member_aoi_id, request)
        .then((data) => { 
            props.onSave();
            setInProgress(false);
            setUploadedFiles({ data: [], meta: [], files: {}});
            toast.success(message);
        })
        .catch(error => {
            setInProgress(false);
            console.log('Error in Saving Attributes', error, request);
            toast.error('Failed to Save ' + fileType + '! Try Again.');
        });
    };

    const thumbnailRef = useCallback(async ref => {
        if (ref !== null) {
            const filepath = ref.attributes['attr-filepath'].value;
            const isImageFile = ref.attributes['attr-isimage'].value == 'true';
            if (isImageFile && !!filepath) {
                const blobUrl = await getFileBlobUrl(filepath);
                if (!!blobUrl)
                    ref.src = blobUrl;
            }
        }
    }, []);

    return <div className={`aoi-detail-box ${expand ? "expanded" : "collapsed"}`}>
                <div className="aoi-detail-header" onClick={() => setExpand(!expand)}>Property {fileType}s
                </div>
                <div className="aoi-detail-content">
                    <div className="row">
                        <div className="col-12">
                            {
                                props.isImages 
                                ? <AOIPropertyImages files={files} deleteFile={confirmDeleteFile} thumbnailRef={thumbnailRef} />
                                : <AOIPropertyDocuments files={files} deleteFile={confirmDeleteFile} thumbnailRef={thumbnailRef} />
                            }
                        </div>
                    </div>
                    {
                        confirmDelete == true &&
                        <div className="delete-aoi-panel">
                            <div className='confirmation-block'>
                                <h2>Are you sure you want to delete the {fileType} {deleteFileName}?</h2>
                                <div>
                                    <a href="#" className="btn-yes" onClick={() => deleteFile(deleteFilePath)}>YES</a>
                                    <a href="#" className="btn-no" onClick={() => setConfirmDelete(false)}>NO</a>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        {
                            user.isReadOnly == false && inProgress == false &&
                            <FileUpload options={props.isImages ? propertyImageOptions : propertyDocumentsOptions}
                                uploadedFiles={uploadedFiles} 
                                isPropertyImages={false}
                                setUploadedFiles={setUploadedFiles} 
                            />
                        }
                    </div>
                </div>
            </div>;

};

export default AOIPropertyFiles;