import UserProfileLeftNav from "../Components/UserProfile/UserProfileLeftNav";
import React, { useContext } from 'react';
import Profile from '../Components/UserProfile/Profile';
import Settings from "../Components/UserProfile/Settings";
import Configuration from "../Components/UserProfile/Configuration";
import Datasource from "../Components/UserProfile/Datasource";
import Landuse from "../Components/UserProfile/Landuse";
import Usecase from "../Components/UserProfile/Usecase";
import Roles from "../Components/UserProfile/Roles";
import Members from "../Components/UserProfile/Members";
import DomainConfig from "../Components/UserProfile/DomainConfig";
import SendMessageAdmin from "../Components/UserProfile/SendMessageAdmin";
import Help from "../Components/UserProfile/Help";
import MFA from "../Components/UserProfile/MFA";
import BillingSubscriptions from "../Components/UserProfile/BillingSubscriptions";
import AddMember from "../Components/UserProfile/AddMember";
import DACockpit from "../Components/UserProfile/DACockpit/DACockpit";
import CSInsights from "../Components/UserProfile/CSInsights/CSInsights";
import UsecaseEdit from "../Components/UserProfile/UsecaseEdit";
import { useParams } from "react-router";
import ClearCache from "../Components/UserProfile/ClearCache";
import { UserContext } from "../Contexts/UserContext";
import ManageEvents from "../Components/UserProfile/ManageEvents";

const UserProfile = (props) => {
    const { user } = useContext(UserContext);
    let { name } = useParams();
    const getLargeWidthClass = (section) => {
        switch(section) {
            case "da-cockpit":
            case "cs-insights":
            case "usecaseEdit":
            case "usecaseAdd":
            case "members":
                return "large-width";
            default:
                return "";
        }
    };

    const getRightSide = (section) => {
        switch(section) {
            case "profile":
                return <Profile user={user} isAdmin={false} createUser={false}></Profile>;
            case "settings":
                return <Settings></Settings>;
            case "manageevents":
                return <ManageEvents></ManageEvents>;
            case "addmember":
                return <Profile user={null} isAdmin={true} createUser={true}></Profile>;
            case "members":
                return <Members></Members>;
            case "clearcache":
                return <ClearCache></ClearCache>;

            case "mfa":
                return <MFA></MFA>;
            case "billing":
                return <BillingSubscriptions section="billing" role="user"></BillingSubscriptions>;
            case "subscriptions":
                return <BillingSubscriptions section="subscriptions" role="user"></BillingSubscriptions>;
            case "help":
                return <Help></Help>;
            case "configuration":
                return <Configuration></Configuration>;
            case "domainconfig":
                return <DomainConfig></DomainConfig>;
            case "datasource":
                return <Datasource></Datasource>;
            case "landuse":
                return <Landuse></Landuse>;
            case "usecaseAdd":
                return <UsecaseEdit></UsecaseEdit>;
            case "usecaseEdit":
                return <UsecaseEdit name={name}></UsecaseEdit>;
            case "usecase":
                return <Usecase></Usecase>;
            case "roles":
                return <Roles></Roles>;
            case "send-message-admin":
                return <SendMessageAdmin></SendMessageAdmin>;
            case "da-cockpit":
                return <DACockpit></DACockpit>;
            case "cs-insights":
                return <CSInsights></CSInsights>;

        }
        return <div>Page Not Found</div>;
    }

    return (
        <div className={`page-body admin-page ${getLargeWidthClass(props.section)}`}>
            <div className="container">
                <div className="two-column">
                    <UserProfileLeftNav section={props.section}/>
                    <div className="right-col">
                        {
                            getRightSide(props.section)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UserProfile;