import { useContext, useEffect, useRef, useState } from 'react';
import TimeoutIcon from '../Assets/img/icon-timeout.png';
import { userService } from '../Services/userService';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';
import { UserContext } from '../Contexts/UserContext';

const LogoutTimer = (props) => {
    const navigate = useNavigate();
    const [showTimer, setShowTimer] = useState(false);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState("0");
    const [time, setTime] = useState(new Date(props.time));
    const timerRef = useRef(null);
    const expiryTimeRef = useRef(time);
    const { signOut } = useContext(UserContext);

    const countDownTime = 600000;

    useEffect(() => {
        expiryTimeRef.current = time;
        return runTimer();
    }, [props, time]);

    const runTimer = () => {
        const countdown = expiryTimeRef.current - new Date();
        if(countdown > countDownTime) {
            setShowTimer(false);
            timerRef.current = setTimeout(() => {
                runCountdownTimer();
            }, countdown - countDownTime);
            return () => clearTimeout(timerRef.current);
        } else {
            runCountdownTimer();
            return () => clearTimeout(timerRef.current);
        }
    };

    const runCountdownTimer = () => {
        timerRef.current = setTimeout(() => {
            updateTime();
        }, 1000);
    };

    const updateTime = () => {
        const countdown = expiryTimeRef.current - new Date();
        if(countdown <= 0) {
            setShowTimer(false);
            signOut();
            navigate("/");
            return;
        }
        setMinutes(parseInt(countdown/1000/60));
        const sec = parseInt((countdown/1000)%60);
        setSeconds(sec < 10 ? "0" + sec : sec + "");
        setShowTimer(true);
        runCountdownTimer();
    };

    const extendSession = () => {
        userService.extendSession().then((result) => {
          window.authToken = result.accessToken;
          window.expiryTime = result.expiryTime;
          setTime(new Date(result.expiryTime));
          Cookies.remove("authtoken");
          Cookies.remove("expiryTime");
          localStorage.removeItem("authtoken");
          localStorage.removeItem("expiryTime");
          Cookies.set("authtoken", result.accessToken);
          Cookies.set("expiryTime", result.expiryTime);
          localStorage.setItem("authtoken", result.accessToken);
          localStorage.setItem("expiryTime", result.expiryTime);
          toast.success("Extended your session");
        }).catch((e) => {
          console.log(e);
          toast.error("Unable to extend the session. Please logout and login again");
        });
    };

    return showTimer && <div className="alert alert-danger logout-alert" role="alert">
                            <img src={TimeoutIcon} alt="" />
                            You would be logged out in <span className="timer">{minutes}:{seconds}</span> minutes. 
                            <a className="test" onClick={extendSession}>Click Here</a> to extend the session.
                        </div>;
}

export default LogoutTimer;